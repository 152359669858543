import {
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import { types } from '../../actions/order';

import {
  getOrders,
  getUnverifiedOrders,
  getPartialOrders,
  getOrder,
  createOrder,
  assignOrder,
  reAssignOrder,
  cancelOrder,
  getOrdersStates,
  getOrdersScanTypes,
  getFilteredOrders,
  getFilteredUnverifiedOrders,
  getFilteredPartialOrders,
  getOrderStats,
  getDashboardOrders,
  rescheduleOrder,
  getOrderActivities,
  getHearAboutUsList,
  getProblemStrings,
  getOrderTimeSlots,
  orderNeedsReview,
  getLateOrders,
  emailOrdersCsv,
  getOnTimeRateStatsSaga,
  offlineInProgressSaga,
  deliverOrderSaga,
  verifyExternalOrderSaga,
  followUpOrderSaga,
  updateAdditionalNotesSaga,
  updateScanTypesSaga,
  getCancellationStrings,
  getOrderSourcesSaga,
  getReassignmentStringsSaga,
  orderFollowUpAddressedSaga,
  getAddressTypesSaga,
  getRescheduleStringsSaga,
  getPartialOrderDetail,
  cancelPartialOrder,
} from './order';

export function* orderSaga() {
  yield takeLatest(types.GET_ORDERS_REQUEST, getOrders);
  yield takeLatest(types.GET_UNVERIFIED_ORDERS_REQUEST, getUnverifiedOrders);
  yield takeLatest(types.GET_PARTIAL_ORDERS_REQUEST, getPartialOrders);
  yield takeLatest(types.GET_PARTIAL_ORDER_DETAIL_REQUEST, getPartialOrderDetail);
  yield takeLatest(types.CANCEL_PARTIAL_ORDERS_REQUEST, cancelPartialOrder);
  yield takeEvery(types.GET_FILTERED_ORDERS_REQUEST, getFilteredOrders);
  yield takeEvery(types.GET_FILTERED_UNVERIFIED_ORDERS_REQUEST, getFilteredUnverifiedOrders);
  yield takeEvery(types.GET_FILTERED_PARTIAL_ORDERS_REQUEST, getFilteredPartialOrders);
  yield takeEvery(types.GET_DASHBOARD_ORDERS_REQUEST, getDashboardOrders);
  yield takeLatest(types.GET_ORDER_SCAN_TYPES_REQUEST, getOrdersScanTypes);
  yield takeEvery(types.GET_ORDER_REQUEST, getOrder);
  yield takeEvery(types.GET_ORDERS_STATES_REQUEST, getOrdersStates);
  yield takeEvery(types.CREATE_ORDER_REQUEST, createOrder);
  yield takeEvery(types.ASSIGN_ORDER_REQUEST, assignOrder);
  yield takeEvery(types.REASSIGN_ORDER_REQUEST, reAssignOrder);
  yield takeEvery(types.CANCEL_ORDER_REQUEST, cancelOrder);
  yield takeEvery(types.GET_ORDER_STATS_REQUEST, getOrderStats);
  yield takeEvery(types.RESCHEDULE_ORDER_REQUEST, rescheduleOrder);
  yield takeEvery(types.GET_ORDER_ACTIVITIES_REQUEST, getOrderActivities);
  yield takeEvery(types.GET_HEAR_ABOUT_US_LIST_REQUEST, getHearAboutUsList);
  yield takeEvery(types.GET_PROBLEM_STRINGS_REQUEST, getProblemStrings);
  yield takeEvery(types.GET_CANCELLATION_STRINGS_REQUEST, getCancellationStrings);
  yield takeEvery(types.GET_ORDER_TIME_SLOTS_REQUEST, getOrderTimeSlots);
  yield takeEvery(types.ORDER_NEEDS_REVIEW_REQUEST, orderNeedsReview);
  yield takeEvery(types.GET_LATE_ORDERS_REQUEST, getLateOrders);
  yield takeEvery(types.EMAIL_ORDERS_CSV_REQUEST, emailOrdersCsv);
  yield takeEvery(types.GET_ON_TIME_RATE_STATS_REQUEST, getOnTimeRateStatsSaga);
  yield takeEvery(types.OFFLINE_IN_PROGRESS_REQUEST, offlineInProgressSaga);
  yield takeEvery(types.DELIVER_ORDER_REQUEST, deliverOrderSaga);
  yield takeEvery(types.VERIFY_EXTERNAL_ORDER_REQUEST, verifyExternalOrderSaga);
  yield takeEvery(types.FOLLOW_UP_ORDER_REQUEST, followUpOrderSaga);
  yield takeEvery(types.ORDER_UPDATE_ADDITIONAL_NOTES_REQUEST, updateAdditionalNotesSaga);
  yield takeEvery(types.ORDER_UPDATE_SCAN_TYPES_REQUEST, updateScanTypesSaga);
  yield takeEvery(types.GET_ORDER_SOURCES_REQUEST, getOrderSourcesSaga);
  yield takeEvery(types.GET_REASSIGNMENT_STRINGS_REQUEST, getReassignmentStringsSaga);
  yield takeEvery(types.ORDER_FOLLOW_UP_ADDRESSED_REQUEST, orderFollowUpAddressedSaga);
  yield takeEvery(types.GET_ADDRESS_TYPES_REQUEST, getAddressTypesSaga);
  yield takeEvery(types.GET_RESCHEDULE_STRINGS_REQUEST, getRescheduleStringsSaga);
}
