import * as types from './types';
import {
  payloadActionCreator,
  emptyActionCreator,
} from '../../utils/actions';

export const getOrderRequest = payloadActionCreator(types.GET_ORDER_REQUEST);
export const getOrderSuccess = payloadActionCreator(types.GET_ORDER_SUCCESS);
export const getOrderFailure = payloadActionCreator(types.GET_ORDER_FAILURE);

export const orderScanTypesRequest = payloadActionCreator(types.GET_ORDER_SCAN_TYPES_REQUEST);
export const orderScanTypesSuccess = payloadActionCreator(types.GET_ORDER_SCAN_TYPES_SUCCESS);
export const orderScanTypesFailure = payloadActionCreator(types.GET_ORDER_SCAN_TYPES_FAILURE);

export const getOrdersRequest = payloadActionCreator(types.GET_ORDERS_REQUEST);
export const getOrdersSuccess = payloadActionCreator(types.GET_ORDERS_SUCCESS);
export const getOrdersFailure = payloadActionCreator(types.GET_ORDERS_FAILURE);

export const getUnverifiedOrdersRequest = payloadActionCreator(types.GET_UNVERIFIED_ORDERS_REQUEST);
export const getUnverifiedOrdersSuccess = payloadActionCreator(types.GET_UNVERIFIED_ORDERS_SUCCESS);
export const getUnverifiedOrdersFailure = payloadActionCreator(types.GET_UNVERIFIED_ORDERS_FAILURE);

export const getPartialOrdersRequest = payloadActionCreator(types.GET_PARTIAL_ORDERS_REQUEST);
export const getPartialOrdersSuccess = payloadActionCreator(types.GET_PARTIAL_ORDERS_SUCCESS);
export const getPartialOrdersFailure = payloadActionCreator(types.GET_PARTIAL_ORDERS_FAILURE);

export const getPartialOrderDetailRequest = payloadActionCreator(types.GET_PARTIAL_ORDER_DETAIL_REQUEST);
export const getPartialOrderDetailSuccess = payloadActionCreator(types.GET_PARTIAL_ORDER_DETAIL_SUCCESS);
export const getPartialOrderDetailFailure = payloadActionCreator(types.GET_PARTIAL_ORDER_DETAIL_FAILURE);

export const cancelPartialOrdersRequest = payloadActionCreator(types.CANCEL_PARTIAL_ORDERS_REQUEST);
export const cancelPartialOrdersSuccess = payloadActionCreator(types.CANCEL_PARTIAL_ORDERS_SUCCESS);
export const cancelPartialOrdersFailure = payloadActionCreator(types.CANCEL_PARTIAL_ORDERS_FAILURE);

export const getFilteredOrdersRequest = payloadActionCreator(types.GET_FILTERED_ORDERS_REQUEST);
export const getFilteredOrdersSuccess = payloadActionCreator(types.GET_FILTERED_ORDERS_SUCCESS);
export const getFilteredOrdersFailure = payloadActionCreator(types.GET_FILTERED_ORDERS_FAILURE);

export const getFilteredUnverifiedOrdersRequest = payloadActionCreator(types.GET_FILTERED_UNVERIFIED_ORDERS_REQUEST);
export const getFilteredUnverifiedOrdersSuccess = payloadActionCreator(types.GET_FILTERED_UNVERIFIED_ORDERS_SUCCESS);
export const getFilteredUnverifiedOrdersFailure = payloadActionCreator(types.GET_FILTERED_UNVERIFIED_ORDERS_FAILURE);

export const getFilteredPartialOrdersRequest = payloadActionCreator(types.GET_FILTERED_PARTIAL_ORDERS_REQUEST);
export const getFilteredPartialOrdersSuccess = payloadActionCreator(types.GET_FILTERED_PARTIAL_ORDERS_SUCCESS);
export const getFilteredPartialOrdersFailure = payloadActionCreator(types.GET_FILTERED_PARTIAL_ORDERS_FAILURE);;

export const getDashboardOrdersRequest = payloadActionCreator(types.GET_DASHBOARD_ORDERS_REQUEST);
export const getDashboardOrdersSuccess = payloadActionCreator(types.GET_DASHBOARD_ORDERS_SUCCESS);
export const getDashboardOrdersFailure = payloadActionCreator(types.GET_DASHBOARD_ORDERS_FAILURE);

export const getOrdersStatesRequest = payloadActionCreator(types.GET_ORDERS_STATES_REQUEST);
export const getOrdersStatesSuccess = payloadActionCreator(types.GET_ORDERS_STATES_SUCCESS);
export const getOrdersStatesFailure = payloadActionCreator(types.GET_ORDERS_STATES_FAILURE);

export const createOrderRequest = payloadActionCreator(types.CREATE_ORDER_REQUEST);
export const createOrderSuccess = payloadActionCreator(types.CREATE_ORDER_SUCCESS);
export const createOrderFailure = payloadActionCreator(types.CREATE_ORDER_FAILURE);

export const assignOrderRequest = payloadActionCreator(types.ASSIGN_ORDER_REQUEST);
export const assignOrderSuccess = payloadActionCreator(types.ASSIGN_ORDER_SUCCESS);
export const assignOrderFailure = payloadActionCreator(types.ASSIGN_ORDER_FAILURE);

export const reAssignOrderRequest = payloadActionCreator(types.REASSIGN_ORDER_REQUEST);
export const reAssignOrderSuccess = payloadActionCreator(types.REASSIGN_ORDER_SUCCESS);
export const reAssignOrderFailure = payloadActionCreator(types.REASSIGN_ORDER_FAILURE);

export const cancelOrderRequest = payloadActionCreator(types.CANCEL_ORDER_REQUEST);
export const cancelOrderSuccess = payloadActionCreator(types.CANCEL_ORDER_SUCCESS);
export const cancelOrderFailure = payloadActionCreator(types.CANCEL_ORDER_FAILURE);

export const getOrderStatsRequest = payloadActionCreator(types.GET_ORDER_STATS_REQUEST);
export const getOrderStatsSuccess = payloadActionCreator(types.GET_ORDER_STATS_SUCCESS);
export const getOrderStatsFailure = payloadActionCreator(types.GET_ORDER_STATS_FAILURE);

export const resetOrderState = emptyActionCreator(types.RESET_ORDER_STATE);
export const resetScanTypesState = emptyActionCreator(types.RESET_SCAN_TYPES_STATE);
export const updateOrderParams = payloadActionCreator(types.UPDATE_ORDER_PARAMS);

export const rescheduleOrderRequest = payloadActionCreator(types.RESCHEDULE_ORDER_REQUEST);
export const rescheduleOrderSuccess = payloadActionCreator(types.RESCHEDULE_ORDER_SUCCESS);
export const rescheduleOrderFailure = payloadActionCreator(types.RESCHEDULE_ORDER_FAILURE);

export const getOrderActivitiesRequest = payloadActionCreator(types.GET_ORDER_ACTIVITIES_REQUEST);
export const getOrderActivitiesSuccess = payloadActionCreator(types.GET_ORDER_ACTIVITIES_SUCCESS);
export const getOrderActivitiesFailure = payloadActionCreator(types.GET_ORDER_ACTIVITIES_FAILURE);
export const resetOrderActivitiesState = emptyActionCreator(types.RESET_ORDER_ACTIVITIES_STATE);

export const getHearAboutUsListRequest = payloadActionCreator(types.GET_HEAR_ABOUT_US_LIST_REQUEST);
export const getHearAboutUsListSuccess = payloadActionCreator(types.GET_HEAR_ABOUT_US_LIST_SUCCESS);
export const getHearAboutUsListFailure = payloadActionCreator(types.GET_HEAR_ABOUT_US_LIST_FAILURE);

export const getProblemStringsRequest = payloadActionCreator(types.GET_PROBLEM_STRINGS_REQUEST);
export const getProblemStringsSuccess = payloadActionCreator(types.GET_PROBLEM_STRINGS_SUCCESS);
export const getProblemStringsFailure = payloadActionCreator(types.GET_PROBLEM_STRINGS_FAILURE);

export const getCancellationStringsRequest = payloadActionCreator(types.GET_CANCELLATION_STRINGS_REQUEST);
export const getCancellationStringsSuccess = payloadActionCreator(types.GET_CANCELLATION_STRINGS_SUCCESS);
export const getCancellationStringsFailure = payloadActionCreator(types.GET_CANCELLATION_STRINGS_FAILURE);

export const getOrderTimeSlotsRequest = payloadActionCreator(types.GET_ORDER_TIME_SLOTS_REQUEST);
export const getOrderTimeSlotsSuccess = payloadActionCreator(types.GET_ORDER_TIME_SLOTS_SUCCESS);
export const getOrderTimeSlotsFailure = payloadActionCreator(types.GET_ORDER_TIME_SLOTS_FAILURE);
export const resetOrderTimeSlotsState = emptyActionCreator(types.RESET_ORDER_TIME_SLOTS_STATE);

export const copyOrder = payloadActionCreator(types.COPY_ORDER);
export const resetCopyOrder = emptyActionCreator(types.RESET_COPY_ORDER);

export const orderNeedsReviewRequest = payloadActionCreator(types.ORDER_NEEDS_REVIEW_REQUEST);
export const orderNeedsReviewSuccess = payloadActionCreator(types.ORDER_NEEDS_REVIEW_SUCCESS);
export const orderNeedsReviewFailure = payloadActionCreator(types.ORDER_NEEDS_REVIEW_FAILURE);

export const getLateOrdersRequest = payloadActionCreator(types.GET_LATE_ORDERS_REQUEST);
export const getLateOrdersSuccess = payloadActionCreator(types.GET_LATE_ORDERS_SUCCESS);
export const getLateOrdersFailure = payloadActionCreator(types.GET_LATE_ORDERS_FAILURE);

export const emailOrdersCsvRequest = payloadActionCreator(types.EMAIL_ORDERS_CSV_REQUEST);
export const emailOrdersCsvSuccess = payloadActionCreator(types.EMAIL_ORDERS_CSV_SUCCESS);
export const emailOrdersCsvFailure = payloadActionCreator(types.EMAIL_ORDERS_CSV_FAILURE);

export const getOnTimeRateStatsRequest = payloadActionCreator(types.GET_ON_TIME_RATE_STATS_REQUEST);
export const getOnTimeRateStatsFailure = payloadActionCreator(types.GET_ON_TIME_RATE_STATS_FAILURE);
export const getOnTimeRateStatsSuccess = payloadActionCreator(types.GET_ON_TIME_RATE_STATS_SUCCESS);

export const offlineInProgressRequest = payloadActionCreator(types.OFFLINE_IN_PROGRESS_REQUEST);
export const offlineInProgressSuccess = payloadActionCreator(types.OFFLINE_IN_PROGRESS_SUCCESS);
export const offlineInProgressFailure = payloadActionCreator(types.OFFLINE_IN_PROGRESS_FAILURE);

export const deliverOrderRequest = payloadActionCreator(types.DELIVER_ORDER_REQUEST);
export const deliverOrderSuccess = payloadActionCreator(types.DELIVER_ORDER_SUCCESS);
export const deliverOrderFailure = payloadActionCreator(types.DELIVER_ORDER_FAILURE);

export const verifyExternalOrderRequest = payloadActionCreator(types.VERIFY_EXTERNAL_ORDER_REQUEST);
export const verifyExternalOrderSuccess = payloadActionCreator(types.VERIFY_EXTERNAL_ORDER_SUCCESS);
export const verifyExternalOrderFailure = payloadActionCreator(types.VERIFY_EXTERNAL_ORDER_FAILURE);

export const updateExternalOrderParams = payloadActionCreator(types.UPDATE_EXTERNAL_ORDER_PARAMS);

export const followUpOrderRequest = payloadActionCreator(types.FOLLOW_UP_ORDER_REQUEST);
export const followUpOrderSuccess = payloadActionCreator(types.FOLLOW_UP_ORDER_SUCCESS);
export const followUpOrderFailure = payloadActionCreator(types.FOLLOW_UP_ORDER_FAILURE);

export const orderUpdateAdditionalNotesRequest = payloadActionCreator(types.ORDER_UPDATE_ADDITIONAL_NOTES_REQUEST);
export const orderUpdateAdditionalNotesSuccess = payloadActionCreator(types.ORDER_UPDATE_ADDITIONAL_NOTES_SUCCESS);
export const orderUpdateAdditionalNotesFailure = payloadActionCreator(types.ORDER_UPDATE_ADDITIONAL_NOTES_FAILURE);

export const orderUpdateScanTypesRequest = payloadActionCreator(types.ORDER_UPDATE_SCAN_TYPES_REQUEST);
export const orderUpdateScanTypesSuccess = payloadActionCreator(types.ORDER_UPDATE_SCAN_TYPES_SUCCESS);
export const orderUpdateScanTypesFailure = payloadActionCreator(types.ORDER_UPDATE_SCAN_TYPES_FAILURE);

export const getOrderSourcesRequest = payloadActionCreator(types.GET_ORDER_SOURCES_REQUEST);
export const getOrderSourcesSuccess = payloadActionCreator(types.GET_ORDER_SOURCES_SUCCESS);
export const getOrderSourcesFailure = payloadActionCreator(types.GET_ORDER_SOURCES_FAILURE);

export const getReassignmentStringsRequest = payloadActionCreator(types.GET_REASSIGNMENT_STRINGS_REQUEST);
export const getReassignmentStringsSuccess = payloadActionCreator(types.GET_REASSIGNMENT_STRINGS_SUCCESS);
export const getReassignmentStringsFailure = payloadActionCreator(types.GET_REASSIGNMENT_STRINGS_FAILURE);

export const orderFollowUpAddressedRequest = payloadActionCreator(types.ORDER_FOLLOW_UP_ADDRESSED_REQUEST);
export const orderFollowUpAddressedSuccess = payloadActionCreator(types.ORDER_FOLLOW_UP_ADDRESSED_SUCCESS);
export const orderFollowUpAddressedFailure = payloadActionCreator(types.ORDER_FOLLOW_UP_ADDRESSED_FAILURE);

export const getAddressTypesRequest = payloadActionCreator(types.GET_ADDRESS_TYPES_REQUEST);
export const getAddressTypesSuccess = payloadActionCreator(types.GET_ADDRESS_TYPES_SUCCESS);
export const getAddressTypesFailure = payloadActionCreator(types.GET_ADDRESS_TYPES_FAILURE);

export const getRescheduleStringsRequest = payloadActionCreator(types.GET_RESCHEDULE_STRINGS_REQUEST);
export const getRescheduleStringsSuccess = payloadActionCreator(types.GET_RESCHEDULE_STRINGS_SUCCESS);
export const getRescheduleStringsFailure = payloadActionCreator(types.GET_RESCHEDULE_STRINGS_FAILURE);
