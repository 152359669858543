import React from 'react';
import { getIn } from 'formik';
import { ErrorField } from '../error-field';
import { object, shape, string } from 'prop-types';
import { MuiPickersUtilsProvider, TimePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export const TimeFieldWithError = (
  {
    field,
    form,
    ...props
  }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return (
    <React.Fragment>
      <div className="date-picker">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            format="hh:mm a"
            placeholder="HH:MM AM/PM"
            {...field}
            {...props}/>
        </MuiPickersUtilsProvider>
      </div>
    </React.Fragment>
  );
};

TimeFieldWithError.propTypes = {
  field: shape({
    name: string,
    value: string,
  }).isRequired,
  form: object,
};
