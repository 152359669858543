import React from 'react';
import rattingIcon from '../../assets/images/ratting-icon.svg'
import Rating from './rating';

const OrderReviewPopup = (props) => {
  const { orderReview, closeModal } = props;

  return (
    <div className="lightbox">
      <div className="feedback-lightbox">
        <a
          href=''
          className="lightbox-close icon-clear"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        />
        <div className="feedback-left">
          <div className="lft-holder">
            <img src={rattingIcon} alt="rattingIcon"/>
            <span className="title">Customer Feedback</span>
          </div>
        </div>
        <div className="feedback-right">
          <h2>{orderReview.order}</h2>
          <div className="ratting-holder">
            <div className="ratting-row">
              <span className="title">Overall</span>
              <div className="right">
                <Rating rating={orderReview.overallStarRating}/>
                <span className="point">{orderReview.overallStarRating | '0'}</span>
              </div>
            </div>
            <div className="ratting-row">
              <span className="title">On Time Pickup</span>
              <div className="right">
                <Rating rating={orderReview.onTimeStarRating}/>
                <span className="point">{orderReview.onTimeStarRating}</span>
              </div>
            </div>
            <div className="ratting-row">
              <span className="title">Representative</span>
              <div className="right">
                <Rating rating={orderReview.riderStarRating}/>
                <span className="point">{orderReview.riderStarRating}</span>
              </div>
            </div>
          </div>
          <span className="note">
            {orderReview.feedbackText}
          </span>
        </div>
      </div>
    </div>
  )
};

export default OrderReviewPopup;

