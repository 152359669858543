import React from 'react';
import { getIn } from 'formik';
import { ErrorField } from 'components/ui/error-field';
import { shape, string, object } from 'prop-types';
import { TextFormField } from './text-form-field';

export const TextFormFieldWithError = ({
  field,
  form,
  ...props
}) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return (
    <React.Fragment>
      <TextFormField
        { ...field }
        { ...props }
      />
      { errorText && <ErrorField value={ errorText } /> }
    </React.Fragment>
  );
};

TextFormFieldWithError.propTypes = {
  field: shape({
    name: string,
    value: string,
  }).isRequired,
  form: object,
};
