import { startCase } from 'lodash';
export const MAX_STAR = 5;
export const FETCH_RIDER_LIVE_LOCATION_INTERVAL = 1000 * 5;
export const ORDER_DETAIL_RIDER_INTERVAL= 1000 * 20;
export const GOOGLE_API_KEY = 'AIzaSyBzgyG2xPdbBjBF7hQftTJoeyxBTHaIMpY';
export const DEFAULT_ORDER_PICKUP_TIME = 60;
export const MAX_ORDER_PICKUP_DAY = 60;
export const IS_SHOW_ORDERS = 'IS_SHOW_ORDERS';
export const IS_SHOW_CONNECTION_CENTERS = 'IS_SHOW_CONNECTION_CENTERS';
export const IS_SHOW_RIDERS = 'IS_SHOW_RIDERS';
export const IS_ORDERS_FILTER_OPEN = 'IS_ORDERS_FILTER_OPEN';
export const IS_CUSTOMERS_FILTER_OPEN = 'IS_CUSTOMERS_FILTER_OPEN';
export const IS_RIDERS_FILTER_OPEN = 'IS_RIDERS_FILTER_OPEN';
export const IS_SHOW_CELLS = 'IS_SHOW_CELLS';
export const IS_SHOW_BOUNDARY = 'IS_SHOW_BOUNDARY';
export const IS_SHOW_BLACK_LISTED_AREAS = 'IS_SHOW_BLACK_LISTED_AREAS';
export const TOKEN = 'TOKEN';
export const USER = 'USER';

export const ORDER_STATE = {
  NEEDS_ASSIGNMENT: 'needs assignment',
  SCHEDULED: 'scheduled',
  ASSIGNED: 'assigned',
  COLLECTED: 'collected',
  DELIVERED: 'delivered',
  PROBLEM: 'problem',
  CANCELLED: 'cancelled',
  UNVERIFIED: 'unverified'
};

export const RIDER_MARKER = 'RIDER_MARKER';
export const ORDER_MARKER = 'ORDER_MARKER';
export const ORDER_REACHED_MARKER = 'ORDER_REACHED_MARKER';
export const ORDER_COLLECTED_MARKER = 'ORDER_COLLECTED_MARKER';
export const COLLECTION_CENTER_MARKER = 'COLLECTION_CENTER_MARKER';
export const CELL_MARKER = 'CELL_MARKER';
export const CUSTOMER_MARKER = 'CUSTOMER_MARKER';
export const STATES_TO_CANCEL = ['scheduled', 'needs assignment', 'assigned', 'reached', 'problem', 'offline-in-progress', ORDER_STATE.UNVERIFIED];
export const STATES_TO_ASSIGN = ['problem', 'needs assignment'];
export const STATES_TO_REASSIGN = ['assigned'];
export const STATES_TO_DELIVER = ['offline-in-progress'];
export const STATES_TO_OFFLINE_IN_PROGRESS = ['scheduled'];
export const STATES_TO_RESCHEDULE = ['scheduled', 'needs assignment', 'assigned', 'reached', 'problem'];
export const STATES_TO_SHOW_RIDER = ['assigned', 'reached', 'collected', 'delivered',];
export const STATES_TO_SHOW_RIDER_ON_MAP = ['assigned', 'reached', 'collected',];
export const STATES_TO_SHOW_TIMER = ['delivered', 'cancelled', 'collected', ORDER_STATE.UNVERIFIED];
export const STATES_TO_VERIFY = [ORDER_STATE.UNVERIFIED]

export const SEARCH_ON_LETTER = 3;
export const SELECTED_REGION_DETAIL = 'SELECTED_REGION_DETAIL';

export const RIDER_STATES = [
  {'state': 'idle'},
  {'state': 'picking up has capacity'},
  {'state': 'picking up full'},
  {'state': 'delivering'},
];

export const RIDER_STATE = {
  IDLE: 0,
  PICKING_UP_HAS_CAPACITY: 1,
  PICKING_UP_FULL: 2,
  DELIVERING: 3,
};

export const RIDER_STATE_MAP = {
  'idle': 0,
  'picking up has capacity': 1,
  'picking up full': 2,
  'delivering': 3,
}

export const riderStates = Object.keys(RIDER_STATE).map((key)=> {
  return { id: RIDER_STATE[key], label: startCase(key.toLocaleLowerCase())}
});

export const RIDER_STATUS = {
  OFFLINE: 0,
  ONLINE: 1,
};
export const riderStatuses = Object.keys(RIDER_STATUS).map((key)=> {
  return { id: RIDER_STATUS[key], label: startCase(key.toLocaleLowerCase())}
});

export const WILDCARD_STATUS = {
  YES: 1,
  NO: 0,
};
export const wildcardStatuses = Object.keys(WILDCARD_STATUS).map((key)=> {
  return { id: WILDCARD_STATUS[key], label: startCase(key.toLocaleLowerCase())}
});

export const RIDER_DUTY_STATUS = {
  OFF: 0,
  ON: 1,
};
export const riderDutyStatuses = Object.keys(RIDER_DUTY_STATUS).map((key)=> {
  return { id: RIDER_DUTY_STATUS[key], label: startCase(key.toLocaleLowerCase())}
});

export const ORDER_NEEDS_REVIEW = {
  YES: 1,
  NO: 0,
};

export const orderNeedsReviews = Object.keys(ORDER_NEEDS_REVIEW).map((key)=> {
  return { id: ORDER_NEEDS_REVIEW[key], label: startCase(key.toLocaleLowerCase())}
});

export const BOOLEAN_FILTER = {
  YES: 1,
  NO: 0,
};

export const booleanFilters = Object.keys(BOOLEAN_FILTER).map((key)=> {
  return { id: BOOLEAN_FILTER[key], label: startCase(key.toLocaleLowerCase())}
});

export const RED_RIDER_MARKER_STATES = [RIDER_STATE.PICKING_UP_FULL, RIDER_STATE.DELIVERING,];
export const BUSY_RIDER_STATES = [RIDER_STATE.PICKING_UP_HAS_CAPACITY, RIDER_STATE.PICKING_UP_FULL, RIDER_STATE.DELIVERING];
export const IDLE_RIDER_STATES = [RIDER_STATE.IDLE];

export const PAKISTAN_LAT_LONG = {lat : 30.3753, lng :69.3451 };

export const USER_TYPE = {
  RIDER: 1,
  CUSTOMER: 2,
  STAFF: 3,
};

export const SORTING = {
  NONE: 1,
  ASCENDING: 2,
  DESCENDING: 3,
};

export const SORTING_PARAM = {
  CREATED: 'created',
  PICKUP: 'pickup',
  MODIFIED: 'modified',
};

export const defaultOrderListSorting =  {
  [SORTING_PARAM.CREATED]: SORTING.DESCENDING,
  [SORTING_PARAM.PICKUP]: SORTING.NONE,
  [SORTING_PARAM.MODIFIED]: SORTING.NONE,
};


export const RIDER_OFF_DUTY_API = {
  WILDCARD: 'WILDCARD',
  OFF_DUTY: 'OFF_DUTY',
  COVID: 'COVID',
};

export const PERMISSION = {
  CAN_ADD_RIDER: 'Can add rider',
  CAN_DOWNLOAD_ORDER_LISTING: 'Can download order listing',
  CAN_DOWNLOAD_ATTENDANCE_LISTING: 'Can download attendance listing',
  CAN_VIEW_SHIFT_SCHEDULE: 'Can view rider shift schedule',
  CAN_VIEW_RIDER_SHIFT: 'Can view rider shift',
  CAN_RESCHEDULE_ORDER: 'Can reschedule order',
  CAN_CHANGE_USER: 'Can change user',
  CAN_ASSIGN_ORDER: 'Can assign order',
  CAN_DELETE_PATIENT: 'Can delete patient',
  CAN_COPY_ORDER: 'Can copy order'
};

export const WEEKDAY = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
};

export const SHIFT_CREATE_ACTION = {
  SAVE: 'SAVE',
  SAVE_AND_ADD_RIDER: 'SAVE_AND_ADD_RIDER',
};

export const FEEDBACK_QUESTION_TYPES = {
  STAR_RATING: 'STAR_RATING',
  LONG_FORM_TEXT: 'LONG_FORM_TEXT'
}

export const FULFILLMENT_METHOD_TYPES = {
  ONLINE: 1,
  OFFLINE: 2
}

export const SHIFT_BREAK_TIMES = [
  {
    label: '15 mins',
    key: 1,
    value: 15
  },
  {
    label: '30 mins',
    key: 2,
    value: 30
  },
  {
    label: '45 mins',
    key: 3,
    value: 45
  },
  {
    label: '60 mins',
    key: 4,
    value: 60
  },
]

export const FULFILLMENT_METHOD_OPTIONS = [
  {
    label: 'Regular',
    value: FULFILLMENT_METHOD_TYPES.ONLINE,
    id: FULFILLMENT_METHOD_TYPES.ONLINE,
  },
  {
    label: 'Hospital',
    value: FULFILLMENT_METHOD_TYPES.OFFLINE,
    id: FULFILLMENT_METHOD_TYPES.OFFLINE,
  }
]

export const FOLLOW_UP_OPTIONS = [
  {
    label: '5+',
    value: 5,
    id: 5
  },
  {
    label: '4+',
    value: 4,
    id: 4
  },
  {
    label: '3+',
    value: 3,
    id: 3
  },
  {
    label: '2+',
    value: 2,
    id: 2
  },
  {
    label: '1+',
    value: 1,
    id: 1
  },
]

export const STAR_RATING = [
  'Very Bad',
  'Bad',
  'Okay',
  'Good',
  'Excellent'
]

export const SOMETHING_WENT_WRONG = 'Something went wrong!';

export const UNSAVED_SHIFT_RIDER_ERROR_MESSAGE = 'You have unsaved riders in this shift!'

export const REDASH_REPORTING_URL = 'https://redash.chughtailab.com/public/dashboards/zuPMTm5eJ9ptqq9vbiLJkUV40oVwwDDpYY11kpTs'

export const REDASH_CITY_PARAM = 'p_City'

export const REDASH_DATE_PARAM = 'p_Date'

export const MAX_FOLLOW_UP_COUNT = 5;

export const TIME_FORMAT = 'h:mm a'

export const DATE_FORMAT = 'D-M-YYYY'

export const CLICK_UP_RELEASE_NOTES_URL = 'https://doc.clickup.com/p/h/3717y-95/70e356e9481f01e';

export const CLICK_UP_FAQS_URL = 'https://doc.clickup.com/p/h/3717y-158/e29b53d68da1044';

export const ONE_SIGNAL_PUSH_GRANTED = 'granted';

export const ONE_SIGNAL_PLAYER_ID = 'ONE_SIGNAL_PLAYER_ID'

export const VERTICALS = {
  COMMON: 'COMMON',
  BLOOD: 'BLOOD',
  XRAY: 'X-RAY',
  blood: 'blood',
  xray: 'xray',
}

export const FILTER_TYPES = {
  NEEDS_REVIEW: 'needsReview',
  WAS_AUTO_ASSIGNED: 'wasAutoAssigned',
  WAS_MANUAL_ASSIGNED: 'wasManualAssigned',
  IS_COVID: 'isCovid',
  FOLLOW_UP: 'followUp',
  IS_FOLLOW_UP_ADDRESSED: 'isFollowUpAddressed',
  IS_UNVERIFIED_BY_RIDER: 'isUnverifiedByRider',
};
