const selectLocationFromState = state => state.location;

export const selectCitiesListFromState = state => selectLocationFromState(state).cities.list;
export const selectCollectionCentersListFromState = state => selectLocationFromState(state).collectionCenters.list;
export const selectRegionsListFromState = state => selectLocationFromState(state).regions.list;
export const selectHostSpotsListFromState = state => selectLocationFromState(state).hotSpots.list;
export const selectRiderLiveLocationFromState = state => selectLocationFromState(state).liveLocation;
export const selectRegionDetailFromState = (state, type) => {
  return selectLocationFromState(state).regions.regionDetail[type];
}
export const selectCityDetailFromState = (state, type) => {
  return selectLocationFromState(state).cities.cityDetail[type];
}
