import { types } from '../../actions/auth';
import {INITIAL_STATE} from "../initial-state";

export const auth = (state = INITIAL_STATE.auth, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        token: action.payload.token,
      };
    case types.AUTH_LOGIN_FAIL:
      return {
        ...state,
        user: {},
        token: null,
        error: '',
      };
    case types.AUTH_TOKEN_REFRESH_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        token: {
          ...state.token,
          tokenType: payload.tokenType,
          accessToken: payload.accessToken,
          refreshToken: payload.refreshToken,
        },
      };
    case types.AUTH_LOGOUT_SUCCESS: {
      return {
        ...state,
        user: {},
        error: '',
        token: {},
      };
    }
     case types.AUTH_LOGOUT_FAIL:
      return {
        ...state,
        user: {},
        token: null,
        error: action.data,
      };
    case types.GET_NP_STAFF_USERS_REQUEST:
      return {
        ...state,
        npStaffUsers: [],
      };
    case types.GET_NP_STAFF_USERS_SUCCESS:
      return {
        ...state,
        npStaffUsers: action.payload,
      };
    case types.RESET_NP_STAFF_USERS_STATE:
      return {
        ...state,
        npStaffUsers: [],
      };
    default:
      return state;
  }
};
