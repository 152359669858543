import { types } from '../../actions/attendance'
import { INITIAL_STATE } from "../initial-state"

export const attendance = (state = INITIAL_STATE.attendance, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_ATTENDANCE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_ATTENDANCE_LIST_SUCCESS:
      return {
        ...state,
        list: [...state.list, ...payload.results],
        next: payload.next,
        count: payload.count,
        loading: false,
      }
    case types.GET_ATTENDANCE_LIST_FAILURE:
      return {
        ...state,
        loading: false
      }
    case types.GET_FILTERED_ATTENDANCE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_FILTERED_ATTENDANCE_LIST_SUCCESS:

      return {
        ...state,
        list: payload.results,
        next: payload.next,
        count: payload.count,
        loading: false
      };
    case types.GET_FILTERED_ATTENDANCE_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.UPDATE_ATTENDANCE_PARAMS:
      return {
        ...state,
        params: payload
      };
    case types.GET_ATTENDANCE_SHIFTS_REQUEST:
      return {
        ...state,
        attendanceShifts: [],
        loading: true
      }
    case types.GET_ATTENDANCE_SHIFTS_SUCCESS:
      return {
        ...state,
        attendanceShifts: payload,
        loading: false,
      }
    case types.GET_ATTENDANCE_SHIFTS_FAILURE:
      return {
        ...state,
        loading: false
      }
    case types.GET_RIDER_SHIFT_ATTENDANCE_REQUEST:
      return {
        ...state,
        riderShiftAttendance: INITIAL_STATE.attendance.riderShiftAttendance,
        loading: true
      }
    case types.GET_RIDER_SHIFT_ATTENDANCE_SUCCESS:
      return {
        ...state,
        riderShiftAttendance: payload,
        loading: false,
      }
    case types.GET_RIDER_SHIFT_ATTENDANCE_FAILURE:
      return {
        ...state,
        loading: false
      }
    case types.RESET_ATTENDANCE_STATE:
      return {
        ...state,
        ...INITIAL_STATE.attendance,
      };
    default:
      return state
  }
}
