import React, {useEffect, useState} from 'react';
import {DEFAULT_ORDER_PICKUP_TIME, MAX_ORDER_PICKUP_DAY, VERTICALS} from '../../utils/constants';
import {add} from "date-fns";
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {pDateFormat, yyyyMMdd} from '../../utils/helper';
import {toast} from 'react-toastify';

const RescheduleOrder = (props) => {
  const {
    orderDetail,
    rescheduleOrder,
    closeModal,
    getOrderTimeSlots,
    resetOrderTimeSlotsState,
    orderTimeSlots,
    vertical,
    rescheduleStrings
  } = props;
  const [rescheduleDate, setRescheduleDate] = useState(add(new Date(), {minutes: DEFAULT_ORDER_PICKUP_TIME}));
  const [orderTimeSlot, setOrderTimeSlot] = useState('');

  const [reason, setReason] = useState('');
  const [rescheduleStringId, setRescheduleStringId] = useState('');

  useEffect(() => {
    if (rescheduleStrings.length > 0) {
      setRescheduleStringId(rescheduleStrings[0].id);
    }
  }, rescheduleStrings);

  useEffect(() => {
    return () => {
      setOrderTimeSlot('');
      resetOrderTimeSlotsState();
    }
  }, []);

  useEffect(() => {
    if (!rescheduleDate || !orderDetail.region) {
      return;
    }
    setOrderTimeSlot('');
    resetOrderTimeSlotsState();
    getOrderTimeSlots({
      date: yyyyMMdd(rescheduleDate),
      regionId: orderDetail.region,
      isCovid: orderDetail.scanTypes.some(item => item.scanType.isCovid),
      vertical,
      orderId: orderDetail.id,
      customerAddressId: orderDetail.customerAddress.id,
    });
  }, [rescheduleDate, orderDetail.region]);

  const onRescheduleOrder = (e) => {
    e.preventDefault();
    if (!orderTimeSlot) {
      toast.error('Select re-schedule time');
      return;
    }
    const timeSlot = orderTimeSlots.find((ots => ots.id == orderTimeSlot))
    if (!timeSlot) {
      setOrderTimeSlot('');
      return
    }
    rescheduleOrder({
      id: orderDetail.id,
      vertical,
      data: {
        date: yyyyMMdd(timeSlot.dateTime),
        timeSlot: timeSlot.id,
        reason,
        rescheduleStringId
      }
    });
    closeModal();
  };
  return (
    <div className="lightbox lightbox-order-top re-assign">
      <div className="lightbox-head">
        <a
          href=''
          className="lightbox-close icon-clear"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        />
        <h3>Re-Schedule Order</h3>
      </div>
      <div className="cl-holder">
        <div className="datepick">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disableToolbar
              variant="static"
              minDate={new Date()}
              disablePast
              maxDate={add(new Date(), {days: MAX_ORDER_PICKUP_DAY})}
              onChange={val => {
                setRescheduleDate(val);
              }}
              name="pickup"
              value={rescheduleDate}
              format="yyyy/MM/dd"
              placeholder="DD/MM/YYYY"
            />
          </MuiPickersUtilsProvider>
          <div className="reschedule-reason">
            <div className="form-row">
              <select onChange={e => {
                setRescheduleStringId(rescheduleStrings.find(i => i.text === e.target.value).id)
              }}>
                {rescheduleStrings.map(i => <option>{i.text}</option>)}
              </select>
            </div>
            <div className="form-row">
                <textarea
                  placeholder=""
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
            </div>
          </div>
        </div>
        <div className="time-slot">
          <span className="title"> Available Time</span>
          <ul className="time-list">
            {
              orderTimeSlots.map(timeSlot => {
                return <li
                  key={timeSlot.dateTime.toISOString()}
                  className={`${timeSlot.id === orderTimeSlot ? 'active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setOrderTimeSlot(timeSlot.id)
                  }}
                >
                  {pDateFormat(timeSlot.dateTime)}
                </li>
              })
            }
          </ul>
          <a
            href=""
            className="assigner-create-btn"
            onClick={onRescheduleOrder}
          >
            Re-schedule Order</a>
        </div>
      </div>
    </div>
  );
};

export default RescheduleOrder;

