export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REQUEST_PASSWORD_RESET: '/password-reset-request',
  PASSWORD_RESET: '/password-reset',
  CUSTOMER: '/customers',
  BLOOD_ORDERS: '/blood/orders',
  XRAY_ORDERS: '/xray/orders',
  RIDERS: '/riders',
  SHIFTS: '/shifts',
  ATTENDANCE: '/attendance',
  BLOOD_DASHBOARD: '/blood/dashboard',
  BLOOD_CITY_DASHBOARD: '/blood/city/dashboard',
  XRAY_CITY_DASHBOARD: '/xray/city/dashboard',
  XRAY_DASHBOARD: '/xray/dashboard',
  ORDER_REVIEW: '/order-review',
  ORDER_REVIEW_SUCCESS: '/order-review-success',
  ORDER_REVIEW_EXPIRED: '/order-review-expired',
  ORDER_REVIEW_ERROR: '/order-review-error',
  PHYSICAL_REVIEW: '/physical-visit-review',
  UNVERIFIED_ORDERS: '/unverified-orders',
  PARTIAL_ORDERS: '/partial-orders',
  REDASH: '/redash',
  ISSUE_REPORTING: '/issue-reporting',
  RELEASE_NOTES: '/release-notes',
  FAQS: '/faqs',
  RIDER_LIVE_LOCATION: '/rider-live-location',
  RIDER_LIVE_LOCATION_LINK_EXPIRED: '/rider-live-location-link-expired',
  CRO_FEEDBACK: '/cro-feedback',
  CRO_FEEDBACK_SUCCESS: '/cro-feedback-success',
  CRO_FEEDBACK_ERROR: '/cro-feedback-error',
  CRO_FEEDBACK_EXPIRED: '/cro-feedback-expired',
};
