import React, { useEffect } from 'react';
import { fullName, ddMMMyyyhhmmaaa, getTaskType } from '../../utils/helper';
import { isEmpty, truncate } from 'lodash';
import { Loader } from './loader';

export const RiderTasksList = ({ tasks, getRiderTasks, rider, closeModal, isLoading, resetRiderTasksState, vertical }) => {
  useEffect(() => {
    if (rider && rider.id) {
      getRiderTasks({ riderId: rider.id, vertical });
    }
    return () => resetRiderTasksState();
  }, []);

  const noRiders = () => {
    if (isLoading) {
      return <Loader/>
    }
    if (isEmpty(tasks))
      return <p>No Tasks</p>
  };

  const getAddress = (task)=> {
    let address = '';
    if (task.type === 0) {
      address = task.order.address
    } else {
      address = task.collectionCenter.name
    }
    return <address>{truncate(address, { length: 40 })}</address>
  };

  return <div className="lightbox">
    <div className="queue-lightbox">
      <a
        href=''
        className="lightbox-close icon-clear"
        onClick={(e) => {
          e.preventDefault();
          closeModal();
        }}
      />
      <div className="queue-left">
        <div className="lft-holder">
          <img src={require("../../assets/images/img4.svg")} alt="rider-image"/>
          <span className="title">{fullName(rider)}</span>
          <span className="tel">{rider.phone}</span>
        </div>
      </div>
      <div className="queue-right">
        <h2>Tasks in queue</h2>
        <ul className="queue-list">
          {tasks.map((task) => {
            return <li key={task.id}>
              <div className="head">
                <span className="number">{task.order.number}</span>
                <span className="time">{ddMMMyyyhhmmaaa(task.deadline)}</span>
              </div>
              <span className="pick"><img src={require("../../assets/images/lab-white.svg")}
                                          alt="pickup"/>{getTaskType(task.type)}</span>
              {getAddress(task)}
            </li>
          })}
        </ul>
        {noRiders()}
      </div>
    </div>
  </div>
};
