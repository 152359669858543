const selectFeedbackFromState = state => state.feedback;

export const selectFeedbackLoadingFromState = state => selectFeedbackFromState(state).loading;
export const selectOrderReviewFromState = state => selectFeedbackFromState(state).orderReview;

export const selectPhysicalVisitReviewQuestionsFromState = state =>
  selectFeedbackFromState(state).physicalVisitFeedbackQuestions;

export const selectIsReviewCompleteFromState = state => selectFeedbackFromState(state).isReviewComplete;

export const selectIsInteractionIdValidFromState = state => selectFeedbackFromState(state).isInteractionIdValid;

export const selectShowGoogleMapReviewLinkFromState = state => selectFeedbackFromState(state).showGoogleMapReviewLink;

export const selectIsExpiredFromState = state => selectFeedbackFromState(state).isExpired;

export const selectIsSatisfiedFromState = state => selectFeedbackFromState(state).isSatisfied;

export const selectCROFeedbackQuestionsFromState = state => selectFeedbackFromState(state).croFeedbackQuestions;
