import React from 'react';
import ReactMinimalPieChart from 'react-minimal-pie-chart';

export const PercentChart = ({ percent = 0 }) => {
  if (!percent) {
    percent = 0
  }
  return <ReactMinimalPieChart
    data={[
      {
        color: '#D0D0D0',
        value: 100 - percent,
      },
      {
        color: '#2E3790',
        value: percent,
      },
    ]}
    label={() => `${percent}%`}
    labelPosition={0}
    labelStyle={{
      fontFamily: 'Roboto',
      fontSize: '24px',
      color: '#2E3790',
    }}
    radius={45}
    startAngle={270}
    lengthAngle={-360}
    lineWidth={20}
    totalValue={100}
  />
};
