import React from 'react';
import error from '../../assets/images/error-icon.svg';
import { OrderReviewWrapper } from './order-review-wrapper';

export const OrderReviewError = () => {
  return <OrderReviewWrapper>
    <div className="page-table-body">
      <div className="feedback-hoder add">
        <img src={error} alt="error" />
          <span className="title">Something went wrong!</span>
          <p>Please refresh or visit after sometime to submit your feedback</p>
      </div>
    </div>
  </OrderReviewWrapper>
};
