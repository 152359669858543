import {
  call,
  put,
} from 'redux-saga/effects';
import { logger } from 'utils/logger';
import { customerActions } from '../../actions/customer';
import {
  getCustomerDetailAPI,
  getCustomerAddressAPI,
  createCustomerAPI,
  createCustomerAddressAPI,
  getCustomersIdAPI,
  getCustomersAPI,
  updateCustomerAPI,
  deleteCustomerAddressAPI,
  updateCustomerPatientAPI,
  createCustomerPatientAPI,
  deleteCustomerPatientAPI,
  updateCustomerAddressAPI,
} from '../../api/customer';
import { toast } from 'react-toastify';
import { goBack } from "connected-react-router";
import {getCustomerOrdersAPI, getOrderAPI, getOrdersAPI} from '../../api/order';
import { orderActions } from '../../actions/order';
import {VERTICALS} from "../../utils/constants";

export function* getCustomers({payload}) {
  try {
    const resp = yield call(getCustomersAPI, payload);
    if (resp) {
      yield put(customerActions.getCustomersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.getCustomersFailure(error));
  }
}

export function* getFilteredCustomers({payload}) {
  try {
    const resp = yield call(getCustomersAPI, payload);
    if (resp) {
      yield put(customerActions.getFilteredCustomersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.getFilteredCustomersFailure(error));
  }
}

export function* getCustomerOrders({payload}) {
  try {
    const resp = yield call(getCustomerOrdersAPI, payload);
    if (resp) {
      yield put(customerActions.getCustomerOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.getCustomerOrdersFailure(error));
  }
}

export function* getFilteredCustomerOrders({payload}) {
  try {
    const resp = yield call(getCustomerOrdersAPI, payload);
    if (resp) {
      yield put(customerActions.getFilteredCustomerOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.getFilteredCustomerOrdersFailure(error));
  }
}

export function* getCustomersId({payload: values}) {
  try {
    const resp = yield call(getCustomersIdAPI, values);
    if (resp) {
      yield put(customerActions.getCustomersIdSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.getCustomersIdFailure(error));
  }
}
export function* getCustomerDetail({payload}) {
  try {
    const resp = yield call(getCustomerDetailAPI, payload);
    if (resp) {
      yield put(customerActions.getCustomerDetailSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.getCustomerDetailFailure(error));
  }
}
export function* getCustomerAddress({payload: values}) {
  try {
    const resp = yield call(getCustomerAddressAPI, values);
    if (resp) {
      yield put(customerActions.getCustomerAddressSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.getCustomerAddressFailure(error));
  }
}
export function* createCustomer({ payload : values }) {
  try {
    const resp = yield call(createCustomerAPI, values);
    if (resp) {
      toast.success(`Customer created successfully`);
      // todo: remove patients:[]
      yield put(customerActions.createCustomerSuccess({...resp.data, patients:[]}));

    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.createCustomerFailure(error));
  }
}

export function* createCustomerAddress({payload}) {
  try {
    const resp = yield call(createCustomerAddressAPI, payload);
    if (resp) {
      yield put(customerActions.createCustomerAddressSuccess(resp.data));
      const customer = yield call(getCustomerDetailAPI, {id: payload.customer, vertical: payload.vertical == VERTICALS.blood ? VERTICALS.BLOOD : VERTICALS.XRAY});
      if (customer) {
        yield put(customerActions.getCustomerDetailSuccess(customer.data));
      }

    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.createCustomerAddressFailure(error));
  }
}

export function* updateCustomer({ payload : values }) {
  try {
    const resp = yield call(updateCustomerAPI, values);
    if (resp) {
      yield put(customerActions.updateCustomerSuccess(resp.data));
      toast.success(`Customer updated successfully`);
      yield put(customerActions.resetCustomerState());
      yield put(goBack());
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.updateCustomerFailure(error));
  }
}

export function* updateCustomerAddress({ payload }) {
  try {
    const resp = yield call(updateCustomerAddressAPI, payload.data);
    if (resp) {
      yield put(customerActions.updateCustomerAddressSuccess(resp.data));
      toast.success(`Customer address updated successfully`);
      const order = yield call(getOrderAPI, payload.orderId);
      if (order) {
        yield put(orderActions.getOrderSuccess(order.data));
      }
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.updateCustomerAddressFailure(error));
  }
}

export function* deleteCustomerAddress({ payload }) {
  try {
    const {customerAddressId, orderId } = payload;
    const resp = yield call(deleteCustomerAddressAPI, customerAddressId);
    if (resp) {
      yield put(customerActions.deleteCustomerAddressSuccess(customerAddressId));
      if (orderId) {
        const order = yield call(getOrderAPI, orderId);
        if (order) {
          yield put(orderActions.getOrderSuccess(order.data));
        }
      }
      toast.success(`Customer address deleted successfully`);
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.deleteCustomerAddressFailure(error));
  }
}


export function* createCustomerPatient({payload: values}) {
  try {
    const resp = yield call(createCustomerPatientAPI, values);
    if (resp) {
      yield put(customerActions.createCustomerPatientSuccess(resp.data));
      toast.success(`Patient created successfully`);
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.createCustomerPatientFailure(error));
  }
}

export function* updateCustomerPatient({ payload : values }) {
  try {
    const resp = yield call(updateCustomerPatientAPI, values);
    if (resp) {
      yield put(customerActions.updateCustomerPatientSuccess(resp.data));
      toast.success(`Patient updated successfully`);
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.updateCustomerPatientFailure(error));
  }
}

export function* deleteCustomerPatient({ payload : value }) {
  try {
    const resp = yield call(deleteCustomerPatientAPI, value);
    if (resp) {
      yield put(customerActions.deleteCustomerPatientSuccess(value));
      toast.success(`Patient deleted successfully`);
    }
  } catch (error) {
    logger.error(error);
    yield put(customerActions.deleteCustomerPatientFailure(error));
  }
}
