import React from 'react';
import {Marker, Popup} from "react-leaflet";
import Map from "../commons/Map";
import {RiderIcon} from "../../assets/images/Icons";


export const RiderLastSeen = ({ latitude, longitude, closeModal, title='Last seen location' }) => {


  return <div className="lightbox">
    <div className="lightbox-head rider-lightbox-info">
      <a
        href=''
        className="lightbox-close icon-clear"
        onClick={(e) => {
          e.preventDefault();
          closeModal();
        }}
      />
      <div className="info">
        <h3>{title}</h3>
      </div>
    </div>
    <div className="rider-map">
      <Map
        initialCenter={[+latitude, +longitude]}
        initialZoom={15}
        styles={{height: "100%", zIndex: 0}}
      >
        <Marker icon={RiderIcon} position={[+latitude, +longitude]}>
          <Popup>
            Rider
          </Popup>
        </Marker>
      </Map>
    </div>
  </div>
};
