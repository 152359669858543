import React, {useEffect} from 'react';
import {Nav} from "../commons/nav";
import {ddMMMyyyhhmmaaa, ddMMMyyyy, fullName, hasPermission} from '../../utils/helper';
import Modal from "react-modal";
import {RiderLastSeen} from './rider-last-seen';
import {RiderOrdersList} from '../commons/rider-orders-list';
import {startCase} from 'lodash';
import {Loading} from '../commons';
import {PERMISSION, RIDER_OFF_DUTY_API, RIDER_STATE, VERTICALS} from '../../utils/constants';

const RiderDetail = (props) => {
  const {
    match: {params: {id}},
    history,
    getRiderDetail,
    riderDetail,
    resetRiderState,
    getFilteredRiderOrders,
    getRiderOrders,
    riderOrders,
    riderOrdersNext,
    riderOrdersLoading,
    getRiderForceDeliver,
    riderMarkOffDuty,
  } = props;

  const [isOpenRiderLastSeenModal, setIsOpenRiderLastSeenModal] = React.useState(false);

  useEffect(() => {
    getRiderDetail(id);
    return () => {
      resetRiderState();
    }
  }, []);

  const openRiderLastSeenModalModal = (e) => {
    e.preventDefault();
    setIsOpenRiderLastSeenModal(true);
  };

  const closeRiderLastSeenModal = () => {
    setIsOpenRiderLastSeenModal(false);
  };

  const {profile = {}} = riderDetail;

  if (!riderDetail.id) {
    return <Loading/>
  }

  const handleWildcardChange = (e) => {
    e.preventDefault();
    riderMarkOffDuty({
      id: riderDetail.id,
      action: RIDER_OFF_DUTY_API.WILDCARD,
      data: {
        isWildcard: !profile.isWildcard,
      }
    })
  };

  const handleCovidChange = (e) => {
    e.preventDefault();
    riderMarkOffDuty({
      id: riderDetail.id,
      action: RIDER_OFF_DUTY_API.COVID,
      data: {
        isCovid: !profile.isCovid,
      }
    })
  };

  return <div id="wrapper" className='inner-page'>
    <Nav/>
    {riderDetail && riderDetail.id && <div className="rider-detail-page">
      <div className="rider-detail-head">
        <div className="lft-head">
          <h2>{fullName(riderDetail)}</h2>
          <ul className="breadcrumb-page">
            <li>
              <a
                href=''
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}>Riders</a></li>
            <li>{fullName(riderDetail)}</li>
          </ul>
        </div>
        {
          profile.vertical.name === VERTICALS.BLOOD && profile.state === RIDER_STATE.DELIVERING &&
          <a
            href=" "
            className="logout-btn deliver"
            onClick={(e) => {
              e.preventDefault();
              getRiderForceDeliver(riderDetail.id)
            }}
          >
            Force Deliver Orders
          </a>
        }
      </div>

      <div className="rider-d-cols">
        <div className="rider-d-col">
          <div className="rider-info">
            <span className="bar"></span>
            <div className="rider-info-holder">
              <div className="rider-profile-img">
                <div className="img-holder">
                  <img src={require('../../assets/images/img-1.png')} alt="profile image"/>
                  <span className={riderDetail.isOnline ? 'available-dot' : ''}></span>
                </div>
                <span className="text">{riderDetail.isActive ? 'Active' : 'Inactive'}</span>
                <label className="switcher">
                  <input type="checkbox" checked={riderDetail.isActive} disabled/>
                  <span className="button"></span>
                </label>
              </div>
              <dl>
                <dd>{riderDetail.firstName}</dd>
                <dt>First Name</dt>
              </dl>
              <dl>
                <dd>{riderDetail.lastName}</dd>
                <dt>Last Name</dt>
              </dl>
              <dl>
                <dd>{profile.region.name}</dd>
                <dt>region</dt>
              </dl>
              <dl>
                <dd>{ddMMMyyyy(riderDetail.dateJoined)}</dd>
                <dt>Date of Joining</dt>
              </dl>
              <dl>
                <dd>{riderDetail.email || '---'}</dd>
                <dt>Email</dt>
              </dl>
              {
                hasPermission(PERMISSION.CAN_CHANGE_USER) &&
                <dl>
                  <dd>
                    <label className="switcher">
                      <input type="checkbox" checked={profile.isWildcard} onClick={e => handleWildcardChange(e)}/>
                      <span className="button"></span>
                    </label>
                  </dd>
                  <dt>Wildcard</dt>
                </dl>
              }
              {
                hasPermission(PERMISSION.CAN_CHANGE_USER) &&
                <dl>
                  <dd>
                    <label className="switcher">
                      <input type="checkbox" checked={profile.isCovid} onClick={e => handleCovidChange(e)}/>
                      <span className="button"></span>
                    </label>
                  </dd>
                  <dt>COVID</dt>
                </dl>
              }
              <dl>
                <dd>{riderDetail.shiftBreakEndTime ? ddMMMyyyhhmmaaa(riderDetail.shiftBreakEndTime) : '---'}</dd>
                <dt>Break ends at</dt>
              </dl>
            </div>
          </div>
        </div>
        <div className="rider-d-col">
          <div className="rider-cell-info">
            <div className="verified-area">
              <i className="icon-cell"></i>
              <div className="info">
                <span className="tel">{riderDetail.phone}</span>
                {riderDetail.phoneVerified ?
                  <span className="tag"><i className='icon-check'/> Verified</span> :
                  <span className="tag"><i className='icon-clear'/> Unverified</span>
                }
              </div>
            </div>
            <div className="cell-footer">
              <div className="footer-col">
                <img src={require('../../assets/images/battery.svg')} alt="battery"/>
                <div className="info">
                  <span className="title">{riderDetail.battery === null ? 'N/A' : `${riderDetail.battery}%`}</span>
                  <span className="text">Battery</span>
                </div>
              </div>
              <div className="footer-col">
                <img src={require('../../assets/images/network-strength.svg')} alt="network-strength"/>
                <div className="info">
                  <span className="title">{startCase(riderDetail.networkState)}</span>
                  <span className="text">{riderDetail.isOnline ? 'Connected' : 'Disconnected'}</span>
                </div>
              </div>
            </div>
            <div className="cell-footer">
              <div className="footer-col">
                <img src={require('../../assets/images/cell.svg')} alt="mobile-model"/>
                <div className="info">
                  <span className="title">{profile.mobileModel === null ? 'N/A' : `${profile.mobileModel}`}</span>
                  <span className="text">Mobile Model</span>
                </div>
              </div>
              <div className="footer-col">
                <img src={require('../../assets/images/info.svg')} alt="app-version"/>
                <div className="info">
                  <span className="title">{profile.appVersion === null ? 'N/A' : `${profile.appVersion} (${profile.isUsingLatestApp ? 'latest' : 'outdated'})`}</span>
                  <span className="text">App Version</span>
                </div>
              </div>
            </div>
          </div>
          <div className="rider-address-area">
            <span className="title">Address</span>
            <address>
              {riderDetail.address && riderDetail.address}
              {riderDetail.address && <br/>}
              {profile.region.name}
            </address>
          </div>
          <div className="rider-location-area">
            <span className="title">Last seen location</span>
            {profile.lastKnownLatitude && profile.lastKnownLongitude ?
              <a
                href=""
                onClick={openRiderLastSeenModalModal}
                className="loc-link">{`${profile.lastKnownLatitude} , ${profile.lastKnownLongitude}`}</a> : 'N/A'
            }
          </div>
        </div>
        <div className="rider-d-col activity-info">
          <h2>Order History</h2>
          <RiderOrdersList
            riderId={riderDetail.id}
            riderOrders={riderOrders}
            getRiderOrders={getRiderOrders}
            getFilteredRiderOrders={getFilteredRiderOrders}
            isLoading={riderOrdersLoading}
            riderOrdersNext={riderOrdersNext}
            history={history}
          />
        </div>
      </div>
      {isOpenRiderLastSeenModal && profile.lastKnownLatitude && profile.lastKnownLongitude &&
      <Modal isOpen={isOpenRiderLastSeenModal} onRequestClose={closeRiderLastSeenModal}>
        <RiderLastSeen
          latitude={profile.lastKnownLatitude}
          longitude={profile.lastKnownLongitude}
          closeModal={closeRiderLastSeenModal}
        />
      </Modal>
      }
    </div>
    }
  </div>
};
export default RiderDetail;
