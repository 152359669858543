export const GET_ORDER_REVIEW_REQUEST = 'GET_ORDER_REVIEW_REQUEST';
export const GET_ORDER_REVIEW_SUCCESS = 'GET_ORDER_REVIEW_SUCCESS';
export const GET_ORDER_REVIEW_FAILURE = 'GET_ORDER_REVIEW_FAILURE';

export const CREATE_ORDER_REVIEW_REQUEST = 'CREATE_ORDER_REVIEW_REQUEST';
export const CREATE_ORDER_REVIEW_SUCCESS = 'CREATE_ORDER_REVIEW_SUCCESS';
export const CREATE_ORDER_REVIEW_FAILURE = 'CREATE_ORDER_REVIEW_FAILURE';

export const RESET_FEEDBACK_STATE = 'RESET_FEEDBACK_STATE';

export const GET_PHYSICAL_VISIT_REVIEW_QUESTIONS_REQUEST = 'GET_PHYSICAL_VISIT_REVIEW_QUESTIONS_REQUEST';
export const GET_PHYSICAL_VISIT_REVIEW_QUESTIONS_SUCCESS = 'GET_PHYSICAL_VISIT_REVIEW_QUESTIONS_SUCCESS';
export const GET_PHYSICAL_VISIT_REVIEW_QUESTIONS_FAILURE = 'GET_PHYSICAL_VISIT_REVIEW_QUESTIONS_FAILURE';

export const POST_PHYSICAL_VISIT_REVIEW_FEEDBACK_REQUEST = 'POST_PHYSICAL_VISIT_REVIEW_FEEDBACK_REQUEST';
export const POST_PHYSICAL_VISIT_REVIEW_FEEDBACK_SUCCESS = 'POST_PHYSICAL_VISIT_REVIEW_FEEDBACK_SUCCESS';
export const POST_PHYSICAL_VISIT_REVIEW_FEEDBACK_FAILURE = 'POST_PHYSICAL_VISIT_REVIEW_FEEDBACK_FAILURE';

export const GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_REQUEST = 'GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_REQUEST';
export const GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_SUCCESS = 'GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_SUCCESS';
export const GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_FAILURE = 'GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_FAILURE';

export const GET_CRO_FEEDBACK_QUESTIONS_REQUEST = 'GET_CRO_FEEDBACK_QUESTIONS_REQUEST';
export const GET_CRO_FEEDBACK_QUESTIONS_SUCCESS = 'GET_CRO_FEEDBACK_QUESTIONS_SUCCESS';
export const GET_CRO_FEEDBACK_QUESTIONS_FAILURE = 'GET_CRO_FEEDBACK_QUESTIONS_FAILURE';

export const POST_CRO_FEEDBACK_REQUEST = 'POST_CRO_FEEDBACKK_REQUEST';
export const POST_CRO_FEEDBACK_SUCCESS = 'POST_CRO_FEEDBACK_SUCCESS';
export const POST_CRO_FEEDBACK_FAILURE = 'POST_CRO_FEEDBACK_FAILURE';

export const GET_CRO_FEEDBACK_REQUEST = 'GET_CRO_FEEDBACK_REQUEST';
export const GET_CRO_FEEDBACK_SUCCESS = 'GET_CRO_FEEDBACK_SUCCESS';
export const GET_CRO_FEEDBACK_FAILURE = 'GET_CRO_FEEDBACK_FAILURE';
