import { call, put, } from 'redux-saga/effects';
import { push } from "connected-react-router";
import * as _ from 'lodash';
import { logger } from '../../utils/logger';
import { feedbackActions } from '../../actions/feedback';
import { ROUTES } from "../../routes/constants";
import {
  createOrderReviewAPI, getCROFeedbackAPI, getCROFeedbackQuestionsAPI,
  getOrderReviewAPI, getPhysicalVisitReviewFeedbackAPI,
  getPhysicalVisitReviewQuestions,
  getPhysicalVisitReviewQuestionsAPI, postCROFeedbackAPI, postPhysicalVisitReviewFeedbackAPI
} from '../../api/feedback';
import { ERROR_MESSAGES } from '../../api/constants';


export function* getOrderReview({ payload: value }) {
  try {
    const resp = yield call(getOrderReviewAPI, value);
    if (resp) {
      yield put(feedbackActions.getOrderReviewSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    if (_.get(error, ['response', 'data', 'detail']) === ERROR_MESSAGES.NOT_FOUND) {
      yield put(push({ pathname: ROUTES.ORDER_REVIEW_ERROR }));
    }
    yield put(feedbackActions.getOrderReviewFailure(error));
  }
}

export function* createOrderReview({ payload: values }) {
  try {
    const resp = yield call(createOrderReviewAPI, values);
    if (resp) {
      yield put(feedbackActions.createOrderReviewSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(feedbackActions.createOrderReviewFailure(error));
  }
}

export function* getPhysicalVisitReviewQuestionsSaga({ payload: values }) {
  try {
    const resp = yield call(getPhysicalVisitReviewQuestionsAPI, values);
    if (resp) {
      yield put(feedbackActions.getPhysicalVisitReviewQuestionsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(feedbackActions.getPhysicalVisitReviewQuestionsFailure(error));
  }
}

export function* postPhysicalVisitReviewFeedbackSaga({payload: values}) {
  try {
    const resp = yield call(postPhysicalVisitReviewFeedbackAPI, values);
    if (resp) {
      yield put(feedbackActions.postPhysicalVisitReviewFeedbackSuccess(resp.data));

    }
  } catch (error) {
    logger.error(error);
    yield put(feedbackActions.postPhysicalVisitReviewFeedbackFailure(error));
  }
}

export function* getPhysicalVisitReviewFeedbackSaga({payload: values}) {
  try {
    const resp = yield call(getPhysicalVisitReviewFeedbackAPI, values);
    if (resp) {
      yield put(feedbackActions.getPhysicalVisitReviewFeedbackSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(feedbackActions.getPhysicalVisitReviewFeedbackFailure(error));
  }
}

export function* getCROFeedbackQuestionsSaga({ payload: values }) {
  try {
    const resp = yield call(getCROFeedbackQuestionsAPI, values);
    if (resp) {
      yield put(feedbackActions.getCROFeedbackQuestionsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(feedbackActions.getCROFeedbackQuestionsFailure(error));
  }
}

export function* postCROFeedbackSaga({payload: values}) {
  try {
    const resp = yield call(postCROFeedbackAPI, values);
    if (resp) {
      yield put(feedbackActions.postCROFeedbackSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(feedbackActions.postCROFeedbackFailure(error));
  }
}

export function* getCROFeedbackSaga({payload: values}) {
  try {
    const resp = yield call(getCROFeedbackAPI, values);
    if (resp) {
      yield put(feedbackActions.getCROFeedbackSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(feedbackActions.getCROFeedbackFailure(error));
  }
}
