import { connect } from 'react-redux';
import {
  selectShowGoogleMapReviewLinkFromState
} from '../../selectors/feedback';
import {OrderReviewSuccess} from "./order-review-success";

const mapStateToProps = state => ({
  showGoogleMapReviewLink: selectShowGoogleMapReviewLinkFromState(state),
});

export default connect(mapStateToProps, null)(OrderReviewSuccess);
