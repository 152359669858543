import {getLocalStorageItem} from '../../utils/helper';
export const selectAuthFromState = state => state.auth;

export const selectLoggedInUserFromState = state => selectAuthFromState(state).user;
export const selectAccessTokenFromState = () => {
    return getLocalStorageItem('TOKEN')
}
export const selectRefreshTokenFromState = state => selectAuthFromState(state).token.refreshToken;
export const selectAuthTypeFromState = state => selectAuthFromState(state).token.tokenType;
export const selectAuthSuccessFromState = state => selectAuthFromState(state).success;
export const selectAuthErrorFromState = state => selectAuthFromState(state).error;
export const selectNpStaffUsersListFromState = state => selectAuthFromState(state).npStaffUsers;
