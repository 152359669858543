// CUSTOMERS
export const GET_CUSTOMER_DETAIL_REQUEST = 'GET_CUSTOMER_DETAIL_REQUEST';
export const GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS';
export const GET_CUSTOMER_DETAIL_FAILURE = 'GET_CUSTOMER_DETAIL_FAILURE';

export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE';

// FILTERED CUSTOMERS
export const GET_FILTERED_CUSTOMERS_REQUEST = 'GET_FILTERED_CUSTOMERS_REQUEST';
export const GET_FILTERED_CUSTOMERS_SUCCESS = 'GET_FILTERED_CUSTOMERS_SUCCESS';
export const GET_FILTERED_CUSTOMERS_FAILURE = 'GET_FILTERED_CUSTOMERS_FAILURE';

// CUSTOMER
export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';


export const GET_CUSTOMER_ADDRESS_REQUEST = 'GET_CUSTOMER_ADDRESS_REQUEST';
export const GET_CUSTOMER_ADDRESS_SUCCESS = 'GET_CUSTOMER_ADDRESS_SUCCESS';
export const GET_CUSTOMER_ADDRESS_FAILURE = 'GET_CUSTOMER_ADDRESS_FAILURE';

export const CREATE_CUSTOMER_ADDRESS_REQUEST = 'CREATE_CUSTOMER_ADDRESS_REQUEST';
export const CREATE_CUSTOMER_ADDRESS_SUCCESS = 'CREATE_CUSTOMER_ADDRESS_SUCCESS';
export const CREATE_CUSTOMER_ADDRESS_FAILURE = 'CREATE_CUSTOMER_ADDRESS_FAILURE';

export const GET_CUSTOMERS_ID_REQUEST = 'GET_CUSTOMERS_ID_REQUEST';
export const GET_CUSTOMERS_ID_SUCCESS = 'GET_CUSTOMERS_ID_SUCCESS';
export const GET_CUSTOMERS_ID_FAILURE = 'GET_CUSTOMERS_ID_FAILURE';

// CREATE CUSTOMER
export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const RESET_CUSTOMER_STATE = 'RESET_CUSTOMER_STATE';
export const UPDATE_CUSTOMER_PARAMS = 'UPDATE_CUSTOMER_PARAMS';

export const GET_CUSTOMER_ORDERS_REQUEST = 'GET_CUSTOMER_ORDERS_REQUEST';
export const GET_CUSTOMER_ORDERS_SUCCESS = 'GET_CUSTOMER_ORDERS_SUCCESS';
export const GET_CUSTOMER_ORDERS_FAILURE = 'GET_CUSTOMER_ORDERS_FAILURE';

export const GET_FILTERED_CUSTOMER_ORDERS_REQUEST = 'GET_FILTERED_CUSTOMER_ORDERS_REQUEST';
export const GET_FILTERED_CUSTOMER_ORDERS_SUCCESS = 'GET_FILTERED_CUSTOMER_ORDERS_SUCCESS';
export const GET_FILTERED_CUSTOMER_ORDERS_FAILURE = 'GET_FILTERED_CUSTOMER_ORDERS_FAILURE';

export const DELETE_CUSTOMER_ADDRESS_REQUEST = 'DELETE_CUSTOMER_ADDRESS_REQUEST';
export const DELETE_CUSTOMER_ADDRESS_SUCCESS = 'DELETE_CUSTOMER_ADDRESS_SUCCESS';
export const DELETE_CUSTOMER_ADDRESS_FAILURE = 'DELETE_CUSTOMER_ADDRESS_FAILURE';

export const CREATE_CUSTOMER_PATIENT_REQUEST = 'CREATE_CUSTOMER_PATIENT_REQUEST';
export const CREATE_CUSTOMER_PATIENT_SUCCESS = 'CREATE_CUSTOMER_PATIENT_SUCCESS';
export const CREATE_CUSTOMER_PATIENT_FAILURE = 'CREATE_CUSTOMER_PATIENT_FAILURE';

export const UPDATE_CUSTOMER_PATIENT_REQUEST = 'UPDATE_CUSTOMER_PATIENT_REQUEST';
export const UPDATE_CUSTOMER_PATIENT_SUCCESS = 'UPDATE_CUSTOMER_PATIENT_SUCCESS';
export const UPDATE_CUSTOMER_PATIENT_FAILURE = 'UPDATE_CUSTOMER_PATIENT_FAILURE';

export const DELETE_CUSTOMER_PATIENT_REQUEST = 'DELETE_CUSTOMER_PATIENT_REQUEST';
export const DELETE_CUSTOMER_PATIENT_SUCCESS = 'DELETE_CUSTOMER_PATIENT_SUCCESS';
export const DELETE_CUSTOMER_PATIENT_FAILURE = 'DELETE_CUSTOMER_PATIENT_FAILURE';

export const UPDATE_CUSTOMER_ADDRESS_REQUEST = 'UPDATE_CUSTOMER_ADDRESS_REQUEST';
export const UPDATE_CUSTOMER_ADDRESS_SUCCESS = 'UPDATE_CUSTOMER_ADDRESS_SUCCESS';
export const UPDATE_CUSTOMER_ADDRESS_FAILURE = 'UPDATE_CUSTOMER_ADDRESS_FAILURE';
