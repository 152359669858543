import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import sagaMiddlewareFactory from 'redux-saga';
import { rootReducer } from 'reducers';
import { rootSaga } from 'sagas';

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});

export function configureStore(preloadedState) {
  const sagaMiddleware = sagaMiddlewareFactory();
  const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const enhancers = composedEnhancers(applyMiddleware(
    routerMiddleware(history),
    sagaMiddleware,
  ));

  const store = createStore(
    rootReducer(history),
    preloadedState,
    enhancers,
  );

  sagaMiddleware.run(rootSaga);

  return { store };
}
