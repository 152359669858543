import { apiCaller } from '../api-caller';
import { ENDPOINTS, REQUEST_TYPES } from '../constants';
import * as caseConverter from 'change-object-case';

const formatAttendanceFilter = (filter = {}) => {
  let formattedFilter = { ...filter, };

  if (formattedFilter.startDatetime) {
    formattedFilter.startDatetime = new Date(formattedFilter.startDatetime).toISOString();
  }
  if (formattedFilter.endDatetime) {
    formattedFilter.endDatetime = new Date(formattedFilter.endDatetime).toISOString();
  }
  return caseConverter.snakeKeys(formattedFilter);
};

export const getAttendanceListAPI = (filter = {}) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.SHIFTS}rider-shifts/`,
    params: formatAttendanceFilter({ ...filter }),
  })
}

export const shiftBreakAPI = (data) => {
  return apiCaller({
    method: REQUEST_TYPES.PUT,
    url: `${ENDPOINTS.SHIFTS}rider-shifts/${data.id}/`,
    data
  })
}

export const getAttendanceShiftsAPI = (filter = {}) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.SHIFTS}shifts/`,
    params: formatAttendanceFilter({ ...filter }),
  })
}

export const emailAttendanceCsvAPI = (filter={} ) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.SHIFTS}/send_attendance_listing_email/`,
    params: formatAttendanceFilter({...filter}),
  });
};


export const getRiderShiftAttendanceAPI = (filter = {}) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.SHIFTS}rider-shift-attendance/`,
    params: caseConverter.snakeKeys({ ...filter }),
  })
}
