import React from 'react';
import { isToday, formatDistance } from 'date-fns';
import { fullName, formatScanTypes, hmmaaa, scanTypeLabel } from '../../utils/helper';
import { STATES_TO_CANCEL } from '../../utils/constants';

export const OrderInfoWindow = ({ info: order, onCancelOrderClick, history }) => {
  let { id, number, address, pickup, scanTypes, state } = order;
  const formattedScanTypes = formatScanTypes(scanTypes);
  pickup = new Date(pickup);
  const pickupText = isToday(pickup) ? 'Today' : formatDistance(pickup, new Date(), { addSuffix: true });
  return <div className="map-popup">
    <span className="time">{pickupText} at {hmmaaa(pickup)}</span>
    <div className="head">
      <span className="order-id">{number}</span>
      <ul className="action-list">
        <li>
          <a
          href=''
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push(`/orders/detail/${id}`)
          }}
        ><img src={require("../../assets/images/pencil.svg")}/></a>
      </li>

        {STATES_TO_CANCEL.includes(state) &&
        <li>
          <a
            href=''
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onCancelOrderClick(order.id)
            }}
          ><img src={require("../../assets/images/cancel.svg")}/></a></li>
        }
      </ul>
    </div>
    <span className="name">{fullName(order.patient)}</span>
    <span className="address"><img src={require("../../assets/images/map-marker-white.svg")}/>{address}</span>
    <div className="lab-test"><img src={require("../../assets/images/lab-white.svg")}/>
      {[...formattedScanTypes].splice(0, 2).map((item) => {
        return <span className="tag">{scanTypeLabel(item)}</span>
      })}
      {formattedScanTypes.length > 2 ? <span className="tag">{formattedScanTypes.length - 2} More</span> : ''}

    </div>
  </div>
};
