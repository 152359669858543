import {connect} from 'react-redux';

import {shiftActions} from '../../actions/shift';
import {attendanceActions} from '../../actions/attendance';
import {selectRidersSearchListFromState} from "../../selectors/rider";
import {selectRegionsListFromState, selectRegionDetailFromState,} from "../../selectors/location";
import {VERTICALS} from "../../utils/constants";
import {riderActions} from "../../actions/rider";
import {locationActions} from '../../actions/location';
import {orderActions} from '../../actions/order';
import {
  selectAttendanceListCountFromState,
  selectAttendanceListFromState,
  selectAttendanceListLoadingFromState,
  selectAttendanceListNextFromState,
  selectAttendanceShiftsFromState,
  selectRiderShiftAttendanceFromState,
} from '../../selectors/attendance';

let Components = {
  'AttendanceList': require('./attendance-list').default,
}

const mapDispatchToProps = {
  selectRegionDetail: locationActions.selectRegionDetail,
  updateOrderParams: orderActions.updateOrderParams,
  updateRiderParams: riderActions.updateRiderParams,
  updateAttendanceParams: attendanceActions.updateAttendanceParams,
  resetAttendanceState: attendanceActions.resetAttendanceState,
  getShifts: shiftActions.getShiftsRequest,
  getFilteredAttendanceList: attendanceActions.getFilteredAttendanceListRequest,
  getAttendanceList: attendanceActions.getAttendanceListRequest,
  getAttendanceShifts: attendanceActions.getAttendanceShiftsRequest,
  getRiderShiftAttendance: attendanceActions.getRiderShiftAttendanceRequest,
  emailAttendanceCsv: attendanceActions.emailAttendanceCsvRequest,
  getRidersSearch: riderActions.getRidersSearchRequest,
  resetRiderState: riderActions.resetRiderState,
  addShiftBreak: attendanceActions.addShiftBreakRequest,
  getAllRegions: locationActions.getAllRegionsRequest,
}

const mapStateToProps = state => ({
  attendanceList: selectAttendanceListFromState(state),
  attendanceListCount: selectAttendanceListCountFromState(state),
  attendanceListNext: selectAttendanceListNextFromState(state),
  attendanceLoading: selectAttendanceListLoadingFromState(state),
  regions: selectRegionsListFromState(state),
  regionDetail: selectRegionDetailFromState(state, VERTICALS.COMMON),
  attendanceShifts: selectAttendanceShiftsFromState(state),
  riderShiftAttendance: selectRiderShiftAttendanceFromState(state),
  ridersSearch: selectRidersSearchListFromState(state),
})

export const AttendanceContainer = (componentName) => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Components[componentName]);
};
