import { connect } from 'react-redux';
import { BarChart } from './bar-chart';
import {graphSelector} from "../../../selectors/order";


const mapStateToProps = state => ({
  data: graphSelector(state),
});

const mapDispatchToProps = {
};

export const BarChartContainer = connect(mapStateToProps, mapDispatchToProps)(BarChart);
