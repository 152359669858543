export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE';

export const SELECT_REGION_DETAIL = 'SELECT_REGION_DETAIL';
export const SELECT_CITY_DETAIL = 'SELECT_CITY_DETAIL';

export const GET_COLLECTION_CENTERS_REQUEST = 'GET_COLLECTION_CENTERS_REQUEST';
export const GET_COLLECTION_CENTERS_SUCCESS = 'GET_COLLECTION_CENTERS_SUCCESS';
export const GET_COLLECTION_CENTERS_FAILURE = 'GET_COLLECTION_CENTERS_FAILURE';

export const GET_ALL_REGIONS_REQUEST = 'GET_ALL_REGIONS_REQUEST';
export const GET_ALL_REGIONS_SUCCESS = 'GET_ALL_REGIONS_SUCCESS';
export const GET_ALL_REGIONS_FAILURE = 'GET_ALL_REGIONS_FAILURE';

export const GET_REGIONS_REQUEST = 'GET_REGIONS_REQUEST';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_FAILURE = 'GET_REGIONS_FAILURE';

export const GET_HOT_SPOTS_REQUEST = 'GET_HOT_SPOTS_REQUEST'
export const GET_HOT_SPOTS_SUCCESS = 'GET_HOT_SPOTS_SUCCESS'
export const GET_HOT_SPOTS_FAILURE = 'GET_HOT_SPOTS_FAILURE'

export const GET_RIDER_LIVE_LOCATION_REQUEST = 'GET_RIDER_LIVE_LOCATION_REQUEST';
export const GET_RIDER_LIVE_LOCATION_SUCCESS = 'GET_RIDER_LIVE_LOCATION_SUCCESS';
export const GET_RIDER_LIVE_LOCATION_FAILURE = 'GET_RIDER_LIVE_LOCATION_FAILURE';
