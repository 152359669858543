import { types } from '../../actions/shift'
import { INITIAL_STATE } from "../initial-state"

export const shift = (state = INITIAL_STATE.shift, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_SHIFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SHIFT_SUCCESS:
      return {
        ...state,
        shiftDetail: action.payload,
        loading: false,
      };
    case types.GET_SHIFT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_SHIFTS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_SHIFTS_SUCCESS:
      return {
        ...state,
        shiftDetail: {},
        shiftRiders: INITIAL_STATE.shift.shiftRiders,
        list: payload,
        loading: false,
      }
    case types.GET_SHIFTS_FAILURE:
      return {
        ...state,
        loading: false
      }
    case types.CREATE_SHIFT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.CREATE_SHIFT_SUCCESS:
    case types.CREATE_SHIFT_FAILURE:
      return {
        ...state,
        loading: false
      }
    case types.GET_SHIFT_RIDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_SHIFT_RIDERS_SUCCESS:
      return {
        ...state,
        shiftRiders: payload,
        loading: false
      };
    case types.GET_SHIFT_RIDERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.CREATE_SHIFT_RIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CREATE_SHIFT_RIDER_SUCCESS:
      return {
        ...state,
        shiftRiders: state.shiftRiders.map((shift) => shift.rider.id === payload.rider.id ? payload : shift),
        loading: false
      };
    case types.CREATE_SHIFT_RIDER_FAILURE:
      return {
        ...state,
        loading: false
      };

    case types.UPDATE_SHIFT_RIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.UPDATE_SHIFT_RIDER_SUCCESS:
      return {
        ...state,
        shiftRiders: state.shiftRiders.map((shift) => shift.rider.id === payload.rider.id ? payload : shift),
        loading: false
      };
    case types.UPDATE_SHIFT_RIDER_FAILURE:
      return {
        ...state,
        loading: false
      };

    case types.DELETE_SHIFT_RIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.DELETE_SHIFT_RIDER_SUCCESS:
      return {
        ...state,
        shiftRiders: state.shiftRiders.filter((shift) => shift.id !== payload),
        loading: false
      };
    case types.DELETE_SHIFT_RIDER_FAILURE:
      return {
        ...state,
        loading: false
      };

    case types.ADD_SHIFT_RIDER:
      return {
        ...state,
        shiftRiders: [payload, ...state.shiftRiders],
        loading: false
      };

    case types.REMOVE_SHIFT_RIDER:
      const riderId = payload
      return {
        ...state,
        shiftRiders: state.shiftRiders.filter((shift) => shift.rider.id != riderId),
        loading: false
      };

    case types.UPDATE_SHIFT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.UPDATE_SHIFT_SUCCESS:
      return {
        ...state,
        shiftDetail: payload,
        loading: false
      };
    case types.UPDATE_SHIFT_FAILURE:
      return {
        ...state,
        loading: false
      };

    case types.DELETE_SHIFT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.DELETE_SHIFT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.COPY_SHIFT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.COPY_SHIFT_SUCCESS:
      return {
        ...state,
        list: [...state.list, payload],
        loading: false,
      }
    case types.COPY_SHIFT_FAILURE:
      return {
        ...state,
        loading: false
      }
    case types.RESET_SHIFT_STATE:
      return {
        ...state,
        ...INITIAL_STATE.shift,
      };
    default:
      return state
  }
}
