import React from 'react';
import Spinner from 'react-spinkit';

export const Loader = () => {
  return <Spinner name="three-bounce" style={{
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign:'center'
  }}/>
};
