import * as types from './types';
import {
  payloadActionCreator,
    emptyActionCreator
} from '../../utils/actions';

export const getCustomerDetailRequest = payloadActionCreator(types.GET_CUSTOMER_DETAIL_REQUEST);
export const getCustomerDetailSuccess = payloadActionCreator(types.GET_CUSTOMER_DETAIL_SUCCESS);
export const getCustomerDetailFailure = payloadActionCreator(types.GET_CUSTOMER_DETAIL_FAILURE);

export const getCustomersRequest = payloadActionCreator(types.GET_CUSTOMERS_REQUEST);
export const getCustomersSuccess = payloadActionCreator(types.GET_CUSTOMERS_SUCCESS);
export const getCustomersFailure = payloadActionCreator(types.GET_CUSTOMERS_FAILURE);

export const getFilteredCustomersRequest = payloadActionCreator(types.GET_FILTERED_CUSTOMERS_REQUEST);
export const getFilteredCustomersSuccess = payloadActionCreator(types.GET_FILTERED_CUSTOMERS_SUCCESS);
export const getFilteredCustomersFailure = payloadActionCreator(types.GET_FILTERED_CUSTOMERS_FAILURE);

export const getCustomerAddressRequest = payloadActionCreator(types.GET_CUSTOMER_ADDRESS_REQUEST);
export const getCustomerAddressSuccess = payloadActionCreator(types.GET_CUSTOMER_ADDRESS_SUCCESS);
export const getCustomerAddressFailure = payloadActionCreator(types.GET_CUSTOMER_ADDRESS_FAILURE);

export const createCustomerAddressRequest = payloadActionCreator(types.CREATE_CUSTOMER_ADDRESS_REQUEST);
export const createCustomerAddressSuccess = payloadActionCreator(types.CREATE_CUSTOMER_ADDRESS_SUCCESS);
export const createCustomerAddressFailure = payloadActionCreator(types.CREATE_CUSTOMER_ADDRESS_FAILURE);

export const createCustomerRequest = payloadActionCreator(types.CREATE_CUSTOMER_REQUEST);
export const createCustomerSuccess = payloadActionCreator(types.CREATE_CUSTOMER_SUCCESS);
export const createCustomerFailure = payloadActionCreator(types.CREATE_CUSTOMER_FAILURE);

export const updateCustomerRequest = payloadActionCreator(types.UPDATE_CUSTOMER_REQUEST);
export const updateCustomerSuccess = payloadActionCreator(types.UPDATE_CUSTOMER_SUCCESS);
export const updateCustomerFailure = payloadActionCreator(types.UPDATE_CUSTOMER_FAILURE);

export const getCustomersIdRequest = payloadActionCreator(types.GET_CUSTOMERS_ID_REQUEST);
export const getCustomersIdSuccess = payloadActionCreator(types.GET_CUSTOMERS_ID_SUCCESS);
export const getCustomersIdFailure = payloadActionCreator(types.GET_CUSTOMERS_ID_FAILURE);

export const resetCustomerState = emptyActionCreator(types.RESET_CUSTOMER_STATE);
export const updateCustomerParams = payloadActionCreator(types.UPDATE_CUSTOMER_PARAMS);

export const getCustomerOrdersRequest = payloadActionCreator(types.GET_CUSTOMER_ORDERS_REQUEST);
export const getCustomerOrdersSuccess = payloadActionCreator(types.GET_CUSTOMER_ORDERS_SUCCESS);
export const getCustomerOrdersFailure = payloadActionCreator(types.GET_CUSTOMER_ORDERS_FAILURE);

export const getFilteredCustomerOrdersRequest = payloadActionCreator(types.GET_FILTERED_CUSTOMER_ORDERS_REQUEST);
export const getFilteredCustomerOrdersSuccess = payloadActionCreator(types.GET_FILTERED_CUSTOMER_ORDERS_SUCCESS);
export const getFilteredCustomerOrdersFailure = payloadActionCreator(types.GET_FILTERED_CUSTOMER_ORDERS_FAILURE);

export const deleteCustomerAddressRequest = payloadActionCreator(types.DELETE_CUSTOMER_ADDRESS_REQUEST);
export const deleteCustomerAddressSuccess = payloadActionCreator(types.DELETE_CUSTOMER_ADDRESS_SUCCESS);
export const deleteCustomerAddressFailure = payloadActionCreator(types.DELETE_CUSTOMER_ADDRESS_FAILURE);

export const createCustomerPatientRequest = payloadActionCreator(types.CREATE_CUSTOMER_PATIENT_REQUEST);
export const createCustomerPatientSuccess = payloadActionCreator(types.CREATE_CUSTOMER_PATIENT_SUCCESS);
export const createCustomerPatientFailure = payloadActionCreator(types.CREATE_CUSTOMER_PATIENT_FAILURE);

export const updateCustomerPatientRequest = payloadActionCreator(types.UPDATE_CUSTOMER_PATIENT_REQUEST);
export const updateCustomerPatientSuccess = payloadActionCreator(types.UPDATE_CUSTOMER_PATIENT_SUCCESS);
export const updateCustomerPatientFailure = payloadActionCreator(types.UPDATE_CUSTOMER_PATIENT_FAILURE);

export const deleteCustomerPatientRequest = payloadActionCreator(types.DELETE_CUSTOMER_PATIENT_REQUEST);
export const deleteCustomerPatientSuccess = payloadActionCreator(types.DELETE_CUSTOMER_PATIENT_SUCCESS);
export const deleteCustomerPatientFailure = payloadActionCreator(types.DELETE_CUSTOMER_PATIENT_FAILURE);

export const updateCustomerAddressRequest = payloadActionCreator(types.UPDATE_CUSTOMER_ADDRESS_REQUEST);
export const updateCustomerAddressSuccess = payloadActionCreator(types.UPDATE_CUSTOMER_ADDRESS_SUCCESS);
export const updateCustomerAddressFailure = payloadActionCreator(types.UPDATE_CUSTOMER_ADDRESS_FAILURE);
