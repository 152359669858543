import {apiCaller} from '../api-caller';
import {ENDPOINTS, REQUEST_TYPES} from '../constants';

export const getCitiesAPI = ({vertical}) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_CITIES,
    params: {vertical}
  })
};

export const getCollectionCentersAPI = ({regionId}) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.GET_COLLECTION_CENTERS}?region=${regionId}`,
});

export const getAllRegionsAPI = () => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.GET_REGIONS,
});

export const getRegionsAPI = ({vertical}) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.GET_REGIONS,
  params: {vertical}
});

export const getHotSpotsAPI = (filter = {}) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.GET_HOT_SPOTS,
  params: filter,
});

export const getRiderLiveLocationAPI = uuid => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.RIDER_LIVE_LOCATION}${uuid}/`
});
