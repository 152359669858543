import { fork } from 'redux-saga/effects';
import { authSaga } from './auth';
import { riderSaga } from './rider';
import { orderSaga } from './order';
import { customerSaga } from './customer';
import { locationSaga } from './location';
import { feedbackSaga } from './feedback';
import { shiftSaga } from './shift';
import { attendanceSaga } from './attendance';

export function* rootSaga() {
  yield fork(authSaga);
  yield fork(riderSaga);
  yield fork(orderSaga);
  yield fork(customerSaga);
  yield fork(locationSaga);
  yield fork(feedbackSaga);
  yield fork(shiftSaga);
  yield fork(attendanceSaga);
}
