import {connect} from 'react-redux';
import {
  selectCustomerAddressFromState,
  selectCustomerDetailFromState,
  selectCustomerOrdersListFromState,
  selectCustomerOrdersLoadingFromState,
  selectCustomerOrdersNextFromState,
  selectCustomerParamsFromState,
  selectCustomersCountFromState,
  selectCustomersListFromState,
  selectCustomersListLoadingFromState,
  selectCustomersNextFromState,
} from "../../selectors/customer";
import {
  selectCitiesListFromState,
  selectRegionDetailFromState,
  selectRegionsListFromState
} from "../../selectors/location";
import {VERTICALS} from "../../utils/constants";
import {customerActions} from "../../actions/customer";
import {
  selectExternalOrderParamsFromState,
  selectFilteredOrderParamsFromState,
} from '../../selectors/order';
import {selectRiderParamsFromState} from '../../selectors/rider';
import {locationActions} from '../../actions/location';
import {selectAttendanceListParamsFromState} from '../../selectors/attendance';

let Components = {
  'CustomerList': require('./customer-list').default,
  'CustomerDetail': require('./customer-detail').default,
  'CustomerCreate': require('./customer-create').default,
  'CustomerUpdate': require('./customer-update').default,
};
const mapDispatchToProps = {
  getCustomers: customerActions.getCustomersRequest,
  getFilteredCustomers: customerActions.getFilteredCustomersRequest,
  updateCustomerParams: customerActions.updateCustomerParams,
  getCustomerDetail: customerActions.getCustomerDetailRequest,
  getCustomerAddress: customerActions.getCustomerAddressRequest,
  createCustomerAddress: customerActions.createCustomerAddressRequest,
  createCustomer: customerActions.createCustomerRequest,
  updateCustomer: customerActions.updateCustomerRequest,
  resetCustomerState: customerActions.resetCustomerState,
  getCustomerOrders: customerActions.getCustomerOrdersRequest,
  getFilteredCustomerOrders: customerActions.getFilteredCustomerOrdersRequest,
  deleteCustomerAddress: customerActions.deleteCustomerAddressRequest,
  getAllRegions: locationActions.getAllRegionsRequest,
  getCities: locationActions.getCitiesRequest,
};

const mapStateToProps = state => ({
  customers: selectCustomersListFromState(state),
  customersLoading: selectCustomersListLoadingFromState(state),
  customersCount: selectCustomersCountFromState(state),
  customersNext: selectCustomersNextFromState(state),
  bloodOrderParams: selectFilteredOrderParamsFromState(state, VERTICALS.BLOOD),
  xrayOrderParams: selectFilteredOrderParamsFromState(state, VERTICALS.XRAY),
  riderParams: selectRiderParamsFromState(state),
  customerParams: selectCustomerParamsFromState(state),
  attendanceParams: selectAttendanceListParamsFromState(state),
  externalOrderParams: selectExternalOrderParamsFromState(state),
  customerAddress: selectCustomerAddressFromState(state),
  customerDetail: selectCustomerDetailFromState(state),
  customerOrders: selectCustomerOrdersListFromState(state),
  customerOrdersNext: selectCustomerOrdersNextFromState(state),
  customerOrdersLoading: selectCustomerOrdersLoadingFromState(state),
  cities: selectCitiesListFromState(state),
  regions: selectRegionsListFromState(state),
  regionDetail: selectRegionDetailFromState(state, VERTICALS.COMMON)
});

export const CustomerContainer = (componentName) => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Components[componentName]);
};
