import React, {useEffect, useState} from 'react';
import reassignmentReason from '../../assets/images/re-assignment.png';

const OrderReassignmentReason = ({
                                   orderId,
                                   selectedRiderId,
                                   assignOrder,
                                   orderParams,
                                   closeModal,
                                   reassignmentStrings,
                                   setSelectedRiderId,
                                   vertical
                                 }) => {
  const [reason, setReason] = useState('');
  const [reassignmentStringId, setReassignmentStringId] = useState('');

  useEffect(() => {
    if (reassignmentStrings.length > 0) {
      setReassignmentStringId(reassignmentStrings[0].id);
    }
  }, reassignmentStrings);

  return (
    <>
      <div className="lightbox schedule">
        <div className="add-comment-lightbox">
          <a
            href=''
            className="lightbox-close icon-clear"
            onClick={(e) => {
              e.preventDefault();
              setSelectedRiderId(null);
              closeModal();
            }}
          />
          <h2>Order Reassignment</h2>
          <div className="content-holder">
            <div className="img-holder">
              <img src={reassignmentReason} width="224" alt=""/>
            </div>
            <div className="right-area">
              <div className="form-row">
                <label>Reason for Reassignment</label>
                <select onChange={e => {
                  setReassignmentStringId(reassignmentStrings.find(i => i.text === e.target.value).id)
                }}>
                  {reassignmentStrings.map(i => <option>{i.text}</option>)}
                </select>
              </div>
              <div className="form-row">
                <textarea
                  placeholder=""
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
              <div className="btns-holder">
                <a href="#" className="red" onClick={e => {
                  e.preventDefault();
                  assignOrder({
                    orderParams,
                    data: {
                      id: orderId,
                      rider: selectedRiderId,
                      reassignmentStringId,
                      reason,
                      vertical
                    },
                  });
                  setSelectedRiderId(null);
                  closeModal();
                }}>Reassign Order</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderReassignmentReason;
