import React from 'react';
import { object, shape, string } from 'prop-types';


export const RadioButtonWithLabel = ({ field, form, ...props }) => {
  return (
    <React.Fragment>
      <label>
        <input
          {...field}
          {...props}
        />
        <span className="fake-radio"/>
        <span className="label-text">{props.label}</span>
      </label>
    </React.Fragment>
  );
};

RadioButtonWithLabel.propTypes = {
  field: shape({
    name: string,
    value: string,
  }).isRequired,
  form: object,
};

