import React from 'react';
import { isEmpty } from 'lodash';
import { Field, Formik } from 'formik';
import { TextFormFieldWithError } from '../ui/form-fields/text-form-field-with-error';
import { RadioButtonWithLabel } from '../ui/form-fields/radio-button-with-label';
import { patientSchema } from '../../utils/validation';


const PatientInformationCreate = (props) => {
  const { patient, createCustomerPatient, updateCustomerPatient, customerId, onCancel, setSelectedPatient, order, id } = props;
  const isUpdate = !isEmpty(patient);

  const initialValues = isUpdate ? {
    ...patient,
    gender: patient.gender.toString(),
    age: patient?.ageWithUnit?.age,
    ageUnit: patient?.ageWithUnit?.ageUnit
  } :
    {
      firstName: id ? order?.name : '',
      lastName: '',
      email: id ? order?.email : '',
      gender: '1',
      age: '',
      secondaryPhone: '',
      ageUnit: 'YEARS'
    };

  return <div className="col-info customer-info">
    <h2>Patient Information</h2>
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={patientSchema}
      onSubmit={(values) => {
        if (isUpdate) {
          updateCustomerPatient({
            id: patient.id,
            data: { ...values, customer: customerId },
          });
        } else {
          createCustomerPatient({ ...values, customer: customerId });
          setSelectedPatient({});
        }
      }}
    >
      {formik => (
        <>
          <div className="form-area">
            <div className="form-row">
              <label>First Name *</label>
              <Field
                type="text"
                name='firstName'
                placeholder="FIRST NAME"
                component={TextFormFieldWithError}
              />
            </div>
            <div className="form-row">
              <label>Last Name *</label>
              <Field
                type="text"
                name='lastName'
                placeholder="LAST NAME"
                component={TextFormFieldWithError}
              />
            </div>
            <div className="form-row">
              <label>Email</label>
              <Field
                type="text"
                name='email'
                placeholder="johndoe@gmail.com"
                component={TextFormFieldWithError}
              />
            </div>
            <div className="form-row">
              <label>Secondary Phone</label>
              <Field
                type="text"
                name='secondaryPhone'
                placeholder="03000000000"
                component={TextFormFieldWithError}
              />
            </div>
            <div className="form-row">
              <span className="label-text">Gender *</span>
              <ul className="radio-list">
                <li>
                  <Field name="gender" type="radio" value="1" label='Male'
                    component={RadioButtonWithLabel}
                  />
                </li>
                <li>
                  <Field name="gender" type="radio" value="2" label='Female'
                    component={RadioButtonWithLabel}
                  />
                </li>
              </ul>
            </div>

            <div className='row'>
              <div className=" col-6">
                <label>Age</label>
                <Field
                  className='!w-[200px]'
                  type="text"
                  name='age'
                  placeholder="Enter Age"
                  component={TextFormFieldWithError}
                />
              </div>

              <div className="col-6 select">
                <label>Age unit</label>
                <div className='select'>
                  <Field
                    className='w-[200px]'
                    as="select"
                    name="ageUnit"
                  >
                    <option value="YEARS">Year</option>
                    <option value="MONTHS">Month</option>
                    <option value="DAYS">Day</option>
                  </Field>
                </div>
              </div>
            </div>
          </div>

          <ul className="btn-holder">
            <li>
              <button className="cancel" onClick={(e) => onCancel(e, isUpdate)}>Cancel</button>
            </li>
            <li>
              <button onClick={formik.submitForm}>{isUpdate ? 'Save' : 'Create'}</button>
            </li>
          </ul>
        </>
      )}
    </Formik>
  </div>;
};

export default PatientInformationCreate;
