import { connect } from 'react-redux';

import {
  selectIsInteractionIdValidFromState,
  selectIsReviewCompleteFromState,
  selectIsExpiredFromState, selectCROFeedbackQuestionsFromState, selectIsSatisfiedFromState,
} from "../../../selectors/feedback";
import {feedbackActions} from "../../../actions/feedback";
import {CROFeedback} from "./CROFeedback";

const mapStateToProps = state => ({
  croFeedbackQuestions: selectCROFeedbackQuestionsFromState(state),
  isComplete: selectIsReviewCompleteFromState(state),
  isInteractionIdValid: selectIsInteractionIdValidFromState(state),
  isExpired: selectIsExpiredFromState(state),
  isSatisfied: selectIsSatisfiedFromState(state),
});

const mapDispatchToProps = {
  getCROFeedbackQuestions: feedbackActions.getCROFeedbackQuestionsRequest,
  postCROFeedback: feedbackActions.postCROFeedbackRequest,
  getCROFeedback: feedbackActions.getCROFeedbackRequest
};

export const CROFeedbackContainer = connect(mapStateToProps, mapDispatchToProps)(CROFeedback);
