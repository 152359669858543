import * as types from './types';
import {payloadActionCreator} from '../../utils/actions';

export const getCitiesRequest = payloadActionCreator(types.GET_CITIES_REQUEST);
export const getCitiesSuccess = payloadActionCreator(types.GET_CITIES_SUCCESS);
export const getCitiesFailure = payloadActionCreator(types.GET_CITIES_FAILURE);

export const selectRegionDetail = payloadActionCreator(types.SELECT_REGION_DETAIL);
export const selectCityDetail = payloadActionCreator(types.SELECT_CITY_DETAIL);

export const getCollectionCentersRequest = payloadActionCreator(types.GET_COLLECTION_CENTERS_REQUEST);
export const getCollectionCentersSuccess = payloadActionCreator(types.GET_COLLECTION_CENTERS_SUCCESS);
export const getCollectionCentersFailure = payloadActionCreator(types.GET_COLLECTION_CENTERS_FAILURE);

export const getAllRegionsRequest = payloadActionCreator(types.GET_ALL_REGIONS_REQUEST);
export const getAllRegionsSuccess = payloadActionCreator(types.GET_ALL_REGIONS_SUCCESS);
export const getAllRegionsFailure = payloadActionCreator(types.GET_ALL_REGIONS_FAILURE);

export const getRegionsRequest = payloadActionCreator(types.GET_REGIONS_REQUEST);
export const getRegionsSuccess = payloadActionCreator(types.GET_REGIONS_SUCCESS);
export const getRegionsFailure = payloadActionCreator(types.GET_REGIONS_FAILURE);

export const getHotSpotsRequest = payloadActionCreator(types.GET_HOT_SPOTS_REQUEST)
export const getHotSpotsSuccess = payloadActionCreator(types.GET_HOT_SPOTS_SUCCESS)
export const getHotSpotsFailure = payloadActionCreator(types.GET_HOT_SPOTS_FAILURE)

export const getRiderLiveLocationRequest = payloadActionCreator(types.GET_RIDER_LIVE_LOCATION_REQUEST)
export const getRiderLiveLocationSuccess = payloadActionCreator(types.GET_RIDER_LIVE_LOCATION_SUCCESS)
export const getRiderLiveLocationFailure = payloadActionCreator(types.GET_RIDER_LIVE_LOCATION_FAILURE)
