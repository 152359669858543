import React from 'react';
import logo from '../../assets/images/logo2.png'

export const OrderReviewWrapper = ({ children }) => {
  return <div id="wrapper">
    <div className="page-table">
      <div className="page-table-head">
        <div className="head-holder">
          <div className="logo-one">
            <a href="#"><img src={logo} alt="logo"/></a>
          </div>
        </div>
      </div>
      {children}
      <div className="page-table-footer">
        <div className="footer-holder">
          <p>For any business related query and new offers please visit our website</p>
          <a href="https://chughtailab.com/" target='_blank'>www.chughtailab.com</a>
        </div>
      </div>
    </div>
  </div>
};
