import React from 'react';

const Confirmation = ({ text, onConfirm, onClose }) => {
  return (
    <>
      <div className="lightbox small-modal-con">
        <div className="lightbox-con">
          <a
            href=''
            className="lightbox-close icon-clear"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          />
          <div className="form-row">
            <p>{text}</p>
          </div>
          <button className="create-btn" onClick={(e) => {
            e.preventDefault();
            onConfirm()
          }}>Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
