import { call, put, } from 'redux-saga/effects';
import * as _ from 'lodash'
import { logger } from 'utils/logger';
import { attendanceActions } from '../../actions/attendance';
import {
  emailAttendanceCsvAPI,
  getAttendanceListAPI,
  getAttendanceShiftsAPI,
  getRiderShiftAttendanceAPI, shiftBreakAPI,
} from '../../api/attendance';
import { toast } from 'react-toastify';

const transformRiderShiftAttendance = (riderShiftAttendance) => {
  if (_.isEmpty(riderShiftAttendance)) {
    return {}
  }
  // i don't know how this works
  const transformedRiderShiftAttendance = {};
  let datetime = new Date(riderShiftAttendance[0].startDatetimeslot)
  let min = datetime.getMinutes() % 5
  let startDateTime = (new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate(), datetime.getHours(), min)).getTime()
  let endDateTime = (new Date(riderShiftAttendance[riderShiftAttendance.length - 1].endDatetimeslot))
  endDateTime = (new Date(endDateTime.getFullYear(), endDateTime.getMonth(), endDateTime.getDate(), endDateTime.getHours(), 60)).getTime()
  for (let time = startDateTime; time < endDateTime; time = time + 300000) {
    let datetime = new Date(time)
    let datetimeString = datetime.toISOString()
    let datetimeWithoutMinutes = new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate(), datetime.getHours())
    let datetimeWithoutMinutesString = datetimeWithoutMinutes.toISOString()
    if (datetimeWithoutMinutesString in transformedRiderShiftAttendance) {
      transformedRiderShiftAttendance[datetimeWithoutMinutesString][datetimeString] = false
    } else {
      transformedRiderShiftAttendance[datetimeWithoutMinutesString] = { [datetimeString]: false }
    }
  }
  for (let riderShiftAttend of riderShiftAttendance) {
    let datetime = new Date(riderShiftAttend.startDatetimeslot)
    datetime.setSeconds(0)
    let datetimeString = datetime.toISOString()
    let datetimeWithoutMinutes = new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate(), datetime.getHours())
    let datetimeWithoutMinutesString = datetimeWithoutMinutes.toISOString()
    if (datetimeWithoutMinutesString in transformedRiderShiftAttendance) {
      transformedRiderShiftAttendance[datetimeWithoutMinutesString][datetimeString] = riderShiftAttend.isPresent
    } else {
      transformedRiderShiftAttendance[datetimeWithoutMinutesString] = { [datetimeString]: riderShiftAttend.isPresent }
    }
  }
  return transformedRiderShiftAttendance
};

export function* getAttendanceList({ payload: values }) {
  try {
    const resp = yield call(getAttendanceListAPI, values);
    if (resp) {
      yield put(attendanceActions.getAttendanceListSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(attendanceActions.getAttendanceListFailure(error));
  }
}

export function* getFilteredAttendanceList({ payload: values }) {
  try {
    const resp = yield call(getAttendanceListAPI, values);
    if (resp) {
      yield put(attendanceActions.getFilteredAttendanceListSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(attendanceActions.getFilteredAttendanceListFailure(error));
  }
}

export function* getAttendanceShiftsList({ payload: values }) {
  try {
    const resp = yield call(getAttendanceShiftsAPI, values);
    if (resp) {
      yield put(attendanceActions.getAttendanceShiftsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(attendanceActions.getAttendanceShiftsFailure(error));
  }
}

export function* emailAttendanceCsv({ payload: values }) {
  try {
    const resp = yield call(emailAttendanceCsvAPI, values);
    if (resp) {
      toast.success('An email of the attendance data will be sent to you shortly.');
      yield put(attendanceActions.emailAttendanceCsvSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(attendanceActions.emailAttendanceCsvFailure(error));
  }
}

export function* getRiderShiftAttendance({ payload: values }) {
  try {
    const resp = yield call(getRiderShiftAttendanceAPI, values);
    if (resp) {
      yield put(attendanceActions.getRiderShiftAttendanceSuccess(transformRiderShiftAttendance(resp.data)));
    }
  } catch (error) {
    logger.error(error);
    yield put(attendanceActions.getRiderShiftAttendanceFailure(error));
  }
}

export function* addShiftBreakSaga({ payload: values }) {
  try {
    const resp = yield call(shiftBreakAPI, values);
    if (resp) {
      toast.success('Break given successfully!');
      yield put(attendanceActions.addShiftBreakSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(attendanceActions.addShiftBreakFailure(error));
  }
}
