import { call, put, } from 'redux-saga/effects';
import { logger } from 'utils/logger';
import { shiftActions } from '../../actions/shift';
import {
  copyShiftAPI,
  createShiftAPI,
  createShiftRiderAPI,
  deleteShiftAPI,
  deleteShiftRiderAPI,
  getShiftAPI,
  getShiftRidersAPI,
  getShiftsAPI,
  updateShiftAPI,
  updateShiftRiderAPI
} from '../../api/shift';
import { toast } from 'react-toastify';
import { push } from "connected-react-router";
import { ROUTES } from '../../routes/constants';
import { SHIFT_CREATE_ACTION } from '../../utils/constants';

export function* getShift({ payload: values }) {
  try {
    const resp = yield call(getShiftAPI, values);
    if (resp) {
      yield put(shiftActions.getShiftSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(shiftActions.getShiftFailure(error));
  }
}

export function* getShifts({ payload: values }) {
  try {
    const resp = yield call(getShiftsAPI, values);
    if (resp) {
      yield put(shiftActions.getShiftsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(shiftActions.getShiftsFailure(error));
  }
}

export function* createShift({ payload }) {
  try {
    const resp = yield call(createShiftAPI, payload.data)
    if (resp) {
      yield put(shiftActions.createShiftSuccess(resp.data))
      toast.success(`Your shift created successfully`)
      if (payload.action === SHIFT_CREATE_ACTION.SAVE) {
        yield put(push({ pathname: ROUTES.SHIFTS }))
      } else if (payload.action === SHIFT_CREATE_ACTION.SAVE_AND_ADD_RIDER) {
        yield put(push({ pathname: `${ROUTES.SHIFTS}/detail/${resp.data.id}` }));
      }
    }
  } catch (error) {
    logger.error(error);
    yield put(shiftActions.createShiftFailure(error));
  }
}


export function* getShiftRiders({ payload: values }) {
  try {
    const resp = yield call(getShiftRidersAPI, values);
    if (resp) {
      yield put(shiftActions.getShiftRidersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(shiftActions.getShiftRidersFailure(error));
  }
}

export function* createShiftRider({ payload }) {
  try {
    const resp = yield call(createShiftRiderAPI, payload)
    if (resp) {
      yield put(shiftActions.createShiftRiderSuccess(resp.data))
      toast.success(`Rider added to shift successfully`)
    }
  } catch (error) {
    logger.error(error);
    yield put(shiftActions.createShiftRiderFailure(error));
  }
}

export function* updateShiftRider({ payload }) {
  try {
    const resp = yield call(updateShiftRiderAPI, payload)
    if (resp) {
      yield put(shiftActions.updateShiftRiderSuccess(resp.data))
      toast.success(`Rider shift updated successfully`)
    }
  } catch (error) {
    logger.error(error);
    yield put(shiftActions.updateShiftRiderFailure(error));
  }
}

export function* deleteShiftRider({ payload: id }) {
  try {
    const resp = yield call(deleteShiftRiderAPI, id)
    if (resp) {
      yield put(shiftActions.deleteShiftRiderSuccess(id))
      toast.success(`Rider removed from shift successfully`)
    }
  } catch (error) {
    logger.error(error);
    yield put(shiftActions.deleteShiftRiderFailure(error));
  }
}

export function* updateShift({ payload }) {
  try {
    const resp = yield call(updateShiftAPI, payload)
    if (resp) {
      yield put(shiftActions.updateShiftSuccess(resp.data))
      yield put(push({ pathname: ROUTES.SHIFTS }))
      toast.success(`Shift updated successfully`)
    }
  } catch (error) {
    logger.error(error);
    yield put(shiftActions.updateShiftFailure(error));
  }
}

export function* deleteShift({ payload: id }) {
  try {
    const resp = yield call(deleteShiftAPI, id)
    if (resp) {
      yield put(shiftActions.deleteShiftSuccess(id))
      yield put(push({ pathname: ROUTES.SHIFTS }))
      toast.success(`Shift deleted successfully`)
    }
  } catch (error) {
    logger.error(error);
    yield put(shiftActions.deleteShiftFailure(error));
  }
}

export function* copyShift({ payload }) {
  try {
    const resp = yield call(copyShiftAPI, payload)
    if (resp) {
      yield put(shiftActions.copyShiftSuccess(resp.data))
      toast.success(`Shift copied successfully`)
    }
  } catch (error) {
    logger.error(error)
    yield put(shiftActions.copyShiftFailure(error))
  }
}
