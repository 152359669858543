// RIDERS
export const GET_RIDERS_REQUEST = 'GET_RIDERS_REQUEST';
export const GET_RIDERS_SUCCESS = 'GET_RIDERS_SUCCESS';
export const GET_RIDERS_FAILURE = 'GET_RIDERS_FAILURE';

export const GET_FILTERED_RIDERS_REQUEST = 'GET_FILTERED_RIDERS_REQUEST';
export const GET_FILTERED_RIDERS_SUCCESS = 'GET_FILTERED_RIDERS_SUCCESS';
export const GET_FILTERED_RIDERS_FAILURE = 'GET_FILTERED_RIDERS_FAILURE';
export const UPDATE_RIDER_PARAMS = 'UPDATE_RIDER_PARAMS';
// ONLINE RIDERS
export const GET_ONLINE_RIDERS_REQUEST = 'GET_ONLINE_RIDERS_REQUEST';
export const GET_ONLINE_RIDERS_SUCCESS = 'GET_ONLINE_RIDERS_SUCCESS';
export const GET_ONLINE_RIDERS_FAILURE = 'GET_ONLINE_RIDERS_FAILURE';

// ASSIGNABLE RIDER
export const GET_ASSIGNABLE_RIDER_REQUEST = 'GET_ASSIGNABLE_RIDER_REQUEST';
export const GET_ASSIGNABLE_RIDER_SUCCESS = 'GET_ASSIGNABLE_RIDER_SUCCESS';
export const GET_ASSIGNABLE_RIDER_FAILURE = 'GET_ASSIGNABLE_RIDER_FAILURE';
export const RESET_ASSIGNABLE_RIDER_STATE = 'RESET_ASSIGNABLE_RIDER_STATE';

// RIDER
export const GET_RIDER_REQUEST = 'GET_RIDER_REQUEST';
export const GET_RIDER_SUCCESS = 'GET_RIDER_SUCCESS';
export const GET_RIDER_FAILURE = 'GET_RIDER_FAILURE';

// RIDER STATS
export const GET_RIDER_STATS_REQUEST = 'GET_RIDER_STATS_REQUEST';
export const GET_RIDER_STATS_SUCCESS = 'GET_RIDER_STATS_SUCCESS';
export const GET_RIDER_STATS_FAILURE = 'GET_RIDER_STATS_FAILURE';

// RIDER LOCATION
export const GET_RIDER_LOCATION_REQUEST = 'GET_RIDER_LOCATION_REQUEST';
export const GET_RIDER_LOCATION_SUCCESS = 'GET_RIDER_LOCATION_SUCCESS';
export const GET_RIDER_LOCATION_FAILURE = 'GET_RIDER_LOCATION_FAILURE';

// RIDER TASKS
export const GET_RIDER_TASKS_REQUEST = 'GET_RIDER_TASKS_REQUEST';
export const GET_RIDER_TASKS_SUCCESS = 'GET_RIDER_TASKS_SUCCESS';
export const GET_RIDER_TASKS_FAILURE = 'GET_RIDER_TASKS_FAILURE';

export const RESET_RIDER_TASKS_STATE = 'RESET_RIDER_TASKS_STATE';

export const GET_NP_RIDERS_REQUEST = 'GET_NP_RIDERS_REQUEST';
export const GET_NP_RIDERS_SUCCESS = 'GET_NP_RIDERS_SUCCESS';
export const GET_NP_RIDERS_FAILURE = 'GET_NP_RIDERS_FAILURE';

export const GET_RIDERS_SEARCH_REQUEST = 'GET_RIDERS_SEARCH_REQUEST';
export const GET_RIDERS_SEARCH_SUCCESS = 'GET_RIDERS_SEARCH_SUCCESS';
export const GET_RIDERS_SEARCH_FAILURE = 'GET_RIDERS_SEARCH_FAILURE';

export const RESET_RIDER_STATE = 'RESET_RIDER_STATE';

export const GET_RIDER_ORDERS_REQUEST = 'GET_RIDER_ORDERS_REQUEST';
export const GET_RIDER_ORDERS_SUCCESS = 'GET_RIDER_ORDERS_SUCCESS';
export const GET_RIDER_ORDERS_FAILURE = 'GET_RIDER_ORDERS_FAILURE';

export const GET_FILTERED_RIDER_ORDERS_REQUEST = 'GET_FILTERED_RIDER_ORDERS_REQUEST';
export const GET_FILTERED_RIDER_ORDERS_SUCCESS = 'GET_FILTERED_RIDER_ORDERS_SUCCESS';
export const GET_FILTERED_RIDER_ORDERS_FAILURE = 'GET_FILTERED_RIDER_ORDERS_FAILURE';

export const GET_RIDER_FORCE_DELIVER_REQUEST = 'GET_RIDER_FORCE_DELIVER_REQUEST';
export const GET_RIDER_FORCE_DELIVER_SUCCESS = 'GET_RIDER_FORCE_DELIVER_SUCCESS';
export const GET_RIDER_FORCE_DELIVER_FAILURE = 'GET_RIDER_FORCE_DELIVER_FAILURE';

export const RIDER_MARK_OFF_DUTY_REQUEST = 'RIDER_MARK_OFF_DUTY_REQUEST';
export const RIDER_MARK_OFF_DUTY_SUCCESS = 'RIDER_MARK_OFF_DUTY_SUCCESS';
export const RIDER_MARK_OFF_DUTY_FAILURE = 'RIDER_MARK_OFF_DUTY_FAILURE';

export const CREATE_RIDER_REQUEST = 'CREATE_RIDER_REQUEST';
export const CREATE_RIDER_SUCCESS = 'CREATE_RIDER_SUCCESS';
export const CREATE_RIDER_FAILURE = 'CREATE_RIDER_FAILURE';

export const UPDATE_RIDER_REQUEST = 'UPDATE_RIDER_REQUEST';
export const UPDATE_RIDER_SUCCESS = 'UPDATE_RIDER_SUCCESS';
export const UPDATE_RIDER_FAILURE = 'UPDATE_RIDER_FAILURE';

export const REMOVE_RIDERS_SEARCH_ITEM = 'REMOVE_RIDERS_SEARCH_ITEM';
