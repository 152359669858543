import React from "react";
import Iframe from 'react-iframe'
import moment from "moment";
import {REDASH_CITY_PARAM, REDASH_DATE_PARAM, REDASH_REPORTING_URL} from "../../utils/constants";
import {Nav} from "../commons/nav";


const Issue = () => {
  return (
    <div id='wrapper'>
      <div className='reports-container'>
        <div className='nav-holder'>
          <Nav/>
        </div>
        <div className='report-holder'>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSflwFcaviMbEhu3YKbcF7iw-mrM5fKB4sukagkqtptjI5TSjA/viewform?embedded=true"
            width='100%' height='1000px' display='block'/>
        </div>
      </div>
    </div>
  );
};


export default Issue;
