import {
  call,
  put,
} from 'redux-saga/effects';
import { logger } from 'utils/logger';
import { isEmpty } from 'lodash';
import { orderActions } from '../../actions/order';
import {
  getOrdersAPI,
  getUnverifiedOrdersAPI,
  getOrderAPI,
  createOrderAPI,
  assignOrderAPI,
  cancelOrderAPI,
  getOrdersStatusAPI,
  getOrdersScanTypesAPI,
  reAssignOrderAPI,
  getOrderStatsAPI,
  getDashboardOrdersAPI,
  rescheduleOrderAPI,
  getOrderActivitiesAPI,
  getHearAboutUsListAPI,
  getProblemStringsAPI,
  getOrderTimeSlotsAPI,
  orderNeedsReviewAPI,
  getLateOrdersAPI,
  emailOrdersCsvAPI,
  getOnTimeRateStats,
  offlineInProgressOrderAPI,
  deliverOrderAPI,
  verifyExternalOrderAPI,
  followUpOrderAPI,
  updateAdditionalNotesAPI,
  updateScanTypesAPI,
  getCancellationStringsAPI,
  getOrderSourcesAPI,
  getReassignmentStringsAPI,
  orderFollowUpAddressedAPI,
  getAddressTypesAPI, getRescheduleStringsAPI,
  getPartialOrdersAPI,
  getPartialOrderDetailAPI,
  cancelPartialOrderAPI,
} from '../../api/order';
import { push } from "connected-react-router";
import { ROUTES } from "../../routes/constants";
import { toast } from 'react-toastify';
import { qsStringify } from '../../utils/helper';

const transformOrderTimeSlots = (date, orderTimeSlots) => {
  const transformedOrderTimeSlots = [];
  for (let orderTimeSlot of orderTimeSlots) {
    transformedOrderTimeSlots.push({
      ...orderTimeSlot,
      dateTime: new Date(`${date}T${orderTimeSlot.time}`)
    });
  }
  transformedOrderTimeSlots.sort((a, b) => a.dateTime - b.dateTime);
  return transformedOrderTimeSlots;
};

export function* getOrders({ payload }) {
  try {
    const resp = yield call(getOrdersAPI, payload);
    if (resp) {
      yield put(orderActions.getOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getOrdersFailure(error));
  }
}

export function* getUnverifiedOrders({ payload: values }) {
  try {
    const resp = yield call(getUnverifiedOrdersAPI, values);
    if (resp) {
      yield put(orderActions.getUnverifiedOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getUnverifiedOrdersFailure(error));
  }
}

export function* getPartialOrders({ payload: values }) {
  try {
    const resp = yield call(getPartialOrdersAPI, values);
    if (resp) {
      yield put(orderActions.getPartialOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getPartialOrdersFailure(error));
  }
}

export function* getPartialOrderDetail({ payload: values }) {
  try {
    const resp = yield call(getPartialOrderDetailAPI, values);
    if (resp) {
      yield put(orderActions.getPartialOrderDetailSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getPartialOrderDetailFailure(error));
  }
}

export function* getFilteredOrders({ payload }) {
  try {
    const resp = yield call(getOrdersAPI, payload);
    if (resp) {
      yield put(orderActions.getFilteredOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getFilteredOrdersFailure(error));
  }
}

export function* getFilteredUnverifiedOrders({ payload: values }) {
  try {
    const resp = yield call(getUnverifiedOrdersAPI, values);
    if (resp) {
      yield put(orderActions.getFilteredUnverifiedOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getFilteredUnverifiedOrdersFailure(error));
  }
}

export function* getFilteredPartialOrders({ payload: values }) {
  try {
    const resp = yield call(getPartialOrdersAPI, values);
    if (resp) {
      yield put(orderActions.getFilteredPartialOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getFilteredPartialOrdersFailure(error));
  }
}

export function* getDashboardOrders({ payload }) {
  try {
    const resp = yield call(getDashboardOrdersAPI, payload);
    if (resp) {
      yield put(orderActions.getDashboardOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getDashboardOrdersFailure(error));
  }
}

export function* getOrdersStates({ payload }) {
  try {
    const resp = yield call(getOrdersStatusAPI, payload);
    if (resp) {
      yield put(orderActions.getOrdersStatesSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getOrdersStatesFailure(error));
  }
}

export function* getOrder({ payload }) {
  try {
    const resp = yield call(getOrderAPI, payload);
    if (resp) {
      yield put(orderActions.getOrderSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getOrderFailure(error));
  }
}

export function* createOrder({ payload }) {
  try {
    const { vertical } = payload.data;
    const resp = yield call(createOrderAPI, payload.data);
    if (resp) {
      yield put(orderActions.createOrderSuccess(resp.data));
      toast.success(`Order created successfully, Order ID: ${resp.data.number}`);
      const orderParamsPath = qsStringify(payload.orderParams);
      yield put(push({
        pathname: `/${vertical}/orders`,
        search: '?' + orderParamsPath
      }));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.createOrderFailure(error));
  }
}

export function* assignOrder({ payload }) {
  try {
    const { vertical } = payload.data;
    const resp = yield call(assignOrderAPI, payload.data);
    if (resp) {
      yield put(orderActions.assignOrderSuccess(resp.data));
      const orderParamsPath = qsStringify(payload.orderParams);
      yield put(push({
        pathname: `/${vertical}/orders`,
        search: '?' + orderParamsPath
      }));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.assignOrderFailure(error));
  }
}

export function* reAssignOrder({ payload }) {
  try {
    const resp = yield call(reAssignOrderAPI, payload.data);
    if (resp) {
      yield put(orderActions.reAssignOrderSuccess(resp.data));
      const order = yield call(getOrderAPI, { id: payload.data.id, vertical: payload.data.vertical });
      if (order) {
        yield put(orderActions.getOrderSuccess(order.data));
      }
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.reAssignOrderFailure(error));
  }
}

export function* cancelOrder({ payload }) {
  try {
    const { vertical } = payload;
    const resp = yield call(cancelOrderAPI, payload);
    if (resp) {
      yield put(orderActions.cancelOrderSuccess(resp.data));
      if (!isEmpty(payload.filter)) {
        if (payload.isLoadDashboardOrders) {
          const orders = yield call(getDashboardOrdersAPI, payload.filter);
          if (orders) {
            yield put(orderActions.getDashboardOrdersSuccess(orders.data));
          }
        } else {
          if (payload.data?.isUnverified) {
            const orders = yield call(getUnverifiedOrdersAPI, payload.filter);
            if (orders) {
              yield put(orderActions.getFilteredUnverifiedOrdersSuccess(orders.data));
            }
          } else {
            const orders = yield call(getOrdersAPI, { ...payload.filter, vertical });
            if (orders) {
              yield put(orderActions.getFilteredOrdersSuccess(orders.data));
            }
          }
        }
      }
    }

  } catch (error) {
    logger.error(error);
    yield put(orderActions.cancelOrderFailure(error));
  }
}

export function* cancelPartialOrder({ payload }) {
  try {
    const resp = yield call(cancelPartialOrderAPI, payload);
    if (resp) {
      const newResp = yield call(getPartialOrdersAPI, payload.filter);
      if (newResp) {
        yield put(orderActions.getFilteredPartialOrdersSuccess(newResp.data));
      }
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.cancelPartialOrdersFailure(error));
  }
}

export function* getOrdersScanTypes({ payload: values }) {
  try {
    const resp = yield call(getOrdersScanTypesAPI, values);
    if (resp) {
      yield put(orderActions.orderScanTypesSuccess(resp.data));

    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.orderScanTypesFailure(error));
  }
}

export function* getOrderStats({ payload }) {
  try {
    const resp = yield call(getOrderStatsAPI, payload);
    if (resp) {
      yield put(orderActions.getOrderStatsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getOrderStatsFailure(error));
  }
}

export function* rescheduleOrder({ payload }) {
  try {
    const resp = yield call(rescheduleOrderAPI, payload);
    if (resp) {
      yield put(orderActions.rescheduleOrderSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.rescheduleOrderFailure(error));
  }
}

export function* getOrderActivities({ payload: values }) {
  try {
    const resp = yield call(getOrderActivitiesAPI, values);
    if (resp) {
      yield put(orderActions.getOrderActivitiesSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getOrderActivitiesFailure(error));
  }
}

export function* getHearAboutUsList() {
  try {
    const resp = yield call(getHearAboutUsListAPI);
    if (resp) {
      yield put(orderActions.getHearAboutUsListSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getHearAboutUsListFailure(error));
  }
}

export function* getProblemStrings({ payload: values }) {
  try {
    const resp = yield call(getProblemStringsAPI, values);
    if (resp) {
      yield put(orderActions.getProblemStringsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getProblemStringsFailure(error));
  }
}

export function* getCancellationStrings({ payload: values }) {
  try {
    const resp = yield call(getCancellationStringsAPI, values);
    if (resp) {
      yield put(orderActions.getCancellationStringsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getCancellationStringsFailure(error));
  }
}

export function* getOrderTimeSlots({ payload }) {
  try {
    const resp = yield call(getOrderTimeSlotsAPI, payload);
    if (resp) {
      const transformedOrderTimeSlots = transformOrderTimeSlots(payload.date, resp.data);
      yield put(orderActions.getOrderTimeSlotsSuccess(transformedOrderTimeSlots));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getOrderTimeSlotsFailure(error));
  }
}

export function* orderNeedsReview({ payload }) {
  try {
    const { vertical } = payload;
    const resp = yield call(orderNeedsReviewAPI, payload);
    if (resp) {
      yield put(orderActions.orderNeedsReviewSuccess(resp.data));
      toast.success(`Order ${resp.data.needsReview ? 'reviewed' : 'un-reviewed'} successfully`);
      if (isEmpty(payload.filter)) {
        const order = yield call(getOrderAPI, payload);
        if (order) {
          yield put(orderActions.getOrderSuccess(order.data));
        }
      } else {
        const orders = yield call(getOrdersAPI, { ...payload.filter, vertical });
        if (orders) {
          yield put(orderActions.getFilteredOrdersSuccess(orders.data));
        }
      }
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.orderNeedsReviewFailure(error));
  }
}

export function* getLateOrders({ payload }) {
  try {
    const resp = yield call(getLateOrdersAPI, payload);
    if (resp) {
      yield put(orderActions.getLateOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getLateOrdersFailure(error));
  }
}

export function* emailOrdersCsv({ payload }) {
  try {
    const resp = yield call(emailOrdersCsvAPI, payload);
    if (resp) {
      toast.success('An email of the orders data will be sent to you shortly.');
      yield put(orderActions.emailOrdersCsvSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.emailOrdersCsvFailure(error));
  }
}


export function* getOnTimeRateStatsSaga({ payload }) {
  try {
    const resp = yield call(getOnTimeRateStats, payload);
    if (resp) {
      yield put(orderActions.getOnTimeRateStatsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getOnTimeRateStatsFailure(error));
  }
}


export function* offlineInProgressSaga({ payload }) {
  try {
    const resp = yield call(offlineInProgressOrderAPI, payload);
    if (resp) {
      yield put(orderActions.offlineInProgressSuccess(resp.data));
      toast.success('Order marked in progress');
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.offlineInProgressFailure(error));
  }
}


export function* deliverOrderSaga({ payload }) {
  try {
    const resp = yield call(deliverOrderAPI, payload);
    if (resp) {
      yield put(orderActions.deliverOrderSuccess(resp.data));
      toast.success('Order marked as delivered');
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.deliverOrderFailure(error));
  }
}

export function* verifyExternalOrderSaga({ payload }) {
  try {
    const { vertical } = payload;
    const resp = yield call(verifyExternalOrderAPI, payload);
    if (resp) {
      yield put(orderActions.verifyExternalOrderSuccess(resp.data));
      if (!isEmpty(payload.filter)) {
        if (payload.isLoadDashboardOrders) {
          const orders = yield call(getDashboardOrdersAPI, { ...payload.filter, vertical });
          if (orders) {
            yield put(orderActions.getDashboardOrdersSuccess(orders.data));
          }
        } else {
          const orders = yield call(getUnverifiedOrdersAPI, payload.filter);
          if (orders) {
            yield put(orderActions.getFilteredUnverifiedOrdersSuccess(orders.data));
          }
        }
      }
      toast.success('Order marked as verified!');
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.verifyExternalOrderFailure(error));
  }
}


export function* followUpOrderSaga({ payload: value }) {
  try {
    const resp = yield call(followUpOrderAPI, value);
    if (resp) {
      yield put(orderActions.followUpOrderSuccess(resp.data));
      toast.success('Follow Up Saved!');
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.followUpOrderFailure(error));
  }
}

export function* updateAdditionalNotesSaga({ payload }) {
  try {
    const resp = yield call(updateAdditionalNotesAPI, payload);
    if (resp) {
      yield put(orderActions.orderUpdateAdditionalNotesSuccess(resp.data));
      toast.success('Additional Notes Saved!');
      const order = yield call(getOrderAPI, payload);
      if (order) {
        yield put(orderActions.getOrderSuccess(order.data));
      }
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.orderUpdateAdditionalNotesFailure(error));
  }
}

export function* updateScanTypesSaga({ payload: value }) {
  try {
    const resp = yield call(updateScanTypesAPI, value);
    if (resp) {
      yield put(orderActions.orderUpdateScanTypesSuccess(resp.data));
      toast.success('Scan Types Saved!');
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.orderUpdateScanTypesFailure(error));
  }
}

export function* getOrderSourcesSaga() {
  try {
    const resp = yield call(getOrderSourcesAPI);
    if (resp) {
      yield put(orderActions.getOrderSourcesSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getOrderSourcesFailure(error));
  }
}

export function* getReassignmentStringsSaga() {
  try {
    const resp = yield call(getReassignmentStringsAPI);
    if (resp) {
      yield put(orderActions.getReassignmentStringsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getReassignmentStringsFailure(error));
  }
}

export function* orderFollowUpAddressedSaga({ payload }) {
  try {
    const { vertical } = payload;
    const resp = yield call(orderFollowUpAddressedAPI, payload);
    if (resp) {
      yield put(orderActions.orderFollowUpAddressedSuccess(resp.data));
      toast.success(`Order follow up ${resp.data.isFollowUpAddressed ? 'addressed' : 'un-addressed'} successfully`);
      if (isEmpty(payload.filter)) {
        const order = yield call(getOrderAPI, payload);
        if (order) {
          yield put(orderActions.getOrderSuccess(order.data));
        }
      } else {
        const orders = yield call(getOrdersAPI, { ...payload.filter, vertical });
        if (orders) {
          yield put(orderActions.getFilteredOrdersSuccess(orders.data));
        }
      }
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.orderFollowUpAddressedFailure(error));
  }
}


export function* getAddressTypesSaga({ payload: values }) {
  try {
    const resp = yield call(getAddressTypesAPI, values);
    if (resp) {
      yield put(orderActions.getAddressTypesSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getAddressTypesFailure(error));
  }
}

export function* getRescheduleStringsSaga() {
  try {
    const resp = yield call(getRescheduleStringsAPI);
    if (resp) {
      yield put(orderActions.getRescheduleStringsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(orderActions.getRescheduleStringsFailure(error));
  }
}
