import React from "react";
import Iframe from 'react-iframe'
import moment from "moment";
import {REDASH_CITY_PARAM, REDASH_DATE_PARAM, REDASH_REPORTING_URL} from "../../utils/constants";
import {Nav} from "../commons/nav";


const Redash = ({regionDetail}) => {
  const date = moment().format('YYYY-MM-DD');
  return (
    <div id='wrapper'>
      <div className='reports-container'>
        <div className='nav-holder'>
          <Nav/>
        </div>
        <div className='report-holder'>
          <Iframe url={`${REDASH_REPORTING_URL}?${REDASH_CITY_PARAM}=${regionDetail?.id}&${REDASH_DATE_PARAM}=${date}`}
                  width='100%'
                  height='1000px'
                  display='block'
          />
        </div>
      </div>
    </div>
  );
};


export default Redash;
