import * as caseConverter from 'change-object-case';
import { apiCaller } from '../api-caller';
import { ENDPOINTS, REQUEST_TYPES } from '../constants';


export const getShiftAPI = (id) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.SHIFTS}shift-schedules/${id}/`,
  })
}

export const getShiftsAPI = (filter = {}) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.SHIFTS}shift-schedules/`,
    params: caseConverter.snakeKeys(filter),
  })
}

export const createShiftAPI = (data = {}) => {
  return apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.SHIFTS}shift-schedules/`,
    data,
  })
}

export const getShiftRidersAPI = (filter = {}) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.SHIFTS}rider-shift-schedules/`,
    params: caseConverter.snakeKeys(filter),
  })
}

export const createShiftRiderAPI = (data = {}) => {
  return apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.SHIFTS}rider-shift-schedules/`,
    data,
  })
}

export const updateShiftRiderAPI = ({ id, data = {} }) => {
  return apiCaller({
    method: REQUEST_TYPES.PUT,
    url: `${ENDPOINTS.SHIFTS}rider-shift-schedules/${id}/`,
    data,
  })
}

export const deleteShiftRiderAPI = (id) => {
  return apiCaller({
    method: REQUEST_TYPES.DELETE,
    url: `${ENDPOINTS.SHIFTS}rider-shift-schedules/${id}/`,
  })
}

export const updateShiftAPI = ({ id, data = {} }) => {
  return apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.SHIFTS}shift-schedules/${id}/`,
    data,
  })
}

export const deleteShiftAPI = (id) => {
  return apiCaller({
    method: REQUEST_TYPES.DELETE,
    url: `${ENDPOINTS.SHIFTS}shift-schedules/${id}/`,
  })
}

export const copyShiftAPI = ({ id, data = {} }) => {
  return apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.SHIFTS}copy-shift-schedule/${id}/`,
    data,
  })
}
