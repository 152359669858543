import { types } from '../../actions/order';
import { INITIAL_STATE } from "../initial-state";
import { scanTypeLabel } from '../../utils/helper';

export const order = (state = INITIAL_STATE.order, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_ORDERS_REQUEST:
    case types.GET_UNVERIFIED_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ORDERS_SUCCESS:
    case types.GET_UNVERIFIED_ORDERS_SUCCESS:
      return {
        ...state,
        orderDetail: {},
        list: [...state.list, ...payload.results],
        next: payload.next,
        count: payload.count,
        loading: false
      };
    case types.GET_ORDERS_FAILURE:
    case types.GET_UNVERIFIED_ORDERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_ORDERS_REQUEST:
    case types.GET_PARTIAL_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ORDERS_SUCCESS:
    case types.GET_PARTIAL_ORDERS_SUCCESS:
      return {
        ...state,
        orderDetail: {},
        list: [...state.list, ...payload.results],
        next: payload.next,
        count: payload.count,
        loading: false
      };
    case types.GET_ORDERS_FAILURE:
    case types.GET_PARTIAL_ORDERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_FILTERED_ORDERS_REQUEST:
    case types.GET_FILTERED_UNVERIFIED_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_FILTERED_ORDERS_SUCCESS:
    case types.GET_FILTERED_UNVERIFIED_ORDERS_SUCCESS:
      return {
        ...state,
        orderDetail: {},
        list: action.payload.results,
        next: action.payload.next,
        count: action.payload.count,
        loading: false

      };
    case types.GET_FILTERED_ORDERS_FAILURE:
    case types.GET_FILTERED_UNVERIFIED_ORDERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_FILTERED_ORDERS_REQUEST:
    case types.GET_FILTERED_PARTIAL_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_FILTERED_ORDERS_SUCCESS:
    case types.GET_FILTERED_PARTIAL_ORDERS_SUCCESS:
      return {
        ...state,
        orderDetail: {},
        list: action.payload.results,
        next: action.payload.next,
        count: action.payload.count,
        loading: false

      };
    case types.GET_FILTERED_ORDERS_FAILURE:
    case types.GET_FILTERED_PARTIAL_ORDERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_PARTIAL_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_PARTIAL_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetail: {},
        list: payload,
        next: null,
        count: 0,
        loading: false
      };
    case types.GET_PARTIAL_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_DASHBOARD_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_DASHBOARD_ORDERS_SUCCESS:
      return {
        ...state,
        orderDetail: {},
        list: action.payload,
        next: null,
        count: 0,
        loading: false
      };
    case types.GET_DASHBOARD_ORDERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_ORDER_SCAN_TYPES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ORDER_SCAN_TYPES_SUCCESS:
      return {
        ...state,
        scanTypes: action.payload.map(item => {
          return {
            ...item,
            label: scanTypeLabel(item)
          };
        }),
        loading: false

      };
    case types.GET_ORDER_SCAN_TYPES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.RESET_SCAN_TYPES_STATE:
      return {
        ...state,
        scanTypes: [],
        loading: false
      };
    case types.GET_ORDERS_STATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ORDERS_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        loading: false

      };
    case types.GET_ORDERS_STATES_FAILURE:
      return {
        ...state,
        loading: false
      };

    case types.GET_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ORDER_SUCCESS:
      return {
        ...state,
        orderDetail: action.payload,
        loading: false

      };
    case types.GET_ORDER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.CREATE_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        orderDetail: action.payload,
        loading: false

      };
    case types.CREATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.ASSIGN_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.ASSIGN_ORDER_SUCCESS:
      return {
        ...state,
        loading: false

      };
    case types.ASSIGN_ORDER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.REASSIGN_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.REASSIGN_ORDER_SUCCESS:
      return {
        ...state,
        loading: false

      };
    case types.REASSIGN_ORDER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.CANCEL_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CANCEL_ORDER_SUCCESS:
    case types.DELIVER_ORDER_SUCCESS:
    case types.OFFLINE_IN_PROGRESS_SUCCESS:
      return {
        ...state,
        orderDetail: action.payload,
        loading: false

      };
    case types.CANCEL_ORDER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_ORDER_STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ORDER_STATS_SUCCESS:
      return {
        ...state,
        stats: action.payload,
        loading: false

      };
    case types.GET_ORDER_STATS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.RESET_ORDER_STATE:
      return {
        ...state,
        statuses: state.statuses,
        orderDetail: {},
        list: [],
        next: null,
        count: 0,
        scanTypes: state.scanTypes,
        stats: state.stats,
        hearAboutUsList: [],
        problemStrings: state.problemStrings,
        orderTimeSlots: state.orderTimeSlots,
        copiedOrder: {},
        loading: false,
        lateOrders: [],
        orderSources: state.orderSources,
        addressTypes: state.addressTypes,
      };
    case types.UPDATE_ORDER_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          [action.payload.type]: action.payload.filter
        }
      };

    case types.RESCHEDULE_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.RESCHEDULE_ORDER_SUCCESS:
      return {
        ...state,
        orderDetail: action.payload,
        loading: false

      };
    case types.RESCHEDULE_ORDER_FAILURE:
      return {
        ...state,
        loading: false
      };

    case types.GET_ORDER_ACTIVITIES_REQUEST:
      return {
        ...state,
        orderActivities: [],
        loading: true
      };
    case types.GET_ORDER_ACTIVITIES_SUCCESS:
      return {
        ...state,
        orderActivities: action.payload,
        loading: false

      };
    case types.GET_ORDER_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.RESET_ORDER_ACTIVITIES_STATE:
      return {
        ...state,
        orderActivities: [],
      };

    case types.GET_HEAR_ABOUT_US_LIST_REQUEST:
      return {
        ...state,
        hearAboutUsList: [],
        loading: true
      };
    case types.GET_HEAR_ABOUT_US_LIST_SUCCESS:
      return {
        ...state,
        hearAboutUsList: action.payload,
        loading: false

      };
    case types.GET_HEAR_ABOUT_US_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_PROBLEM_STRINGS_REQUEST:
      return {
        ...state,
        problemStrings: [],
      };
    case types.GET_PROBLEM_STRINGS_SUCCESS:
      return {
        ...state,
        problemStrings: action.payload,

      };
    case types.GET_PROBLEM_STRINGS_FAILURE:
      return {
        ...state,
      };
    case types.GET_CANCELLATION_STRINGS_REQUEST:
      return {
        ...state,
        cancellationStrings: [],
      };
    case types.GET_CANCELLATION_STRINGS_SUCCESS:
      return {
        ...state,
        cancellationStrings: action.payload,
      };
    case types.GET_CANCELLATION_STRINGS_FAILURE:
      return {
        ...state,
      };
    case types.GET_ORDER_TIME_SLOTS_REQUEST:
      return {
        ...state,
        orderTimeSlots: [],
      };
    case types.GET_ORDER_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        orderTimeSlots: action.payload,

      };
    case types.GET_ORDER_TIME_SLOTS_FAILURE:
      return {
        ...state,
      };
    case types.RESET_ORDER_TIME_SLOTS_STATE:
      return {
        ...state,
        orderTimeSlots: [],
      };
    case types.COPY_ORDER:
      return {
        ...state,
        copiedOrder: action.payload,
      };
    case types.RESET_COPY_ORDER:
      return {
        ...state,
        copiedOrder: {},
      };
    case types.ORDER_NEEDS_REVIEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.ORDER_NEEDS_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false

      };
    case types.ORDER_NEEDS_REVIEW_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_LATE_ORDERS_SUCCESS:
      return {
        ...state,
        lateOrders: action.payload,
      };
    case types.GET_ON_TIME_RATE_STATS_SUCCESS:
      return {
        ...state,
        onTimeRate: action.payload,
      };
    case types.UPDATE_EXTERNAL_ORDER_PARAMS:
      return {
        ...state,
        externalOrderParams: action.payload
      };
    case types.ORDER_UPDATE_ADDITIONAL_NOTES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.ORDER_UPDATE_ADDITIONAL_NOTES_SUCCESS:
      return {
        ...state,
        orderDetail: {
          ...state.orderDetail,
          ...action.payload
        },
        loading: false
      };
    case types.ORDER_UPDATE_ADDITIONAL_NOTES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.ORDER_UPDATE_SCAN_TYPES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.ORDER_UPDATE_SCAN_TYPES_SUCCESS:
      return {
        ...state,
        orderDetail: action.payload,
        loading: false
      };
    case types.ORDER_UPDATE_SCAN_TYPES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_ORDER_SOURCES_REQUEST:
      return {
        ...state,
        orderSources: [],
      };
    case types.GET_ORDER_SOURCES_SUCCESS:
      return {
        ...state,
        orderSources: action.payload,

      };
    case types.GET_ORDER_SOURCES_FAILURE:
      return {
        ...state,
      };
    case types.GET_REASSIGNMENT_STRINGS_REQUEST:
      return {
        ...state,
        reassignmentStrings: [],
      };
    case types.GET_REASSIGNMENT_STRINGS_SUCCESS:
      return {
        ...state,
        reassignmentStrings: action.payload,
      };
    case types.GET_REASSIGNMENT_STRINGS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_ADDRESS_TYPES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ADDRESS_TYPES_SUCCESS:
      return {
        ...state,
        addressTypes: action.payload,
        loading: false

      };
    case types.GET_ADDRESS_TYPES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_RESCHEDULE_STRINGS_REQUEST:
      return {
        ...state,
        rescheduleStrings: [],
      };
    case types.GET_RESCHEDULE_STRINGS_SUCCESS:
      return {
        ...state,
        rescheduleStrings: action.payload,
      };
    case types.GET_RESCHEDULE_STRINGS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
