import {connect} from 'react-redux';

import {riderActions} from '../../actions/rider';
import {
  selectRiderDetailFromState,
  selectRiderOrdersListFromState,
  selectRiderOrdersListLoadingFromState,
  selectRiderOrdersListNextFromState,
  selectRiderParamsFromState,
  selectRidersCountListFromState,
  selectRidersListFromState,
  selectRidersListLoadingFromState,
  selectRidersNextFromState,
} from "../../selectors/rider";
import {selectCustomerParamsFromState} from "../../selectors/customer";
import {selectRegionsListFromState, selectRegionDetailFromState} from "../../selectors/location";
import {VERTICALS} from "../../utils/constants";
import {
  selectExternalOrderParamsFromState,
  selectFilteredOrderParamsFromState,
} from '../../selectors/order';
import {selectAttendanceListParamsFromState} from '../../selectors/attendance';
import {locationActions} from "../../actions/location";

let Components = {
  'RiderList': require('./riders-list').default,
  'RiderDetail': require('./rider-detail').default,
};

const mapDispatchToProps = {
  getRiders: riderActions.getRidersRequest,
  getRiderDetail: riderActions.getRiderRequest,
  getRiderForceDeliver: riderActions.getRiderForceDeliverRequest,
  resetRiderState: riderActions.resetRiderState,
  getFilteredRiders: riderActions.getFilteredRidersRequest,
  updateRiderParams: riderActions.updateRiderParams,
  getRiderOrders: riderActions.getRiderOrdersRequest,
  getFilteredRiderOrders: riderActions.getFilteredRiderOrdersRequest,
  riderMarkOffDuty: riderActions.riderMarkOffDutyRequest,
  createRider: riderActions.createRiderRequest,
  updateRider: riderActions.updateRiderRequest,
  getAllRegions: locationActions.getAllRegionsRequest,
};
const mapStateToProps = state => ({
  riders: selectRidersListFromState(state),
  ridersNext: selectRidersNextFromState(state),
  ridersLoading: selectRidersListLoadingFromState(state),
  ridersCount: selectRidersCountListFromState(state),
  regions: selectRegionsListFromState(state),
  regionDetail: selectRegionDetailFromState(state, VERTICALS.COMMON),
  riderDetail: selectRiderDetailFromState(state),
  bloodOrderParams: selectFilteredOrderParamsFromState(state, VERTICALS.BLOOD),
  xrayOrderParams: selectFilteredOrderParamsFromState(state, VERTICALS.XRAY),
  riderParams: selectRiderParamsFromState(state),
  customerParams: selectCustomerParamsFromState(state),
  attendanceParams: selectAttendanceListParamsFromState(state),
  externalOrderParams: selectExternalOrderParamsFromState(state),
  riderOrders: selectRiderOrdersListFromState(state),
  riderOrdersNext: selectRiderOrdersListNextFromState(state),
  riderOrdersLoading: selectRiderOrdersListLoadingFromState(state),
});

export const RiderContainer = (componentName) => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Components[componentName]);
};
