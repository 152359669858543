import { connect } from 'react-redux';

import { riderActions } from '../../actions/rider';
import { orderActions } from '../../actions/order';
import { actions as authActions } from '../../actions/auth';
import {
  selectLOnlineRidersListFromState,
  selectRefreshTimeFromState,
  selectRiderStatsFromState
} from '../../selectors/rider';
import {
  selectLateOrdersListFromState,
  selectLOrdersListFromState,
  selectOrderStatsFromState
} from '../../selectors/order';
import { VERTICALS } from '../../utils/constants';
import {
  selectRegionsListFromState,
  selectRegionDetailFromState,
  selectCollectionCentersListFromState, selectCitiesListFromState, selectCityDetailFromState
} from "../../selectors/location";
import { locationActions } from "../../actions/location";
import { attendanceActions } from '../../actions/attendance';

let Components = {
  'BloodDashboard': require('./blood-dashboard').default,
  'XRayDashboard': require('./xray-dashboard').default,
  'BloodCityDashboard': require('./blood-city-dashboard').default,
  'XRayCityDashboard': require('./xray-city-dashboard').default,
};

const mapDispatchToProps = {
  getRegions: locationActions.getRegionsRequest,
  getCities: locationActions.getCitiesRequest,
  getRiders: riderActions.getOnlineRidersRequest,
  getOrders: orderActions.getOrdersRequest,
  getUnverifiedOrders: orderActions.getUnverifiedOrdersRequest,
  getDashboardOrders: orderActions.getDashboardOrdersRequest,
  getFilteredUnverifiedOrders: orderActions.getFilteredUnverifiedOrdersRequest,
  cancelOrder: orderActions.cancelOrderRequest,
  getOrderStats: orderActions.getOrderStatsRequest,
  getRiderStats: riderActions.getRiderStatsRequest,
  selectRegionDetail: locationActions.selectRegionDetail,
  selectCityDetail: locationActions.selectCityDetail,
  getCollectionCenters: locationActions.getCollectionCentersRequest,
  updateOrderParams: orderActions.updateOrderParams,
  updateAttendanceParams: attendanceActions.updateAttendanceParams,
  updateRiderParams: riderActions.updateRiderParams,
  resetOrderState: orderActions.resetOrderState,
  getLateOrders: orderActions.getLateOrdersRequest,
  getOnTimeRateStats: orderActions.getOnTimeRateStatsRequest,
  logoutAttempt: authActions.logoutAttempt
};

const mapStateToProps = state => ({
  riders: selectLOnlineRidersListFromState(state),
  orders: selectLOrdersListFromState(state),
  orderStats: selectOrderStatsFromState(state),
  riderStats: selectRiderStatsFromState(state),
  regions: selectRegionsListFromState(state),
  cities: selectCitiesListFromState(state),
  bloodRegionDetail: selectRegionDetailFromState(state, VERTICALS.BLOOD),
  xrayRegionDetail: selectRegionDetailFromState(state, VERTICALS.XRAY),
  bloodCityDetail: selectCityDetailFromState(state, VERTICALS.BLOOD),
  xrayCityDetail: selectCityDetailFromState(state, VERTICALS.XRAY),
  collectionCenters: selectCollectionCentersListFromState(state),
  lateOrders: selectLateOrdersListFromState(state),
  refreshTime: selectRefreshTimeFromState(state),
});

export const DashboardContainer = (componentName) => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Components[componentName]);
};
