import React from 'react';
import Proptypes from 'prop-types';

export const ErrorField = ({ value }) => (
  <div
    style={{
      textAlign: 'left',
      marginTop: 8,
      color: 'tomato',
    }}
  >
    { value }
  </div>
);

ErrorField.propTypes = {
  value: Proptypes.string.isRequired,
};
