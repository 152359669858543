import {
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { types } from '../../actions/customer';

import {
  getCustomerDetail,
  getCustomerAddress,
  createCustomerAddress,
  createCustomer,
  getCustomersId,
  getCustomers,
  getFilteredCustomers,
  updateCustomer,
  getCustomerOrders,
  getFilteredCustomerOrders,
  deleteCustomerAddress,
  createCustomerPatient,
  updateCustomerPatient,
  deleteCustomerPatient,
  updateCustomerAddress,
} from './customer';

export function* customerSaga() {
  yield takeEvery(types.GET_CUSTOMERS_REQUEST, getCustomers);
  yield takeEvery(types.GET_FILTERED_CUSTOMERS_REQUEST, getFilteredCustomers);
  yield takeEvery(types.GET_CUSTOMER_ORDERS_REQUEST, getCustomerOrders);
  yield takeEvery(types.GET_FILTERED_CUSTOMER_ORDERS_REQUEST, getFilteredCustomerOrders);
  yield takeEvery(types.GET_CUSTOMER_ADDRESS_REQUEST, getCustomerAddress);
  yield takeEvery(types.CREATE_CUSTOMER_REQUEST, createCustomer);
  yield takeEvery(types.CREATE_CUSTOMER_ADDRESS_REQUEST, createCustomerAddress);
  yield takeLatest(types.GET_CUSTOMERS_ID_REQUEST, getCustomersId);
  yield takeEvery(types.GET_CUSTOMER_DETAIL_REQUEST, getCustomerDetail);
  yield takeEvery(types.UPDATE_CUSTOMER_REQUEST, updateCustomer);
  yield takeEvery(types.UPDATE_CUSTOMER_ADDRESS_REQUEST, updateCustomerAddress);
  yield takeEvery(types.DELETE_CUSTOMER_ADDRESS_REQUEST, deleteCustomerAddress);
  yield takeEvery(types.CREATE_CUSTOMER_PATIENT_REQUEST, createCustomerPatient);
  yield takeEvery(types.UPDATE_CUSTOMER_PATIENT_REQUEST, updateCustomerPatient);
  yield takeEvery(types.DELETE_CUSTOMER_PATIENT_REQUEST, deleteCustomerPatient);
}
