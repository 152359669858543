import React from 'react';
import { MAX_STAR } from '../../utils/constants';

const Rating = ({ rating }) => {

  return (
    <ul className="full-star-ratting">
      {[...Array(MAX_STAR)].map((x, i) =>
        <li key={i} className={rating > i ? 'full' : ''}>
          <a href=" " onClick={(e) => e.preventDefault()}>
            <i className="icon-star"></i>
            <i className="icon-star fill"></i>
          </a>
        </li>
      )}
    </ul>
  );
};

export default Rating;
