import {connect} from 'react-redux';
import {selectRegionDetailFromState} from "../../selectors/location";
import {VERTICALS} from "../../utils/constants";


let components = {
  'Redash': require('./Redash').default,
  'Issue': require('./Issue').default,
};


const mapStateToProps = state => ({
  regionDetail: selectRegionDetailFromState(state, VERTICALS.COMMON),
});

const mapDispatchToProps = {};

export const ReportsContainer = componentName => {
  return connect(mapStateToProps, mapDispatchToProps)(components[componentName]);
};
