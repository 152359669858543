import React, { useState } from 'react';

const AdditionalNotes = ({ orderId, additionalNotes, orderAdditionalNotes, closeModal }) => {
  const [notes, setNotes] = useState(additionalNotes);

  return (
    <>
      <div className="lightbox schedule">
        <div className="add-comment-lightbox">
          <a href=""
             className="lightbox-close icon-clear"
             onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}></a>
          <h2>Additional Comments</h2>
          <div className="content-holder">
            <div className="img-holder">
              <img src={require("../../assets/images/comment-img.png")} width="224" alt="" />
            </div>
            <div className="right-area">
              <div className="form-row">
                <textarea
                  maxLength={250}
                  value={notes}
                  onChange={e => setNotes(e.target.value)}
                ></textarea>
              </div>
              <div className="btns-holder">
                <a href="#" className="blue"
                   onClick={e => {
                     e.preventDefault();
                     orderAdditionalNotes(notes)
                   }}
                >Save</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalNotes;
