import { apiCaller } from '../api-caller';
import { ENDPOINTS, REQUEST_TYPES } from '../constants';

export const getOrderReviewAPI = (uuid) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.ORDER_REVIEW}${uuid}/`,
  showErrors: false,
});

export const createOrderReviewAPI = ({ uuid, data }) => apiCaller({
  method: REQUEST_TYPES.PUT,
  url: `${ENDPOINTS.ORDER_REVIEW}${uuid}/`,
  data
});

export const getPhysicalVisitReviewQuestionsAPI = () => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.PHYSICAL_REVIEW_QUESTIONS}`,
});

export const  postPhysicalVisitReviewFeedbackAPI = ({interactionId, responses}) => apiCaller({
  method: REQUEST_TYPES.PATCH,
  url: `${ENDPOINTS.PHYSICAL_REVIEW_FEEDBACK}/${interactionId}`,
  data: {
    responses: responses
  }
});

export const  getPhysicalVisitReviewFeedbackAPI = ({interactionId}) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.PHYSICAL_REVIEW_FEEDBACK}/${interactionId}`,
  showErrors: false
});

export const getCROFeedbackQuestionsAPI = () => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.CRO_FEEDBACK_QUESTIONS}`,
});

export const postCROFeedbackAPI = ({interactionId, responses}) => apiCaller({
  method: REQUEST_TYPES.PATCH,
  url: `${ENDPOINTS.CRO_FEEDBACK}${interactionId}/`,
  data: {
    responses: responses
  }
});

export const getCROFeedbackAPI = ({interactionId}) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.CRO_FEEDBACK}${interactionId}/`,
  showErrors: false
});
