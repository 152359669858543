import React from 'react';
import { object, shape, string } from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export const DateField = (
  {
    field,
    form,
    ...props
  }) => {
  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          format="dd/MM/yyyy"
          placeholder="DD/MM/YYYY"
          {...field}
          {...props}/>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
};

DateField.propTypes = {
  field: shape({
    name: string,
    value: string,
  }).isRequired,
  form: object,
};
