import {object, number, string, boolean, default as Yup,} from 'yup';

export const yupPhoneSearch = string()
  .matches(/^[0-9]*$/, 'Must be a valid phone number')
  .max(11, 'Phone must be at most 11 characters');

export const yupPhone = string().matches(/^\d{11}$/, 'Must be a valid phone number');
export const yupSecondaryPhone = string()
  .matches(/^\d{11}$/, 'Must be a valid phone number')
  .nullable();

export const yupFirstName = string()
  .max(40, 'First name must be at most 40 characters')
  .required('Required field')
  .nullable();

export const yupLastName = string()
  .max(40, 'Last name must be at most 40 characters')
  .required('Required field')
  .nullable();

export const yupEmail = string()
  .email('Email must be a valid email')
  .nullable();

export const yupPassword = string()
  .min(6, 'Must be at least 6 characters long')
  .required('Required field');

export const yupAge = number('Age must be a number')
  .min(0, 'Age can not be less than 0')
  .nullable()
  .typeError('Invalid age')
  .required('Required field');


export const patientSchema = object().shape({
  firstName: yupFirstName,
  lastName: yupLastName,
  email: yupEmail,
  age: yupAge,
  secondaryPhone: yupSecondaryPhone,
});
