import * as types from './types';
import { emptyActionCreator, payloadActionCreator } from '../../utils/actions';

export const getOrderReviewRequest = payloadActionCreator(types.GET_ORDER_REVIEW_REQUEST);
export const getOrderReviewSuccess = payloadActionCreator(types.GET_ORDER_REVIEW_SUCCESS);
export const getOrderReviewFailure = payloadActionCreator(types.GET_ORDER_REVIEW_FAILURE);
export const createOrderReviewRequest = payloadActionCreator(types.CREATE_ORDER_REVIEW_REQUEST);
export const createOrderReviewSuccess = payloadActionCreator(types.CREATE_ORDER_REVIEW_SUCCESS);
export const createOrderReviewFailure = payloadActionCreator(types.CREATE_ORDER_REVIEW_FAILURE);
export const resetFeedbackState = emptyActionCreator(types.RESET_FEEDBACK_STATE);

export const getPhysicalVisitReviewQuestionsRequest = payloadActionCreator(types.GET_PHYSICAL_VISIT_REVIEW_QUESTIONS_REQUEST);
export const getPhysicalVisitReviewQuestionsSuccess = payloadActionCreator(types.GET_PHYSICAL_VISIT_REVIEW_QUESTIONS_SUCCESS);
export const getPhysicalVisitReviewQuestionsFailure = payloadActionCreator(types.GET_PHYSICAL_VISIT_REVIEW_QUESTIONS_FAILURE);

export const postPhysicalVisitReviewFeedbackRequest = payloadActionCreator(types.POST_PHYSICAL_VISIT_REVIEW_FEEDBACK_REQUEST);
export const postPhysicalVisitReviewFeedbackSuccess = payloadActionCreator(types.POST_PHYSICAL_VISIT_REVIEW_FEEDBACK_SUCCESS);
export const postPhysicalVisitReviewFeedbackFailure = payloadActionCreator(types.POST_PHYSICAL_VISIT_REVIEW_FEEDBACK_FAILURE);

export const getPhysicalVisitReviewFeedbackRequest = payloadActionCreator(types.GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_REQUEST);
export const getPhysicalVisitReviewFeedbackSuccess = payloadActionCreator(types.GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_SUCCESS);
export const getPhysicalVisitReviewFeedbackFailure = payloadActionCreator(types.GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_FAILURE);

export const getCROFeedbackQuestionsRequest = payloadActionCreator(types.GET_CRO_FEEDBACK_QUESTIONS_REQUEST);
export const getCROFeedbackQuestionsSuccess = payloadActionCreator(types.GET_CRO_FEEDBACK_QUESTIONS_SUCCESS);
export const getCROFeedbackQuestionsFailure = payloadActionCreator(types.GET_CRO_FEEDBACK_QUESTIONS_FAILURE);

export const postCROFeedbackRequest = payloadActionCreator(types.POST_CRO_FEEDBACK_REQUEST);
export const postCROFeedbackSuccess = payloadActionCreator(types.POST_CRO_FEEDBACK_SUCCESS);
export const postCROFeedbackFailure = payloadActionCreator(types.POST_CRO_FEEDBACK_FAILURE);

export const getCROFeedbackRequest = payloadActionCreator(types.GET_CRO_FEEDBACK_REQUEST);
export const getCROFeedbackSuccess = payloadActionCreator(types.GET_CRO_FEEDBACK_SUCCESS);
export const getCROFeedbackFailure = payloadActionCreator(types.GET_CRO_FEEDBACK_FAILURE);
