import React, {useEffect, useState} from 'react';
import {Nav} from "../commons/nav";
import Modal from 'react-modal';
import {RegionsList} from '../commons/regions-list';
import {VERTICALS, WEEKDAY} from '../../utils/constants';
import Days from './days';
import ShiftCreate from './shift-create';
import {disableDiv} from '../../utils/helper';
import Confirmation from '../commons/confirmation';
import CopyShift from './copy-shift';


const ShiftList = ({
                     getShifts,
                     shifts,
                     regions,
                     resetShiftState,
                     shiftsLoading,
                     history,
                     regionDetail,
                     selectRegionDetail,
                     updateOrderParams,
                     updateAttendanceParams,
                     updateRiderParams,
                     createShift,
                     deleteShift,
                     copyShift,
                     getAllRegions,
                   }) => {
  const [selectedWeekDay, setSelectedWeekday] = useState(-1)
  const [selectedShift, setSelectedShift] = useState(null)
  const [isOpenShiftDeleteModal, setIsOpenShiftDeleteModal] = useState(false)
  const [isOpenCopyShiftModal, setIsOpenCopyShiftModal] = React.useState(false)

  useEffect(() => {
    getAllRegions({vertical: VERTICALS.COMMON});

    return () => {
      resetShiftState()
    }
  }, []);

  useEffect(() => {
    regionDetail?.id && getShifts({region: regionDetail.id});
    return () => {
      resetShiftState();
    }
  }, [regionDetail.id]);

  const closeShiftModal = () => {
    setSelectedWeekday(-1)
  }

  const onShiftDelete = (selectedShift) => {
    setSelectedShift(selectedShift)
    setIsOpenShiftDeleteModal(true)
  }

  const closeShiftDeleteModal = () => {
    setIsOpenShiftDeleteModal(false)
    setSelectedShift(null)
  }

  const onCopyShift = (selectedShift) => {
    setSelectedShift(selectedShift)
    setIsOpenCopyShiftModal(true)
  };

  const closeCopyShiftModal = () => {
    setIsOpenCopyShiftModal(false)
    setSelectedShift(null)
  }

  return <div id="wrapper" className="inner-page">
    <Nav/>
    <div className="schedule-content" style={disableDiv(shiftsLoading)}>
      <div className="schedule-head">
        <h2>Shift Schedule</h2>
        <RegionsList regions={regions}
                     regionDetail={regionDetail}
                     selectRegionDetail={selectRegionDetail}
                     updateOrderParams={updateOrderParams}
                     updateRiderParams={updateRiderParams}
                     updateAttendanceParams={updateAttendanceParams}
        />
      </div>
      <div className="days-schedule">
        <div className="days-schedule-head">
          <h3>Weekly Shifts</h3>
        </div>
        <div className="days-holder">
          {
            Object.values(WEEKDAY).map(weekday => {
              return <Days history={history}
                           shifts={shifts}
                           weekday={weekday}
                           setSelectedWeekday={setSelectedWeekday}
                           onCopyShift={onCopyShift}
                           onShiftDelete={onShiftDelete}
              />
            })
          }
        </div>
      </div>
    </div>
    {selectedWeekDay !== -1 &&
    <Modal isOpen={selectedWeekDay !== -1}>
      <ShiftCreate
        createShift={createShift}
        weekday={selectedWeekDay}
        regionId={regionDetail.id}
        closeModal={closeShiftModal}
      />
    </Modal>
    }
    {isOpenShiftDeleteModal &&
    <Modal isOpen={isOpenShiftDeleteModal} onRequestClose={closeShiftDeleteModal}
           className='small-modal'>
      <Confirmation
        text={`You want to delete this shift(${selectedShift.id})?`}
        onConfirm={() => {
          selectedShift && deleteShift(selectedShift.id)
        }}
        onClose={closeShiftDeleteModal}
      />
    </Modal>
    }
    {isOpenCopyShiftModal &&
    <Modal isOpen={isOpenCopyShiftModal} onRequestClose={closeCopyShiftModal} className='small-modal'>
      <CopyShift
        closeModal={closeCopyShiftModal}
        selectedShift={selectedShift}
        copyShift={copyShift}
      />
    </Modal>
    }
  </div>
};
export default ShiftList;
