import React, {useState} from 'react';
import {getRegionIcon} from "../../utils/helper";

export const RegionsList = ({
                              regions,
                              regionDetail,
                              selectRegionDetail,
                              updateOrderParams,
                              updateRiderParams,
                              updateAttendanceParams,
                              handleOnCityChange,
                              isWhite = false,
                              vertical
                            }) => {
  const [open, setOpen] = useState(false);
  return <div className={`city-dropdown ${isWhite ? 'white' : ''}`}>
    {open === true && <span className="close-link">
              <a
                href=''
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(!open)
                }}
              ><i className="icon-angle-right"/></a>
            </span>
    }
    {open === true && <ul className="city-list">
      {
        regions.map(region => {
          return <li key={region.id}>
            <a
              href=''
              onClick={(e) => {
                e.preventDefault();
                selectRegionDetail({vertical, region});
                updateRiderParams('');
                updateAttendanceParams('');
                setOpen(!open);
                handleOnCityChange && handleOnCityChange(region)
              }}>
              <div className="img-holder">
                <img src={getRegionIcon(region.name.toLowerCase(), isWhite)}
                     alt="" width="33px"/>
              </div>
              <span className="name">{region.name}</span>
            </a>
          </li>
        })
      }
    </ul>}
    {regionDetail.id && <a
      href=''
      onClick={(e) => {
        e.preventDefault();
        setOpen(!open)
      }}
      className="selected">
      <div className="img-holder">
        <img
          src={getRegionIcon(regionDetail.name.toLowerCase(), isWhite)}
          alt=""
          width="33px"
        />
      </div>
      <span className="name">{regionDetail.name} <i className="icon-angle-right"/></span>
    </a>
    }
  </div>
};
