export const GET_SHIFT_REQUEST = 'GET_SHIFT_REQUEST'
export const GET_SHIFT_SUCCESS = 'GET_SHIFT_SUCCESS'
export const GET_SHIFT_FAILURE = 'GET_SHIFT_FAILURE'

export const GET_SHIFTS_REQUEST = 'GET_SHIFTS_REQUEST'
export const GET_SHIFTS_SUCCESS = 'GET_SHIFTS_SUCCESS'
export const GET_SHIFTS_FAILURE = 'GET_SHIFTS_FAILURE'

export const RESET_SHIFT_STATE = 'RESET_SHIFT_STATE'

export const CREATE_SHIFT_REQUEST = 'CREATE_SHIFT_REQUEST'
export const CREATE_SHIFT_SUCCESS = 'CREATE_SHIFT_SUCCESS'
export const CREATE_SHIFT_FAILURE = 'CREATE_SHIFT_FAILURE'

export const GET_SHIFT_RIDERS_REQUEST = 'GET_SHIFT_RIDERS_REQUEST'
export const GET_SHIFT_RIDERS_SUCCESS = 'GET_SHIFT_RIDERS_SUCCESS'
export const GET_SHIFT_RIDERS_FAILURE = 'GET_SHIFT_RIDERS_FAILURE'

export const CREATE_SHIFT_RIDER_REQUEST = 'CREATE_SHIFT_RIDER_REQUEST'
export const CREATE_SHIFT_RIDER_SUCCESS = 'CREATE_SHIFT_RIDER_SUCCESS'
export const CREATE_SHIFT_RIDER_FAILURE = 'CREATE_SHIFT_RIDER_FAILURE'

export const UPDATE_SHIFT_RIDER_REQUEST = 'UPDATE_SHIFT_RIDER_REQUEST'
export const UPDATE_SHIFT_RIDER_SUCCESS = 'UPDATE_SHIFT_RIDER_SUCCESS'
export const UPDATE_SHIFT_RIDER_FAILURE = 'UPDATE_SHIFT_RIDER_FAILURE'

export const DELETE_SHIFT_RIDER_REQUEST = 'DELETE_SHIFT_RIDER_REQUEST'
export const DELETE_SHIFT_RIDER_SUCCESS = 'DELETE_SHIFT_RIDER_SUCCESS'
export const DELETE_SHIFT_RIDER_FAILURE = 'DELETE_SHIFT_RIDER_FAILURE'

export const ADD_SHIFT_RIDER = 'ADD_SHIFT_RIDER'
export const REMOVE_SHIFT_RIDER = 'REMOVE_SHIFT_RIDER'

export const UPDATE_SHIFT_REQUEST = 'UPDATE_SHIFT_REQUEST'
export const UPDATE_SHIFT_SUCCESS = 'UPDATE_SHIFT_SUCCESS'
export const UPDATE_SHIFT_FAILURE = 'UPDATE_SHIFT_FAILURE'

export const DELETE_SHIFT_REQUEST = 'DELETE_SHIFT_REQUEST'
export const DELETE_SHIFT_SUCCESS = 'DELETE_SHIFT_SUCCESS'
export const DELETE_SHIFT_FAILURE = 'DELETE_SHIFT_FAILURE'

export const COPY_SHIFT_REQUEST = 'COPY_SHIFT_REQUEST'
export const COPY_SHIFT_SUCCESS = 'COPY_SHIFT_SUCCESS'
export const COPY_SHIFT_FAILURE = 'COPY_SHIFT_FAILURE'
