import React from 'react';
import moment from 'moment';

export const RidersList = ({ list, history }) => {
    return <ul className="dashbord-order-list">
        {list.map((item, index) =>
            <li key={item.id}>
                <span className="time"
                      onClick={() => history.push(`/riders/detail/${item.id}`)}
                      style={{cursor: 'pointer'}}
                ><strong>{index + 1}</strong>{`. ${item.firstName} ${item.lastName}`}</span>
                <span className="cell-info">
                    {item.phone}
                    <i className="icon-cell"/>
                </span>
                <span className="order-id inline">Last active: {(moment(item.lastSeenAt).fromNow())}</span>
                {/*<h3>{`${item.firstName} ${item.lastName} (${item.phone})`}</h3>*/}
                {/*<span className="address"><img src="../../images/map-marker.svg"/> Lahore University of Management Sciences. Sector...</span>
                <div className="lab-test"><img src="../../images/icon-lab.svg"/> <span className="tag">CBC</span> <span
                    className="tag">Another</span> <span className="tag">2 More</span></div>
*/}            </li>
        )}
    </ul>
};
