import * as types from './types'
import { payloadActionCreator, } from '../../utils/actions'

export const resetAttendanceState = payloadActionCreator(types.RESET_ATTENDANCE_STATE)
export const updateAttendanceParams = payloadActionCreator(types.UPDATE_ATTENDANCE_PARAMS);

export const getAttendanceListRequest = payloadActionCreator(types.GET_ATTENDANCE_LIST_REQUEST)
export const getAttendanceListSuccess = payloadActionCreator(types.GET_ATTENDANCE_LIST_SUCCESS)
export const getAttendanceListFailure = payloadActionCreator(types.GET_ATTENDANCE_LIST_FAILURE)

export const getFilteredAttendanceListRequest = payloadActionCreator(types.GET_FILTERED_ATTENDANCE_LIST_REQUEST)
export const getFilteredAttendanceListSuccess = payloadActionCreator(types.GET_FILTERED_ATTENDANCE_LIST_SUCCESS)
export const getFilteredAttendanceListFailure = payloadActionCreator(types.GET_FILTERED_ATTENDANCE_LIST_FAILURE)

export const getAttendanceShiftsRequest = payloadActionCreator(types.GET_ATTENDANCE_SHIFTS_REQUEST)
export const getAttendanceShiftsSuccess = payloadActionCreator(types.GET_ATTENDANCE_SHIFTS_SUCCESS)
export const getAttendanceShiftsFailure = payloadActionCreator(types.GET_ATTENDANCE_SHIFTS_FAILURE)

export const emailAttendanceCsvRequest = payloadActionCreator(types.EMAIL_ATTENDANCE_CSV_REQUEST);
export const emailAttendanceCsvSuccess = payloadActionCreator(types.EMAIL_ATTENDANCE_CSV_SUCCESS);
export const emailAttendanceCsvFailure = payloadActionCreator(types.EMAIL_ATTENDANCE_CSV_FAILURE);

export const getRiderShiftAttendanceRequest = payloadActionCreator(types.GET_RIDER_SHIFT_ATTENDANCE_REQUEST)
export const getRiderShiftAttendanceSuccess = payloadActionCreator(types.GET_RIDER_SHIFT_ATTENDANCE_SUCCESS)
export const getRiderShiftAttendanceFailure = payloadActionCreator(types.GET_RIDER_SHIFT_ATTENDANCE_FAILURE)

export const addShiftBreakRequest = payloadActionCreator(types.ADD_SHIFT_BREAK_REQUEST)
export const addShiftBreakSuccess = payloadActionCreator(types.ADD_SHIFT_BREAK_SUCCESS)
export const addShiftBreakFailure = payloadActionCreator(types.ADD_SHIFT_BREAK_FAILURE)
