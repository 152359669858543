import { connect } from 'react-redux';

import {PhysicalReview} from "./physical-review";
import {
  selectIsInteractionIdValidFromState,
  selectIsReviewCompleteFromState,
  selectIsExpiredFromState,
  selectPhysicalVisitReviewQuestionsFromState
} from "../../../selectors/feedback";
import {feedbackActions} from "../../../actions/feedback";

const mapStateToProps = state => ({
  physicalVisitReviewQuestions: selectPhysicalVisitReviewQuestionsFromState(state),
  isReviewComplete: selectIsReviewCompleteFromState(state),
  isInteractionIdValid: selectIsInteractionIdValidFromState(state),
  isExpired: selectIsExpiredFromState(state)
});

const mapDispatchToProps = {
  getPhysicalVisitReviewQuestions: feedbackActions.getPhysicalVisitReviewQuestionsRequest,
  postPhysicalVisitReviewFeedback: feedbackActions.postPhysicalVisitReviewFeedbackRequest,
  getPhysicalVisitReviewFeedback: feedbackActions.getPhysicalVisitReviewFeedbackRequest
};

export const PhysicalReviewContainer = connect(mapStateToProps, mapDispatchToProps)(PhysicalReview);
