import { takeEvery, } from 'redux-saga/effects';
import { types } from '../../actions/attendance';
import {
  addShiftBreakSaga,
  emailAttendanceCsv,
  getAttendanceList,
  getAttendanceShiftsList,
  getFilteredAttendanceList, getRiderShiftAttendance
} from './attendance';

export function* attendanceSaga() {
  yield takeEvery(types.GET_ATTENDANCE_LIST_REQUEST, getAttendanceList);
  yield takeEvery(types.GET_FILTERED_ATTENDANCE_LIST_REQUEST, getFilteredAttendanceList);
  yield takeEvery(types.GET_ATTENDANCE_SHIFTS_REQUEST, getAttendanceShiftsList);
  yield takeEvery(types.EMAIL_ATTENDANCE_CSV_REQUEST, emailAttendanceCsv);
  yield takeEvery(types.GET_RIDER_SHIFT_ATTENDANCE_REQUEST, getRiderShiftAttendance);
  yield takeEvery(types.ADD_SHIFT_BREAK_REQUEST, addShiftBreakSaga);
}
