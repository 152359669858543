import Axios from 'axios';
import { store } from '../../components/app';
import { refreshTokenAPI } from '../auth';
import { tokenRefreshSuccess, logoutAttempt } from '../../actions/auth/actions';
import { ERROR_MESSAGES } from 'api/constants';

export const isTokenExpiredError = (status, data) => status === 401 && data.message === ERROR_MESSAGES.TOKEN_EXPIRED;

export const resetTokenAndReattemptRequest = (originalRequest, data, authType) => new Promise((resolve, reject) => {
  refreshTokenAPI(data).then(res => {
    originalRequest.headers.Authorization = `${authType} ${res.data.accessToken || ''}`;
    store.dispatch(tokenRefreshSuccess(res.data));
    resolve(Axios(originalRequest));
  }).catch(err => {
    store.dispatch(logoutAttempt());
    reject(err);
  });
});
