import React from 'react';

export const SearchBar = (props) => {
  const { handleSubmit, placeholder } = props;
  return <form className="id-search-form">
    <input type="search"
           placeholder={placeholder}
           onChange={(e) => handleSubmit(e.target.value)}
    />
    <button onClick={(e) => e.preventDefault()} className="search-btn"><i className="icon-magnify"/></button>
  </form>
};
