import {ORDER_STATE} from "../../utils/constants";

const selectOrderFromState = state => state.order;

export const selectLOrdersListFromState = state => selectOrderFromState(state).list;
export const selectOrdersCountListFromState = state => selectOrderFromState(state).count;
export const selectLOrdersListLoadingFromState = state => selectOrderFromState(state).loading;
export const selectOrdersStatesFromState = state => selectOrderFromState(state).states;
export const selectOrderDetailFromState = state => selectOrderFromState(state).orderDetail;
export const selectOrderScanTypesFromState = state => selectOrderFromState(state).scanTypes;
export const selectOrderScanTypesLoadingFromState = state => selectOrderFromState(state).loading;
export const selectOrdersNextFromState = state => selectOrderFromState(state).next;
export const selectOrderStatsFromState = state => selectOrderFromState(state).stats;
export const selectOrderParamsFromState = state => selectOrderFromState(state).params;
export const selectExternalOrderParamsFromState = state => selectOrderFromState(state).externalOrderParams;
export const selectOrderActivitiesFromState = state => selectOrderFromState(state).orderActivities;
export const selectHearAboutUsListFromState = state => selectOrderFromState(state).hearAboutUsList;
export const selectProblemStringsFromState = state => selectOrderFromState(state).problemStrings;
export const selectCancellationStringsFromState = state => selectOrderFromState(state).cancellationStrings;
export const selectOrderTimeSlotsFromState = state => selectOrderFromState(state).orderTimeSlots;
export const selectCopiedOrderFromState = state => selectOrderFromState(state).copiedOrder;
export const selectLateOrdersListFromState = state => selectOrderFromState(state).lateOrders;
export const selectOrderSourcesFromState = state => selectOrderFromState(state).orderSources;
export const selectReassignmentStringsFromState = state => selectOrderFromState(state).reassignmentStrings;
export const selectAddressTypesFromState = state => selectOrderFromState(state).addressTypes;
export const selectRescheduleStringsFromState = state => selectOrderFromState(state).rescheduleStrings;


export const graphSelector = state => {
  const graphData = selectOrderFromState(state).onTimeRate;
  const values = [];
  const barChartData = {
    labels: [],
    datasets: [],
  };

  graphData && graphData.map(data => {
    barChartData.labels.push(data.date);
    values.push(data.percentageOnTimeCollections);
  });
  barChartData.datasets.push({
    label: 'On Time Rate',
    backgroundColor: '#3D49B9',
    borderColor: '#3D49B9',
    hoverBackgroundColor: '#3D49B9',
    hoverBorderColor: '#3D49B9',
    data: values,
  });
  return barChartData;
};

export const selectFilteredOrderParamsFromState = (state, type) => {
  return selectOrderFromState(state).params[type]
}
