import React from 'react';
import {isEmpty} from 'lodash';
import {BarChart} from "../dashboard/bar-chart";

export const StatsSummary = ({stats}) => {
  return <div className="graph-panel">
    <div className="graph"><BarChart/></div>
    <div className="graph-info">
      <table className="collection-table table">
        <thead>
        <tr>
          <th></th>
          <th><span className="title">Reached</span></th>
          <th><span className="title">Collected</span></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <div className="left-info">
              <span className="box traffic"></span>
              <span className="text">On Time</span>
            </div>
          </td>
          <td><span
            className="value">{!isEmpty(stats) && `${stats.onTimeReachedPercentage.toFixed(2).toString()}%`}</span></td>
          <td><span
            className="value">{!isEmpty(stats) && `${stats.onTimeCollectedPercentage.toFixed(2).toString()}%`}</span>
          </td>
        </tr>
        <tr>
          <td>
            <div className="left-info">
              <span className="box too-many"></span>
              <span className="text">Off Time</span>
            </div>
          </td>
          <td><span
            className="value">{!isEmpty(stats) && `${(stats.onTimeReachedPercentage > 0 ? (100 - stats.onTimeReachedPercentage).toFixed(2) : 100).toString()}%`}</span>
          </td>
          <td><span
            className="value">{!isEmpty(stats) && `${(stats.onTimeCollectedPercentage > 0 ? (100 - stats.onTimeCollectedPercentage).toFixed(2) : 100).toString()}%`}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
};
