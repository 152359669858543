import { types } from '../../actions/customer';
import { INITIAL_STATE } from "../initial-state";

export const customer = (state = INITIAL_STATE.customer, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customerDetail: {},
        list: [...state.list, ...payload.results],
        next: payload.next,
        count: payload.count,
        loading: false

      };
    case types.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_FILTERED_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_FILTERED_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customerDetail: {},
        list: action.payload.results,
        next: action.payload.next,
        count: payload.count,
        loading: false
      };
    case types.GET_FILTERED_CUSTOMERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.UPDATE_CUSTOMER_PARAMS:
      return {
        ...state,
        params: action.payload
      };
    case types.GET_CUSTOMER_ORDERS_REQUEST:
      return {
        ...state,
        order: {
          ...state.order,
          loading: true,
        },
      };
    case types.GET_CUSTOMER_ORDERS_SUCCESS:
      return {
        ...state,
        order: {
          list: [...state.order.list, ...payload.results],
          next: payload.next,
          loading: false,
        },
      };
    case types.GET_CUSTOMER_ORDERS_FAILURE:
      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
        },
      };
    case types.GET_FILTERED_CUSTOMER_ORDERS_REQUEST:
      return {
        ...state,
        order: {
          ...INITIAL_STATE.customer.order,
          loading: true,
        },
      };
    case types.GET_FILTERED_CUSTOMER_ORDERS_SUCCESS:
      return {
        ...state,
        order: {
          list: payload.results,
          next: payload.next,
          loading: false,
        },
      };
    case types.GET_FILTERED_CUSTOMER_ORDERS_FAILURE:
      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
        },
      };
    case types.GET_CUSTOMER_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        customerDetail: action.payload,
        loading: false

      };
    case types.GET_CUSTOMER_DETAIL_FAILURE:
      return {
        ...state,
        customerDetail: {},
        loading: false
      };
    case types.CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerDetail: action.payload,
        loading: false

      };
    case types.CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_CUSTOMER_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        customerAddress: action.payload,
        loading: false
      };
    case types.GET_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        customerAddress: [],
        loading: false
      };
    case types.CREATE_CUSTOMER_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CREATE_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        customerDetail: {
          ...state.customerDetail,
          addresses: [...state.customerDetail.addresses]
        },
      };
    case types.CREATE_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        customerAddress: [],
        loading: false
      };
    case types.GET_CUSTOMERS_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_CUSTOMERS_ID_SUCCESS:
      return {
        ...state,
        ids: action.payload.results.map(item => {
          return {
            ...item,
            label: item.phone
          };
        }),
        loading: false
      };
    case types.GET_CUSTOMERS_ID_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.RESET_CUSTOMER_STATE:
      return INITIAL_STATE.customer;

    case types.DELETE_CUSTOMER_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.DELETE_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        customerDetail: {
          ...state.customerDetail,
          addresses: state.customerDetail.addresses.filter((customer) => customer.id !== action.payload)
        },
        loading: false

      };
    case types.DELETE_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.CREATE_CUSTOMER_PATIENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CREATE_CUSTOMER_PATIENT_SUCCESS:
      return {
        ...state,
        customerDetail: {
          ...state.customerDetail,
          patients: [action.payload, ...state.customerDetail.patients],
          createdPatientDetail: action.payload
        },
        loading: false
      };
    case types.CREATE_CUSTOMER_PATIENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.UPDATE_CUSTOMER_PATIENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.UPDATE_CUSTOMER_PATIENT_SUCCESS:
      const updatedPatient = action.payload;
      return {
        ...state,
        customerDetail: {
          ...state.customerDetail,
          patients: state.customerDetail.patients.map(el => el.id === updatedPatient.id ? updatedPatient : el)
        },
        loading: false
      };
    case types.UPDATE_CUSTOMER_PATIENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.DELETE_CUSTOMER_PATIENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.DELETE_CUSTOMER_PATIENT_SUCCESS:
      return {
        ...state,
        customerDetail: {
          ...state.customerDetail,
          patients: state.customerDetail.patients.filter((patient) => patient.id !== action.payload)
        },
        loading: false
      };
    case types.DELETE_CUSTOMER_PATIENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
