import React, { useEffect, useState } from 'react';
import { Nav } from '../commons/nav';
import { find, debounce } from 'lodash';
import { startOfToday, endOfToday } from 'date-fns';
import {
  formatAMPM,
  assignOrderClass,
  qsParse,
  qsStringify,
  fullName,
  getSortingClass,
  getNextSortingState,
  clearOtherSortingParams,
  isNoSortingParams,
  getLocalStorageBoolean,
  setLocalStorageBoolean,
  parseQueryStringObjectToArray,
} from '../../utils/helper';
import moment from 'moment';
import { truncate } from 'lodash';
import { Formik, Field } from 'formik';
import {
  SORTING_PARAM,
  defaultOrderListSorting,
  IS_ORDERS_FILTER_OPEN,
  VERTICALS,
} from '../../utils/constants';
import { Loader } from '../commons/loader';
import Modal from 'react-modal';
import OrderCancel from './order-cancel';
import refreshImage from "../../assets/images/refresh-white.png";
import { Filter } from "../commons/filter";
import { ROUTES } from "../../routes/constants";

let initFilter = {
  search: '',
  regions: [],
  prevSelectedRegions: [],
  allRegions: false,
  pickupFrom: startOfToday(),
  pickupTo: endOfToday(),
  sorting: defaultOrderListSorting,
};

let filter = {
  ...initFilter,
};

const UnverifiedOrdersList = props => {
  const {
    getUnverifiedOrders,
    orders,
    regions,
    cancelOrder,
    ordersNext,
    getFilteredUnverifiedOrders,
    history,
    resetOrderState,
    updateExternalOrderParams,
    ordersLoading,
    bloodRegionDetail: regionDetail,
    ordersCount,
    getRegions,
  } = props;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isOpenCancelOrderModal, setIsOpenCancelOrderModal] = useState(0);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(getLocalStorageBoolean(IS_ORDERS_FILTER_OPEN) === null ? false : getLocalStorageBoolean(IS_ORDERS_FILTER_OPEN));

  const getFilterParamsFromProps = () => {
    let { location: { search: queryParams } } = history;
    queryParams = queryParams.replace('?', '');
    filter = {
      ...filter,
      ...qsParse(queryParams),
    };
    filter.regions = parseQueryStringObjectToArray(filter.regions);
  };

  useEffect(() => {
    resetOrderState();
    getRegions({ vertical: VERTICALS.BLOOD });
    getFilterParamsFromProps();
    filter.regions = parseQueryStringObjectToArray(filter.regions);
    getFilteredUnverifiedOrders(filter);
    setIsFilterChanged(!isFilterChanged);
    updateExternalOrderParams(filter);

    return () => {
      resetOrderState();
      filter = { ...initFilter };
    };
  }, []);

  useEffect(() => {
    if (regionDetail?.id) {
      if (!filter.regions.length) {
        filter.regions = [regionDetail.id];
        applyFilter();
      }
    }
  }, [regionDetail]);

  const loadMore = () => {
    if (!ordersLoading && ordersNext) {
      const paramsObj = qsParse(ordersNext.split('?')[1]);
      if (paramsObj.page)
        getUnverifiedOrders({
          ...filter,
          vertical: VERTICALS.blood,
          page: paramsObj.page,
        });
    }
  };

  window.onscroll = debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (window.location.pathname === `${process.env.PUBLIC_URL}${ROUTES.UNVERIFIED_ORDERS}`) {
        loadMore();
      }
    }
  }, 100);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
    setLocalStorageBoolean(IS_ORDERS_FILTER_OPEN, !isFilterOpen);
  };

  const handleChangeSearch = event => {
    filter.search = event.target.value;
    if (!event.target.value.length) {
      setFilter();
      applyFilter();
    }
  };

  const handleOnKeyDownSearch = event => {
    filter.search = event.target.value;
    if (event.key === 'Enter') {
      setFilter();
      applyFilter();
    }
  };

  const handleRegionChangeFilter = (e, stateName, option) => {
    if (e.target.checked) {
      filter.regions = filter.regions.filter(n => n);
      filter.regions = [...new Set([...filter.regions, option.id])];
      if (filter.regions.length === regions.map(region => region.id).length) {
        filter.allRegions = true;
        filter.prevSelectedRegions = filter.regions;
      }
    } else {
      if (filter.regions.length === 1)
        return;
      filter.regions = filter.regions.filter((id) => id != option.id);
      filter.prevSelectedRegions = filter.regions;
      filter.allRegions = false;
    }
    filter.rider = '';
    setFilter();
  };

  const handleAllRegionChangeFilter = (e) => {
    if (e.target.checked) {
      filter.allRegions = true;
      filter.regions = filter.regions.filter(n => n);
      filter.prevSelectedRegions = filter.regions;
      filter.regions = regions.map(region => region.id);
    } else {
      filter.prevSelectedRegions = parseQueryStringObjectToArray(filter.prevSelectedRegions);
      filter.regions = filter.regions.filter(n => n);
      filter.regions = filter.prevSelectedRegions ? filter.prevSelectedRegions : [regionDetail.id];
      filter.allRegions = filter.regions.length === regions.map(region => region.id).length;
    }
    setFilter();
  };

  const handleDateTimeFilterChange = (filterType, value) => {
    filter[filterType] = value;
    setFilter();
  };

  const handleOnSortingChangeFilter = (e, sortingParam) => {
    filter.sorting[sortingParam] = getNextSortingState(
      filter.sorting[sortingParam]
    );
    filter.sorting = clearOtherSortingParams(filter.sorting, sortingParam);
    if (isNoSortingParams(filter.sorting)) {
      filter.sorting = defaultOrderListSorting;
    }
    applyFilter();
  };

  const setFilter = () => {
    setIsFilterChanged(!isFilterChanged);
    updateExternalOrderParams(filter);
  };

  const applyFilter = () => {
    history.replace({
      pathname: ROUTES.UNVERIFIED_ORDERS,
      search: qsStringify(filter)
    });
  };

  const orderCancel = (orderId, data) => {
    cancelOrder({ id: orderId, vertical: VERTICALS.blood, data, filter });
    setIsOpenCancelOrderModal(0);
  };

  const closeCancelOrderModal = () => {
    setIsOpenCancelOrderModal(0);
  };

  return (
    <div
      id='wrapper'
      className={`inner-page ${modalIsOpen ? 'order-assigin-popup-active' : ''} ${isFilterOpen ? 'filter-active' : ''}`}>
      <Nav />
      <div className='listing-page'>
        <div className='listing-head'>
          <h2>
            Unverified Orders <span className='small'>- {ordersCount} results</span>
          </h2>
        </div>
        <div className='list-search-area'>
          <div className='id-search-form refresh'>
            <Formik
              initialValues={{ search: filter.search }}
              enableReinitialize={true}>
              {props => (
                <form onSubmit={props.handleSubmit}>
                  <Field
                    type='search'
                    placeholder='e.g. Order ID'
                    name='search'
                    onChange={e => {
                      props.setFieldValue('search', e.target.value);
                      handleChangeSearch(e);
                    }}
                    onKeyDown={e => handleOnKeyDownSearch(e)}
                  />
                  <button type='submit'
                    className='search-btn'
                    onClick={() => {
                      setFilter();
                      applyFilter();
                    }}>
                    <i className='icon-magnify' />
                  </button>
                </form>
              )}
            </Formik>
          </div>
          <a
            onClick={() => {
              setFilter();
              applyFilter();
            }}
            className='filter-btn icon-sync'
          />
          <a
            onClick={() => {
              toggleFilter();
            }}
            className='filter-btn icon-filter'
          />
        </div>
        <table className='list-table'>
          <thead>
            <tr>
              <th>SN</th>
              <th>Order Number</th>
              <th>Patient</th>
              <th>Address</th>
              <th>Status</th>
              <th>
                <i className='icon-calendar' /> Collection Time
                <i
                  className={`th-i ${getSortingClass(filter.sorting.pickup)}`}
                  onClick={e =>
                    handleOnSortingChangeFilter(e, SORTING_PARAM.PICKUP)
                  }></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((order, index) => {
              return (
                <tr
                  key={order?.id}
                  onClick={() => history.push(`/unverified-orders/detail/${order?.id}`)}>
                  <td>{index + 1}</td>
                  <td>{order?.number}</td>
                  <td>
                    {truncate(fullName(order?.patient), { length: 30 })}
                    <span className='sm-text'>{order?.patient?.phone}</span>
                  </td>
                  <td title={order?.address}>
                    {truncate(order?.address, { length: 40 })}
                    <span className='sm-text'>
                      <b>
                        {regions &&
                          regions?.length &&
                          find(regions, { id: order?.region })?.name}
                      </b>
                    </span>
                  </td>
                  <td>
                    <span className={assignOrderClass(order?.state)}>
                      {order?.state?.charAt(0).toUpperCase() +
                        order?.state?.slice(1)}
                    </span>
                  </td>
                  <td>
                    {`${new Date(
                      order?.pickup
                    ).toLocaleDateString()} ${formatAMPM(
                      new Date(order?.pickup)
                    )}`}
                    <span className='sm-text'>
                      ({moment(order?.pickup).fromNow()})
                    </span>
                  </td>
                  <td>
                    <a
                      title="Detail"
                      className="action icon-edit"
                      onClick={() => history.push(`/unverified-orders/detail/${order?.id}`)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {ordersLoading && <Loader />}
        {
          !ordersLoading && ordersNext &&
          <button className={`load-more`} onClick={!ordersLoading && loadMore}><img src={refreshImage} />Load More
          </button>
        }
      </div>

      <Filter
        loading={ordersLoading}
        onClose={toggleFilter}
        isFilterOpen={isFilterOpen}
        isFilterUpdated={isFilterChanged}
        applyFilter={applyFilter}
        dateTimeFilter={{
          pickupFrom: filter.pickupFrom,
          pickupFromStateName: 'pickupFrom',
          pickupTo: filter.pickupTo,
          pickupToStateName: 'pickupTo',
          handleDateTimeFilterChange: handleDateTimeFilterChange
        }}
        regionFilter={{
          filterName: 'Regions',
          filterValues: regions.map(r => ({ id: r.id, value: r.name })),
          filterStateName: 'regions',
          onChange: handleRegionChangeFilter,
          selectedValues: filter.regions.map(sv => Number(sv)),
          checkedType: 'id',
          customFilter: {
            value: 'All Regions',
            onChange: handleAllRegionChangeFilter,
            selectedValues: filter.allRegions
          }
        }}
      />

      {!!isOpenCancelOrderModal && (
        <Modal
          isOpen={!!isOpenCancelOrderModal}
          onRequestClose={closeCancelOrderModal}
          className='small-modal'>
          <OrderCancel
            orderId={isOpenCancelOrderModal}
            orderCancel={orderCancel}
            closeModal={closeCancelOrderModal}
          />
        </Modal>
      )}
    </div>
  );
};
export default UnverifiedOrdersList;
