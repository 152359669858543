import React from 'react';
import { getWeekdayName, HHmm } from '../../utils/helper';
import shiftImage from '../../assets/images/img3.svg'
import { Field, Formik } from 'formik';
import { isAfter, isBefore, addHours } from "date-fns";
import { toast } from 'react-toastify';
import { TimeFieldWithError } from '../ui/form-fields/time-form-field-with-error';
import { SHIFT_CREATE_ACTION } from '../../utils/constants';


const ShiftCreate = ({ createShift, weekday, regionId, closeModal, isLoading }) => {

  const handleCreateShift = (e, action, values) => {
    e.preventDefault()
    values = { ...values }
    if (isAfter(values.startTime, values.endTime)) {
      toast.error("Start time can't be greater than end time");
      return;
    }
    if (isBefore(values.endTime, values.startTime)) {
      toast.error("End time can't be less than start time");
      return;
    }
    values.startTime = HHmm(values.startTime)
    values.endTime = HHmm(values.endTime)
    createShift({
      action,
      data: values
    })
    closeModal()
  }
  const initialValues = { weekday, region: regionId, startTime: new Date(), endTime: addHours(new Date(), 1) }
  initialValues['endTime'].setDate((initialValues.startTime.getDate()))

  return (
    <div className="lightbox schedule">
      <div className="add-time-lightbox">
        <a
          href=''
          className="lightbox-close icon-clear"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        />
        <h2>New shift schedule</h2>
        <div className="content-holder">
          <div className="img-holder">
            <img src={shiftImage} alt="shift image"/>
          </div>
          <div className="right-area">
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}>
              {props => (
                <form>
                  <span className="title">Shift timing</span>
                  <span className="day"><i className="icon-calendar"/> {getWeekdayName(weekday)}</span>
                  <div className="select-time-cols">
                    <div className="input-holder">
                      <Field
                        variant="inline"
                        onChange={val => {
                          if (isAfter(val, new Date(props.values.endTime))) {
                            toast.error("Start time can't be greater than end time");
                            return;
                          }
                          props.setFieldValue('startTime', val);
                        }}
                        name="startTime"
                        component={TimeFieldWithError}/>
                    </div>
                    <span className="to">to</span>
                    <div className="input-holder">
                      <Field
                        variant="inline"
                        onChange={val => {
                          if (isBefore(val, new Date(props.values.startTime))) {
                            toast.error("End time can't be less than start time");
                            return;
                          }
                          props.setFieldValue('endTime', val);
                        }}
                        name="endTime"
                        component={TimeFieldWithError}/>
                    </div>
                  </div>
                  <div className="btns-holder">
                    <a href=""
                       className="yellow"
                       onClick={(e) => {
                         handleCreateShift(e, SHIFT_CREATE_ACTION.SAVE, props.values)
                       }}
                    >Save</a>
                    <a
                      href=""
                      className="blue"
                      onClick={(e) => {
                        handleCreateShift(e, SHIFT_CREATE_ACTION.SAVE_AND_ADD_RIDER, props.values)
                      }}
                    >Save & Add Rider</a>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ShiftCreate;
