import StepWizard from 'react-step-wizard';
import React, {useEffect} from 'react';
import CustomerUpdateStepOne from './customer-update-step-one';
import CustomerUpdateStepTwo from './customer-update-step-two';
import {VERTICALS} from "../../utils/constants";

const CustomerUpdate = (props) => {
  const {
    customerDetail,
    getCustomerDetail,
    createCustomerAddress,
    resetCustomerState,
    updateCustomer,
    match: {params: {id: customerId},},
    deleteCustomerAddress,
    getAllRegions,
    getCities,
    regions,
    cities,
  } = props;

  useEffect(() => {
    getAllRegions({vertical: VERTICALS.BLOOD});
    getCities({vertical: VERTICALS.BLOOD});
  }, []);

  const [updatedCustomer, setUpdatedCustomer] = React.useState({});
  return <div id='wrapper' className='inner-page'>
    <StepWizard><
      CustomerUpdateStepOne
      history={props.history}
      getCustomerDetail={getCustomerDetail}
      customerDetail={customerDetail}
      resetCustomerState={resetCustomerState}
      setUpdatedCustomer={setUpdatedCustomer}
      customerId={customerId}
    />

      <CustomerUpdateStepTwo
        history={props.history}
        customerDetail={customerDetail}
        getCustomerDetail={getCustomerDetail}
        createCustomerAddress={createCustomerAddress}
        resetCustomerState={resetCustomerState}
        updateCustomer={updateCustomer}
        updatedCustomer={updatedCustomer}
        deleteCustomerAddress={deleteCustomerAddress}
        regions={regions}
        cities={cities}
      />
    </StepWizard>
  </div>


};
export default CustomerUpdate
