export const AUTH_LOGIN_ATTEMPT = 'AUTH_LOGIN_ATTEMPT';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';

export const AUTH_LOGOUT_ATTEMPT = 'AUTH_LOGOUT_ATTEMPT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL';

export const AUTH_TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';

export const GET_NP_STAFF_USERS_REQUEST = 'GET_NP_STAFF_USERS_REQUEST';
export const GET_NP_STAFF_USERS_SUCCESS = 'GET_NP_STAFF_USERS_SUCCESS';
export const GET_NP_STAFF_USERS_FAILURE = 'GET_NP_STAFF_USERS_FAILURE';
export const RESET_NP_STAFF_USERS_STATE = 'RESET_NP_STAFF_USERS_STATE';

export const INITIATE_PASSWORD_RESET_REQUEST = 'INITIATE_PASSWORD_RESET_REQUEST';
export const INITIATE_PASSWORD_RESET_SUCCESS = 'INITIATE_PASSWORD_RESET_SUCCESS';
export const INITIATE_PASSWORD_RESET_FAILURE = 'INITIATE_PASSWORD_RESET_FAILURE';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
