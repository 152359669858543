import React from 'react';
import pins from '../../assets/images/pins.svg'
import alarm from '../../assets/images/alarm.svg'
import {
  convertMetersToKm,
  userFacingDistanceTravelled
} from '../../utils/helper';

export const OrderAssignRiderInfoWindow = ({ info }) => {

  return <div className="map-popup assign-popup">
    <ul className="list">
      <li>
        {info.rider && <span className="time">{`${info.rider.firstName} ${info.rider.lastName}`}</span>
        }
      </li>
      <li>
        <img src={pins} alt="pins"/>
        <div className="info">
          <span className="km">{userFacingDistanceTravelled(convertMetersToKm(info.roadDistance))}</span>
          <span className="detail">From Destination</span>
        </div>
      </li>
      <li>
        <img src={alarm} alt="alarm"/>
        <div className="info">
          <span className="km">~{info.onRoadTime}</span>
          <span className="detail">From Delivery</span>
        </div>
      </li>
    </ul>
  </div>
};
