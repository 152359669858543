import React from 'react';
import { getIn } from 'formik';
import { ErrorField } from 'components/ui/error-field';
import { object, shape, string } from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export const DateFieldWithError = (
  {
    field,
    form,
    ...props
  }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          format="dd/MM/yyyy"
          placeholder="DD/MM/YYYY"
          {...field}
          {...props}/>
      </MuiPickersUtilsProvider>
      {errorText && <ErrorField value={errorText}/>}
    </React.Fragment>
  );
};

DateFieldWithError.propTypes = {
  field: shape({
    name: string,
    value: string,
  }).isRequired,
  form: object,
};
