import moment from "moment";
import {types} from '../../actions/rider';
import {INITIAL_STATE} from "../initial-state";

export const rider = (state = INITIAL_STATE.rider, action) => {
  const {payload} = action;
  switch (action.type) {
    case types.GET_RIDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_RIDERS_SUCCESS:
      return {
        ...state,
        riderDetail: {},
        list: [...state.list, ...payload.results],
        next: payload.next,
        count: payload.count,
        loading: false

      };
    case types.GET_RIDERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_FILTERED_RIDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_FILTERED_RIDERS_SUCCESS:

      return {
        ...state,
        riderDetail: {},
        list: payload.results,
        next: payload.next,
        count: payload.count,
        loading: false

      };
    case types.GET_FILTERED_RIDERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_ONLINE_RIDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ONLINE_RIDERS_SUCCESS:
      return {
        ...state,
        onlineList: action.payload,
        loading: false

      };
    case types.UPDATE_RIDER_PARAMS:
      return {
        ...state,
        params: action.payload
      };
    case types.GET_ONLINE_RIDERS_FAILURE:
      return {
        ...state,
        loading: false
      };

    case types.GET_ASSIGNABLE_RIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ASSIGNABLE_RIDER_SUCCESS:
      return {
        ...state,
        assignableRider: action.payload,
        loading: false

      };
    case types.GET_ASSIGNABLE_RIDER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.RESET_ASSIGNABLE_RIDER_STATE:
      return {
        ...state,
        assignableRider: {
          availableRiders: [],
          deliveringRiders: [],
          outOfCapacityRiders: [],
          lateRiders: [],
          outOfRegionRiders: [],
          offDutyRiders: [],
        },
        loading: false
      };
    case types.GET_RIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_RIDER_SUCCESS:
      return {
        ...state,
        riderDetail: action.payload,
        loading: false

      };
    case types.GET_RIDER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_RIDER_FORCE_DELIVER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_RIDER_FORCE_DELIVER_SUCCESS:
      return {
        ...state,
        riderDetail: action.payload,
        loading: false

      };
    case types.GET_RIDER_FORCE_DELIVER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_RIDER_STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_RIDER_STATS_SUCCESS:
      return {
        ...state,
        stats: action.payload,
        loading: false,
        refreshTime: moment(),

      };
    case types.GET_RIDER_STATS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_RIDER_LOCATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_RIDER_LOCATION_SUCCESS:
      return {
        ...state,
        'riderDetail.profile': action.payload,
        loading: false
      };
    case types.GET_RIDER_LOCATION_FAILURE:
      return {
        ...state,
        loading: false

      };
    case types.GET_RIDER_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_RIDER_TASKS_SUCCESS:
      return {
        ...state,
        riderTasks: action.payload,
        loading: false,
      };
    case types.GET_RIDER_TASKS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_RIDER_TASKS_STATE:
      return {
        ...state,
        riderTasks: [],
        loading: false
      };
    case types.GET_NP_RIDERS_SUCCESS:
      return {
        ...state,
        npRiders: action.payload,
        loading: false,
      };
    case types.GET_NP_RIDERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_RIDER_ORDERS_REQUEST:
      return {
        ...state,
        order: {
          ...state.order,
          loading: true,
        },
      };
    case types.GET_RIDER_ORDERS_SUCCESS:
      return {
        ...state,
        order: {
          list: [...state.order.list, ...payload.results],
          next: payload.next,
          loading: false,
        },
      };
    case types.GET_RIDER_ORDERS_FAILURE:
      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
        },
      };
    case types.GET_FILTERED_RIDER_ORDERS_REQUEST:
      return {
        ...state,
        order: {
          ...INITIAL_STATE.rider.order,
          loading: true,
        },
      };
    case types.GET_FILTERED_RIDER_ORDERS_SUCCESS:
      return {
        ...state,
        order: {
          list: payload.results,
          next: payload.next,
          loading: false,
        },
      };
    case types.GET_FILTERED_RIDER_ORDERS_FAILURE:
      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
        },
      };
    case types.RIDER_MARK_OFF_DUTY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.RIDER_MARK_OFF_DUTY_SUCCESS:
      return {
        ...state,
        riderDetail: action.payload,
        loading: false

      };
    case types.RIDER_MARK_OFF_DUTY_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.CREATE_RIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CREATE_RIDER_SUCCESS:
    case types.CREATE_RIDER_FAILURE:
      return {
        ...state,
        loading: false

      };
    case types.UPDATE_RIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.UPDATE_RIDER_SUCCESS:
      return {
        ...state,
        riderDetail: action.payload,
        loading: false

      };
    case types.UPDATE_RIDER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_RIDERS_SEARCH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_RIDERS_SEARCH_SUCCESS:
      return {
        ...state,
        ridersSearch: payload,
        loading: false,
      };
    case types.GET_RIDERS_SEARCH_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.REMOVE_RIDERS_SEARCH_ITEM:
      const riderId = payload
      return {
        ...state,
        ridersSearch: state.ridersSearch.filter((rider) => rider.id != riderId),
      };
    case types.RESET_RIDER_STATE:
      return {
        ...state,
        ...INITIAL_STATE.rider,
      };
    default:
      return state;
  }
};
