import {apiCaller} from '../api-caller';
import {ENDPOINTS, REQUEST_TYPES} from '../constants';
import {SORTING} from "../../utils/constants";
import * as caseConverter from "change-object-case";

const formatOrdersFilter = (filter = {}) => {
  let formattedFilter = {...filter,};
  if (filter.regions) {
    formattedFilter['regions'] = filter.regions.join(',');
  }
  return caseConverter.snakeKeys(formattedFilter);
};

export const getCustomersAPI = (filter = {}) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.CUSTOMERS}`,
    params: formatOrdersFilter(filter),
  })
};

export const getCustomerDetailAPI = ({id, vertical}) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.GET_CUSTOMER}${id}/`,
    params: {
      vertical
    }

  })
};

export const deleteCustomerAddressAPI = (id) => apiCaller({
  method: REQUEST_TYPES.DELETE,
  url: `${ENDPOINTS.DELETE_CUSTOMER_ADDRESS}${id}/`,
});

export const getCustomerAddressAPI = (id) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.CUSTOMER_ADDRESS,
  params: {customer: id}
});

export const getCustomersIdAPI = (query) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.CUSTOMER_SEARCH,
  params: query
});

export const createCustomerAPI = (data) => apiCaller({
  method: REQUEST_TYPES.POST,
  url: ENDPOINTS.CREATE_CUSTOMER,
  data
});

export const createCustomerAddressAPI = data => {
  const {vertical} = data;
  return apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.ENTITY}${vertical}/customer-address/`,
    data
  })
};

export const updateCustomerAPI = ({id, data}) => apiCaller({
  method: REQUEST_TYPES.PATCH,
  url: `${ENDPOINTS.UPDATE_CUSTOMER}${id}/`,
  data
});

export const updateCustomerAddressAPI = ({id, data}) => apiCaller({
  method: REQUEST_TYPES.PATCH,
  url: `${ENDPOINTS.UPDATE_CUSTOMER_ADDRESS}${id}`,
  data
});

export const createCustomerPatientAPI = (data) => apiCaller({
  method: REQUEST_TYPES.POST,
  url: ENDPOINTS.CUSTOMER_PATIENT,
  data
});

export const updateCustomerPatientAPI = ({id, data}) => apiCaller({
  method: REQUEST_TYPES.PUT,
  url: `${ENDPOINTS.CUSTOMER_PATIENT}${id}/`,
  data
});

export const deleteCustomerPatientAPI = (id) => apiCaller({
  method: REQUEST_TYPES.DELETE,
  url: `${ENDPOINTS.CUSTOMER_PATIENT}${id}`,
});
