import { types } from '../../actions/feedback';
import { INITIAL_STATE } from "../initial-state";

export const feedback = (state = INITIAL_STATE.feedback, action) => {
  switch (action.type) {
    case types.GET_ORDER_REVIEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ORDER_REVIEW_SUCCESS:
      return {
        ...state,
        orderReview: action.payload,
        loading: false
      };
    case types.GET_ORDER_REVIEW_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.CREATE_ORDER_REVIEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CREATE_ORDER_REVIEW_SUCCESS:
      return {
        ...state,
        orderReview: action.payload,
        loading: false
      };
    case types.CREATE_ORDER_REVIEW_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.RESET_FEEDBACK_STATE:
      return {
        ...state,
        ...INITIAL_STATE.feedback,
      };
    case types.GET_PHYSICAL_VISIT_REVIEW_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        physicalVisitFeedbackQuestions: action.payload
      };
    case types.POST_PHYSICAL_VISIT_REVIEW_FEEDBACK_SUCCESS:
      return {
        ...state,
        isReviewComplete: true,
        showGoogleMapReviewLink: action.payload.responses.every(item => (!isNaN(parseInt(item.answer)) ? item.answer == "5" : true))
      }
    case types.GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_SUCCESS:
      return {
        ...state,
        isReviewComplete: action.payload.isComplete,
        isExpired: action.payload.isExpired
      }
    case types.GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_FAILURE:
      return {
        ...state,
        isInteractionIdValid: false
      }
    case types.GET_CRO_FEEDBACK_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        croFeedbackQuestions: action.payload
      };
    case types.POST_CRO_FEEDBACK_SUCCESS:
      return {
        ...state,
        isReviewComplete: true,
      }
    case types.GET_CRO_FEEDBACK_SUCCESS:
      return {
        ...state,
        isReviewComplete: action.payload.isComplete,
        isExpired: action.payload.isExpired,
        isSatisfied: action.payload.isSatisfied,
      }
    case types.GET_CRO_FEEDBACK_FAILURE:
      return {
        ...state,
        isInteractionIdValid: false
      }
    default:
      return state;
  }
};
