import {
  call,
  put,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as _ from 'lodash'
import { toast } from 'react-toastify';
import { logger } from '../../utils/logger';
import { actions } from '../../actions/auth';
import { ROUTES } from '../../routes/constants';
import {getNpStaffUsersAPI, initiatePasswordResetAPI, loginAPI, logoutAPI, passwordResetAPI} from '../../api/auth';
import {
  setLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageObject,
  removeSessionStorageItem
} from '../../utils/helper';
import {
  IS_ORDERS_FILTER_OPEN,
  IS_SHOW_ORDERS,
  IS_SHOW_RIDERS,
  SELECTED_REGION_DETAIL,
  TOKEN,
  USER,
  VERTICALS
} from '../../utils/constants';

export function* attemptLogin({ payload }) {
  try {
    const resp = yield call(loginAPI, payload);
    if (resp) {
      yield put(actions.loginSuccess(resp.data));
      setLocalStorageItem(TOKEN,resp.data.token );
      setLocalStorageObject(USER, _.pick(resp.data.user, ['firstName', 'lastName', 'userType', 'userPermissions']));
      yield put(push(ROUTES.BLOOD_DASHBOARD));
    }
  } catch (error) {
    logger.error(error);
    yield put(actions.loginFailure(error));
  }
}

export function* attemptLogout({payload}) {
  try {
    const resp = yield call(logoutAPI, payload);
    if (resp) {
      yield put(actions.logoutSuccess());
      removeLocalStorageItem(USER);
      removeLocalStorageItem(TOKEN);
      removeLocalStorageItem(IS_SHOW_ORDERS);
      removeLocalStorageItem(IS_SHOW_RIDERS);
      removeLocalStorageItem(SELECTED_REGION_DETAIL);
      removeLocalStorageItem(IS_ORDERS_FILTER_OPEN);
      removeSessionStorageItem(VERTICALS.XRAY);
      removeSessionStorageItem(VERTICALS.BLOOD);
      yield put(push('/'));
    }
  }
    catch (error) {
    logger.error(error);
    yield put(actions.logoutFailure(error));
  }
}

export function* getNpStaffUsers({payload: values}) {
  try {
    const resp = yield call(getNpStaffUsersAPI, values);
    if (resp) {
      yield put(actions.getNpStaffUsersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(actions.getNpStaffUsersFailure(error));
  }
}

export function* initiatePasswordReset({payload: value}) {
  try {
    const resp = yield call(initiatePasswordResetAPI, value);
    if (resp) {
      yield put(actions.initiatePasswordResetSuccess(resp.data));
      toast.success('Password reset has been requested!')
    }
  } catch (error) {
    logger.error(error);
    yield put(actions.initiatePasswordResetFailure(error));
  }
}

export function* passwordReset({payload: value}) {
  try {
    const resp = yield call(passwordResetAPI, value);
    if (resp) {
      yield put(actions.passwordResetSuccess(resp.data));
      toast.success('Password changed successfully!')
    }
  } catch (error) {
    logger.error(error);
    yield put(actions.passwordResetFailure(error));
  }
}
