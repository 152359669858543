import React, { Fragment, useEffect, useState } from "react";
import { Field, Formik } from "formik";
import logo from "../../../assets/images/logo2.png";
import {MAX_STAR, STAR_RATING} from "../../../utils/constants";
import { ErrorField } from "../../ui/error-field";
import {
  formatFeedbackResponse,
  getInitialValuesForFeedbackForm,
  getStarQuestions,
  getTextQuestions,
} from "../../../utils/feedback";
import { ROUTES } from "../../../routes/constants";


export const CROFeedback = ({
  match: {
    params: { id }
  },
  croFeedbackQuestions,
  getCROFeedbackQuestions,
  postCROFeedback,
  getCROFeedback,
  isInteractionIdValid,
  isComplete,
  isSatisfied,
  isExpired,
  history,
}) => {
  useEffect(() => {
    getCROFeedbackQuestions();
    getCROFeedback({interactionId: id});
  }, []);

  useEffect(() => {
    if (isComplete) {
      history.push(ROUTES.CRO_FEEDBACK_SUCCESS);
    }
  }, [isComplete]);

  useEffect(() => {
    if (!isInteractionIdValid) {
      history.push(ROUTES.CRO_FEEDBACK_ERROR);
    }
  }, [isInteractionIdValid]);

  useEffect(() => {
    if (isExpired) {
      history.push(ROUTES.CRO_FEEDBACK_EXPIRED);
    }
  }, [isExpired]);

  const starQuestions = getStarQuestions(croFeedbackQuestions);
  const textQuestions = getTextQuestions(croFeedbackQuestions);
  const [formErrors, setFormErrors] = useState({});

  return (
    <div id="wrapper">
      <Formik
        initialValues={getInitialValuesForFeedbackForm(
          croFeedbackQuestions
        )}
      >
        {formik => (
          <div className="page-table">
            <div className="page-table-head">
              <div className="head-holder">
                <div className="logo-one">
                  <a href="" onClick={e => e.preventDefault()}>
                    <img src={logo} alt="logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className="page-table-body">
              <div className="value-feedback-hoder">
                <h2>We value your feedback!</h2>
                <p className={'feedback-title'}>Please rate the skills of our customer service representative.</p>
                {starQuestions.map(question => (
                  <ul className="star-list-physical-feedback-form">
                    <div className={'question'}>
                      <li>
                        <span className="text-label-physical-feedback-form">{question.text}</span>
                        <ul className="rating-physical-feedback-form">
                          {[...Array(MAX_STAR)].map((x, i) => (
                            <li
                              key={i}
                              className={
                                formik.values[question.id] > i ? "active" : ""
                              }
                            >
                              <div className={'star-holder'}>
                                <a
                                  href=" "
                                  onClick={e => {
                                    e.preventDefault();
                                    formik.setFieldValue(question.id, i + 1);
                                  }}
                                ></a>
                                <span className={'star-title'}>{STAR_RATING[i]}</span>
                              </div>
                            </li>
                          ))}
                          <ErrorField value={formErrors[question.id]} />
                        </ul>
                      </li>
                    </div>
                  </ul>
                ))}
                {textQuestions.map(question => (
                  <Fragment>
                    <Field
                      component="textarea"
                      name={question.id}
                      placeholder={question.text}
                      className={'text-area'}
                    />
                    <ErrorField value={formErrors[question.id]} />
                  </Fragment>
                ))}
              </div>
            </div>
            <div className="page-table-footer">
              <div className="footer-holder">
                <button
                  className="sub-btn"
                  onClick={() => {
                    let errors = {};
                    let hasError = false;
                    croFeedbackQuestions.map(question => {
                      if (question.isMandatory && !formik.values[question.id]) {
                        errors[question.id] = "Please fill this field!";
                        hasError = true;
                      }
                    });
                    hasError
                      ? setFormErrors(errors)
                      : postCROFeedback({
                          interactionId: id,
                          responses: formatFeedbackResponse(formik.values)
                        });
                  }}
                  disabled={formik.isValidating}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
