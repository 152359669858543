import React from 'react';

const NewOrderSection = ({ id = null, order = {} }) => {
  const orderDetails = [
    { label: "Order ID", value: order?.id },
    { label: "Patient Name", value: order?.name },
    { label: "City", value: order?.city },
    { label: "Phone", value: order?.phone },
    { label: "Date", value: order?.date },
    { label: "Time Slot", value: order?.timeSlot },
    { label: "Address", value: order?.address },
  ];
  return (
    <div className="order-aside">
      <div className="head">
        <h2>{id ? 'Partial Order' : 'New Order'}</h2>
      </div>
      {id ? (
        <ul className='partial-order-data'>
          {orderDetails?.map(({ label, value }) => (
            <li key={label} className='order-data'>
              <span className='data-label'>{label}</span>
              <span className='data-value'>{value}</span>
            </li>
          ))}
        </ul>
      ) : (
        <div className="order-img">
          <img src={require("../../assets/images/order-image.svg")} alt="new order" />
        </div>
      )}
    </div>
  );
};

export default NewOrderSection;
