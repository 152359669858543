import React from 'react';
import {number, object} from "yup";
import {Field, Formik} from "formik";
import {SHIFT_BREAK_TIMES} from '../../utils/constants'
import {toast} from "react-toastify";
import breakIcon from '../../assets/images/break-popup-icon.svg'


const ShiftBreak = ({ closeModal, onSave, attendanceId }) => {
  return (
    <div className="lightbox schedule">
      <div className="online-chart-lightbox">
        <a
          href=''
          className="icon-close icon-clear"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        />
        <img className={'break-img-holder'} src={breakIcon}/>
          <Formik
            initialValues={{
              breakDurationMins: null
            }}
            validationSchema={
              object().shape({
                breakDurationMins: number().required()
              })
            }
            enableReinitialize={true}>
            {
              ({
                 touched,
                 errors,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isValid,
                 isSubmitting,
                 values,
                 setFieldValue,
                 form,
                 field,
               }) => (
                <form className='body'>
                  <div className='field-holder'>
                    <label className='title'> Break Duration </label>
                    <Field
                      as='select'
                      name="breakDurationMins"
                      onChange={handleChange}
                      className={'select'}
                    >
                      <option value="" label="Select break duration"/>
                      {
                        SHIFT_BREAK_TIMES.map(breakTime => {
                          return <option
                            key={breakTime.key}
                            value={breakTime.value}
                            label={breakTime.label}
                          />
                        })
                      }
                    </Field>
                  </div>
                  <a href="" className="save-btn"
                     onClick={(e) => {
                       e.preventDefault()
                       if (!values.breakDurationMins) {
                         toast.error('Break Duration required');
                         return
                       }
                       onSave({
                         id: attendanceId,
                         breakDurationMins: values.breakDurationMins
                       })
                       closeModal(false)
                     }}
                  >Save</a>
                </form>
              )
            }
          </Formik>
      </div>
    </div>
  )
};

export default ShiftBreak;
