import React, {useEffect, useState} from 'react';
import cancellationReason from '../../assets/images/error-icon.png'

const OrderCancel = ({ orderId, orderCancel, closeModal, cancellationStrings, isUnverified }) => {
  const [reason, setReason] = useState('');
  const [cancellationStringId, setCancellationStringId] = useState('');

  useEffect(() => {
    if (cancellationStrings.length > 0) {
      setCancellationStringId(cancellationStrings[0].id);
    }
  }, cancellationStrings);

  return (
    <>
      <div className="lightbox schedule">
        <div className="add-comment-lightbox">
          <a
            href=''
            className="lightbox-close icon-clear"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          />
          <h2>Order Cancellation</h2>
          <div className="content-holder">
            <div className="img-holder">
              <img src={cancellationReason} width="224" alt="" />
            </div>
            <div className="right-area">
              <div className="form-row">
                <label>Reason for cancellation</label>
                <select onChange={e => {
                  setCancellationStringId(cancellationStrings.find(i => i.text === e.target.value).id)
                }}>
                  {cancellationStrings.map(i => <option>{i.text}</option>)}
                </select>
              </div>
              <div className="form-row">
                <textarea
                  placeholder=""
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
              <div className="btns-holder">
                <a href="#" className="red" onClick={e => {
                  e.preventDefault();
                  orderCancel(orderId, {reason, cancellationStringId, isUnverified })
                }}>Cancel Order</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderCancel;
