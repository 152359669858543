import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { WEEKDAY } from '../../utils/constants';
import { getDateFromTime, getWeekdayName, hmmaaa } from '../../utils/helper';

const CopyShift = ({ closeModal, selectedShift, copyShift }) => {
  const selectedWeekday = selectedShift.weekday
  const [selectedCopyWeekday, setSelectedCopyWeekday] = useState('');
  const startTime = getDateFromTime(selectedShift.startTime)
  const endTime = getDateFromTime(selectedShift.endTime)

  return (
    <>
      <div className="lightbox small-modal-con">
        <div className="lightbox-con">
          <a
            href=''
            className="lightbox-close icon-clear"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          />
          <div className="form-row">
            <label>Copy shift {`${hmmaaa(startTime)} - ${hmmaaa(endTime)}`} to other week day</label>
            <select
              name="weekday"
              value={selectedCopyWeekday}
              onChange={(e) => {
                setSelectedCopyWeekday(e.target.value);
              }}
              style={{ display: 'block' }}
            >
              <option key={-1} value="" label="Select Week day"/>
              {
                Object.values(WEEKDAY).map(day =>
                  day === selectedWeekday ? null : <option key={day} value={day}>{getWeekdayName(day)}</option>
                )
              }
            </select>
          </div>
          <button
            className="create-btn"
            onClick={(e) => {
              e.preventDefault();
              if (selectedCopyWeekday) {
                copyShift({
                  id: selectedShift.id,
                  data: { weekday: selectedCopyWeekday }
                })
                closeModal()
              } else {
                toast.error('Select week day')
              }
            }}
          >Confirm Copy
          </button>
        </div>
      </div>
    </>
  );
};

export default CopyShift
