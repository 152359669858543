import {
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { types } from '../../actions/auth';

import {
  attemptLogin,
  attemptLogout,
  getNpStaffUsers,
  initiatePasswordReset,
  passwordReset
} from './auth';

export function* authSaga() {
  yield takeEvery(types.AUTH_LOGIN_ATTEMPT, attemptLogin);
  yield takeLatest(types.AUTH_LOGOUT_ATTEMPT, attemptLogout);
  yield takeEvery(types.GET_NP_STAFF_USERS_REQUEST, getNpStaffUsers);
  yield takeEvery(types.INITIATE_PASSWORD_RESET_REQUEST, initiatePasswordReset);
  yield takeEvery(types.PASSWORD_RESET_REQUEST, passwordReset);
}

