import React from 'react';
import { isAfter, isBefore } from 'date-fns'

import { getDateFromTime, getWeekdayName, getWeekdayNumber, hmmaaa } from '../../utils/helper';
import ShiftPopup from './shift-popup';

const Days = ({ history, shifts, weekday, setSelectedWeekday, onCopyShift, onShiftDelete }) => {
  const isToday = weekday === getWeekdayNumber(weekday)
  return (
    <div key={weekday} className={`days-col ${isToday ? 'current-day' : ''}`}>
      <span className="title">{getWeekdayName(weekday)}</span>
      <ul className="time-list">
        {
          shifts.filter((shift) => shift.weekday === weekday).map((shift, index) => {
            const now = new Date()
            const startTime = getDateFromTime(shift.startTime)
            const endTime = getDateFromTime(shift.endTime)
            const slot = `${hmmaaa(startTime)} - ${hmmaaa(endTime)}`
            return <li key={shift.id}
                       className={`${isToday && isAfter(now, startTime) && isBefore(now, endTime) ? 'active' : ''}`}
                       style={{ cursor: 'pointer' }}
                       onClick={(e) => {
                         e.preventDefault()
                         e.stopPropagation()
                         history.push(`/shifts/detail/${shift.id}`)
                       }}
            >
              {slot}
              {' '}
              <ShiftPopup
                shift={shift}
                weekday={weekday}
                startTime={startTime}
                endTime={endTime}
                onCopyShift={onCopyShift}
                onShiftDelete={onShiftDelete}
              />
            </li>
          })
        }
      </ul>
      <a href=" "
         className="add-time"
         onClick={(e) => {
           e.preventDefault()
           setSelectedWeekday(weekday)
         }}
      >+</a>
    </div>
  );
};

export default Days;
