import React from 'react';
import check from '../../assets/images/check-img.svg'
import { OrderReviewWrapper } from './order-review-wrapper';

export const OrderReviewSuccess = (props) => {
  const { showGoogleMapReviewLink } = props
  return <OrderReviewWrapper>
    <div className="page-table-body">
      <div className="feedback-hoder">
        <img src={check} alt="check"/>
        <span className="title">Thank you for your valuable feedback</span>
        <p>We try our best to make our service better day by day with your feedback</p>
        { showGoogleMapReviewLink && <p>Chughtai Lab Head Office would love your feedback. Post a <a href="https://g.page/chughtai-lab-head-office/review?gm" target='_blank'>review</a> to our profile.</p>}
      </div>
    </div>
  </OrderReviewWrapper>
};
