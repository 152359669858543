import React from 'react';
import moment from 'moment';

export const RiderInfoWindow = ({ info }) => {

  return <div className="map-popup">
    <span className="time">{`${info.firstName} ${info.lastName} (${info.phone})`}</span>
    <span className="time">Last active: {(moment(info.lastSeenAt).fromNow())}</span>

  </div>
};
