import React, {useState} from 'react';
import {DashboardRiders} from './dashboard-riders';
import {DashboardLateOrders} from './dashboard-late-orders';

const TAB = {
  RIDERS: 'Riders',
  LATE_ORDERS: 'Late Orders',
};

export const DashboardSidebar = ({riders, lateOrders, history}) => {
  const [selectedTab, setSelectedTab] = useState(TAB.RIDERS);

  return (
    <div className="dashbord-order-list-panel">
      <ul className="later-tabs">
        {Object.values(TAB).map((currentTab) => {
          return <li
            key={currentTab}
            className={selectedTab === currentTab ? 'active' : ''}>
            <a href=""
               onClick={(e) => {
                 e.preventDefault();
                 setSelectedTab(currentTab);
               }}>{currentTab}</a></li>
        })}
      </ul>
      {
        selectedTab === TAB.RIDERS &&
        <DashboardRiders riders={riders}
                         history={history}
        />
      }
      {
        selectedTab === TAB.LATE_ORDERS &&
        <DashboardLateOrders lateOrders={lateOrders} history={history}/>
      }

    </div>
  )
};
