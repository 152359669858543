import React from 'react';
import { find, isEmpty, truncate } from 'lodash';
import { assignOrderClass, formatAMPM, fullName, getSortingClass, hasPermission } from '../../utils/helper';
import {
  PERMISSION,
  SORTING_PARAM,
  STATES_TO_ASSIGN,
  STATES_TO_CANCEL,
  STATES_TO_SHOW_RIDER
} from '../../utils/constants';
import moment from 'moment';
import yesImage from '../../assets/images/yes.svg';
import noImage from '../../assets/images/no.svg';


export const OrdersTable = props => {
  const {
    orders,
    regions,
    handleNeedsReview,
    handleFollowUpAddressed,
    handleAssignment,
    handleCancel,
    handleOnSortingChange,
    sortingClass,
    detailURL,
    history,
  } = props;

  const renderOrderItem = () => {
    return (
      Object.values(orders)?.map((order, index) => {
        return (
          <tr key={order?.id} onClick={() => { history.push(`${detailURL}/${order?.id}`); }}>
            <td>
              <div className="indicators-holder">
                <span className="count">{index + 1}</span>
                <ul className="indicators">
                  {order?.isFasting && <li><a href="#" className="indicator" title="Fasting"></a></li>}
                  {order?.isMedicineAndSpecificTime && <li className="yellow"><a href="#" className="indicator" title="Medicine and/or specific time"></a></li>}
                  {order?.urgentDelivery && <li className="red"><a href="#" className="indicator" title="Protocol"></a></li>}
                </ul>
              </div>
            </td>
            <td>{order?.number}</td>
            <td>
              {truncate(fullName(order?.patient), { length: 30 })}
              <span className="sm-text">{order?.patient?.phone}</span>
            </td>
            <td title={order?.address}>
              {truncate(order?.address, { length: 40 })}
              <span className="sm-text"><b>{regions && regions?.length && find(regions, { id: order?.region })?.name}</b></span>
            </td>
            <td>{STATES_TO_SHOW_RIDER.includes(order?.state) && order?.assignedRider && fullName(order?.assignedRider)}</td>
            <td><span className={assignOrderClass(order?.state)}>{order?.state}</span></td>
            <td>{`${new Date(order?.pickup).toLocaleDateString()} ${formatAMPM(new Date(order?.pickup))}`}
              <span className="sm-text">({(moment(order?.pickup).fromNow())})</span>
            </td>
            <td>
              <a
                title={order?.needsReview ? 'Needs Review' : 'Reviewed'}
                className={`icon-star-round ${order?.needsReview ? '' : 'yellow'}`}
                onClick={(e) => handleNeedsReview(e, order)}
              />
            </td>
            <td>
              <img
                src={order?.isFollowUpAddressed ? yesImage : noImage}
                alt="follow up address image"
                onClick={e => handleFollowUpAddressed(e, order)}
              />
            </td>
            <td>
              {hasPermission(PERMISSION.CAN_ASSIGN_ORDER) &&
                STATES_TO_ASSIGN.indexOf(order?.state) > -1 && order?.allowManualAssignment &&
                <a
                  title="Assign"
                  onClick={(e) => handleAssignment(e, order)}
                  className="action icon-rider"
                />
              }
              <a
                title='Detail'
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`${detailURL}/${order?.id}`);
                }
                }
                className="action icon-edit"
              />
              {
                STATES_TO_CANCEL.indexOf(order?.state) > -1 &&
                <a
                  title="Cancel"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCancel(order?.id);
                  }
                  }
                  className="action icon-cancel"
                />
              }
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <table className="list-table">
      <thead>
        <tr>
          <th>SN</th>
          <th>Order Number</th>
          <th>Patient</th>
          <th>Address</th>
          <th>Rider</th>
          <th>Status</th>
          <th>
            <i className="icon-calendar" /> Collection Time <i />
            <i
              className={`th-i ${getSortingClass(sortingClass)}`}
              onClick={(e) => handleOnSortingChange(e, SORTING_PARAM.PICKUP)}
            />
          </th>
          <th>Reviewed</th>
          <th>Follow Up Addressed</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {orders && renderOrderItem()}
      </tbody>
    </table>
  );
};
