import {
  takeEvery,
} from 'redux-saga/effects';
import { types } from '../../actions/rider';

import {
  getRiders,
  getFilteredRiders,
  getOnlineRiders,
  getRider,
  getRiderForceDeliver,
  getRiderStats,
  getRiderLocation,
  getRiderTasks,
  getAssignableRider,
  getNpRiders,
  getRiderOrders,
  getFilteredRiderOrders,
  getRiderMarkOffDuty, createRider, updateRider, getRidersSearch,
} from './rider';

export function* riderSaga() {
  yield takeEvery(types.GET_RIDERS_REQUEST, getRiders);
  yield takeEvery(types.GET_FILTERED_RIDERS_REQUEST, getFilteredRiders);
  yield takeEvery(types.GET_NP_RIDERS_REQUEST, getNpRiders);
  yield takeEvery(types.GET_RIDERS_SEARCH_REQUEST, getRidersSearch);
  yield takeEvery(types.GET_ONLINE_RIDERS_REQUEST, getOnlineRiders);
  yield takeEvery(types.GET_ASSIGNABLE_RIDER_REQUEST, getAssignableRider);
  yield takeEvery(types.GET_RIDER_REQUEST, getRider);
  yield takeEvery(types.GET_RIDER_FORCE_DELIVER_REQUEST, getRiderForceDeliver);
  yield takeEvery(types.GET_RIDER_STATS_REQUEST, getRiderStats);
  yield takeEvery(types.GET_RIDER_LOCATION_REQUEST, getRiderLocation);
  yield takeEvery(types.GET_RIDER_TASKS_REQUEST, getRiderTasks);
  yield takeEvery(types.GET_RIDER_ORDERS_REQUEST, getRiderOrders);
  yield takeEvery(types.GET_FILTERED_RIDER_ORDERS_REQUEST, getFilteredRiderOrders);
  yield takeEvery(types.RIDER_MARK_OFF_DUTY_REQUEST, getRiderMarkOffDuty);
  yield takeEvery(types.CREATE_RIDER_REQUEST, createRider);
  yield takeEvery(types.UPDATE_RIDER_REQUEST, updateRider);
}
