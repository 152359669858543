import React, { lazy, Suspense, useEffect } from 'react';
import {
  Route,
  Switch,
  withRouter,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';

import { DashboardContainer } from '../components/dashboard';
import { OrderContainer } from '../components/order';
import { Loading } from '../components/commons/loading';
import { ROUTES } from './constants';
import { getRegionsRequest } from "../actions/location/actions";
import { CustomerContainer } from '../components/customer/container';
import { RiderContainer } from '../components/rider/container';
import { ShiftContainer } from '../components/shift/container';
import { AttendanceContainer } from '../components/attendance/container';
import UnverifiedOrdersList from "../components/order/unverified-orders-list";
import { PERMISSION } from "../utils/constants";
import { ReleaseNotes } from "../components/reports/release-notes";
import { FAQs } from "../components/reports/faqs";
import { ReportsContainer } from "../components/reports/container";

const NotFound = lazy(() => import('../components/not-found'));
const PrivateRoute = lazy(() => import('../components/HOC/private-route'));
const PublicRoute = lazy(() => import('../components/HOC/public-route'));
const Login = lazy(() => import('../components/auth/login'));
const PasswordResetRequest = lazy(() => import('../components/auth/password-reset/password-reset-request'));
const PasswordReset = lazy(() => import('../components/auth/password-reset/password-reset'));

const AppRouter = props => {
  const {
    getRegions
  } = props;

  useEffect(() => {
    getRegions();
  }, []);

  return <Suspense fallback={<Loading />}>
    <Switch>
      <PublicRoute
        component={Login}
        path={ROUTES.LOGIN}
        exact
      />
      <PublicRoute
        component={PasswordResetRequest}
        path={ROUTES.REQUEST_PASSWORD_RESET}
        exact
      />
      <PublicRoute
        component={PasswordReset}
        path={`${ROUTES.PASSWORD_RESET}/:passwordResetToken`}
        exact
      />
      <PrivateRoute
        component={DashboardContainer('BloodDashboard')}
        path={ROUTES.BLOOD_DASHBOARD}
        exact
      />
      <PrivateRoute
        component={DashboardContainer('XRayDashboard')}
        path={ROUTES.XRAY_DASHBOARD}
        exact
      />
      <PrivateRoute
        component={DashboardContainer('BloodCityDashboard')}
        path={ROUTES.BLOOD_CITY_DASHBOARD}
        exact
      />
      <PrivateRoute
        component={DashboardContainer('XRayCityDashboard')}
        path={ROUTES.XRAY_CITY_DASHBOARD}
        exact
      />
      <PrivateRoute
        component={OrderContainer('BloodOrderList')}
        path={ROUTES.BLOOD_ORDERS}
        exact
      />
      <PrivateRoute
        component={OrderContainer('XRayOrderList')}
        path={ROUTES.XRAY_ORDERS}
        exact
      />
      <PrivateRoute
        component={OrderContainer('BloodOrderCreate')}
        path={`${ROUTES.BLOOD_ORDERS}/create/:id`}
        exact
      />
      <PrivateRoute
        component={OrderContainer('BloodOrderCreate')}
        path={`${ROUTES.BLOOD_ORDERS}/create`}
        exact
      />
      <PrivateRoute
        component={OrderContainer('XRayOrderCreate')}
        path={`${ROUTES.XRAY_ORDERS}/create`}
        exact
      />
      <PrivateRoute
        component={OrderContainer('BloodOrderDetail')}
        path={`${ROUTES.BLOOD_ORDERS}/detail/:id`}
      />
      <PrivateRoute
        component={OrderContainer('XRayOrderDetail')}
        path={`${ROUTES.XRAY_ORDERS}/detail/:id`}
      />
      <PrivateRoute
        component={CustomerContainer('CustomerUpdate')}
        path={`${ROUTES.CUSTOMER}/update/:id`}

      />
      <PrivateRoute
        component={CustomerContainer('CustomerDetail')}
        path={`${ROUTES.CUSTOMER}/detail/:id`}

      />
      <PrivateRoute
        component={CustomerContainer('CustomerList')}
        path={ROUTES.CUSTOMER}
        exact
      />
      <PrivateRoute
        component={CustomerContainer('CustomerCreate')}
        path={`${ROUTES.CUSTOMER}/create`}
        exact
      />
      <PrivateRoute
        component={RiderContainer('RiderList')}
        path={ROUTES.RIDERS}
        exact
      />
      <PrivateRoute
        component={RiderContainer('RiderDetail')}
        path={`${ROUTES.RIDERS}/detail/:id`}

      />
      <PrivateRoute
        component={ShiftContainer('ShiftList')}
        path={ROUTES.SHIFTS}
        permission={PERMISSION.CAN_VIEW_SHIFT_SCHEDULE}
        exact
      />
      <PrivateRoute
        component={ShiftContainer('ShiftDetail')}
        path={`${ROUTES.SHIFTS}/detail/:id`}
        permission={PERMISSION.CAN_VIEW_SHIFT_SCHEDULE}
      />
      <PrivateRoute
        component={AttendanceContainer('AttendanceList')}
        path={ROUTES.ATTENDANCE}
        permission={PERMISSION.CAN_VIEW_RIDER_SHIFT}
        exact
      />
      <PrivateRoute
        component={OrderContainer('UnverifiedOrdersList')}
        path={ROUTES.UNVERIFIED_ORDERS}
        exact
      />
      <PrivateRoute
        component={OrderContainer('PartialOrdersList')}
        path={ROUTES.PARTIAL_ORDERS}
        exact
      />
      <PrivateRoute
        component={OrderContainer('UnverifiedOrdersDetail')}
        path={`${ROUTES.UNVERIFIED_ORDERS}/detail/:id`}
      />
      <PrivateRoute
        component={ReportsContainer('Redash')}
        path={ROUTES.REDASH}
      />
      <PrivateRoute
        component={ReportsContainer('Issue')}
        path={ROUTES.ISSUE_REPORTING}
      />
      <PrivateRoute
        component={ReleaseNotes}
        path={ROUTES.RELEASE_NOTES}
      />
      <PrivateRoute
        component={FAQs}
        path={ROUTES.FAQS}
      />
      <Redirect
        from={ROUTES.HOME}
        to={ROUTES.BLOOD_DASHBOARD}
      />
      <Route
        component={NotFound}
      />
    </Switch>
  </Suspense>;
};

export default withRouter(
  connect(
    null,
    { getRegions: getRegionsRequest },
  )(AppRouter),
);
