import React, { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { get } from 'lodash';
import { GOOGLE_API_KEY, PAKISTAN_LAT_LONG } from '../../../utils/constants';
import { Loading } from '../../commons';
import { toast } from "react-toastify";
import { Marker, Popup } from "react-leaflet";
import { GoogleApiWrapper } from "google-maps-react";
import { CustomerIcon } from "../../../assets/images/Icons";
import Map from "../../commons/Map";

let autocomplete;

const CreateAddressForm = (props) => {
  const { google, closeModal, cities, vertical, order, id } = props;
  const defaultCityId = cities && cities.length > 0 ? cities[0].id : '';
  const googleAddressEl = useRef(null);
  const [search, setSearch] = useState('');
  const [isAddressTouched, setIsAddressTouched] = useState(false);
  const [marker, setMarker] = useState([PAKISTAN_LAT_LONG.lat, PAKISTAN_LAT_LONG.lng]);

  const handleSubmit = (values) => {
    const { createCustomerAddress, closeModal, customerId } = props;
    if (!isAddressTouched) {
      toast.error("Select address on map");
      return;
    }
    createCustomerAddress({
      latitude: marker[0].toFixed(6),
      longitude: marker[1].toFixed(6),
      customer: customerId,
      address: values.address,
      googleAddress: search,
      vertical,
    });
    closeModal(marker[0].toFixed(6), marker[1].toFixed(6));
  };

  useEffect(() => {
    return () => {
      autocomplete && autocomplete.unbindAll();
      autocomplete = null;
    };
  }, []);

  useEffect(() => {
    setAutocomplete(defaultCityId);
  }, [defaultCityId]);

  const setAutocomplete = (cityId) => {
    const city = cities.find(city => city.id === cityId);
    if (google && city) {
      setMarker([city.centerPoint.latitude, city.centerPoint.longitude]);
      setSearch('');
      let bounds = null;
      if (city.south && city.west && city.north && city.east) {
        const southWest = new google.maps.LatLng(+city.south, +city.west);
        const northEast = new google.maps.LatLng(+city.north, +city.east);
        bounds = new google.maps.LatLngBounds(southWest, northEast,);
      }
      let options = {
        bounds,
        strictBounds: true,
        componentRestrictions: { country: 'pak' },
        fields: ['geometry.location'],
      };
      if (autocomplete) {
        autocomplete.setOptions(options);
      } else {
        autocomplete = new google.maps.places.Autocomplete(googleAddressEl.current, options);
        autocomplete.addListener('place_changed', function () {
          const { geometry = {} } = autocomplete.getPlace();
          setSearch(get(googleAddressEl, 'current.value', ''));
          if (geometry.location) {
            setIsAddressTouched(true);
            setMarker([geometry.location.lat(), geometry.location.lng()]);
          }
        });
      }
    }
  };

  if (!google) {
    return <Loading />;
  }

  return (
    <div className="lightbox">
      <div className="lightbox-add-address">
        <form className="id-search-form geosuggest">
          <input
            ref={googleAddressEl}
            type="search"
            placeholder="Search location"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
        </form>
        <Map
          initialCenter={marker}
          initialZoom={15}
          currentCenter={marker}
          currentZoom={15}
          styles={{ height: "100%", zIndex: 0 }}
        >
          {
            search &&
            <Marker icon={CustomerIcon} position={marker}>
              <Popup>
                {search}
              </Popup>
            </Marker>
          }
        </Map>
      </div>
      <div className="lightbox-add-address-right">
        <div className="lightbox-head">
          <a onClick={() => closeModal()} className="lightbox-close icon-clear" />
          <div className="info">
            <h3>New Address</h3>
          </div>
        </div>
        <Formik
          initialValues={{
            address: id ? order.address : '',
            city: defaultCityId,
          }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >{({ values, setFieldValue }) => (
          <Form>
            <div className="dropdown-area">
              <span className="label-text">City</span>
              <select
                name="city"
                value={values.city}
                style={{ display: 'block' }}
                onChange={(e) => {
                  setAutocomplete(+e.target.value);
                  setFieldValue('city', +e.target.value);
                }}
              >
                {
                  cities.map(city => {
                    return <option key={city.id} value={city.id} label={city.name} />;
                  })
                }
              </select>
            </div>
            <div className="form-row">
              <label>Address</label>
              <Field type="text" name="address" placeholder="John Doe (CCL121212)" />
            </div>
            <button type="submit" disabled={!values.city || !values.address} className="create-btn">Create</button>
          </Form>
        )}
        </Formik>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({ apiKey: GOOGLE_API_KEY })(CreateAddressForm);
