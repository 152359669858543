import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { yupPhoneSearch } from '../../utils/validation';
import Autocomplete from 'react-autocomplete';
import { ErrorField } from '../ui/error-field';
import { SEARCH_ON_LETTER, VERTICALS } from '../../utils/constants';
import Patients from './patients';
import PatientInformationCreate from './patient-information-create';
import PatientInformationShow from './patient-information-show';
import { SearchBar } from 'components/commons/search-bar';
import { fullName } from 'utils/helper';

const patientViewState = {
  notSelected: 'notSelected',
  create: 'create',
  update: 'update',
  selected: 'selected',
};

const OrderCustomerInformation = props => {
  const {
    orderFormik,
    customerIds,
    getCustomersId,
    getCustomerDetail,
    customerDetail,
    resetCustomerState,
    createCustomerPatient, updateCustomerPatient, deleteCustomerPatient,
    createCustomer,
    customersLoading,
    orderInitialValues,
    copiedOrder,
    setSelectedPatient,
    selectedPatient,
    vertical,
    order = {},
    id = null
  } = props;
  const [patientState, setPatientState] = useState(patientViewState.create);
  const [filteredPatients, setFilteredPatients] = useState([]);

  useEffect(() => {
    if (!isEmpty(copiedOrder)) {
      getCustomerDetail({ id: copiedOrder.customerId, vertical });
    }
  }, []);

  useEffect(() => {
    if (customerDetail.patients.length === 0) {
      setPatientState(patientViewState.notSelected);
    } else if (customerDetail.createdPatientDetail && id) {
      setPatientState(patientViewState.selected);
      const patient = customerDetail.patients.find((p) => p.id === customerDetail?.createdPatientDetail?.id);
      if (patient) {
        setSelectedPatient(patient);
      }
    } else if (customerDetail.patients.length > 0 && !id) {
      setPatientState(patientViewState.selected);
      if (isEmpty(copiedOrder)) {
        const selectedPatientIndex = selectedPatient?.id
          ? customerDetail.patients.findIndex(patient => patient.id == selectedPatient.id)
          : 0;
        setSelectedPatient(customerDetail.patients[selectedPatientIndex]);
      } else {
        const patient = customerDetail.patients.find((p) => p.id === copiedOrder.patientId);
        if (patient) {
          setSelectedPatient(patient);
        }
      }
    }
  }, [customerDetail.patients]);

  const onUpdate = (e) => {
    e.preventDefault();
    setPatientState(patientViewState.update);
  };

  const onDelete = (e) => {
    e.preventDefault();
    deleteCustomerPatient(selectedPatient.id);
    setSelectedPatient({});
  };

  const onCancel = (e, isUpdate) => {
    if (isUpdate) {
      setPatientState(patientViewState.selected);
    } else {
      setPatientState(patientViewState.notSelected);
    }
  };

  const onPatientClick = (patient) => {
    setSelectedPatient(patient);
    setPatientState(patientViewState.selected);
  };

  const onSearchChange = (phone) => {
    if (phone.length % SEARCH_ON_LETTER === 0 || phone.length === 11) {
      getCustomersId({ search: phone });
      resetCustomerState();
      setSelectedPatient({});
    }
  };

  const handleSearchCustomer = (value) => {
    if (isEmpty(value)) { setFilteredPatients(customerDetail.patients); }
    let filtered = customerDetail.patients.filter(patient => fullName(patient).toLowerCase().includes(value.toLowerCase()));
    setFilteredPatients(filtered);
  };

  useEffect(() => {
    setFilteredPatients(customerDetail.patients);
  }, [customerDetail.patients]);

  useEffect(() => {
    if (order?.phone && id) {
      onSearchChange(order?.phone);
      if (order?.customerId !== null) {
        orderFormik.resetForm(orderInitialValues);
        getCustomerDetail({ id: order?.customerId, vertical });
        setPatientState(patientViewState.create);
        orderFormik.setFieldValue('pickupDate', new Date(order?.date));
      }
    }
  }, [order]);

  return <div className="two-cols">
    <div className="col-info customer-colum">
      <h2>Customer Information</h2>
      <Formik
        initialValues={{ phone: order?.phone ? order?.phone : isEmpty(copiedOrder) ? '' : copiedOrder.phone }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          phone: yupPhoneSearch,
        })}
      >
        {formik => (
          <Form>
            <div className="form-row autocomplete">
              <label>Customer Number *</label>
              <Autocomplete
                name='phone'
                getItemValue={item => item.label}
                items={customerIds}
                renderItem={(item, isHighlighted) => (
                  <div
                    key={item.id}
                    style={{ background: isHighlighted ? "lightgray" : "white" }}
                  >
                    {item.phone}
                  </div>
                )}
                value={formik.values.phone}
                onChange={e => {
                  const value = e.target.value;
                  if (value.length > 11) {
                    return;
                  }
                  formik.setFieldValue("phone", value);
                  onSearchChange(value);
                }}
                onSelect={(val, e) => {
                  setSelectedPatient({});
                  orderFormik.resetForm(orderInitialValues);
                  formik.setFieldValue("phone", val);
                  getCustomerDetail({ id: e.id, vertical });
                }}
              />
              <ErrorField value={formik.errors.phone} />
              {
                (formik.values.phone && !customerIds.length && !customerDetail.id && !customersLoading) &&
                <span className="sm-text">{'No customer found '}
                  {formik.values.phone.length === 11 &&
                    formik.isValid && !customersLoading &&
                    <a href=''
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedPatient({});
                        orderFormik.resetForm(orderInitialValues);
                        createCustomer({ phone: formik.values.phone });
                      }}
                    >click here to create customer</a>
                  }
                </span>
              }
            </div>
          </Form>
        )}
      </Formik>
      {!isEmpty(customerDetail.patients) &&
        <div className="search-customer">
          <label>Search Customer</label>
          <SearchBar handleSubmit={handleSearchCustomer} />
        </div>}
      {customerDetail.id && <span className="sub-title">Linked Patients</span>}
      <Patients
        patients={filteredPatients}
        selectedPatient={selectedPatient}
        onPatientClick={onPatientClick}
      />
      {(patientState === patientViewState.notSelected ||
        patientState === patientViewState.selected
      ) && customerDetail.id &&
        <a href='' className="add-patient"
          onClick={(e) => {
            e.preventDefault();
            setSelectedPatient({});
            setPatientState(patientViewState.create);
          }}
        >Add New Patient</a>
      }
      {(patientState === patientViewState.create) &&
        <a href='' className="adding-patient-btn"
          onClick={(e) => e.preventDefault()}
        >Adding New Patient</a>
      }
    </div>
    {patientState === patientViewState.notSelected &&
      <div className="col-info no-selected-patient">
        <img src={require("../../assets/images/no-patient-selected.png")} alt="No patient selected" />
      </div>
    }
    {patientState === patientViewState.selected &&
      <PatientInformationShow
        patient={selectedPatient}
        onDelete={onDelete}
        onUpdate={onUpdate}
        customerDetail={customerDetail}
      />
    }
    {patientState === patientViewState.create &&
      <PatientInformationCreate
        patient={selectedPatient}
        createCustomerPatient={createCustomerPatient}
        updateCustomerPatient={updateCustomerPatient}
        onCancel={onCancel}
        customerId={customerDetail.id}
        setSelectedPatient={setSelectedPatient}
        order={order}
        id={id}
      />
    }
    {patientState === patientViewState.update &&
      <PatientInformationCreate
        patient={selectedPatient}
        onCancel={onCancel}
        createCustomerPatient={createCustomerPatient}
        updateCustomerPatient={updateCustomerPatient}
        customerId={customerDetail.id}
      />
    }

  </div>;
};

export default OrderCustomerInformation;
