import React from 'react';
import {Field, Formik} from 'formik';
import {DateTimeFieldWithError} from '../ui/form-fields/datetime-form-field-with-error';
import {startCase} from 'lodash';
import {booleanFilters} from '../../utils/constants';
import {isAfter, isBefore} from 'date-fns';
import {toast} from 'react-toastify';
import {disableDiv} from "../../utils/helper";


export const Filter = props => {
  const {
    loading,
    onClose,
    isFilterOpen,
    dateTimeFilter,
    regionFilter,
    statesFilter,
    addressFilter,
    needsReviewFilter,
    autoAssignedFilter,
    manualAssignedFilter,
    isCovidFilter,
    followUpFilter,
    followUpAddressedFilter,
    unverifiedByRiderFilter,
    isFasting,
    urgentDelivery,
    isMedicineAndSpecificTime,
    problemSelectFilter,
    riderSelectFilter,
    createdBySelectFilter,
    fulfillmentMethodSelectFilter,
    hasFollowUpsSelectFilter,
    isOnlineFilter,
    isWildcardFilter,
    applyFilter,
  } = props;

  const renderBooleanFilter = (filter) => {
    const { filterName, filterStateName, onChange, selectedValues } = filter;
    return (
      <div className="status-panel">
        <span className="title">{filterName}</span>
        <ul className="status-list">
        {
          booleanFilters.map(booleanFilter => (
            <li key={booleanFilter.id}>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => onChange(e, filterStateName, booleanFilter.id)}
                  value={booleanFilter.id}
                  checked={!!selectedValues.find(s => s == booleanFilter.id)}
                />
                <span className="fake-checkbox"/>
                <span className="text">{startCase(booleanFilter.label)}</span>
              </label>
            </li>
          ))
        }
        </ul>
      </div>
    )
  }

  const renderFiltersWithCheckBox = (filter) => {
    const { filterName, filterValues, filterStateName, onChange, selectedValues, customFilter, checkedType } = filter;
    return (
      <div className="status-panel">
        <span className="title">{filterName}</span>
        <ul className="status-list">
          {
            customFilter && (
              <div>
                <li>
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      onChange={(e) => customFilter.onChange(e)}
                      checked={customFilter.selectedValues === true || customFilter.selectedValues === 'true'}
                    />
                    <span className="fake-checkbox"/>
                    <span className="text">{customFilter.value}</span>
                  </label>
                </li>
              </div>
            )
          }
          {renderCheckBoxes(filterValues, filterStateName, onChange, selectedValues, checkedType)}
        </ul>
      </div>
    )
  };

  const renderCheckBoxes = (filterValues, filterStateName, onChange, selectedValues, checkedType) => {
    return (
      filterValues.map((option) => {
        return (
          <div key={option.value}>
            <li id={option.id}>
              <label className="custom-checkbox">
                <input
                  checked={
                    !!selectedValues.find(sv => checkedType === 'values' ? sv.includes(option.value) : sv === option.id)
                  }
                  type="checkbox"
                  onChange={(e) => onChange(e, filterStateName, option)}
                />
                <span className="fake-checkbox"/>
                <span className="text">{option.value.charAt(0).toUpperCase() + option.value.slice(1)}</span>
              </label>
            </li>
          </div>
        )
      })
    )
  };

  const renderFiltersWithSelect = (filter) => {
    const { filterName, filterOptions, filterStateName, selectedFilter, filterTitle, onChange } = filter;
    return (
      <div className="status-panel mt-3">
        <span className="title">{filterName}</span>
        <select
          name={filterName}
          value={selectedFilter}
          style={{ display: 'block' }}
          onChange={(e) => onChange(e, filterStateName)}
        >
          <option value="" label={filterTitle} />
          {
            filterOptions.map(option => {
              return <option key={option.id} value={option.id} label={`${startCase(option.label)}`} />
            })
          }
        </select>
      </div>
    )
  };

  const renderDateTimeFilter = (dateTimeFilter) => {
    const { pickupFrom, pickupTo, pickupFromStateName, pickupToStateName, handleDateTimeFilterChange} = dateTimeFilter;
    return (
      <div className="status-panel">
        <Formik initialValues={{ pickupFrom: pickupFrom, pickupTo: pickupTo }} enableReinitialize={true}>
          {
            props => (
              <form onSubmit={props.handleSubmit}>
                <span className="title">Pickup From Date</span>
                <ul className="status-list">
                  <div>
                    <li>
                      <Field
                        openTo="date"
                        value={pickupFrom}
                        name="pickupFrom"
                        component={DateTimeFieldWithError}
                        onChange={val => {
                          if (isAfter(val, new Date(props.values.pickupTo))) {
                            toast.error("Pickup from date can't be greater than pickup to date");
                            return;
                          }
                          props.setFieldValue('pickupFrom', val);
                          handleDateTimeFilterChange(pickupFromStateName, val);
                        }}
                      />
                    </li>
                  </div>
                </ul>
                <span className="title">Pickup To Date</span>
                <ul className="status-list">
                  <div>
                    <li>
                      <Field
                        openTo="date"
                        value={pickupTo}
                        name="pickupTo"
                        component={DateTimeFieldWithError}
                        onChange={val => {
                          if (isBefore(val, new Date(props.values.pickupFrom))) {
                            toast.error("Pickup to date can't be less than pickup from date");
                            return;
                          }
                          props.setFieldValue('pickupTo', val);
                          val.setSeconds(0);
                          val.setMilliseconds(0);
                          handleDateTimeFilterChange(pickupToStateName, val);
                        }}
                      />
                    </li>
                  </div>
                </ul>
              </form>
            )
          }
        </Formik>
      </div>
    )
  };

  return (
    <div className="filters-slide" style={disableDiv(loading)}>
      <div className="filter-head">
        <h3>Filter</h3>
        <a
          onClick={() => {
            onClose();
          }}
          className="close-filter icon-clear "
        />
      </div>
      {dateTimeFilter && renderDateTimeFilter(dateTimeFilter)}
      {regionFilter && renderFiltersWithCheckBox(regionFilter)}
      {statesFilter && renderFiltersWithCheckBox(statesFilter)}
      {addressFilter && renderFiltersWithCheckBox(addressFilter)}
      {needsReviewFilter && renderBooleanFilter(needsReviewFilter)}
      {autoAssignedFilter && renderBooleanFilter(autoAssignedFilter)}
      {manualAssignedFilter && renderBooleanFilter(manualAssignedFilter)}
      {isCovidFilter && renderBooleanFilter(isCovidFilter)}
      {followUpFilter && renderBooleanFilter(followUpFilter)}
      {followUpAddressedFilter && renderBooleanFilter(followUpAddressedFilter)}
      {unverifiedByRiderFilter && renderBooleanFilter(unverifiedByRiderFilter)}
      {isFasting && renderBooleanFilter(isFasting)}
      {urgentDelivery && renderBooleanFilter(urgentDelivery)}
      {isMedicineAndSpecificTime && renderBooleanFilter(isMedicineAndSpecificTime)}
      {problemSelectFilter && renderFiltersWithSelect(problemSelectFilter)}
      {riderSelectFilter && renderFiltersWithSelect(riderSelectFilter)}
      {createdBySelectFilter && renderFiltersWithSelect(createdBySelectFilter)}
      {fulfillmentMethodSelectFilter && renderFiltersWithSelect(fulfillmentMethodSelectFilter)}
      {hasFollowUpsSelectFilter && renderFiltersWithSelect(hasFollowUpsSelectFilter)}
      {isOnlineFilter && renderBooleanFilter(isOnlineFilter)}
      {isWildcardFilter && renderBooleanFilter(isWildcardFilter)}
      <div style={{height: '5%'}}>
        {
          isFilterOpen && (
            <button className="filter-button" onClick={applyFilter}>Apply Filters</button>
          )
        }
      </div>
    </div>
  )
};
