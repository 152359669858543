import React, { useEffect, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppRouter from './app-router';
import { Loading } from '../components/commons';
import { ROUTES } from './constants';
import OrderReviewSuccess from '../components/feedback/order-review-success-container';
import { OrderReviewExpired } from '../components/feedback/order-review-expired';
import { PhysicalReview } from "../components/feedback/physical-review";
import { OrderReviewError } from "../components/feedback/order-review-error";
import RiderLiveLocation from "../components/location/rider-live-location";
import RiderLiveLocationLinkExpired from "../components/location/rider-live-location-link-expired";
import { CROFeedback } from "../components/feedback/cro-feedback";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const OrderReviewContainer = lazy(() => import('../components/feedback/order-review-container'));
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const Router = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname
    });
  }, [location]);

  return <Suspense fallback={<Loading />}>
    <Switch>
      <Route
        component={OrderReviewContainer}
        path={`${ROUTES.ORDER_REVIEW}/:uuid`}
        exact
      />
      <Route
        component={PhysicalReview}
        path={`${ROUTES.PHYSICAL_REVIEW}/:id`}
        exact
      />
      <Route
        component={OrderReviewSuccess}
        path={`${ROUTES.ORDER_REVIEW_SUCCESS}`}
        exact
      />
      <Route
        component={OrderReviewExpired}
        path={`${ROUTES.ORDER_REVIEW_EXPIRED}`}
        exact
      />
      <Route
        component={OrderReviewError}
        path={`${ROUTES.ORDER_REVIEW_ERROR}`}
        exact
      />
      <Route
        component={RiderLiveLocation}
        path={`${ROUTES.RIDER_LIVE_LOCATION}/:uuid`}
        exact
      />
      <Route
        component={RiderLiveLocationLinkExpired}
        path={`${ROUTES.RIDER_LIVE_LOCATION_LINK_EXPIRED}/`}
        exact
      />
      <Route
        component={CROFeedback}
        path={`${ROUTES.CRO_FEEDBACK}/:id`}
        exact
      />
      <Route
        component={OrderReviewSuccess}
        path={`${ROUTES.CRO_FEEDBACK_SUCCESS}`}
        exact
      />
      <Route
        component={OrderReviewExpired}
        path={`${ROUTES.CRO_FEEDBACK_EXPIRED}`}
        exact
      />
      <Route
        component={OrderReviewError}
        path={`${ROUTES.CRO_FEEDBACK_ERROR}`}
        exact
      />
      <AppRouter />
    </Switch>
  </Suspense>;
};
export default Router;

