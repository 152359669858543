import React, { useEffect } from 'react';
import { Loader } from '../commons/loader';
import { ddMMMyyyhhmmaaa } from '../../utils/helper';

const getClassName = (verb) => {
  if (verb.includes('problem') || verb.includes('failed assignment of order') || (verb.includes('follow up'))) {
    return 'issue'
  }
  if (verb.includes('delivered')) {
    return 'completed'
  }
  return ''
};

const OrderActivity = ({ orderActivities, getOrderActivities, orderDetail, resetOrderActivitiesState, closeModal, isLoading }) => {

  useEffect(() => {
    if (orderDetail && orderDetail.id) {
      getOrderActivities({ order: orderDetail.id });
    }
  }, []);

  return (
    <div className="lightbox">
      <div className="activity-lightbox">
        <a
          href=''
          className="lightbox-close icon-clear"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        />
        <div className="activity-left">
          <div className="lft-holder">
            <img src={require("../../assets/images/img3.svg")} alt="order activity"/>
            <span className="title">Order Activity</span>
          </div>
        </div>
        <div className="activity-right">
          <span className="title">Today</span>
          {isLoading && <Loader/>}
          <ul className="activity-list">
            {orderActivities.map((activity) => (
              <li key={activity.id}
                  className={getClassName(activity.verb)}
              >
                <span><span> {ddMMMyyyhhmmaaa(activity.created)}</span></span>
                {activity.description && <span className="text">{activity.description}</span>}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrderActivity;
