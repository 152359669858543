import {connect} from 'react-redux';

import {shiftActions} from '../../actions/shift';
import {
  selectShiftDetailFromState,
  selectShiftRidersListFromState,
  selectShiftsListFromState,
  selectShiftsListLoadingFromState
} from "../../selectors/shift";
import {
  selectRidersSearchListFromState
} from "../../selectors/rider";
import {
  selectRegionsListFromState,
  selectRegionDetailFromState,
  selectHostSpotsListFromState,
} from "../../selectors/location";
import {VERTICALS} from "../../utils/constants";
import {riderActions} from "../../actions/rider";
import {locationActions} from '../../actions/location';
import {orderActions} from '../../actions/order';
import {attendanceActions} from '../../actions/attendance';
import {selectAttendanceListParamsFromState} from '../../selectors/attendance';

let Components = {
  'ShiftList': require('./shift-list').default,
  'ShiftDetail': require('./shifts-detail').default,
}

const mapDispatchToProps = {
  getHotSpots: locationActions.getHotSpotsRequest,
  selectRegionDetail: locationActions.selectRegionDetail,
  updateOrderParams: orderActions.updateOrderParams,
  updateRiderParams: riderActions.updateRiderParams,
  updateAttendanceParams: attendanceActions.updateAttendanceParams,
  resetShiftState: shiftActions.resetShiftState,
  getShiftDetail: shiftActions.getShiftRequest,
  getShifts: shiftActions.getShiftsRequest,
  createShift: shiftActions.createShiftRequest,
  createShiftRider: shiftActions.createShiftRiderRequest,
  updateShiftRider: shiftActions.updateShiftRiderRequest,
  deleteShiftRider: shiftActions.deleteShiftRiderRequest,
  getShiftRiders: shiftActions.getShiftRidersRequest,
  getNpRiders: riderActions.getNPRidersRequest,
  getRidersSearch: riderActions.getRidersSearchRequest,
  resetRiderState: riderActions.resetRiderState,
  removeRiderSearchItem: riderActions.removeRiderSearchItem,
  addShiftRider: shiftActions.addShiftRider,
  removeShiftRider: shiftActions.removeShiftRider,
  updateShift: shiftActions.updateShiftRequest,
  deleteShift: shiftActions.deleteShiftRequest,
  copyShift: shiftActions.copyShiftRequest,
  getAllRegions: locationActions.getAllRegionsRequest,
}

const mapStateToProps = state => ({
  shiftDetail: selectShiftDetailFromState(state),
  shifts: selectShiftsListFromState(state),
  shiftsLoading: selectShiftsListLoadingFromState(state),
  regions: selectRegionsListFromState(state),
  regionDetail: selectRegionDetailFromState(state, VERTICALS.COMMON),
  hotSpots: selectHostSpotsListFromState(state),
  shiftRiders: selectShiftRidersListFromState(state),
  ridersSearch: selectRidersSearchListFromState(state),
})

export const ShiftContainer = (componentName) => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Components[componentName]);
};
