import React, {useState} from 'react';
import {SearchBar} from "../commons/search-bar";
import {OrdersList} from './orders-list';

export const DashboardLateOrders = ({lateOrders, history}) => {
  const [orderSearch, setOrderSearch] = useState('');

  const filterLateOrders = (order) => {
    if (orderSearch.includes("\\")) {
      return false;
    }
    const regExp = new RegExp(orderSearch, "i");
    return order.number.match(regExp)
  };

  return (
    <>
      <SearchBar search={orderSearch}
                 handleSubmit={(search) => (setOrderSearch(search))}
                 placeholder='e.g. Order Number'/>
      <h3>Today</h3>
      <OrdersList list={lateOrders.filter(filterLateOrders)} history={history}/>
    </>
  )
};
