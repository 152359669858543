import {
  takeEvery,
} from 'redux-saga/effects';
import {types} from '../../actions/location';

import {
  getCollectionCenters,
  getHotSpots,
  getCities,
  getAllRegions,
  getRegions,
  getRiderLiveLocationSaga
} from './location';

export function* locationSaga() {
  yield takeEvery(types.GET_CITIES_REQUEST, getCities);
  yield takeEvery(types.GET_COLLECTION_CENTERS_REQUEST, getCollectionCenters);
  yield takeEvery(types.GET_ALL_REGIONS_REQUEST, getAllRegions);
  yield takeEvery(types.GET_REGIONS_REQUEST, getRegions);
  yield takeEvery(types.GET_HOT_SPOTS_REQUEST, getHotSpots);
  yield takeEvery(types.GET_RIDER_LIVE_LOCATION_REQUEST, getRiderLiveLocationSaga);
}
