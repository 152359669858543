import { connect } from 'react-redux';

import { actions as authActions } from '../../actions/auth';
import { orderActions } from '../../actions/order';
import { locationActions } from '../../actions/location';
import {
  selectLOrdersListFromState,
  selectOrdersStatesFromState,
  selectOrderDetailFromState,
  selectOrderScanTypesFromState,
  selectOrdersNextFromState,
  selectLOrdersListLoadingFromState,
  selectOrderScanTypesLoadingFromState,
  selectOrderActivitiesFromState,
  selectOrdersCountListFromState,
  selectHearAboutUsListFromState,
  selectProblemStringsFromState,
  selectCancellationStringsFromState,
  selectOrderTimeSlotsFromState,
  selectCopiedOrderFromState,
  selectExternalOrderParamsFromState,
  selectOrderSourcesFromState,
  selectReassignmentStringsFromState,
  selectAddressTypesFromState,
  selectFilteredOrderParamsFromState,
  selectRescheduleStringsFromState,
} from "../../selectors/order";
import {
  selectAssignableRiderFromState,
  selectLOnlineRidersListFromState,
  selectNpRidersListFromState,
  selectRiderDetailFromState,
  selectRidersListLoadingFromState,
  selectRiderTasksFromState
} from "../../selectors/rider";
import {
  selectCustomerAddressFromState,
  selectCustomerIdsFromState,
  selectCustomerDetailFromState,
  selectCustomersListLoadingFromState
} from "../../selectors/customer";
import {
  selectCitiesListFromState,
  selectRegionDetailFromState,
  selectRegionsListFromState,
} from "../../selectors/location";
import { VERTICALS } from "../../utils/constants";
import { customerActions } from "../../actions/customer";
import { riderActions } from "../../actions/rider";
import { selectNpStaffUsersListFromState } from '../../selectors/auth';

let Components = {
  'BloodOrderList': require('./blood-orders-list').default,
  'XRayOrderList': require('./xray-orders-list').default,
  'BloodOrderCreate': require('./blood-order-create').default,
  'XRayOrderCreate': require('./xray-order-create').default,
  'BloodOrderDetail': require('./blood-order-detail').default,
  'XRayOrderDetail': require('./xray-order-detail').default,
  'UnverifiedOrdersList': require('./unverified-orders-list').default,
  'UnverifiedOrdersDetail': require('./unverified-order-detail').default,
  'PartialOrdersList': require('./partial-orders-list').default,
};

const mapDispatchToProps = {
  getAssignableRider: riderActions.getAssignableRiderRequest,
  resetAssignableRiderState: riderActions.resetAssignableRiderState,
  getOrderStates: orderActions.getOrdersStatesRequest,
  getOrders: orderActions.getOrdersRequest,
  getUnverifiedOrders: orderActions.getUnverifiedOrdersRequest,
  getPartialOrders: orderActions.getPartialOrdersRequest,
  cancelPartialOrder: orderActions.cancelPartialOrdersRequest,
  getPartialOrderDetail: orderActions.getPartialOrderDetailRequest,
  getOrderDetail: orderActions.getOrderRequest,
  createOrder: orderActions.createOrderRequest,
  assignOrder: orderActions.assignOrderRequest,
  reAssignOrder: orderActions.reAssignOrderRequest,
  rescheduleOrder: orderActions.rescheduleOrderRequest,
  cancelOrder: orderActions.cancelOrderRequest,
  createCustomer: customerActions.createCustomerRequest,
  getCustomerDetail: customerActions.getCustomerDetailRequest,
  getCustomerAddress: customerActions.getCustomerAddressRequest,
  createCustomerAddress: customerActions.createCustomerAddressRequest,
  getCustomersId: customerActions.getCustomersIdRequest,
  getOrderScanTypes: orderActions.orderScanTypesRequest,
  resetCustomerState: customerActions.resetCustomerState,
  resetOrderState: orderActions.resetOrderState,
  resetScanTypesState: orderActions.resetScanTypesState,
  getFilteredOrders: orderActions.getFilteredOrdersRequest,
  getFilteredUnverifiedOrders: orderActions.getFilteredUnverifiedOrdersRequest,
  getFilteredPartialOrders: orderActions.getFilteredPartialOrdersRequest,
  updateOrderParams: orderActions.updateOrderParams,
  updateRiderParams: riderActions.updateRiderParams,
  getRiderDetail: riderActions.getRiderRequest,
  getRiderLocation: riderActions.getRiderLocationRequest,
  getRiderTasks: riderActions.getRiderTasksRequest,
  resetRiderTasksState: riderActions.resetRiderTasksState,
  createCustomerPatient: customerActions.createCustomerPatientRequest,
  updateCustomerPatient: customerActions.updateCustomerPatientRequest,
  deleteCustomerPatient: customerActions.deleteCustomerPatientRequest,
  getOrderActivities: orderActions.getOrderActivitiesRequest,
  resetOrderActivitiesState: orderActions.resetOrderActivitiesState,
  getHearAboutUsList: orderActions.getHearAboutUsListRequest,
  deleteCustomerAddress: customerActions.deleteCustomerAddressRequest,
  getNPRiders: riderActions.getNPRidersRequest,
  getProblemStrings: orderActions.getProblemStringsRequest,
  getCancellationStrings: orderActions.getCancellationStringsRequest,
  getOrderTimeSlots: orderActions.getOrderTimeSlotsRequest,
  resetOrderTimeSlotsState: orderActions.resetOrderTimeSlotsState,
  resetRiderState: riderActions.resetRiderState,
  copyOrder: orderActions.copyOrder,
  resetCopyOrder: orderActions.resetCopyOrder,
  orderNeedsReview: orderActions.orderNeedsReviewRequest,
  getNpStaffUsers: authActions.getNpStaffUsersRequest,
  resetNpStaffUsers: authActions.resetNpStaffUsersState,
  emailOrders: orderActions.emailOrdersCsvRequest,
  getCities: locationActions.getCitiesRequest,
  getRegions: locationActions.getRegionsRequest,
  updateCustomerAddress: customerActions.updateCustomerAddressRequest,
  offlineInProgress: orderActions.offlineInProgressRequest,
  deliverOrder: orderActions.deliverOrderRequest,
  updateExternalOrderParams: orderActions.updateExternalOrderParams,
  verifyExternalOrder: orderActions.verifyExternalOrderRequest,
  followUpOrder: orderActions.followUpOrderRequest,
  updateAdditionalNotes: orderActions.orderUpdateAdditionalNotesRequest,
  updateScanTypes: orderActions.orderUpdateScanTypesRequest,
  getOrderSources: orderActions.getOrderSourcesRequest,
  getReassignmentStrings: orderActions.getReassignmentStringsRequest,
  orderFollowUpAddressed: orderActions.orderFollowUpAddressedRequest,
  getAddressTypes: orderActions.getAddressTypesRequest,
  getRescheduleStrings: orderActions.getRescheduleStringsRequest,
};

const mapStateToProps = state => ({
  orders: selectLOrdersListFromState(state),
  ordersNext: selectOrdersNextFromState(state),
  ordersLoading: selectLOrdersListLoadingFromState(state),
  ordersCount: selectOrdersCountListFromState(state),
  onlineRiders: selectLOnlineRidersListFromState(state),
  assignableRider: selectAssignableRiderFromState(state),
  assignableRiderLoading: selectRidersListLoadingFromState(state),
  customerAddress: selectCustomerAddressFromState(state),
  cities: selectCitiesListFromState(state),
  bloodRegionDetail: selectRegionDetailFromState(state, VERTICALS.BLOOD),
  xrayRegionDetail: selectRegionDetailFromState(state, VERTICALS.XRAY),
  customerIds: selectCustomerIdsFromState(state),
  customersLoading: selectCustomersListLoadingFromState(state),
  customerDetail: selectCustomerDetailFromState(state),
  states: selectOrdersStatesFromState(state),
  orderDetail: selectOrderDetailFromState(state),
  scanTypes: selectOrderScanTypesFromState(state),
  scanTypesLoading: selectOrderScanTypesLoadingFromState(state),
  riderDetail: selectRiderDetailFromState(state),
  bloodOrderParams: selectFilteredOrderParamsFromState(state, VERTICALS.BLOOD),
  xrayOrderParams: selectFilteredOrderParamsFromState(state, VERTICALS.XRAY),
  externalOrderParams: selectExternalOrderParamsFromState(state),
  riderTasks: selectRiderTasksFromState(state),
  orderActivities: selectOrderActivitiesFromState(state),
  hearAboutUsList: selectHearAboutUsListFromState(state),
  problemStrings: selectProblemStringsFromState(state),
  cancellationStrings: selectCancellationStringsFromState(state),
  orderTimeSlots: selectOrderTimeSlotsFromState(state),
  npRiders: selectNpRidersListFromState(state),
  npStaffUsers: selectNpStaffUsersListFromState(state),
  copiedOrder: selectCopiedOrderFromState(state),
  regions: selectRegionsListFromState(state),
  orderSources: selectOrderSourcesFromState(state),
  reassignmentStrings: selectReassignmentStringsFromState(state),
  addressTypes: selectAddressTypesFromState(state),
  rescheduleStrings: selectRescheduleStringsFromState(state),
});

export const OrderContainer = (componentName) => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Components[componentName]);
};
