import * as types from './types'
import { payloadActionCreator, } from '../../utils/actions'
export const resetShiftState = payloadActionCreator(types.RESET_SHIFT_STATE)

export const getShiftRequest = payloadActionCreator(types.GET_SHIFT_REQUEST)
export const getShiftSuccess = payloadActionCreator(types.GET_SHIFT_SUCCESS)
export const getShiftFailure = payloadActionCreator(types.GET_SHIFT_FAILURE)

export const getShiftsRequest = payloadActionCreator(types.GET_SHIFTS_REQUEST)
export const getShiftsSuccess = payloadActionCreator(types.GET_SHIFTS_SUCCESS)
export const getShiftsFailure = payloadActionCreator(types.GET_SHIFTS_FAILURE)

export const createShiftRequest = payloadActionCreator(types.CREATE_SHIFT_REQUEST)
export const createShiftSuccess = payloadActionCreator(types.CREATE_SHIFT_SUCCESS)
export const createShiftFailure = payloadActionCreator(types.CREATE_SHIFT_FAILURE)

export const getShiftRidersRequest = payloadActionCreator(types.GET_SHIFT_RIDERS_REQUEST)
export const getShiftRidersSuccess = payloadActionCreator(types.GET_SHIFT_RIDERS_SUCCESS)
export const getShiftRidersFailure = payloadActionCreator(types.GET_SHIFT_RIDERS_FAILURE)

export const createShiftRiderRequest = payloadActionCreator(types.CREATE_SHIFT_RIDER_REQUEST)
export const createShiftRiderSuccess = payloadActionCreator(types.CREATE_SHIFT_RIDER_SUCCESS)
export const createShiftRiderFailure = payloadActionCreator(types.CREATE_SHIFT_RIDER_FAILURE)

export const updateShiftRiderRequest = payloadActionCreator(types.UPDATE_SHIFT_RIDER_REQUEST)
export const updateShiftRiderSuccess = payloadActionCreator(types.UPDATE_SHIFT_RIDER_SUCCESS)
export const updateShiftRiderFailure = payloadActionCreator(types.UPDATE_SHIFT_RIDER_FAILURE)

export const deleteShiftRiderRequest = payloadActionCreator(types.DELETE_SHIFT_RIDER_REQUEST)
export const deleteShiftRiderSuccess = payloadActionCreator(types.DELETE_SHIFT_RIDER_SUCCESS)
export const deleteShiftRiderFailure = payloadActionCreator(types.DELETE_SHIFT_RIDER_FAILURE)

export const addShiftRider = payloadActionCreator(types.ADD_SHIFT_RIDER)
export const removeShiftRider = payloadActionCreator(types.REMOVE_SHIFT_RIDER)

export const updateShiftRequest = payloadActionCreator(types.UPDATE_SHIFT_REQUEST)
export const updateShiftSuccess = payloadActionCreator(types.UPDATE_SHIFT_SUCCESS)
export const updateShiftFailure = payloadActionCreator(types.UPDATE_SHIFT_FAILURE)

export const deleteShiftRequest = payloadActionCreator(types.DELETE_SHIFT_REQUEST)
export const deleteShiftSuccess = payloadActionCreator(types.DELETE_SHIFT_SUCCESS)
export const deleteShiftFailure = payloadActionCreator(types.DELETE_SHIFT_FAILURE)

export const copyShiftRequest = payloadActionCreator(types.COPY_SHIFT_REQUEST)
export const copyShiftSuccess = payloadActionCreator(types.COPY_SHIFT_SUCCESS)
export const copyShiftFailure = payloadActionCreator(types.COPY_SHIFT_FAILURE)
