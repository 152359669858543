import L from "leaflet";

export const RiderIcon = new L.Icon({
  iconUrl: require('./rider-location.svg'),
  iconSize: new L.Point(25, 25)
});

export const IdleRiderIcon = new L.Icon({
  iconUrl: require('./idle-rider-marker.svg'),
  iconSize: new L.Point(25, 25)
});

export const BusyRiderIcon = new L.Icon({
  iconUrl: require('./busy-rider-marker.svg'),
  iconSize: new L.Point(25, 25)
});

export const WildcardRiderIcon = new L.Icon({
  iconUrl: require('./wildcard-rider-marker.svg'),
  iconSize: new L.Point(25, 25)
});

export const CovidRiderIcon = new L.Icon({
  iconUrl: require('./covid-rider-marker.svg'),
  iconSize: new L.Point(25, 25),
});

export const CustomerIcon = new L.Icon({
  iconUrl: require('./map-marker-lab.svg'),
  iconSize: new L.Point(25, 25)
});

export const CollectedIcon = new L.Icon({
  iconUrl: require('./rider_mark_collected.svg'),
  iconSize: new L.Point(25, 25)
});

export const ReachedIcon = new L.Icon({
  iconUrl: require('./rider_mark_reached.svg'),
  iconSize: new L.Point(25, 25)
});

export const CollectionCenterIcon = new L.Icon({
  iconUrl: require('./lab-white.svg'),
  iconSize: new L.Point(25, 25)
});

export const CellIcon = new L.Icon({
  iconUrl: require('./cell.svg'),
  iconSize: new L.Point(25, 25),
});
