import React, {useEffect, useState} from 'react';
import {format} from 'date-fns'
import {Nav} from '../commons/nav';
import {Link} from 'react-router-dom';
import {getGenderTitle, isAge} from '../../utils/helper';
import {debounce, findIndex} from 'lodash';
import {PAKISTAN_LAT_LONG} from '../../utils/constants';
import qs from 'qs';
import {Loader} from '../commons/loader';
import {Loading} from '../commons';
import {Marker, Popup} from "react-leaflet";
import {CustomerIcon} from "../../assets/images/Icons";
import Map from "../commons/Map";

const CustomerDetail = (props) => {
  const {
    match: {params: {id}},
    history,
    getCustomerDetail,
    customerDetail,
    getCustomerOrders,
    getFilteredCustomerOrders,
    customerOrders,
    customerOrdersNext,
    customerOrdersLoading,
  } = props;

  const [marker, setMarker] = useState([PAKISTAN_LAT_LONG.lat, PAKISTAN_LAT_LONG.lng]);
  const [selectedAddressId, setSelectedAddressId] = useState(0);
  const [orderHistoryView, setOrderHistoryView] = React.useState('');

  useEffect(() => {
    getCustomerDetail({id});
  }, []);

  window.onscroll = debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      if (!customerOrdersLoading && customerOrdersNext) {
        const paramsObj = qs.parse(customerOrdersNext.split('?')[1]);
        if (paramsObj.page)
          getCustomerOrders({customer_id: customerDetail.id, page: paramsObj.page});
      }
    }
  }, 100);

  useEffect(() => {
    if (!customerDetail.id) {
      return;
    }
    if (selectedAddressId === 0 && customerDetail.addresses.length) {
      setSelectedAddressId(customerDetail.addresses[0].id);
      const {latitude, longitude} = customerDetail.addresses[0];
      setMarker([latitude, longitude]);
    } else if (selectedAddressId && customerDetail.addresses.length) {
      const selectedAddressIndex = findIndex(customerDetail.addresses, {id: selectedAddressId});
      if (selectedAddressIndex > -1) {
        const {latitude, longitude} = customerDetail.addresses[selectedAddressIndex];
        setMarker([latitude, longitude]);
      }
    }
  }, [customerDetail && customerDetail.addresses && customerDetail.addresses.length]);

  const customerName = `${customerDetail.firstName || ' '} ${customerDetail.lastName || ' '}`;

  if (!customerDetail.id) {
    return <Loading/>
  }

  return <div id="wrapper" className={`inner-page ${orderHistoryView}`}>
    <Nav/>
    <div className="customer-detail-page">
      <div className="customer-detail-head">
        <div className="lft-head">
          <h2>{customerName}</h2>
          <ul className="breadcrumb-page">
            <li><a href='' onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}> Customers</a></li>
            <li>{customerName}</li>
          </ul>
        </div>
        <Link to={`/customers/update/${customerDetail.id}`} className="logout-btn">Update</Link>
      </div>
      <div className="customer-cols">
        <div className="customer-col customer-detail-col">
          <div className="cust-detail-block">
            <div className="head">
              <h2>{customerName}</h2>
              <a href='' className="icon-settings"
                 onClick={(e) => {
                   e.preventDefault();
                 }}/>
            </div>
            <dl>
              <dd><strong>{customerDetail.phone}</strong></dd>
              <dt><strong>Phone Number</strong></dt>
            </dl>
            <dl>
              <dd>{isAge(customerDetail.age) ? `${customerDetail.age} Years` : 'N/A'}</dd>
              <dt>Age</dt>
            </dl>
            <dl>
              <dd>{getGenderTitle(customerDetail.gender)}</dd>
              <dt>Gender</dt>
            </dl>
            <dl>
              <dd>{customerDetail.email || ''}</dd>
              <dt>Email</dt>
            </dl>
          </div>
          <div className="cust-order-block">
            <div className="head">
              <h2>Order History</h2>
              {
                customerDetail && !!customerDetail.orderHistory.length &&
                <a href='' onClick={(e) => {
                  e.preventDefault();
                  getFilteredCustomerOrders({customer_id: customerDetail.id});
                  setOrderHistoryView('order-history-active')
                }}>View Details</a>
              }
            </div>
            <ul className="history-list">
              {
                customerDetail && customerDetail.orderHistory.map((order, index) => {
                  return <li key={order.id}>
                    <h3>{order.number}</h3>
                    <div className="foot">
                      <span className="date">{format(new Date(order.created), 'dd MMM yyyy')}</span>
                      <span className="time">{format(new Date(order.created), 'hh:mm aaaa')}</span>
                    </div>
                  </li>
                })
              }
            </ul>
          </div>
        </div>
        <div className="customer-col customer-location">
          <div className="loc-block">
            <h2>Address</h2>
            <ul className="order-address-list">
              {
                customerDetail.addresses.map((address) => {
                  return <li key={address.id} id={address.id}
                             className={address.id === selectedAddressId ? 'current' : ''}
                             onClick={(e) => {
                               setSelectedAddressId(address.id);
                               setMarker([address.latitude, address.longitude]);
                             }}><b>{address.regionCustomerAddresses && address.regionCustomerAddresses[0].region.name}</b>: {address.address}</li>
                })
              }
            </ul>
          </div>
        </div>
        <div className="customer-col map">
          <Map
            initialCenter={marker}
            initialZoom={15}
            currentCenter={marker}
            currentZoom={15}
            styles={{height: "100%", zIndex: 0}}
          >
            {
              customerDetail.addresses.map(address =>
                <Marker
                  icon={CustomerIcon}
                  position={[+address.latitude, +address.longitude]}
                >
                  <Popup>
                    {address.address}
                  </Popup>
                </Marker>
              )
            }
          </Map>
        </div>
      </div>
    </div>

    <div className="customer-history">
      <div className="history-block">
        <div className="head">
          <h2>Order History</h2>
          <a href='' className="icon-clear"
             onClick={(e) => {
               e.preventDefault();
               setOrderHistoryView('')
             }}/>
        </div>
        <ul className="history-list">
          {
            customerOrders && customerOrders.map((order) => {
              return <li key={order.id}>
                <h3>{order.number}</h3>
                <div className="foot">
                  <span className="date">{format(new Date(order.created), 'dd MMM yyyy')}</span>
                  <span className="time">{format(new Date(order.created), 'hh:mm aaaa')}</span>
                </div>
              </li>
            })
          }
          {customerOrdersLoading && <Loader/>}
        </ul>
      </div>
    </div>
  </div>


};
export default CustomerDetail
