import React from "react";
import Iframe from 'react-iframe'
import {
  CLICK_UP_FAQS_URL,
} from "../../utils/constants";
import {Nav} from "../commons/nav";


export const FAQs = () => {
  return (
    <div id='wrapper'>
      <div className='faqs-container'>
        <div className='nav-holder'>
          <Nav/>
        </div>
        <div className='faqs-holder'>
          <Iframe url={`${CLICK_UP_FAQS_URL}`}
                  width='100%'
                  height='1000px'
                  display='block'
          />
        </div>
      </div>
    </div>
  );
};
