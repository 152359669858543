import React, {useEffect} from 'react';
import {Nav} from "../commons/nav";
import {debounce, startCase} from 'lodash';
import {endOfToday, isAfter, isBefore, startOfToday} from 'date-fns';
import yesImage from '../../assets/images/yes.svg'
import noImage from '../../assets/images/no.svg'
import timelineImage from '../../assets/images/timeline.svg'
import breakIcon from '../../assets/images/break-icon.svg'
import breakIconDisabled from '../../assets/images/break-icon-disabled.svg'

import {
  disableDiv,
  fullName,
  convertMetersToKm,
  hasPermission,
  qsParse,
  qsStringify,
  yyyyMMddhhmmaaaa,
  userFacingDistanceTravelled,
} from '../../utils/helper';
import {Field, Formik} from "formik";
import {toast} from 'react-toastify';
import Modal from 'react-modal';
import {DateTimeFieldWithError} from '../ui/form-fields/datetime-form-field-with-error';
import {RegionsList} from '../commons/regions-list';
import {PERMISSION, VERTICALS} from '../../utils/constants';
import {PercentChart} from '../commons/PercentChart';
import RiderShiftAttendance from './rider-shift-attendance';
import ShiftBreak from "./break";
import {RiderLastSeen} from "../rider/rider-last-seen";
import {Loader} from "../commons/loader";
import refreshImage from "../../assets/images/refresh-white.png";

let isFilterChanged = false

let initFilter = {
  region: '',
  shift: '',
  startDatetime: startOfToday(),
  endDatetime: endOfToday(),
  rider: '',
};

let filter = {
  ...initFilter
};

const AttendanceList = props => {
  const {
    history,
    regions,
    getAttendanceList,
    getFilteredAttendanceList,
    attendanceList,
    attendanceListNext,
    resetAttendanceState,
    attendanceLoading,
    regionDetail,
    getRidersSearch,
    ridersSearch,
    resetRiderState,
    selectRegionDetail,
    updateOrderParams,
    updateRiderParams,
    updateAttendanceParams,
    getAttendanceShifts,
    getRiderShiftAttendance,
    riderShiftAttendance,
    attendanceShifts,
    emailAttendanceCsv,
    addShiftBreak,
    getAllRegions,
  } = props;

  const [selectedAttendance, setSelectedAttendance] = React.useState(null);
  const [isBreakModalOpen, setBreakModal] = React.useState(false)
  const [attendanceId, setAttendanceId] = React.useState(null)
  const [isOpenRiderLocationModal, setIsOpenRiderLocationModal] = React.useState(false);
  const [riderLocation, setRiderLocation] = React.useState(null);

  const openRiderLocationModalModal = () => {
    setIsOpenRiderLocationModal(true);
  };

  const closeRiderLocationModal = () => {
    setIsOpenRiderLocationModal(false);
  };

  const getFilterParamsFromProps = () => {
    let {location: {search: queryParams}} = history;
    queryParams = queryParams.replace('?', '');
    filter = {
      ...filter,
      ...qsParse(queryParams),
    };
  };

  const handleOnRegionChange = (region) => {
    filter.region = region.id;
    filter.rider = '';
    filter.shift = '';
    applyFilter();
  };

  useEffect(() => {
    getAllRegions({vertical: VERTICALS.COMMON});
    filter.region = regionDetail.id
    getFilterParamsFromProps()
    resetRiderState();
    resetAttendanceState();
    if (isFilterChanged) {
      getFilteredAttendanceList(filter);
    } else getAttendanceList(filter);
    updateAttendanceParams(filter);

    return () => {
      resetAttendanceState()
      resetRiderState()
      filter = {...initFilter}
    }
  }, []);

  useEffect(() => {
    getRidersSearch({regions: filter.region})
    getAttendanceShifts({region: filter.region, startDatetime: filter.startDatetime, endDatetime: filter.endDatetime})
    return () => {
      resetRiderState()
    }
  }, [])

  window.onscroll = debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      if (!attendanceLoading && attendanceListNext && window.location.pathname === `${process.env.PUBLIC_URL}/attendance`) {
        const paramsObj = qsParse(attendanceListNext.split('?')[1]);
        if (paramsObj.page) getAttendanceList({
          ...filter,
          page: paramsObj.page,
        });
      }
    }
  }, 100);


  const handleOnRiderChangeFilter = (e) => {
    filter.rider = e.target.value;
    applyFilter();
  };

  const handleOnShiftChangeFilter = (e) => {
    filter.shift = e.target.value;
    applyFilter();
  };

  const applyFilter = () => {
    isFilterChanged = true;
    history.replace({
      pathname: '/attendance',
      search: qsStringify(filter)
    });

    updateAttendanceParams(filter);
  };

  const openModal = (e, attendance) => {
    e.stopPropagation();
    setSelectedAttendance(attendance)
  }
  const closeModal = () => {
    setSelectedAttendance(null)
  }

  const handleBreakClick = (e, attendance) => {
    e.preventDefault()
    if (attendance.breakAlreadyGiven || attendance.shift.state !== 'in-progress') {
      return
    }
    setAttendanceId(attendance.id)
    setBreakModal(true)
  }

  const loadMore = () => {
    if (!attendanceLoading && attendanceListNext) {
      const paramsObj = qsParse(attendanceListNext.split('?')[1]);
      if (paramsObj.page) getAttendanceList({
        ...filter,
        page: paramsObj.page,
      });
    }
  };

  return (
    <div id="wrapper" className="inner-page">
      <Nav/>
      <div className="attendance-content">
        <div className="attendance-head" style={disableDiv(attendanceLoading)}>
          <div className="top-head">
            <RegionsList regions={regions}
                         regionDetail={regionDetail}
                         selectRegionDetail={selectRegionDetail}
                         handleOnCityChange={handleOnRegionChange}
                         updateOrderParams={updateOrderParams}
                         updateRiderParams={updateRiderParams}
                         updateAttendanceParams={updateAttendanceParams}
                         isWhite={true}
                         vertical={VERTICALS.COMMON}
            />
            <h2>Attendance</h2>
            <div className="right">

              <Formik initialValues={{startDatetime: filter.startDatetime, endDatetime: filter.endDatetime}}
                      enableReinitialize={true}>
                {props => (
                  <form onSubmit={props.handleSubmit}>
                    <div className="date-holder">
                      <div className="input-holder">
                        <i className="icon-calendar"/>
                        <Field
                          openTo="date"
                          onChange={val => {
                            if (isAfter(val, new Date(props.values.endDatetime))) {
                              toast.error("Start time can't be greater than end time");
                              return;
                            }
                            props.setFieldValue('startDatetime', val);
                            filter.startDatetime = val;
                            applyFilter();
                          }}
                          value={filter.startDatetime}
                          name="startDatetime"
                          component={DateTimeFieldWithError}/>
                      </div>
                      <div className="dash">
                      </div>
                      <div className="input-holder">
                        <i className="icon-calendar"/>
                        <Field
                          openTo="date"
                          onChange={val => {
                            if (isBefore(val, new Date(props.values.startDatetime))) {
                              toast.error("End time can't be less than start time");
                              return;
                            }
                            props.setFieldValue('endDatetime', val)
                            filter.endDatetime = val
                            filter.endDatetime.setSeconds(0)
                            filter.endDatetime.setMilliseconds(0)
                            applyFilter()
                          }}
                          value={filter.endDatetime}
                          name="endDatetime"
                          component={DateTimeFieldWithError}/>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>


              <select
                name="shift"
                value={filter.shift}
                onChange={handleOnShiftChangeFilter}
              >
                <option value="" label="Select a shift"/>
                {
                  attendanceShifts.map(shift => {
                    return <option
                      key={shift.id}
                      value={shift.id}
                      label={`${yyyyMMddhhmmaaaa(shift.startDatetime)} - ${yyyyMMddhhmmaaaa(shift.endDatetime)}`}
                    />
                  })
                }
              </select>
            </div>
          </div>
          <div className="rider-bar">
            <select
              name="rider"
              value={filter.rider}
              onChange={handleOnRiderChangeFilter}
            >
              <option value="" label="Select a rider"/>
              {
                ridersSearch.map(rider => {
                  return <option
                    key={rider.id}
                    value={rider.id}
                    label={`${startCase(fullName(rider))} - ${rider.regionName}`}
                  />
                })
              }
            </select>
            {
              hasPermission(PERMISSION.CAN_DOWNLOAD_ATTENDANCE_LISTING) &&
              <a href=' ' className="download-btn"
                 onClick={(e) => {
                   e.preventDefault();
                   emailAttendanceCsv(filter);
                 }}
              ><i className="icon-download"/> Download</a>
            }
          </div>
        </div>
        <div className="attendance-table">
          <table className="list-table">
            <thead>
            <tr>
              <th>Rider</th>
              <th>Attendance</th>
              <th>Inside Hotspot</th>
              <th>Location</th>
              <th>Location Time</th>
              <th>Shift Start</th>
              <th>Shift End</th>
              <th>On-Time %</th>
              <th>Online %</th>
              <th>Distance Travelled</th>
              <th>Online chart</th>
              <th>Break</th>
            </tr>
            </thead>
            <tbody>
            {
              attendanceList.map((attendance, index) => {
                return <tr key={attendance.id}>
                  <td>
                    <span className="name">{fullName(attendance.rider)}</span>
                    <span className="tel">{attendance.rider.phone}</span>
                  </td>
                  <td>
                    <img src={attendance.isPresent ? yesImage : noImage} alt="attendance image"/>
                  </td>
                  <td>
                    <img src={attendance.riderDidStartInsideHotspot ? yesImage : noImage} alt="spot image"/>
                  </td>
                  <td>
                    {attendance.hotspotLatitude && attendance.hotspotLongitude ?
                      <a href="" onClick={(e) => {
                        e.preventDefault();
                        setRiderLocation({
                          latitude: attendance.hotspotLatitude,
                          longitude: attendance.hotspotLongitude,
                        });
                        openRiderLocationModalModal()
                      }} className="loc-link">
                        {`${attendance.hotspotLatitude} , ${attendance.hotspotLongitude}`}
                      </a> : 'N/A'
                    }
                  </td>
                  <td>
                    <span className="time">{yyyyMMddhhmmaaaa(attendance.hotspotRecordedAt)}</span>
                  </td>
                  <td>
                    <span className="time">{yyyyMMddhhmmaaaa(attendance.shift.startDatetime)}</span>
                    {/*<span className="on-time">On Time</span>*/}
                  </td>

                  <td>
                    <span className="time">{yyyyMMddhhmmaaaa(attendance.shift.endDatetime)}</span>
                    {/*<span className="on-time">On Time</span>*/}
                  </td>
                  <td>
                    <div style={{width: 50, height: 50}}>
                      <PercentChart percent={attendance.riderShiftOnTimePercentage}/>
                    </div>
                  </td>
                  <td>
                    <div style={{width: 50, height: 50}}>
                      <PercentChart percent={attendance.riderShiftOnlinePercentage}/>
                    </div>
                  </td>
                  <td>
                    <span>
                      {attendance.distanceTravelled
                        ? userFacingDistanceTravelled(convertMetersToKm(attendance.distanceTravelled))
                        : '-'}
                    </span>
                  </td>
                  <td>
                    <a href='' onClick={(e) => {
                      e.preventDefault()
                      setSelectedAttendance(attendance)
                    }}><img src={timelineImage} width="30" alt="timeline"/></a>
                  </td>
                  <td>
                    <div onClick={(e) => handleBreakClick(e, attendance)}>
                      <img
                        src={attendance.breakAlreadyGiven || attendance.shift.state !== 'in-progress' ? breakIconDisabled : breakIcon}
                        width="30" alt="timeline"/></div>
                  </td>
                </tr>
              })
            }
            </tbody>
          </table>
          {attendanceLoading && <Loader/>}
          {
            !attendanceLoading && attendanceListNext &&
            <button className={`load-more`} onClick={!attendanceLoading && loadMore}><img src={refreshImage}/>Load More
            </button>
          }
        </div>
      </div>
      {!!selectedAttendance &&
      <Modal isOpen={!!selectedAttendance} onRequestClose={closeModal}>
        <RiderShiftAttendance
          isLoading={attendanceLoading}
          attendanceDetail={selectedAttendance}
          getRiderShiftAttendance={getRiderShiftAttendance}
          riderShiftAttendance={riderShiftAttendance}
          closeModal={closeModal}
        />
      </Modal>
      }

      {isBreakModalOpen &&
      <Modal isOpen={isBreakModalOpen} onRequestClose={() => setBreakModal(false)}>
        <ShiftBreak
          closeModal={() => setBreakModal(false)}
          onSave={addShiftBreak}
          attendanceId={attendanceId}
        />
      </Modal>
      }
      {isOpenRiderLocationModal &&
      <Modal isOpen={isOpenRiderLocationModal} onRequestClose={closeRiderLocationModal}>
        <RiderLastSeen
          latitude={riderLocation.latitude}
          longitude={riderLocation.longitude}
          closeModal={closeRiderLocationModal}
          title='Rider Location'
        />
      </Modal>
      }
    </div>
  )
};
export default AttendanceList;
