import React, { useState } from 'react';
import Profile from '../auth/profile';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { hasPermission, qsStringify } from '../../utils/helper';
import { endOfToday, startOfToday } from "date-fns";
import { defaultOrderListSorting, PERMISSION, VERTICALS } from '../../utils/constants';
import { connect } from 'react-redux';
import { selectRegionDetailFromState } from '../../selectors/location';
import {
  selectExternalOrderParamsFromState,
  selectFilteredOrderParamsFromState,
} from '../../selectors/order';
import { selectRiderParamsFromState } from '../../selectors/rider';
import { selectCustomerParamsFromState } from '../../selectors/customer';
import { selectAttendanceListParamsFromState } from '../../selectors/attendance';
import { ROUTES } from "../../routes/constants";
import Report from '../../images/reports.svg';
import ReleaseNotes from '../../assets/images/release-notes.svg';
import FAQs from '../../assets/images/FAQs.svg';
import UnverifiedOrders from '../../assets/images/unverified-orders-menu-icon-new.svg';
import UnverifiedOrdersTab from '../../assets/images/unverified-orders-tab.svg';
import PartialOrdersTab from '../../assets/images/partial-orders-tab.svg';


const NavComponent = ({
  bloodOrderParams,
  xrayOrderParams,
  customerParams,
  riderParams,
  attendanceParams,
  regionDetail,
  externalOrderParams
}) => {
  const pathName = window.location.pathname;
  let bloodOrderParamsPath = '';
  if (isEmpty(bloodOrderParams)) {
    bloodOrderParamsPath = qsStringify({
      pickupFrom: startOfToday(),
      pickupTo: endOfToday(),
      sorting: defaultOrderListSorting,
    });
  } else {
    bloodOrderParamsPath = qsStringify({
      ...bloodOrderParams,
    });
  }
  let xrayOrderParamsPath;
  if (isEmpty(xrayOrderParams)) {
    xrayOrderParamsPath = qsStringify({
      pickupFrom: startOfToday(),
      pickupTo: endOfToday(),
      sorting: defaultOrderListSorting,
    });
  } else {
    xrayOrderParamsPath = qsStringify({
      ...xrayOrderParams,
    });
  }
  let customerParamsPath = '';
  if (isEmpty(customerParams)) {
    customerParamsPath = qsStringify({
      ordering: '-created'
    });
  } else {
    customerParamsPath = qsStringify({
      ...customerParams,
    });
  }
  let riderParamsPath = '';
  if (isEmpty(riderParams)) {
    riderParamsPath = qsStringify({
      ordering: '-created'
    });
  } else {
    riderParamsPath = qsStringify({
      ...riderParams,
    });
  }

  let attendanceParamsPath = '';
  if (isEmpty(attendanceParams)) {
    attendanceParamsPath += qsStringify({
      region: regionDetail.id,
      startDatetime: startOfToday(),
      endDatetime: endOfToday(),
      ordering: '-created'
    });
  } else {
    attendanceParamsPath += qsStringify({
      ...attendanceParams,
      ordering: '-created'
    });
  }
  let externalOrderParamsPath = '';
  if (isEmpty(externalOrderParams)) {
    externalOrderParamsPath = qsStringify({
      pickupFrom: startOfToday(),
      pickupTo: endOfToday(),
      sorting: defaultOrderListSorting,
    });
  } else {
    externalOrderParamsPath = qsStringify({
      ...externalOrderParams,
    });
  }

  const [overflow, setOverflow] = useState(false);

  return <div className="navbar">
    <div className="logo">
      <Link to={"/blood/dashboard"}><img src={require("../../images/logo.png")} alt="" /></Link>
    </div>
    <ul className='nav' style={overflow ? { overflowY: 'auto', overflowX: 'hidden' } : {}}>
      <li className={(pathName.includes('blood/dashboard') || pathName.includes('xray/dashboard')) ? 'active' : ''}
        onMouseEnter={() => {
          setOverflow(false);
        }}
        onMouseLeave={() => {
          setOverflow(true);
        }}>
        <a href="#" onClick={e => e.preventDefault()}><i className="icon-dashbord"></i></a>
        <div className="dropdown">
          <ul>
            <li>
              <Link to={ROUTES.BLOOD_DASHBOARD}>
                <div className="ico-holder">
                  <i className="icon-Blood"></i>
                </div>
                <span className="text">Blood</span>
              </Link>
            </li>
            <li>
              <Link to={ROUTES.XRAY_DASHBOARD}>
                <div className="ico-holder">
                  <i className="icon-X-ray"></i>
                </div>
                <span className="text">X-Ray</span>
              </Link>
            </li>
          </ul>
        </div>
      </li>
      <li className={pathName.includes('city/dashboard') ? 'active' : ''}
        onMouseEnter={() => {
          setOverflow(false);
        }}
        onMouseLeave={() => {
          setOverflow(true);
        }}>
        <a href="#" onClick={e => e.preventDefault()}><i className="icon-map-marker"></i></a>
        <div className="dropdown">
          <ul>
            <li>
              <Link to={ROUTES.BLOOD_CITY_DASHBOARD}>
                <div className="ico-holder">
                  <i className="icon-Blood"></i>
                </div>
                <span className="text">Blood</span>
              </Link>
            </li>
            <li>
              <Link to={ROUTES.XRAY_CITY_DASHBOARD}>
                <div className="ico-holder">
                  <i className="icon-X-ray"></i>
                </div>
                <span className="text">X-Ray</span>
              </Link>
            </li>
          </ul>
        </div>
      </li>
      <li className={pathName.includes('blood/orders') || pathName.includes('xray/orders') ? 'active' : ''}
        onMouseEnter={() => {
          setOverflow(false);
        }}
        onMouseLeave={() => {
          setOverflow(true);
        }}>
        <a href="#" onClick={e => e.preventDefault()}><i className="icon-lab"></i></a>
        <div className="dropdown">
          <ul>
            <li>
              <Link to={{ pathname: ROUTES.BLOOD_ORDERS, search: `?${bloodOrderParamsPath}` }}>
                <div className="ico-holder">
                  <i className="icon-Blood"></i>
                </div>
                <span className="text">Blood</span>
              </Link>
            </li>
            <li>
              <Link to={{ pathname: ROUTES.XRAY_ORDERS, search: `?${xrayOrderParamsPath}` }}>
                <div className="ico-holder">
                  <i className="icon-X-ray"></i>
                </div>
                <span className="text">X-Ray</span>
              </Link>
            </li>
          </ul>
        </div>
      </li>
      <li className={pathName.includes('riders') ? 'active' : ''}>
        <Link to={{ pathname: '/riders', search: '?' + riderParamsPath }}>
          <i className="icon-rider" />
        </Link>
      </li>
      <li className={pathName.includes('customers') ? 'active' : ''}>
        <Link to={{ pathname: '/customers', search: '?' + customerParamsPath }}>
          <i className="icon-user" />
        </Link>
      </li>
      {
        hasPermission(PERMISSION.CAN_VIEW_SHIFT_SCHEDULE) &&
        <li className={pathName.includes('shifts') ? 'active' : ''}>
          <Link to={'/shifts'}><i className="icon-calendar-check-o" /></Link>
        </li>
      }
      {
        hasPermission(PERMISSION.CAN_VIEW_RIDER_SHIFT) &&
        <li className={pathName.includes('attendance') ? 'active' : ''}>
          <Link to={{ pathname: '/attendance', search: '?' + attendanceParamsPath }}><i
            className="icon-attendance" /></Link>
        </li>

      }

      <li className={pathName.includes('-orders') ? 'active' : ''}
        onMouseEnter={() => {
          setOverflow(false);
        }}
        onMouseLeave={() => {
          setOverflow(true);
        }}>
        <a href="#" onClick={e => e.preventDefault()}><img src={UnverifiedOrders} onClick={e => e.preventDefault()} style={{ width: '37px', height: '24px' }} /></a>
        <div className="dropdown">
          <ul>
            <li>
              <Link to={{ pathname: ROUTES.UNVERIFIED_ORDERS, search: '?' + externalOrderParamsPath }}>
                <img src={UnverifiedOrdersTab} style={{ width: '37px', height: '24px' }} />
              </Link>
            </li>
            <li>
              <Link to={{ pathname: ROUTES.PARTIAL_ORDERS, search: '?' + externalOrderParamsPath }}>
                <img src={PartialOrdersTab} style={{ width: '37px', height: '24px' }} />
              </Link>
            </li>
          </ul>
        </div>
      </li>

      <li className={pathName.includes('redash') ? 'active' : ''}>
        <Link to={ROUTES.REDASH}>
          <img src={Report} style={{ width: '37px', height: '24px' }} />
        </Link>
      </li>
      <li className={pathName.includes('issue-reporting') ? 'active' : ''}>
        <Link to={ROUTES.ISSUE_REPORTING}>
          <i className='icon-cancel' />
        </Link>
      </li>
      <li className={pathName.includes('release-notes') ? 'active' : ''}>
        <Link to={ROUTES.RELEASE_NOTES}>
          <img src={ReleaseNotes} style={{ width: '37px', height: '24px' }} />
        </Link>
      </li>
      <li className={pathName.includes('faqs') ? 'active' : ''}>
        <Link to={ROUTES.FAQS}>
          <img src={FAQs} style={{ width: '37px', height: '24px' }} />
        </Link>
      </li>
    </ul>

    <Profile />
  </div>;

};

const mapStateToProps = state => ({
  bloodOrderParams: selectFilteredOrderParamsFromState(state, VERTICALS.BLOOD),
  xrayOrderParams: selectFilteredOrderParamsFromState(state, VERTICALS.XRAY),
  riderParams: selectRiderParamsFromState(state),
  customerParams: selectCustomerParamsFromState(state),
  attendanceParams: selectAttendanceListParamsFromState(state),
  externalOrderParams: selectExternalOrderParamsFromState(state),
  regionDetail: selectRegionDetailFromState(state, VERTICALS.COMMON),
});

const mapDispatchToProps = {};

export const Nav = connect(mapStateToProps, mapDispatchToProps)(NavComponent);
