const selectRiderFromState = state => state.rider;

export const selectRidersListFromState = state => selectRiderFromState(state).list;
export const selectRidersCountListFromState = state => selectRiderFromState(state).count;
export const selectRidersListLoadingFromState = state => selectRiderFromState(state).loading;
export const selectRidersNextFromState = state => selectRiderFromState(state).next;
export const selectRiderParamsFromState = state => selectRiderFromState(state).params;
export const selectLOnlineRidersListFromState = state => selectRiderFromState(state).onlineList;
export const selectAssignableRiderFromState = state => selectRiderFromState(state).assignableRider;
export const selectRiderDetailFromState = state => selectRiderFromState(state).riderDetail;
export const selectRiderStatsFromState = state => selectRiderFromState(state).stats;
export const selectRiderTasksFromState = state => selectRiderFromState(state).riderTasks;
export const selectNpRidersListFromState = state => selectRiderFromState(state).npRiders;
export const selectRidersSearchListFromState = state => selectRiderFromState(state).ridersSearch;

export const selectRiderOrdersListFromState = state => selectRiderFromState(state).order.list;
export const selectRiderOrdersListNextFromState = state => selectRiderFromState(state).order.next;
export const selectRiderOrdersListLoadingFromState = state => selectRiderFromState(state).order.loading;

export const selectRefreshTimeFromState = state => selectRiderFromState(state).refreshTime;
