import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Marker, Popup} from 'react-leaflet';

import CLHS_LOGO_LIGHT from '../../assets/images/clhs-logo-light.png'
import {locationActions} from "../../actions/location";
import {
  selectRiderLiveLocationFromState
} from "../../selectors/location";
import {formatScanTypes, fullName} from "../../utils/helper";
import {ROUTES} from "../../routes/constants";
import {FETCH_RIDER_LIVE_LOCATION_INTERVAL} from "../../utils/constants";
import {CustomerIcon, RiderIcon} from "../../assets/images/Icons";
import Map from "../commons/Map";

const RiderLiveLocation = ({match: {params: {uuid}}, history, getRiderLiveLocation, riderLiveLocation}) => {
  useEffect(() => {
    getRiderLiveLocation(uuid);
    setInterval(() => {
      getRiderLiveLocation(uuid);
    }, FETCH_RIDER_LIVE_LOCATION_INTERVAL);
  }, []);

  useEffect(() => {
    if (riderLiveLocation && riderLiveLocation.isExpired) {
      history.push(ROUTES.RIDER_LIVE_LOCATION_LINK_EXPIRED)
    }
  }, [riderLiveLocation]);

  return (riderLiveLocation &&
    <div className="page-table live-location">
      <div className="page-table-head">
        <div className="head-holder">
          <div className="logo-one">
            <a href="https://chughtailab.com/"><img src={CLHS_LOGO_LIGHT} alt=""/></a>
          </div>
          <span className="right">{riderLiveLocation.number}</span>
        </div>
      </div>
      <div className="page-table-body">
        <div className="live-map">
          {
            riderLiveLocation.customerAddress && riderLiveLocation.assignedRider &&
            <Map
              initialCenter={[riderLiveLocation.assignedRider.lastKnownLatitude, riderLiveLocation.assignedRider.lastKnownLongitude]}
              initialZoom={15}
              styles={{height: "100%"}}
            >
              <Marker
                icon={CustomerIcon}
                position={[+riderLiveLocation.customerAddress.latitude, +riderLiveLocation.customerAddress.longitude]}>
                <Popup>
                  You
                </Popup>
              </Marker>
              <Marker
                icon={RiderIcon}
                position={[+riderLiveLocation.assignedRider.lastKnownLatitude, +riderLiveLocation.assignedRider.lastKnownLongitude]}>
                <Popup>
                  Rider
                </Popup>
              </Marker>
            </Map>
          }
        </div>
        {riderLiveLocation.patient &&
        <div className="live-location-info">
          <span className="name">{fullName(riderLiveLocation.patient)}</span>
          <div className="info-block">
            <span className="info-list">
              <i className="icon-cell"></i>
              {riderLiveLocation.customer.phone}
            </span>
            {riderLiveLocation.patient.email && <span className="info-list">
              <i className="icon-email"></i>
              {riderLiveLocation.patient.email}
            </span>}
            <span className="info-list">
              <i className="icon-map-marker"></i>
              <span>{riderLiveLocation.customerAddress.address}</span>
            </span>
          </div>
          <div className="info-block">
            <span className="title">Test Information</span>
            {
              formatScanTypes(riderLiveLocation.scanTypes).map(scanType => (
                <div className="price-list">
                  <span className="sub-title">{scanType.name}</span>
                  <span className="price">{scanType.value}</span>
                </div>
              ))
            }
          </div>
          {/*<a href={`tel:${riderLiveLocation.rider.phone}`}>Call Rider</a>*/}
        </div>
        }
      </div>
      <div className="page-table-footer">
        <div className="footer-holder">
          <p>For any business related query and new offers please visit our website</p>
          <a href="https://chughtailab.com/">www.chughtailab.com</a>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  riderLiveLocation: selectRiderLiveLocationFromState(state),
});

const mapDispatchToProps = {
  getRiderLiveLocation: locationActions.getRiderLiveLocationRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RiderLiveLocation);
