import * as caseConverter from 'change-object-case';
import { apiCaller } from '../api-caller';
import { BASE_URL, ENDPOINTS, REQUEST_TYPES } from '../constants';
import { SORTING } from '../../utils/constants';
import moment from "moment";

const formatOrdersFilter = (filter = {}) => {
  let formattedFilter = { ...filter, };
  if (filter.regions) {
    formattedFilter['regions'] = filter.regions.join(',');
  }
  if (filter.states) {
    formattedFilter['states'] = filter.states.join(',');
  }
  if (formattedFilter.pickupFrom) {
    formattedFilter.pickupFrom = new Date(formattedFilter.pickupFrom).toISOString();
  }
  if (formattedFilter.pickupTo) {
    formattedFilter.pickupTo = new Date(formattedFilter.pickupTo).toISOString();
  }
  if (filter.needsReview) {
    formattedFilter['needsReview'] = filter.needsReview.join(',');
  }
  if (filter.wasAutoAssigned) {
    formattedFilter['wasAutoAssigned'] = filter.wasAutoAssigned.join(',');
  }
  if (filter.wasManualAssigned) {
    formattedFilter['wasManualAssigned'] = filter.wasManualAssigned.join(',');
  }
  if (filter.isCovid) {
    formattedFilter['isCovid'] = filter.isCovid.join(',');
  }
  if (filter.followUp) {
    formattedFilter['followUp'] = filter.followUp.join(',');
  }
  if (filter.isFollowUpAddressed) {
    formattedFilter['isFollowUpAddressed'] = filter.isFollowUpAddressed.join(',');
  }
  if (filter.isUnverifiedByRider) {
    formattedFilter['isUnverifiedByRider'] = filter.isUnverifiedByRider.join(',');
  }
  if (filter.addressTypes) {
    formattedFilter['addressTypes'] = filter.addressTypes.join(',');
  }
  if (filter.sorting) {
    let ordering = [];
    for (const [key, value] of Object.entries(filter.sorting)) {
      switch (+value) {
        case SORTING.ASCENDING:
          ordering.push(key);
          break;
        case SORTING.DESCENDING:
          ordering.push(`-${key}`);
          break;
      }
    }
    formattedFilter['ordering'] = ordering.join(',');
    delete formattedFilter['sorting'];
  }
  return caseConverter.snakeKeys(formattedFilter);
};

export const getOrdersAPI = (filter = {}) => {
  const { vertical } = filter;
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.ORDERS}${vertical}/`,
    params: formatOrdersFilter({ ...filter }),
  });
};

export const getUnverifiedOrdersAPI = (filter = {}) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.UNVERIFIED_ORDERS,
    params: formatOrdersFilter({ ...filter }),
  });
};

export const getPartialOrdersAPI = (filter = {}) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.PARTIAL_ORDERS,
    params: formatOrdersFilter({ ...filter }),
  });
};

export const getPartialOrderDetailAPI = (filter = {}) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.PARTIAL_ORDERS}${filter.id}`,
  });
};

export const cancelPartialOrderAPI = ({ id, state }) => apiCaller({
  method: REQUEST_TYPES.PATCH,
  url: `${ENDPOINTS.PARTIAL_ORDERS}${id}/`,
  data: { state }
});

export const getDashboardOrdersAPI = (filter = {}) => {
  const { vertical } = filter;
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.ORDERS}${vertical}/dashboard/`,
    params: formatOrdersFilter(filter),
  });
};

export const getOrdersStatusAPI = (filter = {}) => {
  const { vertical } = filter;
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.ORDERS}${vertical}/states/`
  });
};

export const getOrdersScanTypesAPI = (params) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${BASE_URL}/orders/scan-types/`,
  params,
});

export const getOrderAPI = ({ id, vertical }) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.ORDERS}${vertical}/${id}/`
  });
};
export const cancelOrderAPI = ({ id, vertical, data }) => apiCaller({
  method: REQUEST_TYPES.POST,
  url: `${ENDPOINTS.ORDERS}${vertical}/${id}/cancel/`,
  data
});
export const createOrderAPI = data => {
  const { vertical } = data;
  return apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.ORDERS}${vertical}/`,
    data
  });
};

export const assignOrderAPI = (data) => {
  const { vertical } = data;
  return apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.ORDERS}${vertical}/${data.id}/request/`,
    data: {
      rider_ids: [parseInt(data.rider)],
    }
  });
};

export const reAssignOrderAPI = (data) => {
  const { vertical } = data;
  return apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.ORDERS}${vertical}/${data.id}/reassign/`,
    data: {
      rider_ids: [parseInt(data.rider)],
      reassignment_string_id: data.reassignmentStringId,
      reason: data.reason,
    }
  });
};

export const getOrderStatsAPI = (filter = {}) => {
  const { vertical } = filter;
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.STATS}${vertical}/orders/`,
    params: formatOrdersFilter(filter),
  });
};

export const rescheduleOrderAPI = ({ id, vertical, data }) => {
  return apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.ORDERS}${vertical}/${id}/reschedule/`,
    data: data,
  });
};

export const getOrderActivitiesAPI = (params) => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.ACTIVITY,
    params: caseConverter.snakeKeys(params),
  });
};

export const getHearAboutUsListAPI = () => {
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.HEAR_ABOUT_US,
  });
};

export const getProblemStringsAPI = (filter = {}) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.PROBLEM_STRINGS,
  params: filter,
});

export const getCancellationStringsAPI = (filter = {}) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.CANCELLATION_STRINGS,
  params: filter,
});

export const getOrderTimeSlotsAPI = (filter = {}) => {
  const { vertical } = filter;
  return apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.ORDERS}${vertical}/time-slots/`,
    params: caseConverter.snakeKeys(filter),
  });
};

export const orderNeedsReviewAPI = ({ id, data }) => {
  return apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.ORDERS}update/${id}/`,
    data: data,
  });
};

export const getLateOrdersAPI = (filter = {}) => {
  const { vertical } = filter;
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.ORDERS}${vertical}/late-orders/`,
    params: formatOrdersFilter(filter),
  });
};

export const emailOrdersCsvAPI = (filter = {}) => {
  const { vertical } = filter;
  return apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.ORDERS}${vertical}/send-order-listing-email/`,
    params: formatOrdersFilter(filter),
  });
};

export const getOnTimeRateStats = ({ regionId }) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${BASE_URL}/stats/daily-order-stats/`,
  params: {
    date: moment().subtract(6, "days").format("YYYY-MM-DD"),
    region: regionId
  }
});

export const offlineInProgressOrderAPI = (data) => {
  const { vertical } = data;
  return apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.ORDERS}${vertical}/${data.id}/offline-in-progress/`,
  });
};

export const deliverOrderAPI = (data) => {
  const { vertical } = data;
  return apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.ORDERS}${vertical}/${data.id}/delivered/`,
  });
};

export const verifyExternalOrderAPI = ({ id, vertical }) => apiCaller({
  method: REQUEST_TYPES.POST,
  url: `${ENDPOINTS.ORDERS}${vertical}/${id}/verified/`,
});

export const followUpOrderAPI = ({ orderId, reason }) => apiCaller({
  method: REQUEST_TYPES.POST,
  url: `${ENDPOINTS.ORDER_FOLLOW_UP}/`,
  data: {
    order: orderId,
    reason
  }
});

export const updateAdditionalNotesAPI = ({ id, data }) => apiCaller({
  method: REQUEST_TYPES.PATCH,
  url: `${BASE_URL}/orders/update/${id}/`,
  data: data
});

export const updateScanTypesAPI = ({ orderId, value, scanType }) => apiCaller({
  method: REQUEST_TYPES.POST,
  url: `${BASE_URL}/orders/update-order-scan-types/${orderId}/`,
  data: {
    value,
    scanType
  }
});

export const getOrderSourcesAPI = () => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.ORDER_SOURCES}/`,
});

export const getReassignmentStringsAPI = () => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.REASSIGNMENT_STRINGS,
});

export const orderFollowUpAddressedAPI = ({ id, data }) => {
  return apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${BASE_URL}/orders/update/${id}/`,
    data: data,
  });
};

export const getAddressTypesAPI = () => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.ADDRESS_TYPES}`
});

export const getCustomerOrdersAPI = (params) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${BASE_URL}/orders/history/customer/`,
  params: caseConverter.snakeKeys(params),
});

export const getRescheduleStringsAPI = () => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.RESCHEDULE_STRINGS,
});
