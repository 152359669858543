import React, { useEffect, useRef } from 'react';
import { Field, Formik } from "formik";
import { TextFormFieldWithError } from '../ui/form-fields/text-form-field-with-error';
import * as Yup from 'yup';
import { RadioButtonWithLabel } from '../ui/form-fields/radio-button-with-label';
import moment from "moment";
import { DateFieldWithError } from '../ui/form-fields/date-form-field-with-error';

const CustomerCreateStepOne = (props) => {
  const {
    customerDetail, createCustomer, resetCustomerState
  } = props;
  const formRef = useRef();
  const handleSubmit = () => {
    const { current: form } = formRef;
    form.submitForm();
  };
  const goToNext = (values) => {
    values = {
      ...values,
      dob: values.dob ? moment(values.dob).format('YYYY-MM-DD') : null
    };
    createCustomer(values);
  };

  useEffect(() => {
    if (customerDetail.id && formRef.current.dirty) {
      props.nextStep()
    }
  }, [customerDetail.id]);

  const goBack = () =>{
    props.history.goBack();
  };

  return <>
    <div className="order-page customer-detail">
      <div className="order-right-info">
        <div className="head">
          <div className="customer-info-order">
            <h2></h2>
            <span className="number"></span>
          </div>
          <ul className="order-step">
            <li className="active"><span className="step-number">1</span><span className="text">Details</span></li>
            <li className=""><span className="step-number">2</span><span className="text">Address</span></li>
          </ul>
          <ul className="btn-list">
            <li>
              <button type="button" className="gray" onClick={goBack}>Cancel</button>
            </li>
            <li>
              <button className={"blue next"}
                      type="button"
                      onClick={handleSubmit}
              >Next
              </button>
            </li>
          </ul>
        </div>
        <div className="two-cols">
          <div className="col-info customer">
            <div className="img-hoder">
              <img src={require("../../assets/images/img-customer.jpg")} alt=""/>
              <h3>Customer Information</h3>
            </div>
          </div>
          <div className="col-info customer-info">
            <Formik
              innerRef={formRef}
              initialValues={{
                phone: '',
                first_name: '',
                last_name: '',
                email: '',
                gender: '3',
                dob: null,
              }}
              validationSchema={Yup.object().shape({
                phone: Yup.string()
                  .matches(/^\d{11}$/, 'Customer number must be 11 digits')
                  .required('Required'),
                first_name: Yup.string().max(40, 'First name must be at most 40 characters').required('Required'),
                last_name: Yup.string().max(40, 'Last name must be at most 40 characters').required('Required'),
                email: Yup.string().email('Email must be a valid email'),
                dob: Yup.date().required('Required').typeError('Required'),
              })}
              onSubmit={(e) => goToNext(e)}
            >
              {props => (
                <form>
                  <div className="form-row">
                    <label>Customer Number *</label>
                    <Field
                      id='phone'
                      name='phone'
                      placeholder='03000000000'
                      component={TextFormFieldWithError}
                    />
                  </div>
                  <div className="form-row">
                    <label>Customer First Name *</label>
                    <Field
                      id='first_name'
                      name='first_name'
                      placeholder='FIRST NAME'
                      component={TextFormFieldWithError}
                    />
                  </div>
                  <div className="form-row">
                    <label>Customer Last Name *</label>
                    <Field
                      id='last_name'
                      name='last_name'
                      placeholder='LAST NAME'
                      component={TextFormFieldWithError}
                    />
                  </div>
                  <div className="form-row">
                    <label>Email</label>
                    <Field
                      id='email'
                      name='email'
                      placeholder='johndoe@gmail.com'
                      component={TextFormFieldWithError}
                    />
                  </div>
                  <div className="form-row">
                    <span className="label-text">Gender</span>
                    <ul className="radio-list">
                      <li>
                        <Field name="gender" type="radio" value="1" label='Male'
                               component={RadioButtonWithLabel}
                        />
                      </li>
                      <li>
                        <Field name="gender" type="radio" value="2" label='Female'
                               component={RadioButtonWithLabel}
                        />
                      </li>
                      <li>
                        <label>
                          <Field name="gender" type="radio" value="3" label="Don't want to disclose"
                                 component={RadioButtonWithLabel}
                          />
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div className="form-row">
                    <label>DOB</label>
                    <Field
                      disableFuture
                      openTo="year"
                      views={["year", "month", "date"]}
                      onChange={val => {
                        props.setFieldValue('dob', val);
                      }}
                      value={props.values.dob}
                      name="dob"
                      component={DateFieldWithError}/>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  </>
};


export default CustomerCreateStepOne;
