import React from 'react';
import Popup from 'reactjs-popup'

import { fullName, getWeekdayName, hmmaaa } from '../../utils/helper';

const ShiftPopup = ({ shift, weekday, startTime, endTime, onCopyShift, onShiftDelete }) => {
  return (
    <Popup trigger={<span className="info">i</span>}
           position={`${weekday > 4 ? 'left' : 'right'}`}
           keepTooltipInside={true}
           arrow={false}
           offsetX={16}
      on={'hover'}
    >
      <div className="popup-holder">
        <div className="popup-col">
          <h2>Shift {shift.id}</h2>
          <span className="title"><i className="icon-calendar-today"/> {getWeekdayName(weekday)}</span>
          <div className="date-holder">
            <span className="date">{hmmaaa(startTime)}</span>
            <span className="dash">-</span>
            <span className="date">{hmmaaa(endTime)}</span>
          </div>
          <a href="" hidden={true}></a>
          <a href="" className="btn-popup"
             onClick={(e) => {
               e.preventDefault()
               onCopyShift(shift)
             }}
          ><i className="icon-content-copy"/> Copy</a>
          <a href="" className="btn-popup"
             onClick={(e) => {
               e.preventDefault()
               onShiftDelete(shift)
             }}
          ><i className="icon-minus-circle"/> Delete</a>
        </div>
        <div className="popup-col">
          <h3><i className="icon-account-group-outline"/> Riders {shift.riders.length}</h3>
          <ul className="rider-list">
            {
              shift.riders.map((rider) => {
                return <li key={rider.id}>{fullName(rider)}</li>
              })
            }
          </ul>
        </div>
      </div>
    </Popup>
  )
};

export default ShiftPopup
