import * as types from './types';
import {
  emptyActionCreator,
  payloadActionCreator,
} from '../../utils/actions';

export const getRiderRequest = payloadActionCreator(types.GET_RIDER_REQUEST);
export const getRiderSuccess = payloadActionCreator(types.GET_RIDER_SUCCESS);
export const getRiderFailure = payloadActionCreator(types.GET_RIDER_FAILURE);

export const getRidersRequest = payloadActionCreator(types.GET_RIDERS_REQUEST);
export const getRidersSuccess = payloadActionCreator(types.GET_RIDERS_SUCCESS);
export const getRidersFailure = payloadActionCreator(types.GET_RIDERS_FAILURE);
export const updateRiderParams = payloadActionCreator(types.UPDATE_RIDER_PARAMS);

export const getFilteredRidersRequest = payloadActionCreator(types.GET_FILTERED_RIDERS_REQUEST);
export const getFilteredRidersSuccess = payloadActionCreator(types.GET_FILTERED_RIDERS_SUCCESS);
export const getFilteredRidersFailure = payloadActionCreator(types.GET_FILTERED_RIDERS_FAILURE);

export const getOnlineRidersRequest = payloadActionCreator(types.GET_ONLINE_RIDERS_REQUEST);
export const getOnlineRidersSuccess = payloadActionCreator(types.GET_ONLINE_RIDERS_SUCCESS);
export const getOnlineRidersFailure = payloadActionCreator(types.GET_ONLINE_RIDERS_FAILURE);

export const getAssignableRiderRequest = payloadActionCreator(types.GET_ASSIGNABLE_RIDER_REQUEST);
export const getAssignableRiderSuccess = payloadActionCreator(types.GET_ASSIGNABLE_RIDER_SUCCESS);
export const getAssignableRiderFailure = payloadActionCreator(types.GET_ASSIGNABLE_RIDER_FAILURE);
export const resetAssignableRiderState = emptyActionCreator(types.RESET_ASSIGNABLE_RIDER_STATE);

export const getRiderStatsRequest = payloadActionCreator(types.GET_RIDER_STATS_REQUEST);
export const getRiderStatsSuccess = payloadActionCreator(types.GET_RIDER_STATS_SUCCESS);
export const getRiderStatsFailure = payloadActionCreator(types.GET_RIDER_STATS_FAILURE);

export const getRiderLocationRequest = payloadActionCreator(types.GET_RIDER_LOCATION_REQUEST);
export const getRiderLocationSuccess = payloadActionCreator(types.GET_RIDER_LOCATION_SUCCESS);
export const getRiderLocationFailure = payloadActionCreator(types.GET_RIDER_LOCATION_FAILURE);

export const getRiderTasksRequest = payloadActionCreator(types.GET_RIDER_TASKS_REQUEST);
export const getRiderTasksSuccess = payloadActionCreator(types.GET_RIDER_TASKS_SUCCESS);
export const getRiderTasksFailure = payloadActionCreator(types.GET_RIDER_TASKS_FAILURE);
export const resetRiderTasksState = emptyActionCreator(types.RESET_RIDER_TASKS_STATE);

export const getNPRidersRequest = payloadActionCreator(types.GET_NP_RIDERS_REQUEST);
export const getNPRidersSuccess = payloadActionCreator(types.GET_NP_RIDERS_SUCCESS);
export const getNPRidersFailure = payloadActionCreator(types.GET_NP_RIDERS_FAILURE);

export const getRidersSearchRequest = payloadActionCreator(types.GET_RIDERS_SEARCH_REQUEST);
export const getRidersSearchSuccess = payloadActionCreator(types.GET_RIDERS_SEARCH_SUCCESS);
export const getRidersSearchFailure = payloadActionCreator(types.GET_RIDERS_SEARCH_FAILURE);

export const resetRiderState = emptyActionCreator(types.RESET_RIDER_STATE);

export const getRiderOrdersRequest = payloadActionCreator(types.GET_RIDER_ORDERS_REQUEST);
export const getRiderOrdersSuccess = payloadActionCreator(types.GET_RIDER_ORDERS_SUCCESS);
export const getRiderOrdersFailure = payloadActionCreator(types.GET_RIDER_ORDERS_FAILURE);

export const getFilteredRiderOrdersRequest = payloadActionCreator(types.GET_FILTERED_RIDER_ORDERS_REQUEST);
export const getFilteredRiderOrdersSuccess = payloadActionCreator(types.GET_FILTERED_RIDER_ORDERS_SUCCESS);
export const getFilteredRiderOrdersFailure = payloadActionCreator(types.GET_FILTERED_RIDER_ORDERS_FAILURE);

export const getRiderForceDeliverRequest = payloadActionCreator(types.GET_RIDER_FORCE_DELIVER_REQUEST);
export const getRiderForceDeliverSuccess = payloadActionCreator(types.GET_RIDER_FORCE_DELIVER_SUCCESS);
export const getRiderForceDeliverFailure = payloadActionCreator(types.GET_RIDER_FORCE_DELIVER_FAILURE);

export const riderMarkOffDutyRequest = payloadActionCreator(types.RIDER_MARK_OFF_DUTY_REQUEST);
export const riderMarkOffDutySuccess = payloadActionCreator(types.RIDER_MARK_OFF_DUTY_SUCCESS);
export const riderMarkOffDutyFailure = payloadActionCreator(types.RIDER_MARK_OFF_DUTY_FAILURE);

export const createRiderRequest = payloadActionCreator(types.CREATE_RIDER_REQUEST);
export const createRiderSuccess = payloadActionCreator(types.CREATE_RIDER_SUCCESS);
export const createRiderFailure = payloadActionCreator(types.CREATE_RIDER_FAILURE);

export const updateRiderRequest = payloadActionCreator(types.UPDATE_RIDER_REQUEST);
export const updateRiderSuccess = payloadActionCreator(types.UPDATE_RIDER_SUCCESS);
export const updateRiderFailure = payloadActionCreator(types.UPDATE_RIDER_FAILURE);

export const removeRiderSearchItem = payloadActionCreator(types.REMOVE_RIDERS_SEARCH_ITEM);
