import React, {useEffect} from 'react';
import {ddMMMyyyhhmmaaa} from '../../utils/helper';
import {debounce, isEmpty, startCase, truncate} from 'lodash';
import {Loader} from './loader';
import qs from 'qs';

export const RiderOrdersList = ({
                                  riderId, riderOrders, getRiderOrders, getFilteredRiderOrders, isLoading,
                                  riderOrdersNext, history,
                                }) => {
  const riderOrdersRef = React.createRef();

  useEffect(() => {
    if (riderId) {
      getFilteredRiderOrders({riderId: riderId});
    }
  }, [riderId]);

  const onScroll = debounce(() => {
    if (
      riderOrdersRef.current && riderOrdersRef.current.scrollTop + 2 * riderOrdersRef.current.clientHeight >=
      riderOrdersRef.current.scrollHeight
    ) {
      if (!isLoading && riderOrdersNext) {
        const paramsObj = qs.parse(riderOrdersNext.split('?')[1]);
        if (paramsObj.page) getRiderOrders({riderId: riderId, page: paramsObj.page});
      }
    }
  }, 100);

  const noRiderOrders = () => {
    if (isLoading) {
      return <Loader/>
    }
    if (isEmpty(riderOrders))
      return <p>No Orders</p>
  };

  const getAddress = (task) => {
    return '';
    let address = '';
    if (task.type === 0) {
      address = task.order.address
    } else {
      address = task.collectionCenter.name
    }
    return <address>{truncate(address, {length: 40})}</address>
  };

  return <>
    <ul
      className="queue-list"
      ref={riderOrdersRef}
      onScroll={onScroll}
    >
      {riderOrders && riderOrders.map((order) => {
        return <li
          key={order.id}
          onClick={() => history.push(`/orders/detail/${order.id}`)}
        >
          <div className="head">
            <span className="number">{order.number}</span>
            <span className="time">{ddMMMyyyhhmmaaa(order.pickup)}</span>
          </div>
          <span className="pick"><img src={require("../../assets/images/lab-white.svg")}
                                      alt="pickup"/>{startCase(order.state)}</span>
          <address>{order.address}</address>
        </li>
      })}
    </ul>
    {noRiderOrders()}
  </>
};
