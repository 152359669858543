import { takeEvery, takeLatest } from 'redux-saga/effects';
import { types } from '../../actions/feedback';
import {
  createOrderReview, getCROFeedbackQuestionsSaga, getCROFeedbackSaga,
  getOrderReview,
  getPhysicalVisitReviewFeedbackSaga,
  getPhysicalVisitReviewQuestionsSaga, postCROFeedbackSaga,
  postPhysicalVisitReviewFeedbackSaga
} from './feedback';

export function* feedbackSaga() {
  yield takeLatest(types.GET_ORDER_REVIEW_REQUEST, getOrderReview);
  yield takeEvery(types.CREATE_ORDER_REVIEW_REQUEST, createOrderReview);
  yield takeEvery(types.GET_PHYSICAL_VISIT_REVIEW_QUESTIONS_REQUEST, getPhysicalVisitReviewQuestionsSaga);
  yield takeEvery(types.POST_PHYSICAL_VISIT_REVIEW_FEEDBACK_REQUEST, postPhysicalVisitReviewFeedbackSaga);
  yield takeEvery(types.GET_PHYSICAL_VISIT_REVIEW_FEEDBACK_REQUEST, getPhysicalVisitReviewFeedbackSaga);
  yield takeEvery(types.GET_CRO_FEEDBACK_QUESTIONS_REQUEST, getCROFeedbackQuestionsSaga);
  yield takeEvery(types.POST_CRO_FEEDBACK_REQUEST, postCROFeedbackSaga);
  yield takeEvery(types.GET_CRO_FEEDBACK_REQUEST, getCROFeedbackSaga);
}
