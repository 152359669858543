import React, {useEffect, useState} from 'react';
import {find} from 'lodash';
import {fullName} from '../../../utils/helper';
import {Loader} from '../../commons/loader';
import {OrderAssignRiderInfoWindow} from '../../commons/order-assign-rider-info-window';
import Modal from "react-modal";
import OrderReassignmentReason from "../order-reassignment-reason";
import moment from 'moment'
import {getTaskType} from '../../../utils/helper';
import {truncate} from 'lodash';
import {Popup, Marker} from "react-leaflet";
import {CustomerIcon, RiderIcon} from "../../../assets/images/Icons";
import Map from "../../commons/Map";

const OrderAssignForm = (props) => {
    const {
      getAssignableRider,
      orderDetail,
      getCustomerDetail,
      customerDetail,
      mode,
      isGetCustomerDetail = false,
      closeModal,
      assignableRider,
      modalIsOpen,
      assignableRiderLoading,
      orderParams,
      assignOrder,
      reassignmentStrings,
      vertical,
      getRiderTasks,
      tasks,
      resetRiderTasksState
    } = props;
    const [riderSearch, setRiderSearch] = useState('');
    const riderType = {
      available: 'Available',
      late: 'Late',
      outOfCapacity: 'Out of Capacity',
      delivering: 'Delivering',
      outOfRegion: 'Out of Region',
      offDuty: 'Off Duty',
    };
    const [selectedRiderType, setSelectedRiderType] = useState(riderType.available);
    const [selectedRiderId, setSelectedRiderId] = useState(null);
    const [selectedRiderDetail, setSelectedRiderDetail] = useState({});
    const [isOpenReassignmentReasonModal, setIsOpenReassignmentReasonModal] = useState(false);

    useEffect(() => {
      if (!modalIsOpen) {
        return;
      }
      getAssignableRider({orderId: orderDetail.id, vertical: vertical});
      if (isGetCustomerDetail) {
        getCustomerDetail({id: orderDetail.customer.id});
      }
    }, [modalIsOpen]);

    const closeReassignmentReasonModal = () => {
      setIsOpenReassignmentReasonModal(false);
    };

    const handleRiderClick=(riderId, rider)=>{
      setSelectedRiderId(riderId);
      setSelectedRiderDetail(rider);

      resetRiderTasksState();
      getRiderTasks({riderId, vertical});
    }

    const riderTasksList=(selectedRiderDetail, selectedRiderId)=>{
      const getAddress = (task)=> {
        let address = '';
        if (task.type === 0) {
          address = task.order.address
        } else {
          address = task.collectionCenter.name
        }
        return <address>{truncate(address, { length: 40 })}</address>
      };

      return(
        selectedRiderId &&
        <>
        <div class="top-info">
          {selectedRiderId && <>
           <p className='name'>{`${selectedRiderDetail.firstName} ${selectedRiderDetail.lastName}`} </p>
           <p className='phone'>{selectedRiderDetail.phone}</p>
          </>}
          <ul className="queue-list">
            {tasks && tasks.map((task) => {
              const time=task.type==0 ? task.order.pickup : (task.type ==1 ? task.deadline : 0);
              return <li key={task.id}>
                <div className="head">
                  <span className="number">{task.order.number}</span>
                  <span className="time">{moment(time).format("hh:mm")}</span>
                </div>
                <span className="pick"><img src={require("../../../assets/images/lab-white.svg")}
                                            alt="pickup"/>{getTaskType(task.type)}</span>
                {getAddress(task)}
              </li>
            })}
          </ul>
        </div>
        {assignButton()}
        </>
        )
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      if (!selectedRiderId) {
        return;
      }
      if (mode === 'assign') {
        assignOrder({
          orderParams,
          data: {
            id: orderDetail.id,
            rider: selectedRiderId,
            vertical
          },
        });
      } else {
        setIsOpenReassignmentReasonModal(true);
      }
      const {closeModal} = props;
      closeModal();
    };

    const getAddress = () => {
      let addressObject;
      if (typeof orderDetail.customerAddress === 'object') {
        addressObject = orderDetail.customerAddress;
      } else {
        addressObject = find(customerDetail.addresses, {id: orderDetail.customerAddress});
      }
      return addressObject;
    };

    const unselectableRiders = (rider) => {
      return mode === 'assign' || !orderDetail.rider ?
        <li key={rider.id} aria-disabled>
          <div className="info">
            <span className="name">{`${rider.firstName} ${rider.lastName}`}</span>
            <span className="tel">{rider.phone}</span>
          </div>
        </li> :
        (rider.id !== orderDetail.rider.id) &&
        <li key={rider.id} aria-disabled>
          <div className="info">
            <span className="name">{`${rider.firstName} ${rider.lastName}`}</span>
            <span className="tel">{rider.phone}</span>
          </div>
        </li>
    };

    const selectableRiders = (scoredRider) => {
      return mode === 'assign' || !orderDetail.rider ?
        <li key={scoredRider.rider.id} className={scoredRider.rider.id === selectedRiderId ? 'selected' : ''}
            onClick={() => handleRiderClick(scoredRider.rider.id, scoredRider.rider)}
        >
          <span className="tag"><span>{`${scoredRider.totalScore}`}</span> Score</span>
          <div className="info">
            <span className="name">
              {scoredRider.rider.isCovid && '[C]'}
              {scoredRider.rider.isWildcard && '[W]'}
              {!scoredRider.rider.isCovid && !scoredRider.rider.isWildcard && '[R]'}
              {`${scoredRider.rider.firstName} ${scoredRider.rider.lastName}`}</span>
            <span className="tel">{scoredRider.rider.phone}</span>
          </div>
        </li> :
        (scoredRider.rider.id !== orderDetail.rider.id) &&
        <li key={scoredRider.rider.id} className={scoredRider.rider.id === selectedRiderId ? 'selected' : ''}
            onClick={() => handleRiderClick(scoredRider.rider.id, scoredRider.rider)}
        >
          <span className="tag"><span>{`${scoredRider.totalScore}`}</span> Score</span>
          <div className="info">
            <span className="name">
              {scoredRider.rider.isCovid && '[C]'}
              {scoredRider.rider.isWildcard && '[W]'}
              {!scoredRider.rider.isCovid && !scoredRider.rider.isWildcard && '[R]'}
              {`${scoredRider.rider.firstName} ${scoredRider.rider.lastName}`}</span>
            <span className="tel">{scoredRider.rider.phone}</span>
          </div>
        </li>
    };

    const filterRiders = (rider) => {
      if (riderSearch.includes("\\")) {
        return false;
      }
      rider = rider.rider ? rider.rider : rider;
      const regExp = new RegExp(riderSearch, "i");
      return rider.phone.match(regExp) ||
        rider.firstName.match(regExp) ||
        rider.lastName.match(regExp) ||
        (rider.firstName + ' ' + rider.lastName).match(regExp)
    };
    const assignButton = () => {
      return <a href="" className="assigner-create-btn"
                onClick={handleSubmit}
      >{mode === 'assign' ? 'Assign Order' : 'Re-assign Order'}</a>
    };

    const noRiders = () => {
      return assignableRiderLoading ? <Loader/> : 'No riders';
    };
    const renderRiderTypes = () => {
      if (selectedRiderType === riderType.available) {
        assignableRider.filteredAvailableRiders = assignableRider.availableRiders.filter(filterRiders);
        if (assignableRider.filteredAvailableRiders.length) {
          return assignableRider.filteredAvailableRiders.map(ScoredRider => selectableRiders(ScoredRider))
        }
        return noRiders();
      }
      if (selectedRiderType === riderType.late) {
        assignableRider.filteredLateRiders = assignableRider.lateRiders.filter(filterRiders);
        if (assignableRider.filteredLateRiders.length) {
          return assignableRider.filteredLateRiders.map(ScoredRider => selectableRiders(ScoredRider))
        }
        return noRiders();
      }
      if (selectedRiderType === riderType.outOfCapacity) {
        assignableRider.filteredOutOfCapacityRiders = assignableRider.outOfCapacityRiders.filter(filterRiders);
        if (assignableRider.filteredOutOfCapacityRiders.length) {
          return assignableRider.filteredOutOfCapacityRiders.map(rider => unselectableRiders(rider))
        }
        return noRiders();
      }
      if (selectedRiderType === riderType.delivering) {
        assignableRider.filteredDeliveringRiders = assignableRider.deliveringRiders.filter(filterRiders);
        if (assignableRider.filteredDeliveringRiders.length) {
          return assignableRider.filteredDeliveringRiders.map(rider => unselectableRiders(rider))
        }
        return noRiders();
      }
      if (selectedRiderType === riderType.outOfRegion) {
        assignableRider.filteredOutOfRegionRiders = assignableRider.outOfRegionRiders.filter(filterRiders);
        if (assignableRider.filteredOutOfRegionRiders.length) {
          return assignableRider.filteredOutOfRegionRiders.map(rider => unselectableRiders(rider))
        }
        return noRiders();
      }
      if (selectedRiderType === riderType.offDuty) {
        assignableRider.filteredOffDutyRiders = assignableRider.offDutyRiders.filter(filterRiders);
        if (assignableRider.filteredOffDutyRiders.length) {
          return assignableRider.filteredOffDutyRiders.map(rider => unselectableRiders(rider))
        }
        return noRiders();
      }
    };

    const address = getAddress()
    const initialCenter = address && {lat: address.latitude, lng: address.longitude}
    return <div className="order-assigin-popup">
      <div className="overlay"/>
      {orderDetail && <div className="order-assigin-content">
        <div className="assigin-cols">
          <div className="assigin-col">
            <div className="head">
              <a href=''
                 onClick={(e => {
                   e.preventDefault();
                   setSelectedRiderId(null);
                   closeModal();
                 })}
                 className="icon-clear"/>
              {
                mode === 'assign' ? <h2>Order Assigning</h2> :
                  <h2>Order Re-assigning</h2>
              }
            </div>
            <div className="assigin-map">
              <a href='' onClick={(e) => e.preventDefault()} className="find-rider"><i className="icon-rider"/>
                Find me the best fit rider for this order
              </a>
              <Map
                initialCenter={[orderDetail.customerAddress.latitude, orderDetail.customerAddress.longitude]}
                initialZoom={15}
                styles={{height: "100%", zIndex: 0}}
              >
                {
                  [...assignableRider.availableRiders, ...assignableRider.lateRiders].map(scoredRider => {
                    return <Marker
                      icon={RiderIcon}
                      position={[+scoredRider.rider.lastKnownLatitude, +scoredRider.rider.lastKnownLongitude]}>
                      <Popup>
                        <OrderAssignRiderInfoWindow info={scoredRider} />
                      </Popup>
                    </Marker>
                  })
                }
                {
                  address &&
                  <Marker icon={CustomerIcon} position={[+address.latitude, +address.longitude]}>
                    <Popup>
                      {address.address}
                    </Popup>
                  </Marker>
                }
              </Map>
            </div>
            <div className="assigner-block">
              <div className="assigner-head">
                <h3>{orderDetail.number}</h3>
                {/*<a href="#" className="view-link">View Details</a>*/}
              </div>
              {customerDetail.id &&
              <div className="assiner-content">
                <div className="contact-info">
                  <span className="name">{fullName(orderDetail.patient)}</span>
                  <span className="tel">{orderDetail.patient.phone}</span>
                </div>
                <address className="address">
                  {address && address.address}
                </address>
              </div>
              }
            </div>
          </div>

          <div className="assigin-col">
            <form action="#" className="assigner-search">
              <input type="search" placeholder="Rider Name or Phone"
                     value={riderSearch}
                     onChange={(e) => {
                       setRiderSearch(e.target.value);
                     }}/>
              <button className="icon-magnify" onClick={(e) => e.preventDefault()}/>
            </form>
            <div className="assign-filter-head">
              <ul className="assign-filter-head">
                {Object.values(riderType).map((currentRiderType) => {
                  return <li
                    key={currentRiderType}
                    className={selectedRiderType === currentRiderType ? 'active' : ''}><a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedRiderType(currentRiderType);
                    }}>{currentRiderType}</a></li>
                })}
              </ul>
              <ul className="assigner-list">
                {renderRiderTypes()}
              </ul>
            </div>
          </div>

          <div className="assigin-col order-assign-popup">
              {riderTasksList(selectedRiderDetail, selectedRiderId)}
          </div>

        </div>
      </div>
      }
      {isOpenReassignmentReasonModal &&
      <Modal isOpen={isOpenReassignmentReasonModal} onRequestClose={closeReassignmentReasonModal} className='small-modal'>
        <OrderReassignmentReason
          orderId={orderDetail.id}
          selectedRiderId={selectedRiderId}
          assignOrder={assignOrder}
          orderParams={orderParams}
          closeModal={closeReassignmentReasonModal}
          reassignmentStrings={reassignmentStrings}
          setSelectedRiderId={setSelectedRiderId}
          vertical={vertical}
        />
      </Modal>
      }
    </div>;
  }
;

export default OrderAssignForm;
