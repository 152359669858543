import {
  call,
  put,
} from 'redux-saga/effects';
import {logger} from 'utils/logger';
import {locationActions} from '../../actions/location';
import {
  getCollectionCentersAPI,
  getHotSpotsAPI,
  getCitiesAPI,
  getRegionsAPI,
  getRiderLiveLocationAPI,
  getAllRegionsAPI
} from '../../api/location';

export function* getCities({payload}) {
  try {
    const resp = yield call(getCitiesAPI, payload);
    if (resp) {
      yield put(locationActions.getCitiesSuccess({vertical: payload.vertical, data: resp.data}));
    }
  } catch (error) {
    logger.error(error);
    yield put(locationActions.getCitiesFailure(error));
  }
}

export function* getCollectionCenters({payload}) {
  try {
    const resp = yield call(getCollectionCentersAPI, payload);
    if (resp) {
      yield put(locationActions.getCollectionCentersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(locationActions.getCollectionCentersFailure(error));
  }
}

export function* getAllRegions({payload}) {
  try {
    const resp = yield call(getAllRegionsAPI);
    if (resp) {
      yield put(locationActions.getAllRegionsSuccess({vertical: payload.vertical, data: resp.data}));
    }
  } catch (error) {
    logger.error(error);
    yield put(locationActions.getAllRegionsFailure(error));
  }
}

export function* getRegions({payload}) {
  try {
    const {vertical} = payload;
    const resp = yield call(getRegionsAPI, payload);
    if (resp) {
      yield put(locationActions.getRegionsSuccess({vertical, data: resp.data}));
    }
  } catch (error) {
    logger.error(error);
    yield put(locationActions.getRegionsFailure(error));
  }
}

export function* getHotSpots({payload}) {
  try {
    const resp = yield call(getHotSpotsAPI, payload);
    if (resp) {
      yield put(locationActions.getHotSpotsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(locationActions.getHotSpotsFailure(error));
  }
}


export function* getRiderLiveLocationSaga({payload: value}) {
  try {
    const resp = yield call(getRiderLiveLocationAPI, value);
    if (resp) {
      yield put(locationActions.getRiderLiveLocationSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(locationActions.getRiderLiveLocationFailure(error));
  }
}
