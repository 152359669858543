import React from 'react';
import { MAX_STAR } from '../../utils/constants';

const Slider = ({ value }) => {

  return (
    <div className="full-star-ratting">
      <input type="range" min="1" max="60" value={value} />
    </div>
  );
};

export default Slider;
