const selectAttendanceFromState = state => state.attendance

export const selectAttendanceListFromState = state => selectAttendanceFromState(state).list
export const selectAttendanceListLoadingFromState = state => selectAttendanceFromState(state).loading
export const selectAttendanceListNextFromState = state => selectAttendanceFromState(state).next
export const selectAttendanceListCountFromState = state => selectAttendanceFromState(state).count
export const selectAttendanceListParamsFromState = state => selectAttendanceFromState(state).params;
export const selectAttendanceShiftsFromState = state => selectAttendanceFromState(state).attendanceShifts
export const selectRiderShiftAttendanceFromState = state => selectAttendanceFromState(state).riderShiftAttendance

