import React from 'react';

import CLHS_LOGO_LIGHT from '../../assets/images/clhs-logo-light.png';
import LOCATION_ICON from '../../assets/images/loction-icon.png';


const RiderLiveLocationLinkExpired = () => {
  return (
    <div className="page-table">
      <div className="page-table-head">
        <div className="head-holder">
          <div className="logo-one">
            <a href="https://chughtailab.com/"><img src={CLHS_LOGO_LIGHT} alt="" /></a>
          </div>
        </div>
      </div>
      <div className="page-table-body">
        <div className="feedback-hoder expired">
          <img src={LOCATION_ICON} alt="" />
            <span className="title">This link is expired!</span>
            <p>Live location links are valid until order is picked, Please contact <a href="https://chughtailab.com/aap-ki-sehat-ka-number/">customer support</a>
              , if you still need further assistance</p>
        </div>
      </div>
      <div className="page-table-footer">
        <div className="footer-holder">
          <p>For any business related query and new offers please visit our website</p>
          <a href="https://chughtailab.com/">www.chughtailab.com</a>
        </div>
      </div>
    </div>
  );
};

export default RiderLiveLocationLinkExpired;
