import React from 'react';
import { getDateFromTime, getWeekdayName, HHmm, } from '../../utils/helper';
import { toast } from 'react-toastify';
import { isAfter, isBefore } from "date-fns";
import { TimeFieldWithError } from '../ui/form-fields/time-form-field-with-error';
import { Field, Formik } from 'formik';
import shiftImage from '../../assets/images/img-2.svg';
import {useHistory} from "react-router-dom";
import {UNSAVED_SHIFT_RIDER_ERROR_MESSAGE} from "../../utils/constants";

const ShiftsUpdate = (props) => {
  const {
    shiftDetail,
    updateShift,
    onDelete,
    shiftRiders
  } = props;

  const onSaveShift = (values) => {
    values = { ...values }
    values.startTime = HHmm(values.startTime)
    values.endTime = HHmm(values.endTime)
    const hasUnsavedRiders = shiftRiders.filter(shift => !shift.isDisabled).length > 0
    hasUnsavedRiders? toast.error(UNSAVED_SHIFT_RIDER_ERROR_MESSAGE) :
    updateShift({
      id: shiftDetail.id,
      data: values
    })
  }
  const routerHistory = useHistory()

  return (
    <Formik
      initialValues={{
        startTime: getDateFromTime(shiftDetail.startTime),
        endTime: getDateFromTime(shiftDetail.endTime)
      }}
      enableReinitialize={true}>
      {props => (
        <form>
          <div className="btns-bar">
            <a href="" className="blue"
               onClick={(e) => {
                 e.preventDefault()
                 routerHistory.goBack()
               }}
            >Back</a>
            <a href="" className="red"
               onClick={onDelete}
            >Delete</a>
            <a href="" className="yellow"
               onClick={(e) => {
                 e.preventDefault()
                 onSaveShift(props.values)
               }}
            >Save</a>
          </div>
          <div className="shift-update-head">
            <img src={shiftImage} alt="shift image"/>
            <div className="info">
              <span className="title">Shift {shiftDetail.id}</span>
              <div className="detail">
                <span className="city"><i className="icon-map-marker"/>{shiftDetail?.cityName}</span>
                <span className="day"><i className="icon-calendar"/>{getWeekdayName(shiftDetail?.weekday)}</span>
              </div>
              <div className="select-time-cols">
                <div className="input-hiv select-time-cols">
                  <div className="input-holder">
                    <Field
                      variant="inline"
                      onChange={val => {
                        if (isAfter(val, new Date(props.values.endTime))) {
                          toast.error("Start time can't be greater than end time");
                          return;
                        }
                        props.setFieldValue('startTime', val);
                      }}
                      name="startTime"
                      component={TimeFieldWithError}/>
                  </div>
                  <span className=" to">to</span>
                  <div className="input-holder">
                    <Field
                      variant="inline"
                      onChange={val => {
                        if (isBefore(val, new Date(props.values.startTime))) {
                          toast.error("End time can't be less than start time");
                          return;
                        }
                        props.setFieldValue('endTime', val);
                      }}
                      name="endTime"
                      component={TimeFieldWithError}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
};

export default ShiftsUpdate
