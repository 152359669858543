import StepWizard from 'react-step-wizard';
import React, { useEffect } from 'react';
import CustomerCreateStepOne from './customer-create-step-one';
import CustomerCreateStepTwo from './customer-create-step-two';
import {VERTICALS} from "../../utils/constants";

const CustomerCreate = (props) => {
  const {
    createCustomer,
    customerDetail,
    getCustomerDetail,
    createCustomerAddress,
    resetCustomerState,
    deleteCustomerAddress,
    getCities,
    cities
  } = props;

  useEffect(() => {
    getCities({vertical: VERTICALS.BLOOD});
  }, []);

  return <div id='wrapper' className='inner-page'>
    <StepWizard><CustomerCreateStepOne history={props.history} createCustomer={createCustomer}
                                       customerDetail={customerDetail} resetCustomerState={resetCustomerState}/>
      <CustomerCreateStepTwo history={props.history} customerDetail={customerDetail}
                             getCustomerDetail={getCustomerDetail}
                             createCustomerAddress={createCustomerAddress} resetCustomerState={resetCustomerState}
                             deleteCustomerAddress={deleteCustomerAddress} cities={cities}
      />
    </StepWizard>
  </div>


};
export default CustomerCreate
