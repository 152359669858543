import React, { useEffect } from 'react';
import { Loader } from '../commons/loader';
import { fullName, hmmaaa } from '../../utils/helper';
import onlineChart from '../../assets/images/online-chart.svg'


const RiderShiftAttendance = ({ riderShiftAttendance, getRiderShiftAttendance, attendanceDetail, closeModal, isLoading }) => {

  useEffect(() => {
    if (attendanceDetail && attendanceDetail.id) {
      getRiderShiftAttendance({ riderShift: attendanceDetail.id })
    }
  }, []);

  return (
    <div className="lightbox schedule">
      <div className="online-chart-lightbox">
        <a
          href=''
          className="lightbox-close icon-clear"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        />
        <div className="head">
          <div className="left-holder">
            <img src={onlineChart} alt="online chart"/>
            <span className="title">Online chart</span>
          </div>
          <div className="rider-info">
            <span className="name">{fullName(attendanceDetail.rider)}</span>
            <span className="tel">{attendanceDetail.rider.phone}</span>
          </div>
          <span className="shift-number">Shift {attendanceDetail.shift.id}</span>
        </div>
        {isLoading && <Loader/>}
        <div className="graph-area">
          <table>
            <thead>
            <th></th>
            <th>00</th>
            <th>05</th>
            <th>10</th>
            <th>15</th>
            <th>20</th>
            <th>25</th>
            <th>30</th>
            <th>35</th>
            <th>40</th>
            <th>45</th>
            <th>50</th>
            <th>55</th>
            </thead>
            <tbody>
            {
              riderShiftAttendance && Object.keys(riderShiftAttendance).map((datetimeWithoutMinutes, index) => {
                return <tr key={datetimeWithoutMinutes}>
                  <td>{hmmaaa(datetimeWithoutMinutes)}</td>
                  {Object.keys(riderShiftAttendance[datetimeWithoutMinutes]).map((datetime, index) => {
                    return <td key={riderShiftAttendance[datetimeWithoutMinutes][datetime]}
                               className={riderShiftAttendance[datetimeWithoutMinutes][datetime] ? "active" : ''}></td>
                  })}
                </tr>
              })
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};

export default RiderShiftAttendance;
