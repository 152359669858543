import React from 'react';
import {
  BUSY_RIDER_STATES,
  IDLE_RIDER_STATES,
  PAKISTAN_LAT_LONG,
} from '../../utils/constants';
import {RiderInfoWindow} from './rider-info-window';
import {OrderInfoWindow} from './order-info-window';
import {CollectionCenterInfoWindow} from './collection-center-info-window';
import {CellInfoWindow} from "./cell-info-window";
import Map from "../commons/Map";
import {Marker, Popup, Polygon} from "react-leaflet";
import {
  BusyRiderIcon,
  CellIcon,
  CollectionCenterIcon,
  CovidRiderIcon,
  CustomerIcon,
  IdleRiderIcon,
  WildcardRiderIcon
} from "../../assets/images/Icons";

export const DashboardMapHolder = (
  {
    riders,
    collectionCenters,
    orders,
    isShowOrders,
    isShowRiders,
    isShowBoundary,
    isShowCells,
    isShowBlackListedAreas,
    onCancelOrderClick,
    history,
    mapFull,
    regionDetail,
    isShowCollectionCenters
  }) => {
  const regionCenterPoint = regionDetail && regionDetail.centerPoint && [+regionDetail?.centerPoint?.latitude || PAKISTAN_LAT_LONG.lat, +regionDetail?.centerPoint?.longitude || PAKISTAN_LAT_LONG.lat];

  if (!regionCenterPoint) {
    return <></>
  }

  const getRiderIcon = rider => {
    let icon;

    if (IDLE_RIDER_STATES.includes(+rider.state))
      icon = IdleRiderIcon;

    if (BUSY_RIDER_STATES.includes(+rider.state))
      icon = BusyRiderIcon;

    if (rider.isWildcard)
      icon = WildcardRiderIcon;

    if (rider.isCovid)
      icon = CovidRiderIcon;

    return icon;
  };

  return <Map
    center={regionCenterPoint}
    zoom={regionDetail?.zoomLevel || 11}
    currentCenter={regionCenterPoint}
    currentZoom={regionDetail?.zoomLevel || 11}
    style={{height: "100%"}}
  >
    {isShowRiders &&
      riders?.map(rider =>
        <Marker
          key={`rider-${rider.id}`}
          icon={getRiderIcon(rider)}
          position={[+rider.lastKnownLatitude, +rider.lastKnownLongitude]}
        >
          <Popup>
            <RiderInfoWindow info={rider} />
          </Popup>
        </Marker>
      )
    }
    {
      isShowCollectionCenters &&
      collectionCenters?.map(collectionCenter =>
        <Marker
          key={`collection-center-${collectionCenter.id}`}
          icon={CollectionCenterIcon}
          position={[+collectionCenter.latitude, +collectionCenter.longitude]}
        >
          <Popup>
            <CollectionCenterInfoWindow info={collectionCenter} />
          </Popup>
        </Marker>
      )
    }
    {isShowOrders &&
      orders?.map(order =>
        <Marker
          key={`order-${order.id}`}
          icon={CustomerIcon}
          position={[+order.latitude, +order.longitude]}
        >
          <Popup>
            <OrderInfoWindow
              info={order}
              onCancelOrderClick={onCancelOrderClick}
              history={history} />
          </Popup>
        </Marker>
      )
    }
    {
      isShowBoundary &&
      <Polygon
        positions={regionDetail?.polygon?.map(row => [row.lat, row.lng])}
        pathOptions={{ stroke: false, fillOpacity: 0.2, fillColor: '#4F5CD3' }}
      />
    }
    {
      isShowBlackListedAreas &&
      regionDetail?.blackListedAreas?.map(blackListedArea =>
        <Polygon
          positions={blackListedArea?.coordinates.map(row => [+row.lat, +row.lng])}
          pathOptions={{ stroke: false, fillOpacity: 0.2, fillColor: '#000000' }}
        />
      )
    }
    {
      isShowCells &&
      regionDetail?.cells.map(cell =>
        <Polygon
          positions={cell?.coordinates.map(row => [+row.lat, +row.lng])}
          pathOptions={{ stroke: false, fillOpacity: 0.2, fillColor: '#0000FF' }}
        />
      )
    }
    {
      isShowCells &&
      regionDetail?.cells?.map(cell =>
          <Marker
            icon={CellIcon}
            position={[+cell.centroid.lat, +cell.centroid.lng]}
          >
            <Popup>
              <CellInfoWindow info={cell}/>
            </Popup>
          </Marker>
      )
    }
  </Map>
};
