export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ENDPOINTS = {
  LOGIN: `${BASE_URL}/auth/login/`,
  LOGOUT: `${BASE_URL}/auth/logout/`,
  INITIATE_PASSWORD_RESET: `${BASE_URL}/auth/password-reset-request/`,
  PASSWORD_RESET: `${BASE_URL}/auth/password-reset/`,
  GET_CITIES: `${BASE_URL}/location/cities/`,
  GET_REGIONS: `${BASE_URL}/location/regions/`,
  GET_HOT_SPOTS: `${BASE_URL}/location/hotspots/`,
  GET_COLLECTION_CENTERS: `${BASE_URL}/location/collection-centers/`,
  GET_NP_RIDERS: `${BASE_URL}/entity/riders-np/`,
  GET_RIDERS_SEARCH: `${BASE_URL}/entity/rider-search/`,
  GET_NP_STAFF_USERS: `${BASE_URL}/entity/staff-np/`,
  GET_RIDERS: `${BASE_URL}/entity/riders/`,
  GET_RIDER: `${BASE_URL}/entity/rider/`,
  GET_RIDER_FORCE_DELIVER: `${BASE_URL}/entity/rider_force_deliver/`,
  RIDER_MARK_OFF_DUTY: `${BASE_URL}/entity/riders/mark_off_duty/`,
  CREATE_RIDER: `${BASE_URL}/entity/rider-create/`,
  UPDATE_RIDER: `${BASE_URL}/entity/rider-update/`,
  GET_RIDER_LOCATION: `${BASE_URL}/entity/rider_location/`,
  GET_ONLINE_RIDERS: `${BASE_URL}/entity/online-riders/`,
  ALGORITHM: `${BASE_URL}/algorithm/`,
  ORDERS: `${BASE_URL}/orders/`,
  ENTITY: `${BASE_URL}/entity/`,
  UNVERIFIED_ORDERS: `${BASE_URL}/orders/blood/unverified/`,
  PARTIAL_ORDERS: `${BASE_URL}/orders/partial-order/`,
  ACTIVITY: `${BASE_URL}/activity/`,
  STATS: `${BASE_URL}/stats/`,
  GET_USER: `${BASE_URL}/entity/users/`,
  CREATE_CUSTOMER: `${BASE_URL}/entity/customers/`,
  UPDATE_CUSTOMER: `${BASE_URL}/entity/customer/`,
  UPDATE_CUSTOMER_ADDRESS: `${BASE_URL}/entity/verify_customer_address/`,
  GET_CUSTOMER: `${BASE_URL}/entity/customer/`,
  GET_CUSTOMERS: `${BASE_URL}/entity/customers/`,
  CUSTOMER_SEARCH: `${BASE_URL}/entity/customer-search/`,
  CUSTOMERS: `${BASE_URL}/entity/customers/`,
  CUSTOMER_ADDRESS: `${BASE_URL}/entity/customer-address/`,
  CUSTOMER_PATIENT: `${BASE_URL}/entity/patient/`,
  DELETE_CUSTOMER_ADDRESS: `${BASE_URL}/entity/customer_address/`,
  AUTH_REFRESH_TOKEN_GET: `${BASE_URL}/auth/refresh-token`,
  HEAR_ABOUT_US: `${BASE_URL}/entity/hear_about_us/`,
  PROBLEM_STRINGS: `${BASE_URL}/orders/problem-strings/`,
  CANCELLATION_STRINGS: `${BASE_URL}/orders/cancellation-strings/`,
  ORDER_REVIEW: `${BASE_URL}/feedback/order_review/`,
  SHIFTS: `${BASE_URL}/shift/`,
  PHYSICAL_REVIEW_QUESTIONS: `${BASE_URL}/feedback/physical_visit_questions/`,
  PHYSICAL_REVIEW_FEEDBACK: `${BASE_URL}/feedback/physical_visit`,
  ORDER_FOLLOW_UP: `${BASE_URL}/activity/follow-up`,
  ORDER_SOURCES: `${BASE_URL}/entity/order-sources`,
  REASSIGNMENT_STRINGS: `${BASE_URL}/orders/reassignment-strings/`,
  RIDER_LIVE_LOCATION: `${BASE_URL}/location/rider-live-location/`,
  CRO_FEEDBACK_QUESTIONS: `${BASE_URL}/feedback/cro-feedback-questions/`,
  CRO_FEEDBACK: `${BASE_URL}/feedback/cro-feedback/`,
  ADDRESS_TYPES: `${BASE_URL}/orders/address-types/`,
  RESCHEDULE_STRINGS: `${BASE_URL}/orders/reschedule-strings/`,
};

export const REQUEST_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const ERROR_MESSAGES = {
  TOKEN_EXPIRED: 'jwt expired',
  NOT_FOUND: 'Not found.',
};

