import * as types from './types';
import {
  payloadActionCreator, emptyActionCreator,
} from '../../utils/actions';

export const loginAttempt = payloadActionCreator(types.AUTH_LOGIN_ATTEMPT);
export const loginSuccess = payloadActionCreator(types.AUTH_LOGIN_SUCCESS);
export const loginFailure = payloadActionCreator(types.AUTH_LOGIN_FAIL);


export const logoutAttempt = emptyActionCreator(types.AUTH_LOGOUT_ATTEMPT);
export const logoutSuccess = emptyActionCreator(types.AUTH_LOGOUT_SUCCESS);
export const logoutFailure = payloadActionCreator(types.AUTH_LOGOUT_FAIL);

export const tokenRefreshSuccess = payloadActionCreator(types.AUTH_TOKEN_REFRESH_SUCCESS);

export const getNpStaffUsersRequest = payloadActionCreator(types.GET_NP_STAFF_USERS_REQUEST);
export const getNpStaffUsersSuccess = payloadActionCreator(types.GET_NP_STAFF_USERS_SUCCESS);
export const getNpStaffUsersFailure = payloadActionCreator(types.GET_NP_STAFF_USERS_FAILURE);
export const resetNpStaffUsersState = payloadActionCreator(types.RESET_NP_STAFF_USERS_STATE);

export const initiatePasswordResetRequest = payloadActionCreator(types.INITIATE_PASSWORD_RESET_REQUEST);
export const initiatePasswordResetSuccess = payloadActionCreator(types.INITIATE_PASSWORD_RESET_SUCCESS);
export const initiatePasswordResetFailure = payloadActionCreator(types.INITIATE_PASSWORD_RESET_FAILURE);

export const passwordResetRequest = payloadActionCreator(types.PASSWORD_RESET_REQUEST);
export const passwordResetSuccess = payloadActionCreator(types.PASSWORD_RESET_SUCCESS);
export const passwordResetFailure = payloadActionCreator(types.PASSWORD_RESET_FAILURE);
