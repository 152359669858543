import React, { useState } from 'react';

const FollowUp = ({ orderId, followUpOrder, closeModal }) => {
  const [reason, setReason] = useState('');

  return (
    <>
      <div className="lightbox small-modal-con">
        <div className="lightbox-con">
          <a
            href=''
            className="lightbox-close icon-clear"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          />
          <div className="form-row">
            <label>Follow Up Comments</label>
            <textarea
              maxLength={250}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <button className="create-btn" onClick={(e)=> {
            e.preventDefault();
            followUpOrder(orderId, {reason})
          }}>Confirm Follow Up</button>
        </div>
      </div>
    </>
  );
};

export default FollowUp;
