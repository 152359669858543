import React, {useState} from 'react';
import {RidersList} from "./riders-list";
import {SearchBar} from "../commons/search-bar";

export const DashboardRiders = ({riders, history}) => {
  const [riderSearch, setRiderSearch] = useState('');

  const filterRiders = (rider) => {
    if (riderSearch.includes("\\")) {
      return false;
    }
    const regExp = new RegExp(riderSearch, "i");
    return (rider.phone.match(regExp) ||
        rider.firstName.match(regExp) ||
        rider.lastName.match(regExp) ||
        (rider.firstName + ' ' + rider.lastName).match(regExp)) &&
      rider.isOnline
  };

  return (
    <>
      <SearchBar search={riderSearch} handleSubmit={(search) => (setRiderSearch(search))}
                 placeholder='e.g. Rider Name or Phone'/>
      <h3>Today</h3>
      <RidersList list={riders.filter(filterRiders)}
                  history={history}
      />
    </>
  )
};
