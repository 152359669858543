import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import logo from "../../../assets/images/logo2.png";
import { ddMMMyyyhhmmaaa } from "../../../utils/helper";
import {MAX_STAR, STAR_RATING} from "../../../utils/constants";
import { ErrorField } from "../../ui/error-field";
import {
  formatFeedbackResponse,
  getInitialValuesForFeedbackForm,
  getStarQuestions,
  getTextQuestions,
  getValidationSchemaForPhysicalVisitReview
} from "../../../utils/feedback";
import { ROUTES } from "../../../routes/constants";

export const PhysicalReview = ({
  match: {
    params: { id }
  },
  physicalVisitReviewQuestions,
  getPhysicalVisitReviewQuestions,
  postPhysicalVisitReviewFeedback,
                                 getPhysicalVisitReviewFeedback,
                                 isInteractionIdValid,
  isReviewComplete,
  isExpired,
                                 history,
}) => {
  useEffect(() => {
    getPhysicalVisitReviewQuestions();
    getPhysicalVisitReviewFeedback({interactionId: id});
  }, []);

  useEffect(() => {
    if (isReviewComplete) {
      history.push(ROUTES.ORDER_REVIEW_SUCCESS);
    }
  }, [isReviewComplete]);

  useEffect(() => {
    if (!isInteractionIdValid) {
      history.push(ROUTES.ORDER_REVIEW_ERROR);
    }
  }, [isInteractionIdValid]);

  useEffect(() => {
    if (isExpired) {
      history.push(ROUTES.ORDER_REVIEW_EXPIRED);
    }
  }, [isExpired]);

  const starQuestions = getStarQuestions(physicalVisitReviewQuestions);
  const textQuestions = getTextQuestions(physicalVisitReviewQuestions);
  const [formErrors, setFormErrors] = useState({});
  return (
    <div id="wrapper">
      <Formik
        initialValues={getInitialValuesForFeedbackForm(
          physicalVisitReviewQuestions
        )}
      >
        {formik => (
          <div className="page-table">
            <div className="page-table-head">
              <div className="head-holder">
                <div className="logo-one">
                  <a href="" onClick={e => e.preventDefault()}>
                    <img src={logo} alt="logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className="page-table-body">
              <div className="value-feedback-hoder">
                <h2>We value your feedback!</h2>
                <p className={'feedback-title'}>Let us know how was your experience with our services</p>
                {starQuestions.map(question => (
                  <ul className="star-list-physical-feedback-form">
                    <div className={'question'}>
                      <li>
                        <span className="text-label-physical-feedback-form">{question.text}</span>
                        <br />
                        <span className="text-label-physical-feedback-form noto-nastaliq-urdu">{question.urduText}</span>
                        <ul className="rating-physical-feedback-form">
                          {[...Array(MAX_STAR)].map((x, i) => (
                            <li
                              key={i}
                              className={
                                formik.values[question.id] > i ? "active" : ""
                              }
                            >
                              <div className={'star-holder'}>
                                <a
                                  href=" "
                                  onClick={e => {
                                    e.preventDefault();
                                    formik.setFieldValue(question.id, i + 1);
                                  }}
                                ></a>
                                <span className={'star-title'}>{STAR_RATING[i]}</span>
                              </div>
                            </li>
                          ))}
                          <ErrorField value={formErrors[question.id]} />
                        </ul>
                      </li>
                    </div>
                  </ul>
                ))}
                {textQuestions.map(question => (
                  <Fragment>
                    <Field
                      component="textarea"
                      name={question.id}
                      placeholder={question.text}
                      className={'text-area'}
                    />
                    <ErrorField value={formErrors[question.id]} />
                  </Fragment>
                ))}
              </div>
            </div>
            <div className="page-table-footer">
              <div className="footer-holder">
                <button
                  className="sub-btn"
                  onClick={() => {
                    let errors = {};
                    let hasError = false;
                    physicalVisitReviewQuestions.map(question => {
                      if (question.isMandatory && !formik.values[question.id]) {
                        errors[question.id] = "Please fill this field!";
                        hasError = true;
                      }
                    });
                    hasError
                      ? setFormErrors(errors)
                      : postPhysicalVisitReviewFeedback({
                          interactionId: id,
                          responses: formatFeedbackResponse(formik.values)
                        });
                  }}
                  disabled={formik.isValidating}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

PhysicalReview.propTypes = {};
