import React from 'react';
import expired from '../../assets/images/order-review-expired.svg'
import { OrderReviewWrapper } from './order-review-wrapper';

export const OrderReviewExpired = () => {
  return <OrderReviewWrapper>
    <div className="page-table-body">
      <div className="feedback-hoder">
        <img src={expired} alt="expired"/>
        <span className="title">This link has expired!</span>
        <p>Feedback links are valid for 48 hours, Please contact <a
          href="https://chughtailab.com/aap-ki-sehat-ka-number/" target='_blank'>customer support</a>, if you still want
          to provide you feedback</p>
      </div>
    </div>
  </OrderReviewWrapper>
};
