import React, {useEffect, useState} from 'react'
import {Nav} from '../commons/nav'
import Modal from 'react-modal'
import {default as _, startCase} from 'lodash'
import {
  formatAMPM,
  diffMinutes,
  formatScanTypes,
  scanTypeLabel,
  fullName,
  isAge,
} from '../../utils/helper'
import ReactMinimalPieChart from 'react-minimal-pie-chart'
import {
  STATES_TO_CANCEL,
  STATES_TO_SHOW_TIMER,
  FULFILLMENT_METHOD_TYPES,
  STATES_TO_VERIFY, VERTICALS,
} from '../../utils/constants'
import OrderActivity from './order-activity'
import OrderCancel from './order-cancel'
import OrderIcon from '../../assets/images/icon2.png'
import {toast} from "react-toastify";
import Autocomplete from "react-autocomplete";
import {MapContainer, Marker, Circle, TileLayer, Popup} from "react-leaflet";
import L from "leaflet";
import {CustomerIcon} from "../../assets/images/Icons";
import Map from "../commons/Map";

const UnverifiedOrdersDetail = props => {
  const {
    match: {
      params: {id},
    },
    history,
    getOrderDetail,
    orderDetail,
    cancelOrder,
    externalOrderParams,
    ordersLoading,
    orderActivities,
    getOrderActivities,
    resetOrderActivitiesState,
    verifyExternalOrder,
    scanTypes,
    getOrderScanTypes,
    resetScanTypesState,
    updateScanTypes,
    getCancellationStrings,
    cancellationStrings,
    deleteCustomerAddress,
  } = props;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isOpenOrderActivityModal, setIsOpenOrderActivityModal] = useState(false);
  const [isOpenCancelOrderModal, setIsOpenCancelOrderModal] = useState(false);
  const [isEditTestsPanelOpen, setIsEditTestsPanelOpen] = useState(false);
  const [tests, setTests] = useState([]);
  const [test, setTest] = useState('');
  const [discount, setDiscount] = useState(0);
  const testsTotal = _.sumBy(tests, t => parseInt(t.value));
  const value = testsTotal - ((discount * testsTotal) / 100);

  const prescriptionStyling = `prescription-image-container ${orderDetail.prescriptionImage ? 'overlay-order-popup-with-image' : 'overlay-order-popup'}`;

  const customerIcon = new L.Icon({
    iconUrl: require('../../assets/images/customer-marker.svg'),
    iconSize: new L.Point(25, 25),
  });

  useEffect(() => {
    getOrderDetail({id, vertical: VERTICALS.blood});
    if (!cancellationStrings.length) getCancellationStrings();
  }, [])

  const orderCancel = (orderId, data) => {
    cancelOrder({id: orderId, vertical: VERTICALS.blood, data, filter: externalOrderParams})
    setIsOpenCancelOrderModal(false)
    history.goBack()
  }

  const closeOrderActivityModal = () => {
    setIsOpenOrderActivityModal(false)
  }

  const closeCancelOrderModal = () => {
    setIsOpenCancelOrderModal(false)
  }

  const verifyOrder = id => {
    if (orderDetail.scanTypes.length == 0) {
      toast.error('Tests are required to verify order.');
      return;
    }
    verifyExternalOrder({id: id, vertical: VERTICALS.blood, filter: externalOrderParams})
    history.goBack()
  }

  const getPickupGraphValue = pickup => {
    return diffMinutes(new Date(), pickup)
  }

  const showGender = gender => {
    switch (gender) {
      case 1:
        return 'Male'
      case 2:
        return 'Female'
      case 3:
        return 'Other'
      default:
        return 'Other'
    }
  }

  const openEditTestsPanel = () => {
    if (orderDetail.scanTypes) {
      let separatedScanTypes = []
      let scanTypeIndex = 0
      for (const test of orderDetail.scanTypes) {
        for (let i = 0; i < test.count; i++) {
          separatedScanTypes.push({
            id: test.scanType.id,
            name: test.scanType.name,
            code: test.scanType.code,
            value: test.scanType.value,
            index: scanTypeIndex++
          })
        }
      }
      setTests(separatedScanTypes)
    }
    setIsEditTestsPanelOpen(true);
  }

  const closeEditTestsPanel = () => {
    setIsEditTestsPanelOpen(false)
    setTests([])
  }

  const searchOrderScanTypes = (test) => {
    if (test.length === 0) {
      resetScanTypesState();
      return;
    }
    getOrderScanTypes({search: test, vertical: VERTICALS.BLOOD});
  };

  const orderScanTypes = () => {
    if (!tests.length) {
      toast.error('Select test type');
      return;
    }
    updateScanTypes({orderId: orderDetail.id, value: value, scanType: tests.map(test => test.code)})
    closeEditTestsPanel();
  };

  const {
    state,
    customerAddress,
    number,
    pickup,
    review: orderReview = null,
    reachedThresholdDistance
  } = orderDetail;
  const customerLocation = [+customerAddress?.latitude, +customerAddress?.longitude]

  return (
    <div
      id='wrapper'
      className={`inner-page ${
        modalIsOpen ? 'order-assigin-popup-active' : ''
      }`}>
      <Nav/>
      {orderDetail && orderDetail.id && (
        <div className='order-detail-page'>
          <div className='order-detail-right'>
            <ul className='btn-list'>
              {customerAddress.verified && customerAddress.isValid &&
              orderDetail.fulfillmentMethod ===
              FULFILLMENT_METHOD_TYPES.ONLINE && (
                <li>
                  <button
                    className='red'
                    onClick={e => {
                      e.preventDefault()
                      deleteCustomerAddress({
                        customerAddressId: customerAddress.id, orderId: orderDetail.id
                      });
                    }}>
                    Mark Wrong Pin
                  </button>
                </li>
              )}
              {STATES_TO_VERIFY.indexOf(state) > -1 && (
                <li>
                  <button
                    className={orderDetail.scanTypes.length == 0 ? 'red' : 'green'}
                    onClick={e => verifyOrder(orderDetail.id)}>
                    Verify Order
                  </button>
                </li>
              )}
              {STATES_TO_CANCEL.indexOf(state) > -1 && (
                <li>
                  <button
                    className='red'
                    onClick={e => setIsOpenCancelOrderModal(true)}>
                    Cancel Order
                  </button>
                </li>
              )}
            </ul>
            <div className='detail-map'>
              <span className='map-info'>{customerAddress.address}</span>
              <Map
                initialCenter={customerLocation}
                initialZoom={15}
                styles={{height: "100%", zIndex: 0}}
              >
                <Circle
                  pathOptions={{
                    stroke: false,
                    fillOpacity: 0.2,
                    fillColor: '#4F5CD3'
                }}
                  center={customerLocation}
                  radius={reachedThresholdDistance} />
                <Marker
                  icon={CustomerIcon}
                  position={customerLocation}>
                  <Popup>
                    {customerAddress.address}
                  </Popup>
                </Marker>
              </Map>
            </div>
          </div>
          <div className='order-detail-left'>
            <h1>{number}</h1>
            <ul className='breadcrumb-page'>
              <li>
                <a
                  href=''
                  onClick={e => {
                    e.preventDefault()
                    history.goBack()
                  }}>
                  Unverified Orders
                </a>
              </li>
              <li>{number}</li>
            </ul>
            <div className='detail-cols'>
              <div className='detail-col'>
                <div className='assign-order-block'>
                  <div className='holder'>
                    <img src={OrderIcon} alt=''/>
                    <div className='info'>
                      <span className='title'>
                        {startCase(orderDetail.state)}
                      </span>
                      <a
                        href='#'
                        className='link'
                        onClick={e => {
                          e.preventDefault()
                          setIsOpenOrderActivityModal(true)
                        }}>
                        View Activity
                      </a>
                    </div>
                  </div>
                  <div className='white-block'></div>
                </div>
                <div className='pick-block'>
                  {STATES_TO_SHOW_TIMER.indexOf(state) === -1 && (
                    <div className='time-graph'>
                      <ReactMinimalPieChart
                        animate={true}
                        animationDuration={500}
                        animationEasing='ease-out'
                        cx={50}
                        cy={50}
                        data={[
                          {
                            color: '#a83e32',
                            value: getPickupGraphValue(orderDetail.pickup),
                          },
                        ]}
                        label
                        labelPosition={0}
                        labelStyle={{
                          fontFamily: 'sans-serif',
                          fontSize: '25px',
                        }}
                        lengthAngle={360}
                        lineWidth={30}
                        totalValue={60}
                      />
                    </div>
                  )}
                  <div className='info'>
                    <span className='title'>Pick up time</span>
                    <span className='time'>{`${formatAMPM(
                      new Date(pickup)
                    )}`}</span>
                    <span className='day'>{`${new Date(
                      pickup
                    ).toLocaleDateString()}`}</span>
                  </div>
                </div>
                <div className='customer-block'>
                  <h2>Customer Profile</h2>
                  <h3>{`${fullName(orderDetail.patient)}`}</h3>
                  <div className='gender-info'>
                    <span className='gender'>
                      {showGender(orderDetail.patient.gender)}
                    </span>
                    <span
                      className="age">{`${orderDetail.patient?.ageString}`}</span>
                  </div>
                  <span className='tel'>
                    <i className='icon-cell'/>
                    {orderDetail.patient.phone}
                  </span>
                  <span className='mail'>
                    <i className='icon-email'/>
                    {orderDetail.patient.email}
                  </span>
                  <div className='last-order'>
                    <span className='title'>Last Order</span>
                    {orderDetail.customer.lastOrder && (
                      <span className='date'>
                        {`${new Date(
                          orderDetail.customer.lastOrder.created
                        ).toLocaleDateString()} ${formatAMPM(
                          new Date(orderDetail.customer.lastOrder.created)
                        )}`}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className='detail-col'>
                <div className='order-detail-block'>
                  <div className='head'>
                    <h2>Order Details</h2>
                    <a
                      className="link action icon-edit"
                      onClick={(e) => {
                        openEditTestsPanel()
                      }}
                    />
                  </div>
                  <ul className='test-list-detail'>
                    {formatScanTypes(orderDetail.scanTypes).map(scanType => (
                      <li key={scanType.id}>{scanTypeLabel(scanType)}</li>
                    ))}
                  </ul>

                  <div className='footer'>
                    <span className='title'>ORDER VALUE</span>
                    <span className='total'>PKR {orderDetail.givenValue}</span>
                  </div>
                </div>
                <div className='com-block'>
                  <div className='head'>
                    <h2>Additional Comments</h2>
                  </div>
                  <ul className='com-list'>
                    <li>
                      <p>
                        {orderDetail.additionalNotes
                          ? orderDetail.additionalNotes
                          : ' '}
                      </p>
                    </li>
                  </ul>
                </div>

                <div className={isEditTestsPanelOpen && "order-detail-popup-active"}>
                  {isEditTestsPanelOpen && orderDetail.prescriptionImage && <div className={prescriptionStyling}>
                    <div className="prescription-container">
                      <img className="prescription-image" src={orderDetail.prescriptionImage}></img>
                    </div>
                  </div>}
                  <div className='overlay-order-popup'></div>
                  <div className="order-detail-popup">
                    <div className="head">
                      <h2>Order Test Details</h2>
                      <a href="" className="close-popup" onClick={e => {
                        e.preventDefault();
                        closeEditTestsPanel();
                      }}><span className="icon-clear"></span></a>
                    </div>
                    <div className="form-row autocomplete">
                      <Autocomplete
                        type="text"
                        placeholder="Add medical test types"
                        getItemValue={item => item.label}
                        items={scanTypes}
                        renderItem={(item, isHighlighted) => (
                          scanTypes.length &&
                          <div key={item.id} style={{background: isHighlighted ? "lightgray" : "white"}}>
                            {item.label}
                          </div>
                        )}
                        value={test}
                        onChange={e => {
                          setTest(e.target.value);
                          searchOrderScanTypes(e.target.value);
                        }}
                        onSelect={(val, e) => {
                          if (!e.isAllowed) {
                            toast.error('This test isn\'t allowed in home sampling. Please ask the patient to visit the branch');
                            return;
                          }
                          setTests([
                            ...tests,
                            {
                              id: e.id,
                              name: e.name,
                              code: e.code,
                              value: +e.value,
                              index: tests.length,
                            }]);
                          setTest('');
                        }}
                      />
                    </div>
                    <ul className="test-list">
                      {tests && tests.map(scanType => (
                        <li key={`${scanType.id}${scanType.index}`}>
                          <a href="" className="delete-test" id={scanType.id} onClick={e => {
                            e.preventDefault();
                            setTests(tests.filter(t => t.index !== scanType.index));
                            setTest('');
                          }}>
                            <span className="icon-clear"/>
                          </a>
                          <span className="text">{scanType.name} {scanType.code}</span></li>)
                      )}
                    </ul>
                    <div className="form-row">
                      <label>Discount</label>
                      <div className="value">
                        <span className="rs">%</span>
                        <input
                          type="text"
                          name='discount'
                          placeholder="Order Discount"
                          value={discount}
                          onChange={e => {
                            const d = +e.target.value;
                            if (d >= 0 && d <= 100) {
                              setDiscount(+e.target.value)
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="total-price">
                      <span className="title">Total</span>
                      <span className="des">{value.toFixed(0)}</span>
                    </div>
                    <button
                      className="submit-btn"
                      onClick={() => {
                        orderScanTypes()
                      }}>
                      Save
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
      {isOpenOrderActivityModal && (
        <Modal
          isOpen={isOpenOrderActivityModal}
          onRequestClose={closeOrderActivityModal}>
          <OrderActivity
            orderDetail={orderDetail}
            orderActivities={orderActivities}
            getOrderActivities={getOrderActivities}
            resetOrderActivitiesState={resetOrderActivitiesState}
            isLoading={ordersLoading}
            closeModal={closeOrderActivityModal}
          />
        </Modal>
      )}
      {isOpenCancelOrderModal && (
        <Modal
          isOpen={isOpenCancelOrderModal}
          onRequestClose={closeCancelOrderModal}
          className='small-modal'>
          <OrderCancel
            orderId={orderDetail.id}
            orderCancel={orderCancel}
            closeModal={closeCancelOrderModal}
            cancellationStrings={cancellationStrings}
            isUnverified={true}
          />
        </Modal>
      )}
    </div>
  )
}
export default UnverifiedOrdersDetail
