import { apiCaller } from '../api-caller';
import {BASE_URL, ENDPOINTS, REQUEST_TYPES} from '../constants';
import * as caseConverter from 'change-object-case';
import {RIDER_STATE_MAP} from "../../utils/constants";

const formatRidersFilter = (filter = {})=>{
  let formattedFilter = filter;
  if (filter.regions){
    formattedFilter['regions'] = filter.regions.join(',');
  }
  if (filter?.cities){
    formattedFilter['cities'] = filter.cities.join(',');
  }
  if (filter.states){
    formattedFilter['states'] = filter.states.map(state => RIDER_STATE_MAP[state]).join(',');
  }
  return caseConverter.snakeKeys(formattedFilter);
};

export const getRidersAPI = (filter = {}) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.GET_RIDERS,
  params: formatRidersFilter({...filter}),
});

export const getNpRidersAPI = (filter) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.GET_NP_RIDERS,
  params: formatRidersFilter(filter),
});

export const getRidersSearchAPI = (filter) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.GET_RIDERS_SEARCH,
  params: filter,
});

export const getOnlineRidersAPI = (filter) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.GET_ONLINE_RIDERS}`,
  params: formatRidersFilter(filter)
});

export const getAssignableRiderAPI = ({orderId, vertical}) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.ALGORITHM}${vertical}/suggested-riders/${orderId}/`,
});

export const getRiderAPI = (id) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.GET_RIDER}${id}/`
});
export const getRiderStatsAPI = ({regionId}) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${BASE_URL}/stats/rider/?region_id=${regionId}`
});

export const getRiderLocationAPI = (id) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.GET_RIDER_LOCATION}${id}/`,
});

export const getRiderTasksAPI = ({riderId, vertical}) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.ALGORITHM}${vertical}/tasks/?rider_id=${riderId}`,
});

export const getRiderOrdersAPI = (params) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${BASE_URL}/orders/history/rider/`,
  params: caseConverter.snakeKeys(params),
});

export const getRiderForceDeliverAPI = (id) => apiCaller({
  method: REQUEST_TYPES.GET,
  url: `${ENDPOINTS.GET_RIDER_FORCE_DELIVER}${id}/`,
});

export const riderMarkOffDutyAPI = ({ id, data }) => apiCaller({
  method: REQUEST_TYPES.PATCH,
  url: `${ENDPOINTS.RIDER_MARK_OFF_DUTY}${id}/`,
  data
});

export const createRiderAPI = (data) => apiCaller({
  method: REQUEST_TYPES.POST,
  url: ENDPOINTS.CREATE_RIDER,
  data
});

export const updateRiderAPI = ({ id, data }) => apiCaller({
  method: REQUEST_TYPES.PUT,
  url: `${ENDPOINTS.UPDATE_RIDER}${id}/`,
  data
});
