import React, {useEffect} from 'react';
import {isEmpty} from 'lodash';
import * as Yup from 'yup';
import {DEFAULT_ORDER_PICKUP_TIME, FULFILLMENT_METHOD_TYPES, VERTICALS} from '../../utils/constants';
import {Formik} from 'formik';
import {add} from "date-fns";
import {yyyyMMdd} from '../../utils/helper';
import XRayOrderCreateWrapper from "./xray-order-create-wrapper";

const XRayOrderCreate = (props) => {
  const {
    customerIds,
    customersLoading,
    getCustomersId,
    getCustomerDetail,
    customerDetail,
    history,
    resetCustomerState,
    scanTypes,
    getOrderScanTypes,
    scanTypesLoading,
    resetScanTypesState,
    cities,
    createCustomerAddress,
    createCustomerPatient,
    updateCustomerPatient,
    deleteCustomerPatient,
    createCustomer,
    createOrder,
    ordersLoading,
    xrayOrderParams,
    deleteCustomerAddress,
    hearAboutUsList,
    getHearAboutUsList,
    getOrderTimeSlots,
    resetOrderTimeSlotsState,
    orderTimeSlots,
    resetCopyOrder,
    copiedOrder,
    getCities,
    regions,
    getOrderSources,
    orderSources,
  } = props;

  useEffect(() => {
    getCities({vertical: VERTICALS.XRAY});
  }, []);

  const onDiscard = (e) => {
    e.preventDefault();
    resetScanTypesState();
    resetOrderTimeSlotsState();
    resetCopyOrder();
    history.goBack();
  };

  const orderInitialValues = {
    patient: '',
    value: '',
    discount: '',
    additionalNotes: '',
    pickupDate: add(new Date(), {minutes: DEFAULT_ORDER_PICKUP_TIME}).toString(),
    pickup: '',
    timeSlot: '',
    scanType: [],
    urgentDelivery: false,
    customerAddress: '',
    city: '',
    hearAbout: null,
    fulfillmentMethod: FULFILLMENT_METHOD_TYPES.ONLINE,
    orderSource: null,
  };

  return <div id='wrapper'>
    <Formik
      initialValues={orderInitialValues}
      validationSchema={Yup.object().shape({
        fulfillmentMethod: Yup.number().required('Please select order type'),
        patient: Yup.number().required(),
        discount: Yup.number()
          .min(0, 'Order discount can not be less than 0')
          .max(100, 'Order discount be less than or equal to 100')
          .typeError('Invalid order discount'),
        value: Yup.number().min(0, 'Order value can not be less than 0').typeError('Invalid order value'),
        pickup: Yup.date().required(),
        timeSlot: Yup.number().required(),
        additionalNotes: Yup.string().max(1000, 'Additional notes must be at most 1000 characters'),
        scanType: Yup.array().of(Yup.number()).min(1, 'Select Test Type').required(),
        orderSource: Yup.number().required('Please select order source'),
      })}
      onSubmit={(values) => {
        const data = {...values};
        if (!(data.value > 0)) {
          delete data.value;
        }
        // change param name pickup to date
        data['date'] = data['pickup'];
        delete data['pickup'];
        delete data['pickupDate'];
        delete data['discount'];
        delete data['city'];
        if (!isEmpty(copiedOrder)) {
          data['sourceOrder'] = copiedOrder.sourceOrder
        }
        createOrder({
          orderParams: xrayOrderParams,
          data: {
            ...data,
            orderType: 1,
            customer: customerDetail.id,
            date: yyyyMMdd(data.date),
            vertical: VERTICALS.xray,
          }
        });
      }}
    >
      {orderFormik => (
        <XRayOrderCreateWrapper
          orderFormik={orderFormik}
          customerIds={customerIds}
          customersLoading={customersLoading}
          getCustomersId={getCustomersId}
          getCustomerDetail={getCustomerDetail}
          customerDetail={customerDetail}
          history={history}
          resetCustomerState={resetCustomerState}
          createCustomerPatient={createCustomerPatient}
          updateCustomerPatient={updateCustomerPatient}
          deleteCustomerPatient={deleteCustomerPatient}
          onDiscard={onDiscard}
          createCustomer={createCustomer}
          ordersLoading={ordersLoading}
          orderInitialValues={orderInitialValues}
          copiedOrder={copiedOrder}
          createCustomerAddress={createCustomerAddress}
          cities={cities}
          deleteCustomerAddress={deleteCustomerAddress}
          scanTypes={scanTypes}
          getOrderScanTypes={getOrderScanTypes}
          scanTypesLoading={scanTypesLoading}
          resetScanTypesState={resetScanTypesState}
          hearAboutUsList={hearAboutUsList}
          getHearAboutUsList={getHearAboutUsList}
          getOrderTimeSlots={getOrderTimeSlots}
          resetOrderTimeSlotsState={resetOrderTimeSlotsState}
          orderTimeSlots={orderTimeSlots}
          regions={regions}
          getOrderSources={getOrderSources}
          orderSources={orderSources}
        >
        </XRayOrderCreateWrapper>
      )}
    </Formik>
  </div>


};
export default XRayOrderCreate
