import qs from 'qs';
import * as _ from 'lodash';
import { format, formatDistanceToNow } from "date-fns";
import React from 'react';
import moment from 'moment';
import { MAX_FOLLOW_UP_COUNT, RIDER_STATE, SORTING, USER, USER_TYPE } from './constants';

const hasLocalStorage = () => {
  const uid = new Date();
  let result;
  try {
    localStorage.setItem(uid, uid);
    // need to allow truthy here; no strict equal please
    result = localStorage.getItem(uid) == uid;
    localStorage.removeItem(uid);
    return result;
  } catch (exception) {
    // function will have returned false
    return false;
  }
};

export const setSessionStorageObject = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionStorageObject = key => {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

// no warning
const setLocalStorageItem = (key, val) => {
  if (hasLocalStorage()) {
    localStorage.setItem(key, val);
  }
};

export const setLocalStorageObject = (key, val) => {
  if (hasLocalStorage()) {
    localStorage.setItem(key, JSON.stringify(val));
  }
};
const removeLocalStorageItem = key => {
  if (hasLocalStorage()) {
    localStorage.removeItem(key);
  }
};

export const removeSessionStorageItem = key => {
  sessionStorage.removeItem(key);
};

const getLocalStorageItem = key => {
  if (hasLocalStorage()) {
    return localStorage.getItem(key);
  }
};

export const getLocalStorageObject = key => {
  if (hasLocalStorage()) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      return null;
    }
  }
};

export const setLocalStorageBoolean = (key, val) => {
  if (hasLocalStorage()) {
    localStorage.setItem(key, val);
  }
};

export const getLocalStorageBoolean = key => {
  if (hasLocalStorage()) {
    const value = localStorage.getItem(key);
    return value ? value.toLowerCase() == 'true' : null;
  }
};

const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};
const diffMinutes = (dt2, dt1) => {

  const diff = Math.abs(new Date(dt2) - new Date(dt1));
  const minutes = Math.floor((diff / 1000) / 60);
  if (minutes > 60) return 60;
  else return minutes;

};

export const yyyyMMddhhmmaaaa = (dateString) => {
  return dateString ? format(new Date(dateString), 'yyyy-MM-dd hh:mm aaaa') : '';
};

export const ddMMMyyyy = (dateString) => {
  return dateString ? format(new Date(dateString), 'dd MMM yyyy') : '';
};

export const yyyyMMdd = (dateString) => {
  return dateString ? format(new Date(dateString), 'yyyy-MM-dd') : '';
};

const ddMMMyyyhhmmaaa = (dateString) => {
  return dateString ? format(new Date(dateString), 'dd MMM yyyy hh:mm aaaa') : '';
};

export const hmmaaa = (dateString) => {
  return dateString ? format(new Date(dateString), 'h:mm aaaa') : '';
};

export const HHmm = (dateString) => {
  return dateString ? format(new Date(dateString), 'HH:mm') : '';
};

export const pDateFormat = (dateString) => {
  return dateString ? format(new Date(dateString), 'p') : '';
};

export const ago = (dateString) => {
  return dateString ? formatDistanceToNow(new Date(dateString), { addSuffix: true }) : '';
};

export const scanTypeLabel = (scanType) => {
  return `${scanType.code} - ${scanType.name} | (PKR ${scanType.value})`;
};

const assignOrderClass = (status) => {
  switch (status) {
    case 'scheduled':
      return 'tag scheduled';
    case 'delivered':
      return 'tag delivered';
    case 'assigned':
      return 'tag assigned';
    case 'collected':
      return 'tag collected';
    case 'need_assignment':
      return 'tag need-assignment';
    case 'problem':
      return 'tag problem';
    default:
      return 'tag scheduled';
  }
};

export const riderStateClass = (state) => {
  switch (+state) {
    case RIDER_STATE.IDLE:
      return 'idle';
    case RIDER_STATE.PICKING_UP_HAS_CAPACITY:
      return 'scheduled';
    case RIDER_STATE.PICKING_UP_FULL:
      return 'problem';
    case RIDER_STATE.DELIVERING:
      return 'not-work';
    case RIDER_STATE.BREAK:
      return 'break';
    default:
      return 'idle';
  }
};

export const getTaskType = (pickup) => {
  switch (pickup) {
    case 0:
      return 'Pickup';
    case 1:
      return 'Delivery';
    case 2:
      return 'Break';
    default:
      return '';
  }
};

export const convertMetersToKm = (meter) => {
  if (meter === '' || meter === null) {
    return 'N/A';
  }
  return (+meter / 1000).toFixed(1);
};

export const getRiderState = (state) => {
  switch (+state) {
    case RIDER_STATE.IDLE:
      return 'Idle';
    case RIDER_STATE.PICKING_UP_HAS_CAPACITY:
      return 'Picking up has capacity';
    case RIDER_STATE.PICKING_UP_FULL:
      return 'Picking up full';
    case RIDER_STATE.DELIVERING:
      return 'Delivering';
    case RIDER_STATE.BREAK:
      return 'Break';
    default:
      return '';
  }
};

export const getUserType = (userType) => {
  switch (userType) {
    case USER_TYPE.RIDER:
      return 'Rider';
    case USER_TYPE.CUSTOMER:
      return 'Customer';
    case USER_TYPE.STAFF:
      return 'Staff';
    default:
      return '';
  }
};

export const getRiderType = (rider) => {
  let type = '';
  if (rider.isWildcard) {
    type += 'W |';
  }
  if (rider.isCovid) {
    type += 'C |';
  }
  if (!rider.isWildcard && !rider.isCovid) {
    type += 'R |';
  }

  return ` ${type} `;
};

const scanTypesCount = (scanTypes) => {
  let testCount = 0;
  for (const { count = 0 } of scanTypes) {
    testCount += count;
  }
  return testCount;
};

const formatScanTypes = (scanTypesWithCount) => {
  let formattedScanTypes = [];
  for (const { count = 0, scanType } of scanTypesWithCount) {
    for (let i = 0; i < count; i++) {
      formattedScanTypes.push(scanType);
    }
  }
  return formattedScanTypes;
};

const getGenderTitle = (gender) => {
  switch (gender) {
    case 1:
      return 'Male';
    case 2:
      return 'Female';
    case 3:
      return 'Don\'t want to disclose';
    default:
      return 'N/A';
  }
};

const getUserCity = (addresses, cities) => {
  let uniqueCityIds = Array.from(new Set(addresses.map((item) => item.city)));
  uniqueCityIds = uniqueCityIds.filter(n => n);
  if (!uniqueCityIds.length) {
    return;
  }
  const userCities = cities.filter((o => o.id));
  return userCities.map((o) => o.name).join(',');
};

const qsParse = (s) => {
  return qs.parse(s, { encode: false });
};

const qsStringify = (o) => {
  return qs.stringify(o, { encode: false });
};

const parseQueryStringObjectToArray = (source) => {
  return Array.isArray(source) ? source : Object.values(source);
};

export const formatFilterDates = (filter) => {
  if (filter.pickupFrom) {
    filter.pickupFrom = format((typeof filter.pickupFrom === 'string') ? new Date(filter.pickupFrom) : filter.pickupFrom, 'yyyy-MM-dd');
  }
  if (filter.pickupTo) {
    filter.pickupTo = format((typeof filter.pickupTo === 'string') ? new Date(filter.pickupTo) : filter.pickupTo, 'yyyy-MM-dd');
  }
  return filter;
};

export const fullName = (o) => {
  return `${o?.firstName} ${o?.lastName}`;
};
export const isAge = (value) => {
  return !(value === null || value === undefined || value === '');
};

export const makeCopiedOrder = (order) => {
  return {
    sourceOrder: order.id,
    phone: order.customer.phone,
    customerId: order.customer.id,
    patientId: order.patient.id,
    additionalNotes: order.additionalNotes,
    urgentDelivery: order.urgentDelivery,
    scanTypes: formatScanTypes(order.scanTypes).map((item, index) => {
      return {
        id: item.id,
        name: item.name,
        code: item.code,
        index: index - 1,
        value: +item.value,
        isCovid: item.isCovid
      };
    }),
  };
};

export const disableDiv = (isDisable) => {
  if (isDisable) {
    return { pointerEvents: "none", opacity: "0.5" };
  }
  return {};
};

export const getNextSortingState = (currentState) => {
  switch (+currentState) {
    case SORTING.NONE:
      return SORTING.ASCENDING;
    case SORTING.ASCENDING:
      return SORTING.DESCENDING;
    case SORTING.DESCENDING:
      return SORTING.NONE;
    default:
      return currentState;
  }
};

export const getSortingClass = (currentState) => {
  switch (+currentState) {
    case SORTING.NONE:
      return 'icon-sort';
    case SORTING.ASCENDING:
      return 'icon-arrow-up';
    case SORTING.DESCENDING:
      return 'icon-arrow-down';
    default:
      return '';
  }
};

export const clearOtherSortingParams = (sorting, currentState) => {
  // sorting on one column at one time
  for (const [key, value] of Object.entries(sorting)) {
    if (key != currentState) {
      sorting[key] = SORTING.NONE;
    }
  }
  return sorting;
};

export const isNoSortingParams = (sorting) => {
  for (const [key, value] of Object.entries(sorting)) {
    if (value != SORTING.NONE) {
      return false;
    }
  }
  return true;
};

export const secondsToMinutes = (seconds) => {
  seconds = seconds * -1;
  return (seconds / 60).toFixed();
};

export const hasPermission = (premission) => {
  const user = getLocalStorageObject(USER) || {};
  return _.some(user.userPermissions, { 'name': premission });
};

export const getWeekdayName = (weekday) => {
  return {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
  }[weekday];
};

export const getWeekdayNumber = (weekday) => {
  let day = (new Date()).getDay() - 1;
  return day === -1 ? 6 : day;
};

export const getDateFromTime = (timeString) => {
  if (!timeString) {
    return null;
  }
  const now = new Date();
  now.setHours(timeString.substring(0, 2), timeString.substring(3, 5), timeString.substring(6, 8));
  return now;
};

export const dateTimeDifference = (startTime, endTime) => {
  const start = startTime ? moment(startTime) : moment();
  const end = endTime ? moment(endTime) : moment();
  const duration = moment.duration(end.diff(start));
  return duration.asHours() > 1;
};

export const userFacingDistanceTravelled = distance => `${distance} km`;

export const getRegionIcon = (cityName, isWhite) => {
  try {
    const iconName = !isWhite ? cityName : `${cityName}-dark`;
    try {
      return require(`../assets/images/${iconName}.svg`);
    } catch (e) {
      return require(`../assets/images/${iconName}.png`);
    }
  } catch (e) {
    const testIcon = !isWhite ? 'test-dark' : 'test';
    return require(`../assets/images/${testIcon}.svg`);
  }
};

export const isCustomerFrustrated = followUpCount => {
  return (followUpCount / MAX_FOLLOW_UP_COUNT * 100) > 20;
};

export {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
  formatAMPM,
  diffMinutes,
  ddMMMyyyhhmmaaa,
  assignOrderClass,
  scanTypesCount,
  formatScanTypes,
  getGenderTitle,
  getUserCity,
  qsParse,
  qsStringify,
  parseQueryStringObjectToArray,
};
