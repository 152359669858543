const selectCustomerFromState = state => state.customer;

export const selectCustomersListFromState = state => selectCustomerFromState(state).list;
export const selectCustomersCountFromState = state => selectCustomerFromState(state).count;
export const selectCustomersListLoadingFromState = state => selectCustomerFromState(state).loading;
export const selectCustomersNextFromState = state => selectCustomerFromState(state).next;
export const selectCustomerParamsFromState = state => selectCustomerFromState(state).params;

export const selectCustomerOrdersListFromState = state => selectCustomerFromState(state).order.list;
export const selectCustomerOrdersNextFromState = state => selectCustomerFromState(state).order.next;
export const selectCustomerOrdersLoadingFromState = state => selectCustomerFromState(state).order.loading;

export const selectCustomerAddressFromState = state => selectCustomerFromState(state).customerAddress;
export const selectCustomerIdsFromState = state => selectCustomerFromState(state).ids;
export const selectCustomerDetailFromState = state => selectCustomerFromState(state).customerDetail;
