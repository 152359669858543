import React from 'react';
import { fullName, isAge } from '../../utils/helper';

const Patients = ({ patients, onPatientClick, orderFormik, selectedPatient, setSelectedPatient }) => {
  return <ul className="patient-list">
    {patients && patients.map((patient, index) => {
      const { id, ageWithUnit, cardNumber, age, ageString } = patient;
      return <li
        key={id}
        className={selectedPatient.id === patient.id ? 'selected' : ''}
        onClick={(e)=> {
          e.preventDefault();
          onPatientClick(patient);
        }}>
        <span className="name">{fullName(patient)}</span>
        <span className="age">{`${age || ageString}`}</span>
        <span className="card-number">{cardNumber || "N/A"}</span>
        <img src={require("../../assets/images/man.png")}/>
      </li>
    })}
  </ul>
};

export default Patients;
