import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { auth } from './auth';
import { rider } from './rider';
import { order } from './order';
import { customer } from './customer';
import { location } from './location';
import { feedback } from './feedback';
import { shift } from './shift';
import { attendance } from './attendance';

export const rootReducer = history => combineReducers({
  router: connectRouter(history),
  auth,
  rider,
  order,
  customer,
  location,
  feedback,
  shift,
  attendance,
});
