import React from 'react';
import { fullName, secondsToMinutes } from '../../utils/helper';

export const OrdersList = ({ list, history }) => {
  return <ul className="dashbord-delay-list">
    {list.map((item, index) =>
      <li key={item.id}>
        <span className="id-no"
              style={{cursor: 'pointer'}}
              onClick={() => history.push(`/orders/detail/${item.id}`)}
        >{item.number}</span>
        <span className="name"
              style={{cursor: 'pointer'}}
              onClick={() => item.assignedRider && history.push(`/riders/detail/${item.assignedRider.id}`)}
        >{item.assignedRider ? fullName(item.assignedRider) : '-'}</span>
        <div className="delay-holder">
          <span className="by">Delayed by</span>
          <span className="time">{secondsToMinutes(item.lateTimedelta)} mins</span>
        </div>
      </li>
    )}
  </ul>
};
