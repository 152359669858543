import React from 'react';
import PropTypes from 'prop-types';

import {Bar} from 'react-chartjs-2';


export const BarChart = ({
  data,
}) => {
  return (
    <Bar
      data={ data }
      options={ {
        legend: { display: false },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
      } }
    />

  );
};

BarChart.propTypes = {
  fetchGraph: PropTypes.func,
  data: PropTypes.array,
  filters: PropTypes.object,
};
