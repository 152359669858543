import React, {useEffect} from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {Provider as StoreProvider} from 'react-redux';
import {configureStore, history} from 'store';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Routes from '../../routes';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CacheBuster from 'react-cache-buster';
import {version} from '../../../package.json';
import {Loading} from "../commons";


export const {store} = configureStore({});

export const App = () => {
  const isProduction = process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION';

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading/>} //If not pass, nothing appears at the time of new version check.
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StoreProvider
          store={store}
        >
          <ToastContainer/>
          <ConnectedRouter history={history}>
            <Routes/>
          </ConnectedRouter>
        </StoreProvider>
      </MuiPickersUtilsProvider>
    </CacheBuster>
  )
};
