import {
  call,
  put,
} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {logger} from 'utils/logger';
import {riderActions} from '../../actions/rider';
import {
  getRidersAPI,
  getRiderAPI,
  getOnlineRidersAPI,
  getRiderStatsAPI,
  getRiderLocationAPI,
  getRiderTasksAPI,
  getAssignableRiderAPI, getNpRidersAPI, getRiderOrdersAPI,
  getRiderForceDeliverAPI,
  riderMarkOffDutyAPI, createRiderAPI, updateRiderAPI, getRidersSearchAPI,
} from '../../api/rider';
import {RIDER_OFF_DUTY_API} from '../../utils/constants';
import {qsStringify} from '../../utils/helper';
import {push} from "connected-react-router";
import {ROUTES} from '../../routes/constants';

export function* getRiders({payload: values}) {
  try {
    const resp = yield call(getRidersAPI, values);
    if (resp) {
      yield put(riderActions.getRidersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getRidersFailure(error));
  }
}

export function* getFilteredRiders({payload: values}) {
  try {
    const resp = yield call(getRidersAPI, values);
    if (resp) {
      yield put(riderActions.getFilteredRidersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getFilteredRidersFailure(error));
  }
}

export function* getNpRiders({payload}) {
  try {
    const resp = yield call(getNpRidersAPI, payload);
    if (resp) {
      yield put(riderActions.getNPRidersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getNPRidersFailure(error));
  }
}

export function* getRidersSearch({payload: values}) {
  try {
    const resp = yield call(getRidersSearchAPI, values);
    if (resp) {
      yield put(riderActions.getRidersSearchSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getRidersSearchFailure(error));
  }
}

export function* getOnlineRiders({payload: values}) {
  try {
    const resp = yield call(getOnlineRidersAPI, values);
    if (resp) {
      yield put(riderActions.getOnlineRidersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getOnlineRidersFailure(error));
  }
}

export function* getAssignableRider({payload}) {
  try {
    const resp = yield call(getAssignableRiderAPI, payload);
    if (resp) {
      yield put(riderActions.getAssignableRiderSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getAssignableRiderFailure(error));
  }
}

export function* getRider({payload: id}) {
  try {
    const resp = yield call(getRiderAPI, id);
    if (resp) {
      yield put(riderActions.getRiderSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getRiderFailure(error));
  }
}

export function* getRiderForceDeliver({payload: id}) {
  try {
    const resp = yield call(getRiderForceDeliverAPI, id);
    if (resp) {
      toast.success(`Rider orders force delivered successfully`);
      yield put(riderActions.getRiderForceDeliverSuccess(resp.data));
      yield put(riderActions.getFilteredRiderOrdersRequest({riderId: id}));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getRiderForceDeliverFailure(error));
  }
}

export function* getRiderStats({payload: value}) {
  try {
    const resp = yield call(getRiderStatsAPI, value);
    if (resp) {
      yield put(riderActions.getRiderStatsSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getRiderStatsFailure(error));
  }
}

export function* getRiderLocation({payload: id}) {
  try {
    const resp = yield call(getRiderLocationAPI, id);
    if (resp) {
      yield put(riderActions.getRiderLocationSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getRiderLocationFailure(error));
  }
}

export function* getRiderTasks({payload}) {
  try {
    const resp = yield call(getRiderTasksAPI, payload);
    if (resp) {
      yield put(riderActions.getRiderTasksSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getRiderTasksFailure(error));
  }
}

export function* getFilteredRiderOrders({payload: values}) {
  try {
    const resp = yield call(getRiderOrdersAPI, values);
    if (resp) {
      yield put(riderActions.getFilteredRiderOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getFilteredRiderOrdersFailure(error));
  }
}

export function* getRiderOrders({payload: values}) {
  try {
    const resp = yield call(getRiderOrdersAPI, values);
    if (resp) {
      yield put(riderActions.getRiderOrdersSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.getRiderOrdersFailure(error));
  }
}

export function* getRiderMarkOffDuty({payload: values}) {
  try {
    const resp = yield call(riderMarkOffDutyAPI, values);
    if (resp) {
      const {action = ''} = values;
      if (action === RIDER_OFF_DUTY_API.OFF_DUTY) {
        toast.success(`Rider marked ${values.data.isWildcard ? 'on' : 'off'} duty successfully`);
      } else if (action === RIDER_OFF_DUTY_API.WILDCARD) {
        toast.success(`Rider marked ${values.data.isWildcard ? 'wildcard' : 'un-wildcard'} successfully`);
      } else if (action === RIDER_OFF_DUTY_API.COVID) {
        toast.success(`Rider marked ${values.data.isCovid ? 'covid' : 'un-covid'} successfully`);
      }
      yield put(riderActions.riderMarkOffDutySuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.riderMarkOffDutyFailure(error));
  }
}

export function* createRider({payload}) {
  try {
    const resp = yield call(createRiderAPI, payload.data);
    if (resp) {
      toast.success(`Rider created successfully`);
      yield put(riderActions.createRiderSuccess(resp.data));
      const riderParamsPath = qsStringify(payload.riderParams);
      yield put(push({
        pathname: ROUTES.RIDERS,
        search: '?' + riderParamsPath
      }));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.createRiderFailure(error));
  }
}

export function* updateRider({payload: values}) {
  try {
    const resp = yield call(updateRiderAPI, values);
    if (resp) {
      toast.success(`Rider updated successfully`);
      yield put(riderActions.updateRiderSuccess(resp.data));
    }
  } catch (error) {
    logger.error(error);
    yield put(riderActions.updateRiderFailure(error));
  }
}
