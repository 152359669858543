import React from 'react';
import {Link} from "react-router-dom";
import {ROUTES} from "../../routes/constants";
import {selectRiderParamsFromState} from "../../selectors/rider";
import {connect} from "react-redux";
import {isEmpty} from "lodash";
import {qsStringify} from "../../utils/helper";

export const RidersSummary = ({ stats, riderParams }) => {
    const { available, busy } = stats;
    let riderParamsPath = '';
    if (isEmpty(riderParams)) {
      riderParamsPath += qsStringify({
        ordering: '-created'
      });
    } else {
      riderParamsPath += qsStringify({
        ...riderParams,
        ordering: '-created'
      });
    }
    return <div className="dashbord-rider-panel">
        <div className="head">
            <span className="title">Riders (Shift in Progress) </span>
            <Link to={{pathname:ROUTES.RIDERS, search: '?'+ riderParamsPath}} className="view-more">View all</Link>
        </div>
        <ul className="rider-status">
            <li>
                <img src={require("../../images/bike-avaiable.svg")} alt=""/>
                <span className="numbering">{available} <span>Available</span></span>
            </li>
            <li className="busy">
                <img src={require("../../images/bike-busy.svg")} alt=""/>
                <span className="numbering">{busy} <span>Busy</span></span>
            </li>
        </ul>
    </div>
};

const mapStateToProps = state => ({
  riderParams: selectRiderParamsFromState(state)
});

export default connect(mapStateToProps, null)(RidersSummary);
