import {FEEDBACK_QUESTION_TYPES} from "./constants";
import * as Yup from "yup";

export const getInitialValuesForFeedbackForm = questions => {
  const initialValues = {}
  questions.map(question => {
    initialValues[question.id] = question.type === FEEDBACK_QUESTION_TYPES.STAR_RATING ? 0 : ''
  })
  return initialValues
}

export const getValidationSchemaForPhysicalVisitReview = questions => {
  const validationSchema = {}
  questions.map(question => {
    let schema = question.type === FEEDBACK_QUESTION_TYPES.STAR_RATING ? Yup.number().min(1, 'Please fill out this field') : Yup.string().max(1000)
    if (question.isMandatory) {
      schema = schema.required()
    }
    validationSchema[question.id] = schema
  })
  return Yup.object().shape(validationSchema)
}

export const getStarQuestions = questions => questions.filter(question => question.type === FEEDBACK_QUESTION_TYPES.STAR_RATING)

export const getTextQuestions = questions => questions.filter(question => question.type === FEEDBACK_QUESTION_TYPES.LONG_FORM_TEXT)

export const formatFeedbackResponse = data => {
  let response = []
  for (const [key, value] of Object.entries(data)) {
    response.push({question: key, answer: value})
  }
  return response
}
