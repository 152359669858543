import NewOrderSection from './new-order-section';
import OrderCustomerInformation from './OrderCustomerInformation';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import CreateAddressForm from './form/create-address';
import Autocomplete from 'react-autocomplete';
import { Loader } from '../commons/loader';
import { Field } from 'formik';
import { add } from "date-fns";
import {
  DEFAULT_ORDER_PICKUP_TIME,
  FULFILLMENT_METHOD_TYPES,
  MAX_ORDER_PICKUP_DAY, VERTICALS
} from '../../utils/constants';
import { DateField } from '../ui/form-fields/date-form-field';
import { pDateFormat, yyyyMMdd } from '../../utils/helper';
import HearAboutUs from './hear-about-us';
import { toast } from 'react-toastify';
import { default as _, isEmpty } from 'lodash';
import DeleteCustomerAddress from "./DeleteCustomerAddress";
import yesImage from "../../assets/images/yes.svg";
import noImage from "../../assets/images/no.svg";

const BloodOrderCreateWrapper = (props) => {
  const {
    orderFormik,
    customerIds,
    getCustomersId,
    getCustomerDetail,
    customerDetail,
    resetCustomerState,
    onDiscard,
    createCustomerPatient,
    updateCustomerPatient,
    deleteCustomerPatient,
    createCustomer,
    ordersLoading,
    customersLoading,
    orderInitialValues,
    createCustomerAddress,
    cities,
    deleteCustomerAddress,
    scanTypes,
    getOrderScanTypes,
    scanTypesLoading,
    resetScanTypesState,
    hearAboutUsList,
    getHearAboutUsList,
    getOrderTimeSlots,
    resetOrderTimeSlotsState,
    orderTimeSlots,
    copiedOrder,
    history,
    regions,
    getOrderSources,
    orderSources,
    order,
    id
  } = props;

  const [selectedPatient, setSelectedPatient] = useState({});
  const [isOpenCreateAddressModal, setIsOpenCreateAddressModal] = React.useState(false);
  const [isOpenHearAboutUsModal, setIsOpenHearAboutUsModal] = React.useState(false);
  const [test, setTest] = useState('');
  const [tests, setTests] = useState([]);
  const [orderTimeSlot, setOrderTimeSlot] = useState('');
  const [isCovid, setIsCovid] = useState(false);
  const [isOpenDeleteCustomerAddressModal, setIsOpenDeleteCustomerAddressModal] = React.useState(false);
  const [customerAddressId, setCustomerAddressId] = useState(null);

  const testsTotal = _.sumBy(tests, (t) => t.value);
  const value = testsTotal - ((+orderFormik.values.discount * testsTotal) / 100);

  const onCreateOrder = async (e) => {
    if (!selectedPatient.id) {
      toast.error('Select Patient');
      return;
    }
    orderFormik.setFieldValue("patient", selectedPatient.id);
    if (!orderFormik.values.customerAddress) {
      toast.error('Create or select address');
      return;
    }
    if (!tests.length) {
      toast.error('Select test type');
      return;
    }
    if (!orderTimeSlot) {
      toast.error('Select pickup time');
      return;
    }
    const timeSlot = orderTimeSlots.find((ots => ots.id == orderTimeSlot));
    if (!timeSlot) {
      setOrderTimeSlot('');
      return;
    }
    const pickup = timeSlot.dateTime;
    orderFormik.setFieldValue('scanType', tests.map(test => test.code));
    orderFormik.setFieldValue('pickup', pickup);
    orderFormik.setFieldValue('timeSlot', timeSlot.id);
    orderFormik.setFieldValue('value', value);
    await orderFormik.validateForm();
    if (orderFormik.errors.fulfillmentMethod) {
      return toast.error('Please select order type');
    }
    if (orderFormik.errors.discount) {
      return toast.error(orderFormik.errors.discount);
    }
    if (orderFormik.errors.value) {
      return toast.error(orderFormik.errors.value);
    }
    if (orderFormik.errors.additionalNotes) {
      return toast.error(orderFormik.errors.additionalNotes);
    }
    if (customerDetail.hasFilledHearAboutUs) {
      await orderFormik.submitForm();
    } else {
      setIsOpenHearAboutUsModal(true);
    }
    if (orderFormik.errors.orderSource) {
      return toast.error('Please select order source');
    }
  };

  const confirmOrder = (hearAboutUsId) => {
    orderFormik.values.hearAbout = hearAboutUsId;
    setIsOpenHearAboutUsModal(false);
    orderFormik.submitForm();
  };

  const closeHearAboutUsModal = () => {
    setIsOpenHearAboutUsModal(false);
  };

  const searchOrderScanTypes = (test) => {
    if (test.length === 0) {
      resetScanTypesState();
      return;
    }
    getOrderScanTypes({ search: test, vertical: VERTICALS.BLOOD });
  };

  useEffect(() => {
    if (!isEmpty(copiedOrder) && copiedOrder.customerId === customerDetail.id) {
      setTest('');
      setTests(copiedOrder.scanTypes);
      orderFormik.setFieldValue('urgentDelivery', copiedOrder.urgentDelivery);
      orderFormik.setFieldValue('additionalNotes', copiedOrder.additionalNotes ? copiedOrder.additionalNotes : '');
    }
  }, [customerDetail.id]);

  useEffect(() => {
    setOrderTimeSlot('');
    resetOrderTimeSlotsState();
    if (!orderFormik.values.pickupDate || !orderFormik.values.region) {
      return;
    }
    orderFormik.setFieldValue('pickup', '');
    getOrderTimeSlots({
      date: yyyyMMdd(orderFormik.values.pickupDate),
      regionId: orderFormik.values.region,
      isCovid,
      vertical: VERTICALS.blood,
      customerAddressId: orderFormik.values.customerAddress,
    });
  }, [orderFormik.values.region, orderFormik.values.pickupDate, orderFormik.values.customerAddress, isCovid]);

  useEffect(() => {
    setIsCovid(tests.some(t => t.isCovid));
  }, [tests]);

  useEffect(() => {
    getOrderSources();
  }, []);

  const handleOnOrderTimeSlotChange = (e) => {
    setOrderTimeSlot(e.target.value);
  };


  const openModal = (e) => {
    e.preventDefault();
    if (!customerDetail.id) {
      toast.error("Create or Select Customer");
      return;
    }
    setIsOpenCreateAddressModal(true);
  };

  const closeModal = (lat, lng) => {
    setIsOpenCreateAddressModal(false);
  };

  const closeDeleteCustomerAddressModal = () => {
    setIsOpenDeleteCustomerAddressModal(false);
  };

  const handleCustomerAddressDeletion = customerAddressId => {
    deleteCustomerAddress({ customerAddressId });
    closeDeleteCustomerAddressModal();
  };

  return <>
    <div className="order-page">
      <NewOrderSection id={id} order={order} />
      <div className="order-right-info">
        <div className="head">
          <ul className="btn-list">
            <li>
              <button className="red" onClick={onDiscard}>Discard</button>
            </li>
            <li>
              <button className="yellow" onClick={onCreateOrder}
                disabled={orderFormik.isValidating || ordersLoading}>Create Order
              </button>
            </li>
          </ul>
        </div>
        <OrderCustomerInformation
          id={id}
          order={order}
          selectedPatient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
          orderFormik={orderFormik}
          customerIds={customerIds}
          customersLoading={customersLoading}
          getCustomersId={getCustomersId}
          getCustomerDetail={getCustomerDetail}
          customerDetail={customerDetail}
          history={history}
          resetCustomerState={resetCustomerState}
          createCustomerPatient={createCustomerPatient}
          updateCustomerPatient={updateCustomerPatient}
          deleteCustomerPatient={deleteCustomerPatient}
          createCustomer={createCustomer}
          ordersLoading={ordersLoading}
          orderInitialValues={orderInitialValues}
          copiedOrder={copiedOrder}
          vertical={VERTICALS.BLOOD}
        />
      </div>
    </div>

    <div className="order-page">
      <div className="order-aside step-two">
        <div className="head">
          <img src={require('../../assets/images/s-img.svg')} alt="no test" /> <h2>New Order</h2>
        </div>
        <div>
          <div className="col-info order-col">
            <h2>Location</h2>
            <div className="form-row">
              <label>Region*</label>
              <div className="value select">
                <select
                  name="region"
                  value={orderFormik.values.region}
                  disabled={orderFormik.values.region && orderFormik.values.customerAddress}
                  style={{ display: 'block' }}
                  onChange={(e) => {
                    orderFormik.setFieldValue('region', e.target.value);
                    orderFormik.setFieldValue('customerAddress', '');
                  }}
                >
                  <option value="" label='Select region' />
                  {
                    regions.map(region => {
                      return <option
                        key={region.id}
                        value={region.id}
                        label={region.name}
                      />;
                    })
                  }
                </select>
              </div>
            </div>

            <div className="order-address">
              <h2>Address</h2>
              <ul className="order-address-list">
                {
                  customerDetail.addresses.map((address) => {
                    return <li key={address.id} id={address.id}
                      className={address.id === parseInt(orderFormik.values.customerAddress) ? 'current' : ''}
                      onClick={(e) => {
                        orderFormik.setFieldValue('customerAddress', e.target.id);
                        orderFormik.setFieldValue('region', address.regionCustomerAddresses && address.regionCustomerAddresses[0].region.id);
                      }}>
                      <div className='address-badge'>
                        <img src={address.verified ? yesImage : noImage} />
                        <div className='address-text'>
                          <b>{address.regionCustomerAddresses && address.regionCustomerAddresses[0].region.name}</b>: {address.address}
                        </div>
                      </div>
                      <a href=''
                        className="cancel icon-clear"
                        onClick={(e) => {
                          e.preventDefault();
                          setCustomerAddressId(address.id);
                          setIsOpenDeleteCustomerAddressModal(true);
                        }}
                      ></a>
                    </li>;
                  })
                }
                {customersLoading ? <Loader /> : <></>}
              </ul>
              <a className="add-address" onClick={(e) => openModal(e)}>Add New Address</a>
              <div id="wrapper" className="inner-page">

                <Modal
                  isOpen={isOpenCreateAddressModal}
                  onRequestClose={closeModal}
                  contentLabel="Example Modal"
                >
                  {customerDetail.id &&
                    <CreateAddressForm
                      customerId={customerDetail.id}
                      closeModal={closeModal}
                      getCustomerDetail={getCustomerDetail}
                      createCustomerAddress={createCustomerAddress}
                      cities={cities}
                      vertical={VERTICALS.blood}
                      order={order}
                      id={id}
                    />
                  } </Modal>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="order-right-info">
        <div className="head">
          <ul className="btn-list">
            <li>
              <button className="red" onClick={onDiscard}>Discard</button>
            </li>
            <li>
              <button className="yellow" onClick={onCreateOrder}
                disabled={orderFormik.isValidating || ordersLoading}>Create Order
              </button>
            </li>
          </ul>
        </div>
        <div className="two-cols">


          <div className="col-info test-col">
            <h2>Test Types</h2>
            <div className="form-row autocomplete">
              <Autocomplete
                placeholder="Add medical test types"
                getItemValue={item => item.label}
                items={scanTypes}
                renderItem={(item, isHighlighted) => (
                  scanTypes.length &&
                  <div key={item.id} style={{ background: isHighlighted ? "lightgray" : "white" }}>
                    {item.label}
                  </div>
                )}
                value={test}
                onChange={e => {
                  setTest(e.target.value);
                  searchOrderScanTypes(e.target.value);
                }}
                onSelect={(val, e) => {
                  if (!e.isAllowed) {
                    toast.error('This test isn\'t allowed in home sampling. Please ask the patient to visit the branch');
                    return;
                  }
                  setTests([
                    ...tests,
                    {
                      id: e.id,
                      name: e.name,
                      code: e.code,
                      index: tests.length - 1,
                      value: +e.value,
                      isCovid: e.isCovid
                    }]);
                  setTest('');
                }}
              />
            </div>
            {!tests.length &&
              <div className="no-test-area">
                <img src={require('../../assets/images/no-test.png')} alt="no test" />
              </div>
            }
            {scanTypesLoading && <Loader />}
            <ul className={`selected-tag ${tests.length ? '' : 'no-data'}`}>
              {
                tests.map((test, index) => {
                  return <li key={`${test.id}${index}`}>{test.code} - {test.name} | (PKR {test.value})
                    <a
                      id={test.id}
                      onClick={() => {
                        setTests(tests.filter((t) => t.index !== test.index));
                        setTest('');
                      }
                      } className="clear icon-clear"
                    />
                  </li>;
                })
              }
            </ul>
            <div className="test-total">
              <span className="title">Tests Total</span>
              <div className="price-holder">
                <span className="amo">{testsTotal}</span>
                <span className="pkr">PKR</span>
              </div>
            </div>
          </div>


          <div className="col-info order-col">
            <h2>Order Information</h2>
            <div className="form-row">
              <label>Discount</label>
              <div className="value">
                <span className="rs">%</span>
                <Field
                  type="text"
                  name='discount'
                  placeholder="Order Discount"
                  onChange={(e) => {
                    const discount = +e.target.value;
                    if (discount >= 0 && discount <= 100) {
                      orderFormik.setFieldValue('discount', discount);
                    }
                  }}
                />

              </div>
            </div>

            <div className="form-row">
              <label>Order Type*</label>
              <div className="value select">
                <Field
                  as='select'
                  placeholder='Order Type'
                  name='fulfillmentMethod'
                  style={{ display: 'block' }}
                  onChange={(e) => {
                    orderFormik.setFieldValue('fulfillmentMethod', e.target.value);
                  }}
                >
                  <option style={{ display: 'none' }}>Select order type</option>
                  <option value={FULFILLMENT_METHOD_TYPES.ONLINE}>Online Order</option>
                  <option value={FULFILLMENT_METHOD_TYPES.OFFLINE}>Offline Order</option>
                </Field>

              </div>
            </div>
            <div className="form-row">
              <label>Order Source*</label>
              <div className="value select">
                <Field
                  as='select'
                  placeholder='Order Source'
                  name='orderSource'
                  style={{ display: 'block' }}
                  onChange={(e) => {
                    orderFormik.setFieldValue('orderSource', e.target.value);
                  }}
                >
                  <option>Select order source</option>
                  {
                    orderSources && orderSources.map(orderSource => {
                      return <option
                        key={orderSource.id}
                        value={orderSource.id}
                      >{orderSource.text}</option>;
                    })
                  }
                </Field>

              </div>
            </div>
            <div className="test-total">
              <span className="title">FINAL ORDER VALUE</span>
              <div className="price-holder">
                <span className="amo">{value.toFixed(0)}</span>
                <span className="pkr">PKR</span>
              </div>
            </div>
            <div className="form-row">
              <label>PICKUP DATE</label>
              <div className="date-picker">
                <span className="icon-calendar" />
                <Field
                  minDate={add(new Date(), { minutes: DEFAULT_ORDER_PICKUP_TIME })}
                  disablePast
                  maxDate={add(new Date(), { days: MAX_ORDER_PICKUP_DAY })}
                  onChange={val => {
                    orderFormik.setFieldValue('pickupDate', val);
                  }}
                  value={orderFormik.values.pickupDate}
                  name="pickupDate"
                  component={DateField} />
              </div>
            </div>
            <div className="form-row">
              <label>AVAILABLE TIME</label>
              <div className="value select">
                <select
                  name="orderTimeSlot"
                  value={orderTimeSlot.id}
                  style={{ display: 'block' }}
                  onChange={handleOnOrderTimeSlotChange}
                  disabled={!orderFormik.values.region}
                >
                  <option value="" label={orderTimeSlots.length ? 'Select time' : 'No slots available'} />
                  {
                    orderTimeSlots.map(timeSlot => {
                      return <option
                        key={timeSlot.dateTime.toISOString()}
                        value={timeSlot.id}
                        label={pDateFormat(timeSlot.dateTime)}
                      />;
                    })
                  }
                </select>
              </div>
            </div>
            <div className="delivery-box">
              <label className="custom-checkbox">
                <Field type="checkbox" name="urgentDelivery" checked={orderFormik.values.urgentDelivery} />
                <span className="fake-checkbox" />
                <span className="text">Require Urgent Delivery</span>
              </label>
            </div>
            <Field component="textarea" cols="30" rows="10" name="additionalNotes" placeholder="Additional Comments" />
          </div>
        </div>
      </div>

      {isOpenHearAboutUsModal &&
        <Modal isOpen={isOpenHearAboutUsModal} onRequestClose={closeHearAboutUsModal} className='small-modal'>
          <HearAboutUs
            closeModal={closeHearAboutUsModal}
            hearAboutUsList={hearAboutUsList}
            getHearAboutUsList={getHearAboutUsList}
            loading={ordersLoading}
            confirmOrder={confirmOrder}
            orderFormik={orderFormik}
          />
        </Modal>
      }
      {isOpenDeleteCustomerAddressModal &&
        <Modal isOpen={isOpenDeleteCustomerAddressModal} onRequestClose={closeDeleteCustomerAddressModal}
          className='small-modal'>
          <DeleteCustomerAddress
            customerAddressId={customerAddressId}
            handleCustomerAddressDeletion={handleCustomerAddressDeletion}
            closeModal={closeDeleteCustomerAddressModal}
          />
        </Modal>
      }

    </div>
  </>;
};

export default BloodOrderCreateWrapper;
