import React from 'react';
import rattingIcon from '../../assets/images/ratting-icon.svg'
import Rating from './rating';
import Slider from './Slider';

const RiderToPatientFeedbackPopup = (props) => {
  const { riderToPatientFeedback, closeModal } = props;

  return (
    <div className="lightbox">
      <div className="feedback-lightbox">
        <a
          href=''
          className="lightbox-close icon-clear"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        />
        <div className="feedback-left">
          <div className="lft-holder">
            <img src={rattingIcon} alt="rattingIcon"/>
            <span className="title">Rider Feedback for Patient</span>
          </div>
        </div>
        <div className="feedback-right">
          <h2>{riderToPatientFeedback.order}</h2>
          <div className="ratting-holder">
            <div className="ratting-row">
              <span className="title">Overall</span>
              <div className="right">
                <Rating rating={riderToPatientFeedback.overallStarRating}/>
                <span className="point">{riderToPatientFeedback.overallStarRating | '0'}</span>
              </div>
            </div>
            <div className="ratting-row">
              <span className="title">Patient Behavior</span>
              <div className="right">
                <Rating rating={riderToPatientFeedback.patientBehaviorStarRating}/>
                <span className="point">{riderToPatientFeedback.patientBehaviorStarRating}</span>
              </div>
            </div>
            <div className="ratting-row">
              <span className="title">Wait Time</span>
              <div className="right">
                <Slider value={riderToPatientFeedback.waitTimeMins}/>
                <span className="point">{riderToPatientFeedback.waitTimeMins}</span>
              </div>
            </div>
          </div>
          <span className="note">
            {riderToPatientFeedback.feedbackText}
          </span>
        </div>
      </div>
    </div>
  )
};

export default RiderToPatientFeedbackPopup;
