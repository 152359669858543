import Axios from 'axios';
import * as queryString from 'query-string';
import * as caseConverter from 'change-object-case';
import { store } from '../../components/app';
import {
  selectRefreshTokenFromState,
  selectLoggedInUserFromState,
  selectAuthTypeFromState,
  selectAccessTokenFromState
} from '../../selectors/auth';
import { isTokenExpiredError, resetTokenAndReattemptRequest } from './api-helpers';
import {removeLocalStorageItem} from "../../utils/helper";
import {toast} from "react-toastify";
import {startsWith} from 'lodash';
import * as Sentry from '@sentry/browser';
import {SOMETHING_WENT_WRONG} from "../../utils/constants";


export function apiCaller(
  {
    method = 'GET',
    url = '',
    params = {},
    data = {},
    token = selectAccessTokenFromState(store.getState()),
    showErrors = true
  } = {},
) {
  // set-up case conversion configurations
  caseConverter.options = { recursive: true, arrayRecursive: true };
  return Axios({
    method,
    url,
    params,
    paramsSerializer: queryParams => queryString.stringify(queryParams),
    data,
    transformResponse: [respData => caseConverter.toCamel(respData)],
    transformRequest: [
      reqData => typeof reqData === 'string' ? reqData : JSON.stringify(caseConverter.snakeKeys(reqData)),
      // this check will not stringfigy already stringify object
    ],
    headers: {
      'Authorization': token? `Token ${token}` : '',
      'Content-Type': 'application/json',
    },
    responseType: 'json',
    validateStatus: status => status >= 200 && status < 300,
    showErrors,
  })
    .then(res => res)
    .catch(error => {
      // throw(error);
    });
}

Axios.interceptors.response.use(response => response, error => {
  const {
    config,
    response: { status, data } = {},
  } = error;
  const { showErrors } = config;
  if(error.message === 'Network Error'){
    toast.error(error.message);
  }
  // this will check for token expired specific case in all api's error response
  if(status===401) removeLocalStorageItem('TOKEN');
  if(showErrors && startsWith(status, 4)){
    toast.error(data?.detail || SOMETHING_WENT_WRONG)
  }
  else if(startsWith(status, 5) ){
    toast.error('Uh oh, it seems that something has gone wrong on our end');
      Sentry.captureException(error);
  }
  if (isTokenExpiredError(status, data)) {
    const originalRequest = config;
    const storeState = store.getState();
    const refreshToken = selectRefreshTokenFromState(storeState);
    const { email } = selectLoggedInUserFromState(storeState);
    const authType = selectAuthTypeFromState(storeState);
    // retry to fetch new accesstoken by making an refreshToken api call
    return resetTokenAndReattemptRequest(originalRequest, { refreshToken, email}, authType);
  }
  return Promise.reject(error);
});
