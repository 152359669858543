import React, {useState} from 'react';
import {getRegionIcon} from "../../utils/helper";

export const CitiesList = ({
                              cities,
                              cityDetail,
                              selectCityDetail,
                              isWhite = false,
                              vertical
                            }) => {
  const [open, setOpen] = useState(false);
  return <div className={`city-dropdown ${isWhite ? 'white' : ''}`}>
    {open === true && <span className="close-link">
              <a
                href=''
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(!open)
                }}
              ><i className="icon-angle-right"/></a>
            </span>
    }
    {open === true && <ul className="city-list">
      {
        cities.map(city => {
          return <li key={city.id}>
            <a
              href=''
              onClick={(e) => {
                e.preventDefault();
                selectCityDetail({vertical, city});
                setOpen(!open);
              }}>
              <div className="img-holder">
                <img src={getRegionIcon(city.name.toLowerCase(), isWhite)}
                     alt="" width="33px"/>
              </div>
              <span className="name">{city.name}</span>
            </a>
          </li>
        })
      }
    </ul>}
    {cityDetail.id && <a
      href=''
      onClick={(e) => {
        e.preventDefault();
        setOpen(!open)
      }}
      className="selected">
      <div className="img-holder">
        <img
          src={getRegionIcon(cityDetail.name.toLowerCase(), isWhite)}
          alt=""
          width="33px"
        />
      </div>
      <span className="name">{cityDetail.name} <i className="icon-angle-right"/></span>
    </a>
    }
  </div>
};
