import React from 'react'
import {Popup, Marker} from "react-leaflet";
import {CollectedIcon, CustomerIcon} from "../../assets/images/Icons";
import Map from "../commons/Map";

const PinActivity = ({ address, closeModal }) => {
  return (
    <div className='lightbox pin-activity'>
      <div className='lightbox-pin-activity'>
        <div className='close'>
          <div
            className='icon-clear'
            onClick={e => {
              e.preventDefault()
              closeModal()
            }}
          />
        </div>
        <div className='map'>
          <Map
            initialCenter={[address.latitude, address.longitude]}
            initialZoom={15}
            styles={{width: "832px", height: "530px", zIndex: 0}}
          >
            <Marker icon={CollectedIcon} position={[+address.latitude, +address.longitude]}>
              <Popup>
                <b>Collection Pin</b>: {address.address}
              </Popup>
            </Marker>
            {address.googleAddress &&
              <Marker icon={CustomerIcon} position={[+address.googleLatitude, +address.googleLongitude]}>
                <Popup>
                  <b>Google Pin</b>: {address.googleAddress}
                </Popup>
              </Marker>
            }
          </Map>
        </div>
      </div>
    </div>
  )
}

export default PinActivity
