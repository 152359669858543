import React from 'react';
import { ErrorField } from 'components/ui/error-field';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextFormFieldWithError } from '../ui/form-fields/text-form-field-with-error';
import { yupEmail, yupFirstName, yupLastName, yupPassword, yupPhoneSearch } from '../../utils/validation';

const CreateRider = ({ closeModal, loading, regions, createRider, riderParams }) => {

  const handleSubmit = (values) => {
    values = { ...values };
    values.password2 = values.password1;
    values.phoneVerified = true;
    createRider({ data: values, riderParams });
  };

  return (
    <>
      <div className="lightbox small-modal-con">
        <div className="lightbox-con">
          <a
            href=''
            className="lightbox-close icon-clear"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          />
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              phone: '',
              email: '',
              region: '',
              password1: '',
            }}
            validationSchema={Yup.object().shape({
              firstName: yupFirstName,
              lastName: yupLastName,
              phone: yupPhoneSearch,
              email: yupEmail,
              region: Yup.string().required('Required field'),
              password1: yupPassword,
            })}
            onSubmit={handleSubmit}
          >{(formik) => (
            <Form>
              <div className="form-row">
                <label>First Name</label>
                <Field
                  type="text"
                  name='firstName'
                  placeholder="John"
                  component={TextFormFieldWithError}
                />
              </div>
              <div className="form-row">
                <label>Last Name</label>
                <Field
                  type="text"
                  name='lastName'
                  placeholder="Doe"
                  component={TextFormFieldWithError}
                />
              </div>
              <div className="form-row">
                <label>Phone</label>
                <Field
                  type="text"
                  name='phone'
                  placeholder="03xxxxxxxxx"
                  component={TextFormFieldWithError}
                />
              </div>
              <div className="form-row">
                <label>Email</label>
                <Field
                  type="text"
                  name='email'
                  placeholder=""
                  component={TextFormFieldWithError}
                />
              </div>
              <div className="dropdown-area">
                <span className="label-text">Region</span>
                <select
                  name="region"
                  value={formik.values.region}
                  style={{ display: 'block' }}
                  onChange={(e) => {
                    formik.setFieldValue('region', e.target.value);
                  }}
                >
                  <option value="" label='Select region'/>
                  {
                    regions.map(region => {
                      return <option key={region.id} value={region.id} label={region.name}/>
                    })
                  }
                </select>
                {formik.errors.region && <ErrorField value={formik.errors.region}/>}
              </div>
              <div className="form-row">
                <label>Password</label>
                <Field
                  type="text"
                  name='password1'
                  placeholder=""
                  component={TextFormFieldWithError}
                />
              </div>
              <button type="submit" disabled={loading} className="create-btn">Create Rider</button>
            </Form>
          )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateRider;
