import React, {useEffect, useState} from 'react';
import {Nav} from "../commons/nav";
import {debounce, truncate} from 'lodash';
import {
  fullName,
  getRiderState, hasPermission,
  qsParse,
  qsStringify,
  riderStateClass,
  parseQueryStringObjectToArray, getLocalStorageBoolean, setLocalStorageBoolean,
} from '../../utils/helper';
import {Field, Formik} from "formik";
import {Loader} from '../commons/loader';
import {
  PERMISSION,
  VERTICALS,
  IS_RIDERS_FILTER_OPEN,
  RIDER_STATES
} from '../../utils/constants';
import Modal from 'react-modal';
import CreateRider from '../order/create-rider';
import refreshImage from "../../assets/images/refresh-white.png";
import {ROUTES} from "../../routes/constants";
import {Filter} from "../commons/filter";

let initFilter = {
  search: '',
  regions: [],
  isOnline: [],
  isCovid: [],
  isWildcard: [],
  states: [],
};

let filter = {
  ...initFilter
};

const RidersList = props => {
  const {
    regions,
    getRiders,
    riders,
    ridersLoading,
    ridersNext,
    getFilteredRiders,
    history,
    updateRiderParams,
    riderParams,
    regionDetail,
    ridersCount,
    resetRiderState,
    createRider,
    getAllRegions,
  } = props;

  const getFilterParamsFromProps = () => {
    let {location: {search: queryParams}} = history;
    queryParams = queryParams.replace('?', '');
    filter = {
      ...filter,
      ...qsParse(queryParams),
    };
    filter.regions = parseQueryStringObjectToArray(filter.regions);
    filter.states = parseQueryStringObjectToArray(filter.states);
    filter.isCovid = parseQueryStringObjectToArray(filter.isCovid);
    filter.isOnline = parseQueryStringObjectToArray(filter.isOnline);
    filter.isWildcard = parseQueryStringObjectToArray(filter.isWildcard);
  };

  const [isOpenCreateRiderModal, setIsOpenCreateRiderModal] = React.useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(getLocalStorageBoolean(IS_RIDERS_FILTER_OPEN) === null ? false : getLocalStorageBoolean(IS_RIDERS_FILTER_OPEN));

  useEffect(() => {
    resetRiderState();
    getAllRegions({vertical: VERTICALS.COMMON});
    getFilterParamsFromProps();
    getFilteredRiders(filter);
    setIsFilterChanged(!isFilterChanged);
    updateRiderParams(filter);

    return () => {
      resetRiderState();
      filter = {...initFilter}
    }
  }, []);

  useEffect(() => {
    if (regionDetail?.id) {
      if (!filter.regions.length) {
        filter.regions = [regionDetail.id];
        applyFilter();
      }
    }
  }, [regionDetail]);

  const loadMore = () => {
    if (!ridersLoading && ridersNext) {
      const paramsObj = qsParse(ridersNext.split('?')[1]);
      if (paramsObj.page) getRiders({
        ...filter,
        page: paramsObj.page,
      });
    }
  };

  window.onscroll = debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      if (window.location.pathname === `${process.env.PUBLIC_URL}${ROUTES.RIDERS}`) {
        loadMore();
      }
    }
  }, 100);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
    setLocalStorageBoolean(IS_RIDERS_FILTER_OPEN, !isFilterOpen);
  };

  const handleChangeSearch = event => {
    filter.search = event.target.value;
    if (!event.target.value.length) {
      setFilter();
      applyFilter();
    }
  };

  const handleOnKeyDownSearch = event => {
    filter.search = event.target.value;
    if (event.key === 'Enter') {
      setFilter();
      applyFilter();
    }
  };

  const handleRegionChangeFilter = (e, stateName, option) => {
    if (e.target.checked) {
      filter.regions = filter.regions.filter(n => n)
      filter.regions = [...new Set([...filter.regions, option.id])];
      if (filter.regions.length === regions.map(region => region.id).length) {
        filter.allRegions = true;
        filter.prevSelectedRegions = filter.regions;
      }
    } else {
      if (filter.regions.length === 1)
        return
      filter.regions = filter.regions.filter((id) => id != option.id);
      filter.prevSelectedRegions = filter.regions;
      filter.allRegions = false;
    }
    filter.rider = '';
    setFilter();
  }

  const handleAllRegionChangeFilter = (e) => {
    if (e.target.checked) {
      filter.allRegions = true;
      filter.regions = filter.regions.filter(n => n)
      filter.prevSelectedRegions = filter.regions;
      filter.regions = regions.map(region => region.id);
    } else {
      filter.prevSelectedRegions = parseQueryStringObjectToArray(filter.prevSelectedRegions);
      filter.regions = filter.regions.filter(n => n)
      filter.regions = filter.prevSelectedRegions ? filter.prevSelectedRegions : [regionDetail.id]
      filter.allRegions = filter.regions.length === regions.map(region => region.id).length;
    }
    setFilter();
  }

  const handleListChangeFilter = (e, stateName, option) => {
    if (e.target.checked) {
      filter[stateName] = Array.from(new Set([...filter[stateName], option.value]));
    } else {
      filter[stateName] = filter[stateName].filter(v => v != option.value);
    }
    setFilter();
  }

  const handleBooleanChangeFilter = (e, stateName, id) => {
    if (e.target.checked) {
      filter[stateName] = Array.from(new Set([id.toString()]));
    } else {
      filter[stateName] = filter[stateName].filter(value => value != id);
    }
    setFilter();
  }

  const setFilter = () => {
    setIsFilterChanged(!isFilterChanged);
    updateRiderParams(filter);
  };

  const applyFilter = () => {
    history.replace({
      pathname: ROUTES.RIDERS,
      search: qsStringify(filter)
    });
  }

  const closeCreateRiderModal = () => {
    setIsOpenCreateRiderModal(false);
  };

  return <div id="wrapper" className={`inner-page ${isFilterOpen ? 'filter-active' : ''}`}>
    <Nav/>
    <div className="listing-page">
      <div className="listing-head">
        <h2>Riders <span className="small">- {ridersCount} results</span></h2>
        {
          hasPermission(PERMISSION.CAN_ADD_RIDER) &&
          <a href=' ' className="order-btn"
             onClick={(e) => {
               e.preventDefault();
               setIsOpenCreateRiderModal(true)
             }}
             style={{cursor: 'pointer'}}
          >Create Rider</a>
        }
      </div>
      <div className="list-search-area">
        <div className="id-search-form refresh">
          <Formik initialValues={{search: filter.search}} enableReinitialize={true}>
            {props => (
              <form onSubmit={props.handleSubmit}>
                <Field type="search" placeholder="e.g. Rider Name or Phone"
                       name="search"
                       onChange={(e) => {
                         props.setFieldValue('search', e.target.value);
                         handleChangeSearch(e)
                       }}
                       onKeyDown={(e) => handleOnKeyDownSearch(e)}
                />
                <button type="submit" className="search-btn"><i className="icon-magnify"/></button>
              </form>
            )}
          </Formik>
        </div>
        <a onClick={() => {
          applyFilter();
        }} className="filter-btn icon-sync"/>
        <a onClick={() => {
          toggleFilter();
        }} className="filter-btn icon-filter"/>

      </div>
      <table className="list-table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Region</th>
          <th>State</th>
          <th>Status</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {riders.map(rider => {
          return <tr key={rider.id} onClick={() => history.push(`/riders/detail/${rider.id}`)}>
            <td>{truncate(fullName(rider), {length: 40})}
              <span className="sm-text">{rider.phone}</span>
            </td>
            <td>{rider.cityName}</td>
            <td><span className={`tag ${riderStateClass(rider.state)}`}>{getRiderState(rider.state)}</span></td>
            <td><i
              className={`icon-cell ${rider.isOnline ? 'avilable' : ''}`}/> {rider.isOnline ? 'Online' : 'Offline'}
            </td>
            <td></td>
          </tr>
        })}
        </tbody>
      </table>
      {ridersLoading && <Loader/>}
      {
        !ridersLoading && ridersNext &&
        <button className={`load-more`} onClick={!ridersLoading && loadMore}><img src={refreshImage}/>Load More</button>
      }
    </div>
    <Filter
      loading={ridersLoading}
      onClose={toggleFilter}
      isFilterOpen={isFilterOpen}
      isFilterUpdated={isFilterChanged}
      applyFilter={applyFilter}
      regionFilter={{
        filterName: 'Regions',
        filterValues: regions.map(r => ({id: r.id, value: r.name})),
        filterStateName: 'regions',
        onChange: handleRegionChangeFilter,
        selectedValues: filter.regions.map(sv => Number(sv)),
        checkedType: 'id',
        customFilter: {
          value: 'All Regions',
          onChange: handleAllRegionChangeFilter,
          selectedValues: filter.allRegions
        }
      }}
      statesFilter={{
        filterName: 'States',
        filterValues: RIDER_STATES.map(
          (riderState, index) => (
            {
              id: index,
              value: riderState.state.charAt(0).toLowerCase() + riderState.state.slice(1)
            }
          )
        ),
        filterStateName: 'states',
        checkedType: 'values',
        onChange: handleListChangeFilter,
        selectedValues: filter.states
      }}
      isOnlineFilter={{
        filterName: 'Is Online',
        filterStateName: 'isOnline',
        onChange: handleBooleanChangeFilter,
        selectedValues: filter.isOnline
      }}
      isCovidFilter={{
        filterName: 'Is COVID',
        filterStateName: 'isCovid',
        onChange: handleBooleanChangeFilter,
        selectedValues: filter.isCovid
      }}
      isWildcardFilter={{
        filterName: 'Is Wildcard',
        filterStateName: 'isWildcard',
        onChange: handleBooleanChangeFilter,
        selectedValues: filter.isWildcard
      }}
    />
    {isOpenCreateRiderModal &&
    <Modal isOpen={isOpenCreateRiderModal} onRequestClose={closeCreateRiderModal} className='small-modal'>
      <CreateRider
        closeModal={closeCreateRiderModal}
        loading={ridersLoading}
        regions={regions}
        createRider={createRider}
        riderParams={riderParams}
      />
    </Modal>
    }
  </div>
};

export default RidersList;
