import React from 'react';
import {MapContainer, TileLayer} from "react-leaflet";
import {ChangeMapCenter} from "./ChangeMapCenter";
import 'leaflet/dist/leaflet.css';

const Map = ({
  initialCenter,
  initialZoom,
  styles,
  currentCenter,
  currentZoom,
  children,
  }) => {

  return <MapContainer
    center={initialCenter}
    zoom={initialZoom}
    style={styles}
  >
    {
      currentCenter && currentZoom && <ChangeMapCenter center={currentCenter} zoom={currentZoom} />
    }
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {children}
  </MapContainer>
};

export default Map;
