import React, {useEffect, useState} from 'react';
import {Nav} from "../commons/nav";
import {Link} from 'react-router-dom';
import {debounce, startCase} from 'lodash';
import {startOfToday, endOfToday} from 'date-fns';
import OrderAssignForm from "./form/order-assign";
import {
  qsParse,
  fullName,
  getNextSortingState,
  clearOtherSortingParams,
  isNoSortingParams,
  getLocalStorageBoolean,
  setLocalStorageBoolean,
  hasPermission,
  parseQueryStringObjectToArray,
  qsStringify,
} from '../../utils/helper';
import {Formik, Field} from "formik";
import {
  defaultOrderListSorting,
  IS_ORDERS_FILTER_OPEN,
  PERMISSION,
  FULFILLMENT_METHOD_OPTIONS, VERTICALS, FOLLOW_UP_OPTIONS
} from '../../utils/constants';
import {Loader} from '../commons/loader';
import Modal from 'react-modal';
import OrderCancel from './order-cancel';
import refreshImage from '../../assets/images/refresh-white.png'
import {Filter} from '../commons/filter';
import {OrdersTable} from '../commons/ordersTable';
import {ROUTES} from "../../routes/constants";

let initFilter = {
  search: '',
  regions: [],
  prevSelectedRegions: [],
  allRegions: false,
  states: [],
  pickupFrom: startOfToday(),
  pickupTo: endOfToday(),
  rider: '',
  createdBy: '',
  problemString: '',
  needsReview: [],
  sorting: defaultOrderListSorting,
  wasAutoAssigned: [],
  wasManualAssigned: [],
  isCovid: [],
  fulfillmentMethod: '',
  followUp: [],
  moreThanFollowUps: '',
  isFollowUpAddressed: [],
  isUnverifiedByRider: [],
  isFasting: [],
  urgentDelivery: [],
  isMedicineAndSpecificTime: [],
  addressTypes: [],
};

let filter = {
  ...initFilter
};

const BloodOrdersList = props => {
  const {
    getRegions,
    getOrders,
    orders,
    regions,
    assignOrder,
    cancelOrder,
    getCustomerDetail,
    customerDetail,
    states,
    getOrderStates,
    resetCustomerState,
    ordersNext,
    getFilteredOrders,
    history,
    resetOrderState,
    updateOrderParams,
    bloodOrderParams,
    ordersLoading,
    getAssignableRider,
    assignableRider,
    resetAssignableRiderState,
    assignableRiderLoading,
    bloodRegionDetail: regionDetail,
    ordersCount,
    npRiders,
    getNPRiders,
    resetRiderState,
    getProblemStrings,
    problemStrings,
    getCancellationStrings,
    cancellationStrings,
    orderNeedsReview,
    npStaffUsers,
    getNpStaffUsers,
    emailOrders,
    orderFollowUpAddressed,
    getAddressTypes,
    addressTypes,
  } = props;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState({id: ''});
  const [isOpenCancelOrderModal, setIsOpenCancelOrderModal] = useState(0);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(getLocalStorageBoolean(IS_ORDERS_FILTER_OPEN) === null ? false : getLocalStorageBoolean(IS_ORDERS_FILTER_OPEN));

  const getFilterParamsFromProps = () => {
    let {location: {search: queryParams}} = history;
    queryParams = queryParams.replace('?', '');
    filter = {
      ...filter,
      ...qsParse(queryParams),
    };
    filter.regions = parseQueryStringObjectToArray(filter.regions);
    filter.states = parseQueryStringObjectToArray(filter.states);
    filter.addressTypes = parseQueryStringObjectToArray(filter.addressTypes);
    filter.needsReview = parseQueryStringObjectToArray(filter.needsReview);
    filter.wasAutoAssigned = parseQueryStringObjectToArray(filter.wasAutoAssigned);
    filter.wasManualAssigned = parseQueryStringObjectToArray(filter.wasManualAssigned);
    filter.isCovid = parseQueryStringObjectToArray(filter.isCovid);
    filter.followUp = parseQueryStringObjectToArray(filter.followUp);
    filter.isFollowUpAddressed = parseQueryStringObjectToArray(filter.isFollowUpAddressed);
    filter.isUnverifiedByRider = parseQueryStringObjectToArray(filter.isUnverifiedByRider);
    filter.isFasting = parseQueryStringObjectToArray(filter.isFasting);
    filter.urgentDelivery = parseQueryStringObjectToArray(filter.urgentDelivery);
    filter.isMedicineAndSpecificTime = parseQueryStringObjectToArray(filter.isMedicineAndSpecificTime);
  };

  useEffect(() => {
    resetCustomerState();
    resetOrderState();
    getRegions({vertical: VERTICALS.BLOOD});
    getFilterParamsFromProps();
    getFilteredOrders({...filter, vertical: VERTICALS.blood});
    setIsFilterChanged(!isFilterChanged);
    updateOrderParams({filter: filter, type: VERTICALS.BLOOD});

    getOrderStates({vertical: VERTICALS.blood});
    if (!addressTypes.length) getAddressTypes();
    if (!problemStrings.length) getProblemStrings();
    if (!npStaffUsers.length) getNpStaffUsers();
    if (!cancellationStrings.length) getCancellationStrings();

    return () => {
      resetCustomerState();
      resetOrderState();
      filter = {...initFilter};
    };
  }, []);

  useEffect(() => {
    if (regionDetail?.id) {
      if (!filter.regions.length) {
        filter.regions = [regionDetail.id];
        applyFilter();
      }
    }
  }, [regionDetail]);

  useEffect(() => {
    if (filter.regions.length > 0) {
      getNPRiders({regions: filter.regions});
    }

    return () => {
      resetRiderState();
    }
  }, [filter.regions]);

  const loadMore = () => {
    if (!ordersLoading && ordersNext) {
      const paramsObj = qsParse(ordersNext.split('?')[1]);
      if (paramsObj.page) getOrders({
        ...filter,
        vertical: VERTICALS.blood,
        page: paramsObj.page,
      });
    }
  }

  window.onscroll = debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      if (window.location.pathname === `${process.env.PUBLIC_URL}${ROUTES.BLOOD_ORDERS}`) {
        loadMore();
      }
    }
  }, 100);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
    setLocalStorageBoolean(IS_ORDERS_FILTER_OPEN, !isFilterOpen);
  };

  const handleChangeSearch = event => {
    filter.search = event.target.value;
    if (!event.target.value.length) {
      setFilter();
      applyFilter();
    }
  };

  const handleOnKeyDownSearch = event => {
    filter.search = event.target.value;
    if (event.key === 'Enter') {
      setFilter();
      applyFilter();
    }
  };

  const handleRegionChangeFilter = (e, stateName, option) => {
    if (e.target.checked) {
      filter.regions = filter.regions.filter(n => n)
      filter.regions = [...new Set([...filter.regions, option.id])];
      if (filter.regions.length === regions.map(region => region.id).length) {
        filter.allRegions = true;
        filter.prevSelectedRegions = filter.regions;
      }
    } else {
      if (filter.regions.length === 1)
        return
      filter.regions = filter.regions.filter((id) => id != option.id);
      filter.prevSelectedRegions = filter.regions;
      filter.allRegions = false;
    }
    filter.rider = '';
    setFilter();
  }

  const handleAllRegionChangeFilter = (e) => {
    if (e.target.checked) {
      filter.allRegions = true;
      filter.regions = filter.regions.filter(n => n)
      filter.prevSelectedRegions = filter.regions;
      filter.regions = regions.map(region => region.id);
    } else {
      filter.prevSelectedRegions = parseQueryStringObjectToArray(filter.prevSelectedRegions);
      filter.regions = filter.regions.filter(n => n)
      filter.regions = filter.prevSelectedRegions ? filter.prevSelectedRegions : [regionDetail.id]
      filter.allRegions = filter.regions.length === regions.map(region => region.id).length;
    }
    setFilter();
  }

  const handleOnSortingChangeFilter = (e, sortingParam) => {
    filter.sorting[sortingParam] = getNextSortingState(filter.sorting[sortingParam])
    filter.sorting = clearOtherSortingParams(filter.sorting, sortingParam)
    if (isNoSortingParams(filter.sorting)) {
      filter.sorting = defaultOrderListSorting;
    }
    setFilter();
  };

  const setFilter = () => {
    setIsFilterChanged(!isFilterChanged);
    updateOrderParams({filter: filter, type: VERTICALS.BLOOD});
  };

  const applyFilter = () => {
    history.replace({
      pathname: ROUTES.BLOOD_ORDERS,
      search: qsStringify(filter)
    });
  }

  const openModal = (e, order) => {
    e.stopPropagation();
    setIsOpen(true);
    setSelectedOrder(order)
  }
  const closeModal = () => {
    setIsOpen(false);
    resetCustomerState();
    resetAssignableRiderState();
  }

  const orderCancel = (orderId, data) => {
    cancelOrder({id: orderId, vertical: VERTICALS.blood, data, filter});
    setIsOpenCancelOrderModal(0)
  };

  const closeCancelOrderModal = () => {
    setIsOpenCancelOrderModal(0)
  };

  const handleNeedsReview = (e, order) => {
    e.stopPropagation();
    orderNeedsReview({
      id: order.id,
      vertical: VERTICALS.blood,
      data: {
        needsReview: !order.needsReview,
      },
      filter
    });
  };

  const handleFollowUpAddressed = (e, order) => {
    e.stopPropagation();
    e.preventDefault();
    orderFollowUpAddressed({
      id: order.id,
      vertical: VERTICALS.blood,
      data: {
        isFollowUpAddressed: !order.isFollowUpAddressed,
      },
      filter
    });
  };

  const handleDateTimeFilterChange = (filterType, value) => {
    filter[filterType] = value;
    setFilter();
  };

  const handleListChangeFilter = (e, stateName, option) => {
    if (e.target.checked) {
      filter[stateName] = Array.from(new Set([...filter[stateName], option.value]));
    } else {
      filter[stateName] = filter[stateName].filter(v => v != option.value);
    }
    setFilter();
  }

  const handleBooleanChangeFilter = (e, stateName, id) => {
    if (e.target.checked) {
      filter[stateName] = Array.from(new Set([id.toString()]));
    } else {
      filter[stateName] = filter[stateName].filter(value => value != id);
    }
    setFilter();
  }

  const handleSelectChangeFilter = (e, stateName) => {
    filter[stateName] = e.target.value.toString();
    setFilter();
  }

  return (
    <div
      id="wrapper"
      className={`inner-page ${modalIsOpen ? 'order-assigin-popup-active' : ''} ${isFilterOpen ? 'filter-active' : ''}`}
    >
      <Nav/>
      <div className="listing-page">
        <div className="listing-head">
          <h2>Blood Orders <span className="small">- {ordersCount} results</span></h2>
          <Link to={`${ROUTES.BLOOD_ORDERS}/create`} className="order-btn">New Order</Link>
          {
            hasPermission(PERMISSION.CAN_DOWNLOAD_ORDER_LISTING) &&
            <a
              href='' className="order-btn download mr-2"
              onClick={(e) => {
                e.preventDefault();
                emailOrders({...filter, vertical: VERTICALS.blood});
              }
              }
            >
              Download CSV
            </a>
          }
        </div>
        <div className="list-search-area">
          <div className="id-search-form refresh">
            <Formik initialValues={{search: filter.search}} enableReinitialize={true}>
              {props => (
                <form onSubmit={props.handleSubmit}>
                  <Field
                    type="search" placeholder="e.g. Order ID"
                    name="search"
                    onChange={(e) => {
                      props.setFieldValue('search', e.target.value);
                      handleChangeSearch(e)
                    }
                    }
                    onKeyDown={(e) => handleOnKeyDownSearch(e)}
                  />
                  <button
                    type="submit"
                    className="search-btn"
                    onClick={() => {
                      setFilter();
                      applyFilter();
                    }}
                  >
                    <i className="icon-magnify"/>
                  </button>
                </form>
              )}
            </Formik>
          </div>
          <a
            onClick={() => {
              setFilter();
              applyFilter();
            }
            }
            className="filter-btn icon-sync"/>
          <a
            onClick={() => {
              setIsFilterOpen(!isFilterOpen)
              setLocalStorageBoolean(IS_ORDERS_FILTER_OPEN, true)
            }
            }
            className="filter-btn icon-filter"
          />
        </div>
        <OrdersTable
          orders={orders}
          regions={regions}
          handleNeedsReview={handleNeedsReview}
          handleFollowUpAddressed={handleFollowUpAddressed}
          handleAssignment={openModal}
          sortingClass={filter.sorting.pickup}
          handleOnSortingChange={handleOnSortingChangeFilter}
          handleCancel={setIsOpenCancelOrderModal}
          detailURL={`${ROUTES.BLOOD_ORDERS}/detail`}
          history={history}
        />
        {ordersLoading && <Loader/>}
        {
          !ordersLoading && ordersNext &&
          <button className={`load-more`} onClick={!ordersLoading && loadMore}><img src={refreshImage}/>Load More
          </button>
        }
      </div>
      {
        selectedOrder.id &&
        <OrderAssignForm
          getAssignableRider={getAssignableRider}
          assignableRider={assignableRider}
          orderDetail={selectedOrder}
          regions={regions}
          closeModal={closeModal}
          assignOrder={assignOrder}
          getCustomerDetail={getCustomerDetail}
          customerDetail={customerDetail}
          mode={'assign'}
          isGetCustomerDetail={true}
          modalIsOpen={modalIsOpen}
          assignableRiderLoading={assignableRiderLoading}
          orderParams={bloodOrderParams}
          vertical={VERTICALS.blood}
        />
      }
      <Filter
        loading={ordersLoading}
        onClose={toggleFilter}
        isFilterOpen={isFilterOpen}
        isFilterUpdated={isFilterChanged}
        applyFilter={applyFilter}
        dateTimeFilter={{
          pickupFrom: filter.pickupFrom,
          pickupFromStateName: 'pickupFrom',
          pickupTo: filter.pickupTo,
          pickupToStateName: 'pickupTo',
          handleDateTimeFilterChange: handleDateTimeFilterChange
        }}
        regionFilter={{
          filterName: 'Regions',
          filterValues: regions.map(r => ({id: r.id, value: r.name})),
          filterStateName: 'regions',
          onChange: handleRegionChangeFilter,
          selectedValues: filter.regions.map(sv => Number(sv)),
          checkedType: 'id',
          customFilter: {
            value: 'All Regions',
            onChange: handleAllRegionChangeFilter,
            selectedValues: filter.allRegions
          }
        }}
        addressFilter={{
          filterName: 'Address Type',
          filterValues: addressTypes.map(at => ({id: "", value: at.addressType})),
          filterStateName: 'addressTypes',
          checkedType: 'values',
          onChange: handleListChangeFilter,
          selectedValues: filter.addressTypes
        }}
        statesFilter={{
          filterName: 'States',
          filterValues: states.map(
            (orderState, index) => (
              {
                id: index,
                value: orderState.state.charAt(0).toLowerCase() + orderState.state.slice(1)
              }
            )
          ),
          filterStateName: 'states',
          checkedType: 'values',
          onChange: handleListChangeFilter,
          selectedValues: filter.states
        }}
        needsReviewFilter={{
          filterName: 'Needs Review',
          filterStateName: 'needsReview',
          onChange: handleBooleanChangeFilter,
          selectedValues: filter.needsReview
        }}
        autoAssignedFilter={{
          filterName: 'Auto Assigned',
          filterStateName: 'wasAutoAssigned',
          onChange: handleBooleanChangeFilter,
          selectedValues: filter.wasAutoAssigned
        }}
        manualAssignedFilter={{
          filterName: 'Manual Assigned',
          filterStateName: 'wasManualAssigned',
          onChange: handleBooleanChangeFilter,
          selectedValues: filter.wasManualAssigned
        }}
        isCovidFilter={{
          filterName: 'Is COVID',
          filterStateName: 'isCovid',
          onChange: handleBooleanChangeFilter,
          selectedValues: filter.isCovid
        }}
        followUpFilter={{
          filterName: 'Follow Up',
          filterStateName: 'followUp',
          onChange: handleBooleanChangeFilter,
          selectedValues: filter.followUp
        }}
        followUpAddressedFilter={{
          filterName: 'Follow Up Addressed',
          filterStateName: 'isFollowUpAddressed',
          onChange: handleBooleanChangeFilter,
          selectedValues: filter.isFollowUpAddressed
        }}
        unverifiedByRiderFilter={{
          filterName: 'Unverified by Rider',
          filterStateName: 'isUnverifiedByRider',
          onChange: handleBooleanChangeFilter,
          selectedValues: filter.isUnverifiedByRider
        }}
        isFasting={{
          filterName: 'Is Fasting',
          filterStateName: 'isFasting',
          onChange: handleBooleanChangeFilter,
          selectedValues: filter.isFasting
        }}
        urgentDelivery={{
          filterName: 'Urgent Delivery',
          filterStateName: 'urgentDelivery',
          onChange: handleBooleanChangeFilter,
          selectedValues: filter.urgentDelivery
        }}
        isMedicineAndSpecificTime={{
          filterName: 'Is Medicine and Specific Time',
          filterStateName: 'isMedicineAndSpecificTime',
          onChange: handleBooleanChangeFilter,
          selectedValues: filter.isMedicineAndSpecificTime
        }}
        problemSelectFilter={{
          filterName: 'Problems',
          filterStateName: 'problemString',
          filterOptions: problemStrings.map(ps => ({"id": ps.id, "label": ps.text})),
          selectedFilter: filter.problemString,
          filterTitle: 'Select a Problem',
          onChange: handleSelectChangeFilter
        }}
        riderSelectFilter={{
          filterName: 'Rider',
          filterStateName: 'rider',
          filterOptions: npRiders.map(nr => ({"id": nr.id, "label": `${startCase(fullName(nr))} - ${nr.regionName}`})),
          selectedFilter: filter.rider,
          filterTitle: 'Select a Rider',
          onChange: handleSelectChangeFilter
        }}
        createdBySelectFilter={{
          filterName: 'Created By',
          filterStateName: 'createdBy',
          filterOptions: npStaffUsers.map(staff => ({"id": staff.id, "label": staff.fullName})),
          selectedFilter: filter.createdBy,
          filterTitle: 'Select a Staff User',
          onChange: handleSelectChangeFilter
        }}
        fulfillmentMethodSelectFilter={{
          filterName: 'Fulfillment Method',
          filterStateName: 'fulfillmentMethod',
          filterOptions: FULFILLMENT_METHOD_OPTIONS,
          selectedFilter: filter.fulfillmentMethod,
          filterTitle: 'Select a Fulfillment Method',
          onChange: handleSelectChangeFilter
        }}
        hasFollowUpsSelectFilter={{
          filterName: 'Has Follow Ups',
          filterStateName: 'moreThanFollowUps',
          filterOptions: FOLLOW_UP_OPTIONS,
          selectedFilter: filter.moreThanFollowUps,
          filterTitle: 'Has more than N followups',
          onChange: handleSelectChangeFilter
        }}
      />
      {
        !!isOpenCancelOrderModal &&
        <Modal isOpen={!!isOpenCancelOrderModal} onRequestClose={closeCancelOrderModal} className='small-modal'>
          <OrderCancel
            orderId={isOpenCancelOrderModal}
            orderCancel={orderCancel}
            closeModal={closeCancelOrderModal}
            cancellationStrings={cancellationStrings}
          />
        </Modal>
      }
    </div>
  )
};

export default BloodOrdersList;
