import React, { useCallback, useState } from 'react';
import { truncate } from 'lodash'
import { fullName, getLocalStorageObject, getUserType } from '../../../utils/helper';
import { USER } from '../../../utils/constants';


export const Profile = ({ logoutAttempt }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const user = getLocalStorageObject(USER) || {};
  const full_name = fullName(user);

  const toggleIsPopupOpen = useCallback(() => {
    setIsPopupOpen(!isPopupOpen)
  }, [isPopupOpen]);

  return <div className={`profile-area pointer ${isPopupOpen? 'open': null}`}>
    <img src={require("../../../images/img.png")} alt="" onClick={toggleIsPopupOpen}/>
    <div className="profile-drop">
      {user.userType && <div className="drop-head">
        <span className="name">{full_name}</span>
        <span className="admin">{getUserType(user.userType)}</span>
      </div>
      }
      <ul className="links">
        {/*<li><a href="#"><i className="icon-settings"></i> Settings</a></li>*/}
        <li><a href=" " onClick={(e)=>{
          e.preventDefault();
          logoutAttempt()
        }}><i className="icon-logout1"></i> Logout</a></li>
      </ul>
    </div>
  </div>
};
