import { takeEvery, } from 'redux-saga/effects';
import { types } from '../../actions/shift';

import {
  copyShift,
  createShift,
  createShiftRider,
  deleteShift,
  deleteShiftRider,
  getShift,
  getShiftRiders,
  getShifts,
  updateShift,
  updateShiftRider,
} from './shift';

export function* shiftSaga() {
  yield takeEvery(types.GET_SHIFT_REQUEST, getShift);
  yield takeEvery(types.GET_SHIFTS_REQUEST, getShifts);
  yield takeEvery(types.CREATE_SHIFT_REQUEST, createShift);
  yield takeEvery(types.GET_SHIFT_RIDERS_REQUEST, getShiftRiders);
  yield takeEvery(types.CREATE_SHIFT_RIDER_REQUEST, createShiftRider);
  yield takeEvery(types.UPDATE_SHIFT_RIDER_REQUEST, updateShiftRider);
  yield takeEvery(types.DELETE_SHIFT_RIDER_REQUEST, deleteShiftRider);
  yield takeEvery(types.UPDATE_SHIFT_REQUEST, updateShift);
  yield takeEvery(types.DELETE_SHIFT_REQUEST, deleteShift);
  yield takeEvery(types.COPY_SHIFT_REQUEST, copyShift);
}
