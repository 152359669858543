import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const HearAboutUs = ({ hearAboutUsList, getHearAboutUsList, closeModal, loading, confirmOrder, orderFormik }) => {
  const [hearAboutUsId, setHearAboutUsId] = useState('');

  useEffect(() => {
    getHearAboutUsList();
  }, [getHearAboutUsList]);

  return (
    <>
      <div className="lightbox small-modal-con">
        <div className="lightbox-con">
          <a
            href=''
            className="lightbox-close icon-clear"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          />
          <div className="form-row">
            <label>How did you hear about us?</label>
            <select
              name="hearAboutUs"
              value={hearAboutUsId}
              onChange={(e) => {
                setHearAboutUsId(e.target.value);
              }}
              style={{ display: 'block' }}
            >
              <option value="" label="Select hear about us"/>
              {
                hearAboutUsList.map(hearAboutUs =>
                  <option value={hearAboutUs.id}>{hearAboutUs.text}</option>
                )
              }
            </select>
          </div>
          <button
            className="create-btn"
            disabled={orderFormik.isValidating || loading}
            onClick={(e)=>{
              e.preventDefault();
              if(hearAboutUsId){
                confirmOrder(hearAboutUsId)
              }
              else {
                toast.error('Select hear about us')
              }
            }}
          >Confirm Order</button>
        </div>
      </div>
    </>
  );
};

export default HearAboutUs;
