import React, {useEffect} from 'react';
import Proptypes from 'prop-types';
import {
  VERTICALS
} from '../../utils/constants';
import {Nav} from '../../components/commons/nav';
import {DashboardMapHolder} from "../commons/dashboard-map-holder";
import refreshImage from '../../assets/images/refresh-white.png'
import {CitiesList} from "../commons/cities-list";
import MapRiderListPanel from "./map-rider-list-panel";

export const XRayCityDashboard = props => {
  const {
    getCities,
    cities,
    getRiders,
    riders,
    xrayCityDetail: cityDetail,
    selectCityDetail,
    history,
  } = props;

  useEffect(() => {
    getCities({vertical: VERTICALS.XRAY});
  }, []);

  useEffect(() => {
    if (!cityDetail?.id) {
      return;
    }
    const {id} = cityDetail;
    getRiders({cities: [id], vertical: VERTICALS.XRAY});
  }, [cityDetail]);

  if (!cityDetail) {
    return <p>loading...</p>
  }

  return (
    <div id="wrapper" className="inner-page map-full">
      <Nav/>
      <MapRiderListPanel riders={riders} regionDetail={cityDetail}/>
      <div className="dashbord-content">
        <div className="top-content">
          <div className="map-holder full-height">
            <DashboardMapHolder riders={riders}
                                history={history}
                                mapFull='map-full'
                                regionDetail={cityDetail}
                                isShowRiders={true}
            />

            <button
              className='refresh-button'
              onClick={() => {
                const {id} = cityDetail;
                if (!cityDetail?.id) {
                  return;
                }
                getRiders({cities: [id], vertical: VERTICALS.XRAY});
              }}
              title={'Refresh'}
            >
              <img src={refreshImage}/>
            </button>
          </div>

          <div className="map-right-info full-height">
            <CitiesList
              cities={cities}
              cityDetail={cityDetail}
              selectCityDetail={selectCityDetail}
              vertical={VERTICALS.XRAY}
            />
          </div>
        </div>
      </div>

    </div>

  )
};

XRayCityDashboard.propTypes = {
  getRiders: Proptypes.func.isRequired,
  selectCityDetail: Proptypes.func.isRequired,
  riders: Proptypes.arrayOf(Proptypes.shape({})),
  cityDetail: Proptypes.shape({}),
};

export default XRayCityDashboard;
