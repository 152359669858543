import React from 'react';
import Proptypes from 'prop-types';
import {
  RED_RIDER_MARKER_STATES,
} from '../../utils/constants';
import {
  getRiderState,
  getRiderType,
} from '../../utils/helper';


export const MapRiderListPanel = props => {
  const {
    riders,
    regionDetail,
  } = props;

  return (
    <div className="map-rider-list-panel">
      <h2>Online Riders (On Duty)</h2>
      <ul className="map-rider-list">
        {
          riders && riders.filter((rider => rider.isOnline && rider.isOnDuty)).map((rider, index) =>
            <li key={index}
                style={{color: RED_RIDER_MARKER_STATES.includes(+rider.state) ? '#E55353' : ''}}>
              <strong>{index + 1}. </strong>
              {getRiderType(rider)}
              {regionDetail.isHyperlocalModeEnabled && rider.cell && ` ${rider.cell.name} | `}
              {rider.firstName} {rider.lastName} | {rider.phone} | {getRiderState(rider.state)} | {rider.completedOrdersCount}
            </li>
          )}
      </ul>
      <br/>
      <h2>Online Riders (Off Duty)</h2>
      <ul className="map-rider-list">
        {
          riders && riders.filter((rider => rider.isOnline && !rider.isOnDuty)).map((rider, index) =>
            <li key={index}
                style={{color: RED_RIDER_MARKER_STATES.includes(+rider.state) ? '#E55353' : ''}}>
              <strong>{index + 1}. </strong>
              {getRiderType(rider)}
              {regionDetail.isHyperlocalModeEnabled && rider.cell && ` ${rider.cell.name} | `}
              {rider.firstName} {rider.lastName} | {rider.phone} | {getRiderState(rider.state)} | {rider.completedOrdersCount}
            </li>
          )}
      </ul>
      <br/>
      <h2>Offline Riders(On Duty)</h2>
      <ul className="map-rider-list">
        {
          riders && riders.filter((rider => !rider.isOnline && rider.isOnDuty)).map((rider, index) =>
            <li key={index}
                style={{color: RED_RIDER_MARKER_STATES.includes(+rider.state) || rider.isOnDuty ? '#E55353' : ''}}>
              <strong>{index + 1}. </strong>
              {getRiderType(rider)}
              {regionDetail.isHyperlocalModeEnabled && rider.cell && ` ${rider.cell.name} | `}
              {rider.firstName} {rider.lastName} | {rider.phone} | {getRiderState(rider.state)} | {rider.completedOrdersCount}
            </li>
          )}
      </ul>
      <br/>
      <h2>Offline Riders (Off Duty)</h2>
      <ul className="map-rider-list">
        {
          riders && riders.filter((rider => !rider.isOnline && !rider.isOnDuty)).map((rider, index) =>
            <li key={index}
                style={{color: RED_RIDER_MARKER_STATES.includes(+rider.state) ? '#E55353' : ''}}>
              <strong>{index + 1}. </strong>
              {getRiderType(rider)}
              {regionDetail.isHyperlocalModeEnabled && rider.cell && ` ${rider.cell.name} | `}
              {rider.firstName} {rider.lastName} | {rider.phone} | {getRiderState(rider.state)} | {rider.completedOrdersCount}
            </li>
          )}
      </ul>
    </div>
  )
};

MapRiderListPanel.propTypes = {
  riders: Proptypes.arrayOf(Proptypes.shape({})),
  regionDetail: Proptypes.shape({}),
};

export default MapRiderListPanel;
