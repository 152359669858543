export const RESET_ATTENDANCE_STATE = 'RESET_ATTENDANCE_STATE'
export const UPDATE_ATTENDANCE_PARAMS = 'UPDATE_ATTENDANCE_PARAMS'

export const GET_ATTENDANCE_LIST_REQUEST = 'GET_ATTENDANCE_LIST_REQUEST'
export const GET_ATTENDANCE_LIST_SUCCESS = 'GET_ATTENDANCE_LIST_SUCCESS'
export const GET_ATTENDANCE_LIST_FAILURE = 'GET_ATTENDANCE_LIST_FAILURE'

export const GET_FILTERED_ATTENDANCE_LIST_REQUEST = 'GET_FILTERED_ATTENDANCE_LIST_REQUEST'
export const GET_FILTERED_ATTENDANCE_LIST_SUCCESS = 'GET_FILTERED_ATTENDANCE_LIST_SUCCESS'
export const GET_FILTERED_ATTENDANCE_LIST_FAILURE = 'GET_FILTERED_ATTENDANCE_LIST_FAILURE'

export const GET_ATTENDANCE_SHIFTS_REQUEST = 'GET_ATTENDANCE_SHIFTS_REQUEST'
export const GET_ATTENDANCE_SHIFTS_SUCCESS = 'GET_ATTENDANCE_SHIFTS_SUCCESS'
export const GET_ATTENDANCE_SHIFTS_FAILURE = 'GET_ATTENDANCE_SHIFTS_FAILURE'

export const EMAIL_ATTENDANCE_CSV_REQUEST = 'EMAIL_ATTENDANCE_CSV_REQUEST';
export const EMAIL_ATTENDANCE_CSV_SUCCESS = 'EMAIL_ATTENDANCE_CSV_SUCCESS';
export const EMAIL_ATTENDANCE_CSV_FAILURE = 'EMAIL_ATTENDANCE_CSV_FAILURE';

export const GET_RIDER_SHIFT_ATTENDANCE_REQUEST = 'GET_RIDER_SHIFT_ATTENDANCE_REQUEST'
export const GET_RIDER_SHIFT_ATTENDANCE_SUCCESS = 'GET_RIDER_SHIFT_ATTENDANCE__SUCCESS'
export const GET_RIDER_SHIFT_ATTENDANCE_FAILURE = 'GET_RIDER_SHIFT_ATTENDANCE__FAILURE'

export const ADD_SHIFT_BREAK_REQUEST = 'ADD_SHIFT_BREAK_REQUEST';
export const ADD_SHIFT_BREAK_SUCCESS = 'ADD_SHIFT_BREAK_SUCCESS';
export const ADD_SHIFT_BREAK_FAILURE = 'ADD_SHIFT_BREAK_FAILURE';
