import React, { useState } from 'react';

const DeleteCustomerAddress = ({ customerAddressId, handleCustomerAddressDeletion, closeModal }) => {
  return (
    <>
      <div className="lightbox schedule">
        <div className="add-comment-lightbox">
          <a href=""
             className="lightbox-close icon-clear"
             onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}></a>
          <h2>Delete Customer Address</h2>
          <div className="content-holder">
            <div className="img-holder">
              <img src={require("../../assets/images/customer-address-delete.svg")} width="224" alt="" />
            </div>
            <div className="right-area">
              <div className="form-row">
                <p>Beware that your activity will be recorded.</p>
              </div>
              <div className="btns-holder">
                <a href="#" className="red"
                   onClick={e => {
                     e.preventDefault();
                     handleCustomerAddressDeletion(customerAddressId);
                   }}
                >Confirm Delete</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteCustomerAddress;
