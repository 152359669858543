import {types} from '../../actions/location';
import {INITIAL_STATE} from "../initial-state";
import {VERTICALS} from '../../utils/constants';
import {isEmpty} from "lodash";

export const location = (state = INITIAL_STATE.location, action) => {
  const {payload} = action;
  switch (action.type) {
    case types.GET_CITIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: {
          ...state.cities,
          list: action.payload.data,
          cityDetail: {
            ...state.cities.cityDetail,
            [payload.vertical]: isEmpty(state.cities.cityDetail[payload.vertical]) ? payload.data[0] : state.cities.cityDetail[payload.vertical],
            [VERTICALS.COMMON]: isEmpty(state.cities.cityDetail[payload.vertical]) ? payload.data[0] : state.cities.cityDetail[payload.vertical]
          }
        },
        loading: false,
      };
    case types.GET_CITIES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.SELECT_REGION_DETAIL:
      return {
        ...state,
        regions: {
          ...state.regions,
          regionDetail: {
            [payload.vertical]: payload.region,
            [VERTICALS.COMMON]: payload.region,
          }
        },
        loading: false
      };
    case types.SELECT_CITY_DETAIL:
      return {
        ...state,
        cities: {
          ...state.cities,
          cityDetail: {
            [payload.vertical]: payload.city,
            [VERTICALS.COMMON]: payload.city,
          }
        },
        loading: false
      };
    case types.GET_COLLECTION_CENTERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_COLLECTION_CENTERS_SUCCESS:
      return {
        ...state,
        collectionCenters: {
          list: action.payload,
        },
        loading: false
      };
    case types.GET_COLLECTION_CENTERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.GET_ALL_REGIONS_REQUEST:
    case types.GET_REGIONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_ALL_REGIONS_SUCCESS:
    case types.GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: {
          ...state.regions,
          list: action.payload.data,
          regionDetail: {
            ...state.regions.regionDetail,
            [payload.vertical]: isEmpty(state.regions.regionDetail[payload.vertical]) ? payload.data[0] : state.regions.regionDetail[payload.vertical],
            [VERTICALS.COMMON]: isEmpty(state.regions.regionDetail[payload.vertical]) ? payload.data[0] : state.regions.regionDetail[payload.vertical]
          }
        },
        loading: false,
      };
    case types.GET_ALL_REGIONS_FAILURE:
    case types.GET_REGIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_HOT_SPOTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_HOT_SPOTS_SUCCESS:
      return {
        ...state,
        hotSpots: {
          list: action.payload,
        },
        loading: false,
      };
    case types.GET_HOT_SPOTS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_RIDER_LIVE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_RIDER_LIVE_LOCATION_SUCCESS:
      return {
        ...state,
        liveLocation: action.payload,
        loading: false,
      };
    case types.GET_RIDER_LIVE_LOCATION_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
