import React from 'react';
import {formatAMPM, getGenderTitle, hasPermission, isAge} from '../../utils/helper';
import {PERMISSION} from "../../utils/constants";


const PatientInformationShow = (props) => {
  const { onDelete, onUpdate, patient, customerDetail } = props;


  return <div className="col-info selected-patient-info">
    <div className="head">
      <h2>Patient Information</h2>
      <div className="links">
        <a href="" className="update"
           onClick={onUpdate}
        >Update</a>
        {hasPermission(PERMISSION.CAN_DELETE_PATIENT) && <a href="" className="delete" onClick={onDelete}>Delete</a>}
      </div>
    </div>
    <ul className="patient-info-list">
      <li>
        <span className="label-text">Patient First Name *</span>
        <span className="name">{patient.firstName}</span>
      </li>
      <li>
        <span className="label-text">Patient Last Name *</span>
        <span className="name">{patient.lastName}</span>
      </li>
      <li>
        <span className="label-text">Phone</span>
        <span className="name">{patient.phone || 'N/A'}</span>
      </li>
      <li>
        <span className="label-text">Secondary Phone</span>
        <span className="name">{patient.secondaryPhone || 'N/A'}</span>
      </li>
      <li>
        <span className="label-text">Email</span>
        <span className="name">{patient.email || 'N/A'}</span>
      </li>
      <li>
        <span className="label-text">Gender</span>
        <span className="name">{getGenderTitle(patient.gender)}</span>
      </li>
      <li>
        <span className="label-text">Age</span>
        <span className="name">{`${patient?.age || patient?.ageString}`} </span>
      </li>
    </ul>

    <div className="last-order">
      <span className="title">Last Order :</span>
      {customerDetail.lastOrder && customerDetail.lastOrder.id?<span
          className="value">{`${new Date(customerDetail.lastOrder.created).toLocaleDateString()} ${formatAMPM(new Date(customerDetail.lastOrder.created))}`}</span>
        : <span className="value">---</span>
      }
    </div>
  </div>
};

export default PatientInformationShow;
