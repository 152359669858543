// ORDERS
export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

// UNVERIFIED ORDERS
export const GET_UNVERIFIED_ORDERS_REQUEST = 'GET_UNVERIFIED_ORDERS_REQUEST';
export const GET_UNVERIFIED_ORDERS_SUCCESS = 'GET_UNVERIFIED_ORDERS_SUCCESS';
export const GET_UNVERIFIED_ORDERS_FAILURE = 'GET_UNVERIFIED_ORDERS_FAILURE';

// PARTIAL ORDERS
export const GET_PARTIAL_ORDERS_REQUEST = 'GET_PARTIAL_ORDERS_REQUEST';
export const GET_PARTIAL_ORDERS_SUCCESS = 'GET_PARTIAL_ORDERS_SUCCESS';
export const GET_PARTIAL_ORDERS_FAILURE = 'GET_PARTIAL_ORDERS_FAILURE';

export const GET_PARTIAL_ORDER_DETAIL_REQUEST = 'GET_PARTIAL_ORDER_DETAIL_REQUEST';
export const GET_PARTIAL_ORDER_DETAIL_SUCCESS = 'GET_PARTIAL_ORDER_DETAIL_SUCCESS';
export const GET_PARTIAL_ORDER_DETAIL_FAILURE = 'GET_PARTIAL_ORDER_DETAIL_FAILURE';

// FILTERED ORDERS
export const GET_FILTERED_ORDERS_REQUEST = 'GET_FILTERED_ORDERS_REQUEST';
export const GET_FILTERED_ORDERS_SUCCESS = 'GET_FILTERED_ORDERS_SUCCESS';
export const GET_FILTERED_ORDERS_FAILURE = 'GET_FILTERED_ORDERS_FAILURE';

// FILTERED UNVERIFIED ORDERS
export const GET_FILTERED_UNVERIFIED_ORDERS_REQUEST = 'GET_FILTERED_UNVERIFIED_ORDERS_REQUEST';
export const GET_FILTERED_UNVERIFIED_ORDERS_SUCCESS = 'GET_FILTERED_UNVERIFIED_ORDERS_SUCCESS';
export const GET_FILTERED_UNVERIFIED_ORDERS_FAILURE = 'GET_FILTERED_UNVERIFIED_ORDERS_FAILURE';

// FILTERED PARTIAL ORDERS
export const GET_FILTERED_PARTIAL_ORDERS_REQUEST = 'GET_FILTERED_PARTIAL_ORDERS_REQUEST';
export const GET_FILTERED_PARTIAL_ORDERS_SUCCESS = 'GET_FILTERED_PARTIAL_ORDERS_SUCCESS';
export const GET_FILTERED_PARTIAL_ORDERS_FAILURE = 'GET_FILTERED_PARTIAL_ORDERS_FAILURE';

export const CANCEL_PARTIAL_ORDERS_REQUEST = 'CANCEL_PARTIAL_ORDERS_REQUEST';
export const CANCEL_PARTIAL_ORDERS_SUCCESS = 'CANCEL_PARTIAL_ORDERS_SUCCESS';
export const CANCEL_PARTIAL_ORDERS_FAILURE = 'CANCEL_PARTIAL_ORDERS_FAILURE';

// DASHBOARD ORDERS
export const GET_DASHBOARD_ORDERS_REQUEST = 'GET_DASHBOARD_ORDERS_REQUEST';
export const GET_DASHBOARD_ORDERS_SUCCESS = 'GET_DASHBOARD_ORDERS_SUCCESS';
export const GET_DASHBOARD_ORDERS_FAILURE = 'GET_DASHBOARD_ORDERS_FAILURE';

// ORDERS STATUS
export const GET_ORDERS_STATES_REQUEST = 'GET_ORDERS_STATES_REQUEST';
export const GET_ORDERS_STATES_SUCCESS = 'GET_ORDERS_STATES_SUCCESS';
export const GET_ORDERS_STATES_FAILURE = 'GET_ORDERS_STATES_FAILURE';

// ORDER
export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';

// ORDER SCAN TYPES
export const GET_ORDER_SCAN_TYPES_REQUEST = 'GET_ORDER_SCAN_TYPES_REQUEST';
export const GET_ORDER_SCAN_TYPES_SUCCESS = 'GET_ORDER_SCAN_TYPES_SUCCESS';
export const GET_ORDER_SCAN_TYPES_FAILURE = 'GET_ORDER_SCAN_TYPES_FAILURE';

// CREATE ORDER
export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

// ASSIGN ORDER
export const ASSIGN_ORDER_REQUEST = 'ASSIGN_ORDER_REQUEST';
export const ASSIGN_ORDER_SUCCESS = 'ASSIGN_ORDER_SUCCESS';
export const ASSIGN_ORDER_FAILURE = 'ASSIGN_ORDER_FAILURE';
// RE ASSIGN ORDER
export const REASSIGN_ORDER_REQUEST = 'REASSIGN_ORDER_REQUEST';
export const REASSIGN_ORDER_SUCCESS = 'REASSIGN_ORDER_SUCCESS';
export const REASSIGN_ORDER_FAILURE = 'REASSIGN_ORDER_FAILURE';
// ASSIGN ORDER
export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';

//  ORDER STATS
export const GET_ORDER_STATS_REQUEST = 'GET_ORDER_STATS_REQUEST';
export const GET_ORDER_STATS_SUCCESS = 'GET_ORDER_STATS_SUCCESS';
export const GET_ORDER_STATS_FAILURE = 'GET_ORDER_STATS_FAILURE';

export const RESET_ORDER_STATE = 'RESET_ORDER_STATE';
export const RESET_SCAN_TYPES_STATE = 'RESET_SCAN_TYPES_STATE';
export const UPDATE_ORDER_PARAMS = 'UPDATE_ORDER_PARAMS';

export const RESCHEDULE_ORDER_REQUEST = 'RESCHEDULE_ORDER_REQUEST';
export const RESCHEDULE_ORDER_SUCCESS = 'RESCHEDULE_ORDER_SUCCESS';
export const RESCHEDULE_ORDER_FAILURE = 'RESCHEDULE_ORDER_FAILURE';

export const GET_ORDER_ACTIVITIES_REQUEST = 'GET_ORDER_ACTIVITIES_REQUEST';
export const GET_ORDER_ACTIVITIES_SUCCESS = 'GET_ORDER_ACTIVITIES_SUCCESS';
export const GET_ORDER_ACTIVITIES_FAILURE = 'GET_ORDER_ACTIVITIES_FAILURE';

export const RESET_ORDER_ACTIVITIES_STATE = 'RESET_ORDER_ACTIVITIES_STATE';

export const GET_HEAR_ABOUT_US_LIST_REQUEST = 'GET_HEAR_ABOUT_US_LIST_REQUEST';
export const GET_HEAR_ABOUT_US_LIST_SUCCESS = 'GET_HEAR_ABOUT_US_LIST_SUCCESS';
export const GET_HEAR_ABOUT_US_LIST_FAILURE = 'GET_HEAR_ABOUT_US_LIST_FAILURE';

export const GET_PROBLEM_STRINGS_REQUEST = 'GET_PROBLEM_STRINGS_REQUEST';
export const GET_PROBLEM_STRINGS_SUCCESS = 'GET_PROBLEM_STRINGS_SUCCESS';
export const GET_PROBLEM_STRINGS_FAILURE = 'GET_PROBLEM_STRINGS_FAILURE';

export const GET_ORDER_TIME_SLOTS_REQUEST = 'GET_ORDER_TIME_SLOTS_REQUEST';
export const GET_ORDER_TIME_SLOTS_SUCCESS = 'GET_ORDER_TIME_SLOTS_SUCCESS';
export const GET_ORDER_TIME_SLOTS_FAILURE = 'GET_ORDER_TIME_SLOTS_FAILURE';
export const RESET_ORDER_TIME_SLOTS_STATE = 'RESET_ORDER_TIME_SLOTS_STATE';

export const COPY_ORDER = 'COPY_ORDER';
export const RESET_COPY_ORDER = 'RESET_COPY_ORDER';

export const ORDER_NEEDS_REVIEW_REQUEST = 'ORDER_NEEDS_REVIEW_REQUEST';
export const ORDER_NEEDS_REVIEW_SUCCESS = 'ORDER_NEEDS_REVIEW_SUCCESS';
export const ORDER_NEEDS_REVIEW_FAILURE = 'ORDER_NEEDS_REVIEW_FAILURE';

export const GET_LATE_ORDERS_REQUEST = 'GET_LATE_ORDERS_REQUEST';
export const GET_LATE_ORDERS_SUCCESS = 'GET_LATE_ORDERS_SUCCESS';
export const GET_LATE_ORDERS_FAILURE = 'GET_LATE_ORDERS_FAILURE';

export const EMAIL_ORDERS_CSV_REQUEST = 'EMAIL_ORDERS_CSV_REQUEST';
export const EMAIL_ORDERS_CSV_SUCCESS = 'EMAIL_ORDERS_CSV_SUCCESS';
export const EMAIL_ORDERS_CSV_FAILURE = 'EMAIL_ORDERS_CSV_FAILURE';

export const GET_CANCELLATION_STRINGS_REQUEST = 'GET_CANCELLATION_STRINGS_REQUEST';
export const GET_CANCELLATION_STRINGS_SUCCESS = 'GET_CANCELLATION_STRINGS_SUCCESS';
export const GET_CANCELLATION_STRINGS_FAILURE = 'GET_CANCELLATION_STRINGS_FAILURE';

//on time rate stats
export const GET_ON_TIME_RATE_STATS_REQUEST = 'GET_ON_TIME_RATE_STATS_REQUEST';
export const GET_ON_TIME_RATE_STATS_SUCCESS = 'GET_ON_TIME_RATE_STATS_SUCCESS';
export const GET_ON_TIME_RATE_STATS_FAILURE = 'GET_ON_TIME_RATE_STATS_FAILURE';

export const OFFLINE_IN_PROGRESS_REQUEST = 'OFFLINE_IN_PROGRESS_REQUEST';
export const OFFLINE_IN_PROGRESS_SUCCESS = 'OFFLINE_IN_PROGRESS_SUCCESS';
export const OFFLINE_IN_PROGRESS_FAILURE = 'OFFLINE_IN_PROGRESS_FAILURE';

export const DELIVER_ORDER_REQUEST = 'DELIVER_ORDER_REQUEST';
export const DELIVER_ORDER_SUCCESS = 'DELIVER_ORDER_SUCCESS';
export const DELIVER_ORDER_FAILURE = 'DELIVER_ORDER_FAILURE';

//external order

export const VERIFY_EXTERNAL_ORDER_REQUEST = 'VERIFY_EXTERNAL_ORDER_REQUEST';
export const VERIFY_EXTERNAL_ORDER_SUCCESS = 'VERIFY_EXTERNAL_ORDER_SUCCESS';
export const VERIFY_EXTERNAL_ORDER_FAILURE = 'VERIFY_EXTERNAL_ORDER_FAILURE';

export const UPDATE_EXTERNAL_ORDER_PARAMS = 'UPDATE_EXTERNAL_ORDER_PARAMS';

export const FOLLOW_UP_ORDER_REQUEST = 'FOLLOW_UP_ORDER_REQUEST';
export const FOLLOW_UP_ORDER_SUCCESS = 'FOLLOW_UP_ORDER_SUCCESS';
export const FOLLOW_UP_ORDER_FAILURE = 'FOLLOW_UP_ORDER_FAILURE';

export const ORDER_UPDATE_ADDITIONAL_NOTES_REQUEST = 'ORDER_UPDATE_ADDITIONAL_NOTES_REQUEST';
export const ORDER_UPDATE_ADDITIONAL_NOTES_SUCCESS = 'ORDER_UPDATE_ADDITIONAL_NOTES_SUCCESS';
export const ORDER_UPDATE_ADDITIONAL_NOTES_FAILURE = 'ORDER_UPDATE_ADDITIONAL_NOTES_FAILURE';

export const ORDER_UPDATE_SCAN_TYPES_REQUEST = 'ORDER_UPDATE_SCAN_TYPES_REQUEST';
export const ORDER_UPDATE_SCAN_TYPES_SUCCESS = 'ORDER_UPDATE_SCAN_TYPES_SUCCESS';
export const ORDER_UPDATE_SCAN_TYPES_FAILURE = 'ORDER_UPDATE_SCAN_TYPES_FAILURE';

export const GET_ORDER_SOURCES_REQUEST = 'GET_ORDER_SOURCES_REQUEST';
export const GET_ORDER_SOURCES_SUCCESS = 'GET_ORDER_SOURCES_SUCCESS';
export const GET_ORDER_SOURCES_FAILURE = 'GET_ORDER_SOURCES_FAILURE';

export const GET_REASSIGNMENT_STRINGS_REQUEST = 'GET_REASSIGNMENT_STRINGS_REQUEST';
export const GET_REASSIGNMENT_STRINGS_SUCCESS = 'GET_REASSIGNMENT_STRINGS_SUCCESS';
export const GET_REASSIGNMENT_STRINGS_FAILURE = 'GET_REASSIGNMENT_STRINGS_FAILURE';

export const ORDER_FOLLOW_UP_ADDRESSED_REQUEST = 'ORDER_FOLLOW_UP_ADDRESSED_REQUEST';
export const ORDER_FOLLOW_UP_ADDRESSED_SUCCESS = 'ORDER_FOLLOW_UP_ADDRESSED_SUCCESS';
export const ORDER_FOLLOW_UP_ADDRESSED_FAILURE = 'ORDER_FOLLOW_UP_ADDRESSED_FAILURE';

export const GET_ADDRESS_TYPES_REQUEST = 'GET_ADDRESS_TYPES_REQUEST';
export const GET_ADDRESS_TYPES_SUCCESS = 'GET_ADDRESS_TYPES_SUCCESS';
export const GET_ADDRESS_TYPES_FAILURE = 'GET_ADDRESS_TYPES_FAILURE';

export const GET_RESCHEDULE_STRINGS_REQUEST = 'GET_RESCHEDULE_STRINGS_REQUEST';
export const GET_RESCHEDULE_STRINGS_SUCCESS = 'GET_RESCHEDULE_STRINGS_SUCCESS';
export const GET_RESCHEDULE_STRINGS_FAILURE = 'GET_RESCHEDULE_STRINGS_FAILURE';
