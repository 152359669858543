import { apiCaller } from '../api-caller';
import { ENDPOINTS, REQUEST_TYPES } from '../constants';
import {getLocalStorageItem} from "../../utils/helper";
import {ONE_SIGNAL_PLAYER_ID} from "../../utils/constants";

export const loginAPI = data => apiCaller({
  method: REQUEST_TYPES.POST,
  url: ENDPOINTS.LOGIN,
  data: {
    ...data,
    onesignalPlayerId: getLocalStorageItem(ONE_SIGNAL_PLAYER_ID)
  }
});
export const logoutAPI = data => apiCaller({
  method: REQUEST_TYPES.POST,
  url: ENDPOINTS.LOGOUT,
  data,
});

export const refreshTokenAPI = data => apiCaller({
  method: REQUEST_TYPES.POST,
  url: ENDPOINTS.AUTH_REFRESH_TOKEN_GET,
  data,
});

export const getNpStaffUsersAPI = () => apiCaller({
  method: REQUEST_TYPES.GET,
  url: ENDPOINTS.GET_NP_STAFF_USERS,
});

export const initiatePasswordResetAPI = data => apiCaller({
  method: REQUEST_TYPES.POST,
  url: ENDPOINTS.INITIATE_PASSWORD_RESET,
  data: data
});

export const passwordResetAPI = data => apiCaller({
  method: REQUEST_TYPES.POST,
  url: ENDPOINTS.PASSWORD_RESET,
  data: data
});
