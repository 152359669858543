import React, { useState, useEffect } from 'react';
import Modal from "react-modal";
import CreateAddressForm from "../order/form/create-address";
import { findIndex } from 'lodash';
import {Marker, Popup} from "react-leaflet";
import {CustomerIcon} from "../../assets/images/Icons";
import Map from "../commons/Map";
import {PAKISTAN_LAT_LONG, VERTICALS} from "../../utils/constants";

Modal.setAppElement(document.getElementById('root'));

const CustomerCreateStepTwo = props => {

  const {
    customerDetail,
    createCustomerAddress,
    getCustomerDetail,
    resetCustomerState,
    deleteCustomerAddress,
    cities
  } = props;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [marker, setMarker] = useState([PAKISTAN_LAT_LONG.lat, PAKISTAN_LAT_LONG.lng]);
  const [selectedAddressId, setSelectedAddressId] = useState(0);

  const openModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };
  const closeModal = (lat, lng) => {
    setIsOpen(false);
    if(lat && lng){
      setMarker([lat, lng]);
    }
  };

  useEffect(() => {
    if (!customerDetail.addresses){
      return;
    }
    if(selectedAddressId===0 && customerDetail.addresses.length){
      setSelectedAddressId(customerDetail.addresses[0].id);
      const {latitude, longitude} = customerDetail.addresses[0];
      setMarker([latitude, longitude]);
    }
    else if(selectedAddressId && customerDetail.addresses.length){
      const selectedAddressIndex = findIndex(customerDetail.addresses, {id:selectedAddressId});
      if(selectedAddressIndex>-1) {
        const {latitude, longitude} = customerDetail.addresses[selectedAddressIndex];
        setMarker([latitude, longitude]);
      }
    }
  }, [customerDetail && customerDetail.addresses && customerDetail.addresses.length]);

  const goBack = () =>{
    resetCustomerState();
    props.history.goBack();
  };

  return <div className="order-page customer-detail">
    <div className="order-right-info">
      <div className="head">
        <div className="customer-info-order">
          <h2></h2>
          <span className="number"></span>
        </div>
        <ul className="order-step">
          <li className="done"><span className="step-number">1</span><span className="text">Details</span></li>
          <li className="active"><span className="step-number">2</span><span className="text">Address</span></li>
        </ul>
        <ul className="btn-list">
          <li>
            {/*<button className="blue back" onClick={props.previousStep}>Back</button>*/}
          </li>
          <li>
            <button className="gray" onClick={goBack}>Cancel</button>
          </li>
          <li>
            {customerDetail.addresses && customerDetail.addresses.length > 0 && <button className="green" onClick={goBack} >Update</button>}
          </li>
        </ul>
      </div>
      <div className="customer-loc-cols">
        <div className="customer-map">
          <Map
            initialCenter={marker}
            initialZoom={15}
            currentCenter={marker}
            currentZoom={15}
            styles={{height: "100%", zIndex: 0}}
          >
            {
              customerDetail.addresses.map(address =>
                <Marker
                  icon={CustomerIcon}
                  position={[+address.latitude, +address.longitude]}
                >
                  <Popup>
                    {address.address}
                  </Popup>
                </Marker>
              )
            }
          </Map>
        </div>

        <div className="order-address">
          <h2>Address</h2>
          <ul className="order-address-list">
            {
              customerDetail.addresses && customerDetail.addresses.map((address) => {
                return <li key={address.id} id={address.id}
                           className={ address.id===selectedAddressId ?'current': ''}
                           onClick={(e) => {
                             setSelectedAddressId(address.id);
                             setMarker([address.latitude, address.longitude]);
                           }}><b>{address.regionCustomerAddresses && address.regionCustomerAddresses[0].region.name}</b>: {address.address}
                  <a href=''
                     className="cancel icon-clear"
                     onClick={(e) => {
                       e.preventDefault();
                       deleteCustomerAddress({ customerAddressId: address.id })
                     }}
                  ></a>
                </li>

              })
            }
          </ul>
          <a className="add-address" onClick={(e) => openModal(e)}>Add New Address</a>

          <div id="wrapper" className="inner-page">

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
            >
              {customerDetail.id &&
              <CreateAddressForm
                customerId={customerDetail.id}
                closeModal={closeModal}
                getCustomerDetail={getCustomerDetail}
                createCustomerAddress={createCustomerAddress}
                cities={cities}
                vertical={VERTICALS.blood}
              />
              } </Modal>
          </div>
        </div>
      </div>
    </div>
  </div>
};

export default CustomerCreateStepTwo;
