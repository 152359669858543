import React, {useEffect, useState} from 'react';
import * as _ from 'lodash';
import {disableDiv, fullName} from '../../utils/helper';
import {toast} from 'react-toastify';
import Modal from 'react-modal';
import Confirmation from '../commons/confirmation';
import ShiftsUpdate from './shift-update';
import {startCase} from "lodash";
import {Field, Formik} from "formik";

const ShiftsDetail = (props) => {
  const {
    match: {params: {id}},
    hotSpots,
    getHotSpots,
    shiftsLoading,
    resetShiftState,
    getShiftDetail,
    getShiftRiders,
    shiftDetail,
    shiftRiders,
    regionDetail,
    getRidersSearch,
    ridersSearch,
    resetRiderState,
    createShiftRider,
    updateShiftRider,
    deleteShiftRider,
    removeRiderSearchItem,
    addShiftRider,
    removeShiftRider,
    updateShift,
    deleteShift,
  } = props;
  const [isOpenShiftDeleteModal, setIsOpenShiftDeleteModal] = useState(false);
  const [shiftRiderIds, setShiftRiderIds] = useState([])


  useEffect(() => {
    getShiftDetail(id)
    getShiftRiders({shiftSchedule: id})
    return () => {
      resetShiftState()
      resetRiderState()
    }
  }, [])

  useEffect(() => {
    if (!shiftDetail?.region) {
      return
    }
    getHotSpots({region: shiftDetail.region.id})
  }, [shiftDetail.region])

  useEffect(() => {
    shiftDetail && shiftDetail.region && getRidersSearch({regions: shiftDetail.region.id});
    shiftDetail && shiftDetail.riders && shiftDetail.riders.length && shiftDetail.riders.map(
      rider => setShiftRiderIds([...shiftRiderIds, rider.id]))
  }, [shiftDetail.region])

  const closeShiftDeleteModal = () => {
    setIsOpenShiftDeleteModal(false)
  }

  const onDelete = (e) => {
    e.preventDefault();
    setIsOpenShiftDeleteModal(true)
  };

  const addToShift = (rider) => {
    if (shiftRiders.find((shift => shift.rider.id === rider.id))) {
      toast.error("Rider already added")
      return
    } else {
      toast.warn("Set shift hotspot to added rider")
    }
    addShiftRider({shiftSchedule: shiftDetail.id, rider, hotspot: '', allowBreak: false},)
    removeRiderSearchItem(rider.id)
  }

  const handleOnHotSpotChange = ({hotspot}, shift) => {
    if (!hotspot) {
      toast.error('Please select hotspot!')
    }
    if (_.isEmpty(shift) || !hotspot) {
      return
    }
    const data = {
      shiftSchedule: shift.shiftSchedule,
      rider: shift.rider.id,
      hotspot
    }
    if (shift.id) {
      updateShiftRider({
        id: shift.id,
        data: data
      })
    } else {
      createShiftRider(data)
    }
  };

  const handleShiftRiderDelete = (shift) => {
    if (shift.id) {
      deleteShiftRider(shift.id)
    } else {
      removeShiftRider(shift.rider.id)
    }
  };
  return (
    <div id="wrapper" style={disableDiv(shiftsLoading)}>
      <div className="shift-update-content">
        <div className="shift-update-container">
          <ShiftsUpdate
            shiftDetail={shiftDetail}
            updateShift={updateShift}
            onDelete={onDelete}
            shiftRiders={shiftRiders}
          />
          <div className=" add-rider-input">
        <span className=" labels">
           Riders <span>{shiftRiders.length}</span>
        </span>
            <div className="auto-fill">
              <div className="rider-bar">
                <select
                  name="rider"
                  onChange={e => {
                    addToShift(ridersSearch.find(rider => rider.id == e.target.value))
                  }}
                >
                  <option value="" label="Select a rider"/>
                  {
                    ridersSearch.filter(
                      rider => !shiftRiderIds.includes(rider.id) && regionDetail && regionDetail.name == rider.regionName
                    ).map(rider => {
                      return <option
                        key={rider.id}
                        value={rider.id}
                        label={`${startCase(fullName(rider))} - ${rider.regionName}`}
                      />
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="six-cols">
            <div className="col name">
              <span className="title">Name</span>
            </div>
            <div className="col spot">
              <span className="title">Hotspot </span>
            </div>
            <div className="col btn-col">
              <span className="title">&nbsp;</span>
            </div>
            <div className="col action">
              <span className="title">&nbsp;</span>
            </div>
          </div>
          <div className="shift-table">
            {
              shiftRiders.map((shift, index) => {
                const initialValues = {
                  hotspot: shift.hotspot
                }
                return (
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}>
                    {
                      ({
                         touched,
                         errors,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isValid,
                         isSubmitting,
                         values,
                         setFieldValue,
                         form,
                         field,
                       }) => (
                        <form className="six-cols add">
                          <div className="col name">
                            <span className="name">{fullName(shift.rider)}</span>
                            <span className="tel">{shift.rider.phone}</span>
                          </div>
                          <div className="col spot">
                            <Field
                              as='select'
                              name="hotspot"
                              onChange={(e) => {
                                setFieldValue('hotspot', e.target.value)
                                shift.isDisabled = false
                              }}
                            >
                              <option value="" label="Select a hotspot"/>
                              {
                                hotSpots.map(hotSpot => {
                                  return <option
                                    key={hotSpot.id}
                                    value={hotSpot.id}
                                    label={hotSpot.name}
                                  />
                                })
                              }
                            </Field>
                          </div>
                          <div className='col btn-col'>
                            <button href="" className={`save-btn ${shift.isDisabled && 'disabled'}`}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      !shift.isDisabled &&
                                      handleOnHotSpotChange(values, shift)
                                    }}
                            >Save
                            </button>
                          </div>
                          <div className="col action"><i onClick={(e) => handleShiftRiderDelete(shift)}
                                                         className="icon-minus-circle"/></div>
                        </form>
                      )
                    }
                  </Formik>
                )
              })
            }
          </div>
        </div>
      </div>

      {isOpenShiftDeleteModal &&
      <Modal isOpen={isOpenShiftDeleteModal} onRequestClose={closeShiftDeleteModal}
             className='small-modal'>
        <Confirmation
          text='You want to delete this shift?'
          onConfirm={() => deleteShift(shiftDetail.id)}
          onClose={closeShiftDeleteModal}
        />
      </Modal>
      }

    </div>
  )
};

export default ShiftsDetail;
