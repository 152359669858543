import React from 'react';
import {Link} from 'react-router-dom';
import {qsStringify} from '../../utils/helper';
import {defaultOrderListSorting, VERTICALS} from '../../utils/constants';
import {ROUTES} from "../../routes/constants";

export const OrdersSummary = ({stats, vertical}) => {
  const {total, pending, completed, inProgress, unverified, escalated} = stats;
  const problemOrdersParamsPath = qsStringify({
    states: ['problem'],
    sorting: defaultOrderListSorting,
  });
  const ordersParamsPath = qsStringify({
    sorting: defaultOrderListSorting,
  });

  return <div className="dashbord-order-panel">
    <div className="order-area">
      <div className="col">
        <span className="title">Orders</span>
        <img src={require("../../images/order-icon.svg")} alt=""/>
      </div>
      <div className="col">
        <Link to={vertical === VERTICALS.blood ? ROUTES.BLOOD_ORDERS : ROUTES.XRAY_ORDERS} className="view-more">View all</Link>
        <span className="order-title">
              {total} <span>Total</span>
            </span>
      </div>
    </div>
    <div className="esclated-tag">
      <img src={require("../../images/esclated-icon.svg")} alt=""/>
      <span className="info">
            {escalated} <span><Link
        to={{pathname: `/${vertical}/orders`, search: '?' + problemOrdersParamsPath}}>Escalated</Link></span>
            </span>
    </div>
    <div className="unverified-tag">
      <img src={require("../../images/esclated-icon.svg")} alt=""/>
      <span className="info">
            {unverified} <span><Link
        to={{pathname: '/unverified-orders', search: '?' + ordersParamsPath}}>Unverified</Link></span>
            </span>
    </div>
    <ul className="order-progress">
      <li><i className="icon-start"/> <span className="number">{pending} <span>Pending</span></span></li>
      <li><i className="icon-waiting"/><span className="number">{inProgress} <span>In Progress</span></span>
      </li>
      <li><i className="icon-confrim"/><span className="number">{completed} <span>Completed</span></span>
      </li>
    </ul>
  </div>
};
