import React, {useEffect, useState} from 'react';
import Proptypes from 'prop-types';
import {startOfToday, endOfToday} from 'date-fns'
import {
  IS_SHOW_ORDERS,
  IS_SHOW_RIDERS,
  IS_SHOW_CONNECTION_CENTERS,
  ONE_SIGNAL_PUSH_GRANTED,
  ONE_SIGNAL_PLAYER_ID,
  VERTICALS,
  IS_SHOW_BOUNDARY,
  IS_SHOW_CELLS,
  IS_SHOW_BLACK_LISTED_AREAS,
} from '../../utils/constants';
import {Nav} from '../../components/commons/nav';
import {RidersSummary} from "../commons/riders-summary";
import {DashboardMapHolder} from "../commons/dashboard-map-holder";
import {OrdersSummary} from "../commons/orders-summary";
import {StatsSummary} from "../commons/stats-summary";
import {RegionsList} from "../commons/regions-list";
import {
  getLocalStorageBoolean,
  setLocalStorageBoolean,
  setLocalStorageItem
} from '../../utils/helper';
import Modal from 'react-modal';
import OrderCancel from '../order/order-cancel';
import {DashboardSidebar} from './dashboard-sidebar';
import refreshImage from '../../assets/images/refresh-white.png'
import MapRiderListPanel from "./map-rider-list-panel";

export const BloodDashboard = props => {
  const {
    getRegions,
    getRiders,
    orders,
    riders,
    getOrderStats,
    orderStats,
    getRiderStats,
    riderStats,
    regions,
    bloodRegionDetail: regionDetail,
    selectRegionDetail,
    getCollectionCenters,
    collectionCenters,
    cancelOrder,
    getDashboardOrders,
    updateOrderParams,
    updateAttendanceParams,
    updateRiderParams,
    history,
    resetOrderState,
    lateOrders,
    getLateOrders,
    getOnTimeRateStats,
    logoutAttempt,
  } = props;

  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;

  if (OneSignal.installServiceWorker) {
    OneSignal.installServiceWorker();
  } else {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.register(`/OneSignalSDKWorker.js?appId=${process.env.REACT_APP_ONE_SIGNAL_APP_ID}`);
    }
  }

  useEffect(() => {
    getRegions({vertical: VERTICALS.BLOOD});

    OneSignal.push(() => {
      OneSignal.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID
      });
    });

    OneSignal.push(["getNotificationPermission", permission => {
      if (permission !== ONE_SIGNAL_PUSH_GRANTED) {
        OneSignal.showSlidedownPrompt({force: true});
      }
    }]);

    OneSignal.push(() => {
      OneSignal.on('subscriptionChange', async isSubscribed => {
        if (isSubscribed) {
          const playerId = await OneSignal.getUserId();
          setLocalStorageItem(ONE_SIGNAL_PLAYER_ID, playerId);
          logoutAttempt();
        }
      });
    });
  }, []);


  const [isShowCollectionCenters, setIsShowCollectionCenters] = useState(
    getLocalStorageBoolean(IS_SHOW_CONNECTION_CENTERS) === null ? true : getLocalStorageBoolean(IS_SHOW_CONNECTION_CENTERS));
  const [isShowOrders, setIsShowOrders] = useState(
    getLocalStorageBoolean(IS_SHOW_ORDERS) === null ? true : getLocalStorageBoolean(IS_SHOW_ORDERS));
  const [isShowRiders, setIsShowRiders] = useState(
    getLocalStorageBoolean(IS_SHOW_RIDERS) === null ? true : getLocalStorageBoolean(IS_SHOW_RIDERS));
  const [isShowCells, setIsShowCells] = useState(
    getLocalStorageBoolean(IS_SHOW_CELLS) === null ? false : getLocalStorageBoolean(IS_SHOW_CELLS));
  const [isShowBoundary, setIsShowBoundary] = useState(
    getLocalStorageBoolean(IS_SHOW_BOUNDARY) === null ? true : getLocalStorageBoolean(IS_SHOW_BOUNDARY));
  const [isShowBlackListedAreas, setIsShowBlackListedAreas] = useState(
    getLocalStorageBoolean(IS_SHOW_BLACK_LISTED_AREAS) === null ? false : getLocalStorageBoolean(IS_SHOW_BLACK_LISTED_AREAS));

  const [mapFull, setMapFull] = useState('');
  const [isOpenCancelOrderModal, setIsOpenCancelOrderModal] = useState(0);
  const dashboardOrderFilter = {
    states: ['assigned', 'reached', 'collected'],
    pickupFrom: startOfToday(),
    pickupTo: endOfToday(),
  };
  const lateOrderFilter = {pickupFrom: startOfToday(), pickupTo: endOfToday()};
  const orderStatsFilter = {pickupFrom: startOfToday(), pickupTo: endOfToday(),};

  useEffect(() => {
    if (!regionDetail?.id) {
      return;
    }
    const {id} = regionDetail;
    getRiders({regions: [id]});
    getCollectionCenters({regionId: id});
    getDashboardOrders({...dashboardOrderFilter, regions: [id], vertical: VERTICALS.blood});
    getRiderStats({regionId: id});
    getOrderStats({...orderStatsFilter, regionId: id, vertical: VERTICALS.blood});
    getLateOrders({...lateOrderFilter, regions: [id], vertical: VERTICALS.blood});
    getOnTimeRateStats({regionId: id})
    return () => {
      resetOrderState();
    }
  }, [regionDetail]);

  if (!regionDetail) {
    return <p>loading...</p>
  }

  const onCancelOrderClick = (orderId) => {
    setIsOpenCancelOrderModal(orderId);
  };
  const orderCancel = (orderId, data) => {
    cancelOrder({
      id: orderId,
      data,
      filter: {...dashboardOrderFilter, regionId: regionDetail.id},
      isLoadDashboardOrders: true
    });
    setIsOpenCancelOrderModal(false);
  };

  const closeCancelOrderModal = () => {
    setIsOpenCancelOrderModal(false);
  };
  return (
    <div id="wrapper" className={`inner-page ${mapFull}`}>
      <Nav/>
      <MapRiderListPanel riders={riders} regionDetail={regionDetail}/>
      <DashboardSidebar riders={riders}
                        lateOrders={lateOrders}
                        history={history}
      />
      <div className="dashbord-content">
        <div className="top-content">
          <div className="map-holder">
            <DashboardMapHolder riders={riders}
                                collectionCenters={collectionCenters}
                                orders={orders}
                                isShowCollectionCenters={isShowCollectionCenters}
                                isShowOrders={isShowOrders}
                                isShowRiders={isShowRiders}
                                isShowBoundary={isShowBoundary}
                                isShowCells={isShowCells}
                                isShowBlackListedAreas={isShowBlackListedAreas}
                                onCancelOrderClick={onCancelOrderClick}
                                dashboardOrderFilter={dashboardOrderFilter}
                                history={history}
                                mapFull={mapFull}
                                regionDetail={regionDetail}
            />
            <a onClick={() => {
              const mapSize = mapFull ? '' : 'map-full';
              setMapFull(mapSize);
            }} className="full-map-btn">
              {mapFull ? 'Switch to Basic Mode' : 'Switch to Detail Mode'}
              <span className="switch">
                            {!mapFull && <img src={require("../../images/map-sm.svg")} alt="" className="map-sm"/>}
                {mapFull &&
                <img src={require("../../images/map-view.svg")} alt="" className="map-view"/>}
                        </span>
            </a>

            <button
              className='refresh-button'
              onClick={() => {
                const {id} = regionDetail;
                if (!id) {
                  return;
                }
                getRiders({regions: [id]});
                getRiderStats({regionId: id});
                getOrderStats({...orderStatsFilter, regionId: id, vertical: VERTICALS.blood});
              }}
              title={'Refresh'}
            >
              <img src={refreshImage}/>
            </button>
          </div>

          <div className="map-right-info">

            <RegionsList regions={regions}
                         regionDetail={regionDetail}
                         selectRegionDetail={selectRegionDetail}
                         updateOrderParams={updateOrderParams}
                         updateRiderParams={updateRiderParams}
                         updateAttendanceParams={updateAttendanceParams}
                         vertical={VERTICALS.BLOOD}
            />
            {<ul className="sort-rider-order">
              <li>
                <span className="title">CC</span>
                <label className="switcher">
                  <input type="checkbox"
                         checked={isShowCollectionCenters}
                         onChange={(e) => {
                           const value = e.target.checked;
                           setIsShowCollectionCenters(value);
                           setLocalStorageBoolean(IS_SHOW_CONNECTION_CENTERS, value);
                         }}/>
                  <span className="button"/>
                </label>
              </li>
              <li>
                <span className="title">Orders</span>
                <label className="switcher">
                  <input type="checkbox"
                         checked={isShowOrders}
                         onChange={(e) => {
                           const value = e.target.checked;
                           setIsShowOrders(value);
                           setLocalStorageBoolean(IS_SHOW_ORDERS, value);
                         }}/>
                  <span className="button"/>
                </label>
              </li>
              <li>
                <span className="title">Riders</span>
                <label className="switcher">
                  <input type="checkbox"
                         checked={isShowRiders == true}
                         onChange={(e) => {
                           const value = e.target.checked;
                           setIsShowRiders(value);
                           setLocalStorageBoolean(IS_SHOW_RIDERS, value)
                         }}/>
                  <span className="button"/>
                </label>
              </li>
              <li>
                <span className="title">Boundary</span>
                <label className="switcher">
                  <input type="checkbox"
                         checked={isShowBoundary == true}
                         onChange={(e) => {
                           const value = e.target.checked;
                           setIsShowBoundary(value);
                           setLocalStorageBoolean(IS_SHOW_BOUNDARY, value)
                         }}/>
                  <span className="button"/>
                </label>
              </li>
              {regionDetail.isHyperlocalModeEnabled && <li>
                <span className="title">Cells</span>
                <label className="switcher">
                  <input type="checkbox"
                         checked={isShowCells == true}
                         onChange={(e) => {
                           const value = e.target.checked;
                           setIsShowCells(value);
                           setLocalStorageBoolean(IS_SHOW_CELLS, value)
                         }}/>
                  <span className="button"/>
                </label>
              </li>}
              {regionDetail?.blackListedAreas?.length > 0 && <li>
                <span className="title">BLA</span>
                <label className="switcher">
                  <input type="checkbox"
                         checked={isShowBlackListedAreas == true}
                         onChange={(e) => {
                           const value = e.target.checked;
                           setIsShowBlackListedAreas(value);
                           setLocalStorageBoolean(IS_SHOW_BLACK_LISTED_AREAS, value)
                         }}/>
                  <span className="button"/>
                </label>
              </li>}
            </ul>}
          </div>
        </div>

        <OrdersSummary stats={orderStats} vertical={VERTICALS.blood}/>
        <div className="graph-rider-panel">
          <StatsSummary stats={orderStats}/>
          <RidersSummary stats={riderStats}/>
        </div>
      </div>

      {!!isOpenCancelOrderModal &&
      <Modal isOpen={!!isOpenCancelOrderModal} onRequestClose={closeCancelOrderModal} className='small-modal'>
        <OrderCancel
          orderId={isOpenCancelOrderModal}
          orderCancel={orderCancel}
          closeModal={closeCancelOrderModal}
        />
      </Modal>
      }
    </div>

  )
};

BloodDashboard.propTypes = {
  getRiders: Proptypes.func.isRequired,
  getDashboardOrders: Proptypes.func.isRequired,
  getRiderStats: Proptypes.func.isRequired,
  getOrderStats: Proptypes.func.isRequired,
  selectRegionDetail: Proptypes.func.isRequired,
  riders: Proptypes.arrayOf(Proptypes.shape({})),
  orders: Proptypes.arrayOf(Proptypes.shape({})),
  regions: Proptypes.arrayOf(Proptypes.shape({})),
  regionDetail: Proptypes.shape({}),
  collectionCenters: Proptypes.arrayOf(Proptypes.shape({})),
};

export default BloodDashboard;
