import React, {useEffect, useState} from 'react';
import {Nav} from "../commons/nav";
import {debounce, truncate} from 'lodash';
import {
  ago,
  fullName,
  getLocalStorageBoolean,
  hmmaaa,
  parseQueryStringObjectToArray,
  qsParse,
  qsStringify, setLocalStorageBoolean
} from '../../utils/helper';
import {Formik, Field} from "formik";
import {Loader} from '../commons/loader';
import {
  IS_CUSTOMERS_FILTER_OPEN,
  VERTICALS
} from "../../utils/constants";
import refreshImage from "../../assets/images/refresh-white.png";
import {Filter} from "../commons/filter";
import {ROUTES} from "../../routes/constants";
import {Link} from "react-router-dom";

let initialFilter = {
  search: '',
  regions: [],
};

let filter = {
  ...initialFilter
};

const CustomersList = props => {
  const {
    getCustomers,
    customers,
    regions,
    regionDetail,
    customersCount,
    customersNext,
    getFilteredCustomers,
    history,
    resetCustomerState,
    updateCustomerParams,
    customersLoading,
    getAllRegions,
  } = props;

  const getFilterParamsFromProps = () => {
    let {location: {search: queryParams}} = history;
    queryParams = queryParams.replace('?', '');
    filter = {
      ...filter,
      ...qsParse(queryParams),
    };
    filter.regions = parseQueryStringObjectToArray(filter.regions);
  };

  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(getLocalStorageBoolean(IS_CUSTOMERS_FILTER_OPEN) === null ? false : getLocalStorageBoolean(IS_CUSTOMERS_FILTER_OPEN));

  useEffect(() => {
    resetCustomerState();
    getAllRegions({vertical: VERTICALS.COMMON});
    getFilterParamsFromProps();
    getFilteredCustomers(filter);
    setIsFilterChanged(!isFilterChanged);
    updateCustomerParams(filter);

    return () => {
      resetCustomerState();
      filter = {...initialFilter};
    };
  }, []);

  useEffect(() => {
    if (regionDetail?.id) {
      if (!filter.regions.length) {
        filter.regions = [regionDetail.id];
        applyFilter();
      }
    }
  }, [regionDetail]);

  const loadMore = () => {
    if (!customersLoading && customersNext) {
      const paramsObj = qsParse(customersNext.split('?')[1]);
      if (paramsObj.page) getCustomers({
        ...filter,
        page: paramsObj.page,
      });
    }
  };

  window.onscroll = debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      if (window.location.pathname === `${process.env.PUBLIC_URL}${ROUTES.CUSTOMER}`) {
        loadMore();
      }
    }
  }, 100);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
    setLocalStorageBoolean(IS_CUSTOMERS_FILTER_OPEN, !isFilterOpen);
  };

  const handleChangeSearch = event => {
    filter.search = event.target.value;
    if (!event.target.value.length) {
      setFilter();
      applyFilter();
    }
  };

  const handleOnKeyDownSearch = event => {
    filter.search = event.target.value;
    if (event.key === 'Enter') {
      setFilter();
      applyFilter();
    }
  };

  const handleRegionChangeFilter = (e, stateName, option) => {
    if (e.target.checked) {
      filter.regions = filter.regions.filter(n => n)
      filter.regions = [...new Set([...filter.regions, option.id])];
      if (filter.regions.length === regions.map(region => region.id).length) {
        filter.allRegions = true;
        filter.prevSelectedRegions = filter.regions;
      }
    } else {
      if (filter.regions.length === 1)
        return
      filter.regions = filter.regions.filter((id) => id != option.id);
      filter.prevSelectedRegions = filter.regions;
      filter.allRegions = false;
    }
    filter.rider = '';
    setFilter();
  }

  const handleAllRegionChangeFilter = (e) => {
    if (e.target.checked) {
      filter.allRegions = true;
      filter.regions = filter.regions.filter(n => n)
      filter.prevSelectedRegions = filter.regions;
      filter.regions = regions.map(region => region.id);
    } else {
      filter.prevSelectedRegions = parseQueryStringObjectToArray(filter.prevSelectedRegions);
      filter.regions = filter.regions.filter(n => n)
      filter.regions = filter.prevSelectedRegions ? filter.prevSelectedRegions : [regionDetail.id]
      filter.allRegions = filter.regions.length === regions.map(region => region.id).length;
    }
    setFilter();
  }

  const setFilter = () => {
    setIsFilterChanged(!isFilterChanged);
    updateCustomerParams(filter);
  };

  const applyFilter = () => {
    history.replace({
      pathname: ROUTES.CUSTOMER,
      search: qsStringify(filter)
    });
  }

  return <div id="wrapper" className={`inner-page ${isFilterOpen ? 'filter-active' : ''}`}>
    <Nav/>
    <div className="listing-page">
      <div className="listing-head">
        <h2>Customer <span className="small">- {customersCount} results</span></h2>
        <Link to={`${ROUTES.CUSTOMER}/create`} className="order-btn">New Customer</Link>
      </div>
      <div className="list-search-area">
        <div className="id-search-form">
          <Formik initialValues={{search: filter.search}} enableReinitialize={true}>
            {props => (
              <form onSubmit={props.handleSubmit}>
                <Field type="search" placeholder="e.g. Customer Name or Phone"
                       name="search"
                       onChange={(e) => {
                         props.setFieldValue('search', e.target.value);
                         handleChangeSearch(e)
                       }}
                       onKeyDown={(e) => handleOnKeyDownSearch(e)}
                />
                <button type="submit" className="search-btn"><i className="icon-magnify"/></button>
              </form>
            )}
          </Formik>
        </div>
        <a onClick={() => {
          toggleFilter();
        }} className="filter-btn icon-filter"/>
      </div>
      <table className="list-table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Number of orders</th>
          <th>Last order on</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {customers.map(customer => {
          return <tr key={customer.id} onClick={() => history.push(`/customers/detail/${customer.id}`)}>
            <td>{truncate(fullName(customer), {length: 30})} <span className="sm-text">{customer.phone}</span></td>
            <td>{customer.numberOfOrders}</td>
            {
              customer.lastOrder ?
                <td>
                  {hmmaaa(customer.lastOrder.created)}
                  <span className="sm-text">{ago(customer.lastOrder.created)}</span>
                </td> : <td></td>
            }
            <td>
              <a href='' onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`/customers/detail/${customer.id}`);
              }} className="action icon-edit"/>
            </td>
          </tr>
        })}
        </tbody>
      </table>
      {customersLoading && <Loader/>}
      {
        !customersLoading && customersNext &&
        <button className={`load-more`} onClick={!customersLoading && loadMore}><img src={refreshImage}/>Load More
        </button>
      }
    </div>
    <Filter
      loading={customersLoading}
      onClose={toggleFilter}
      isFilterOpen={isFilterOpen}
      isFilterUpdated={isFilterChanged}
      applyFilter={applyFilter}
      regionFilter={{
        filterName: 'Regions',
        filterValues: regions.map(r => ({id: r.id, value: r.name})),
        filterStateName: 'regions',
        onChange: handleRegionChangeFilter,
        selectedValues: filter.regions.map(sv => Number(sv)),
        checkedType: 'id',
        customFilter: {
          value: 'All Regions',
          onChange: handleAllRegionChangeFilter,
          selectedValues: filter.allRegions
        }
      }}
    />
  </div>
};
export default CustomersList;
