import {VERTICALS} from "../utils/constants";

export const INITIAL_STATE = {
  auth: {
    user: {},
    token: null,
    npStaffUsers: [],
  },
  rider: {
    loading: false,
    count: 0,
    next: null,
    list: [],
    npRiders: [],
    ridersSearch: [],
    onlineList: [],
    assignableRider: {
      availableRiders: [],
      deliveringRiders: [],
      outOfCapacityRiders: [],
      lateRiders: [],
      outOfRegionRiders: [],
      offDutyRiders: [],
    },
    riderDetail: {},
    stats: {},
    refreshTime: {},
    riderTasks: [],
    order: {
      next: null,
      list: [],
    },
  },
  order: {
    loading: false,
    count: 0,
    next: null,
    list: [],
    states: [],
    orderDetail: {},
    orderActivities: [],
    scanTypes: [],
    stats: {},
    hearAboutUsList: [],
    problemStrings: [],
    cancellationStrings: [],
    orderTimeSlots: [],
    copiedOrder: {},
    params: '',
    lateOrders: [],
    onTimeRate: [],
    externalOrderParams: '',
    orderSources: [],
    reassignmentStrings: [],
    addressTypes: [],
    rescheduleStrings: [],
  },
  customer: {
    loading: false,
    count: 0,
    next: null,
    list: [],
    customerDetail: {
      gender: '',
      addresses: [],
      patients: [],
      orderHistory: [],
      lastOrder: {},
    },
    customerAddress: [],
    ids: [],
    order: {
      next: null,
      list: [],
    },
  },
  location: {
    cities: {
      list: [],
      cityDetail: {
        [VERTICALS.COMMON]: {},
        [VERTICALS.BLOOD]: {},
        [VERTICALS.XRAY]: {},
      }
    },
    collectionCenters: {
      list: [],
    },
    regions: {
      list: [],
      regionDetail: {
        [VERTICALS.COMMON]: {},
        [VERTICALS.BLOOD]: {},
        [VERTICALS.XRAY]: {},
      }
    },
    hotSpots: {
      list: [],
    },
    liveLocation: {},
  },
  feedback: {
    loading: false,
    orderReview: {},
    physicalVisitFeedbackQuestions: [],
    croFeedbackQuestions: [],
    isReviewComplete: false,
    isExpired: false,
    isSatisfied: false,
    isInteractionIdValid: true,
    showGoogleMapReviewLink: false
  },
  shift: {
    loading: false,
    list: [],
    shiftDetail: {},
    shiftRiders: [],
  },
  attendance: {
    loading: false,
    count: 0,
    next: null,
    list: [],
    attendanceShifts: [],
    riderShiftAttendance: {},
  },
};
